import { http } from 'helpers'
import { CardDetails, Fulfillments } from 'types'
import type { WithQuoteId } from 'store/types'

const getCard = ({ quoteId }: WithQuoteId) => http.get<CardDetails>(`quotes/${quoteId}/card`)

const getFulfillments = (quoteId: string) =>
  http.get<Fulfillments>(`quotes/${quoteId}/fulfillments`)

export const api = {
  getCard,
  getFulfillments,
}
