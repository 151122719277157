import { Button, Input, Text } from '@ubnt/ui-components'
import { GoogleAddressAutoComplete } from 'components'
import { FC, KeyboardEventHandler, useEffect, useState } from 'react'
import { useActions } from 'store'
import type { Location } from 'types'
import { ValidationError } from 'yup'
import { initShipping, schema } from './constants'
import {
  AddressWrapper,
  ButtonWrapper,
  Form,
  InputsContainer,
  InputWrapper,
  RowContainer,
} from './styles'
import type { ShippingFormProps, UpdateShipping } from './types'

export const ShippingForm: FC<ShippingFormProps> = ({
  children,
  handleError,
  onChange,
  onEnter,
  shipping,
  validateOnMount = false,
}) => {
  const [googleInput, setGoogleInput] = useState('')
  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    location: false,
  })

  const {
    modal: { setModal },
  } = useActions()

  useEffect(() => {
    setGoogleInput(shipping.location.formatted)
    handleError && handleError(!schema.isValidSync(shipping))
  }, [shipping])

  useEffect(() => {
    validateOnMount &&
      schema.validate(shipping, { abortEarly: false }).catch((error) => {
        if (error instanceof ValidationError) {
          error.inner.forEach(({ path, message }) => {
            if (path != null) {
              if (path.includes('location')) {
                return setErrors((prev) => ({ ...prev, location: true }))
              }
              setErrors((prev) => ({ ...prev, [path]: message }))
            }
          })
        }
      })
  }, [])

  const handleEnterKey: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === 'Enter' && onEnter) onEnter()
  }

  const handleChange = (key: string, value: string) => {
    schema
      .validateAt(key, { [key]: value })
      .then(() => setErrors((prev) => ({ ...prev, [key]: '' })))
      .catch((x) => setErrors((prev) => ({ ...prev, [key]: x.errors[0] })))
    onChange(key, value)
  }

  const handleLocationChange = (location: Location) => {
    schema
      .validateAt('location', { location })
      .then(() => setErrors((prev) => ({ ...prev, location: false })))
      .catch(() => setErrors((prev) => ({ ...prev, location: true })))
    onChange('location', location)
  }

  const handleEditAddress = () => {
    setModal({
      modal: 'address',
      props: {
        location: shipping.location,
        updateLocation: handleLocationChange,
      },
    })
  }

  return (
    <Form>
      <InputsContainer>
        <InputWrapper error={!!errors.firstName}>
          <RowContainer>
            <Input
              label='First Name'
              name='firstName'
              autoComplete='off'
              value={shipping.firstName}
              onChange={({ target: { name, value } }) => {
                handleChange(name, value)
              }}
              invalid={errors.firstName}
              invalidProps={{ size: 'caption' }}
              onKeyDown={handleEnterKey}
              size='caption'
              width='48%'
            />

            <Input
              label='Last Name'
              name='lastName'
              autoComplete='off'
              value={shipping.lastName}
              onChange={({ target: { name, value } }) => {
                handleChange(name, value)
              }}
              invalid={errors.lastName}
              invalidProps={{ size: 'caption' }}
              onKeyDown={handleEnterKey}
              size='caption'
              width='48%'
            />
          </RowContainer>
        </InputWrapper>
        <InputWrapper>
          <Input
            label='Phone'
            name='phone'
            autoComplete='chrome-off'
            value={shipping.phone}
            onChange={({ target: { name, value } }) => {
              handleChange(name, value)
            }}
            invalid={errors.phone}
            invalidProps={{ size: 'caption' }}
            onKeyDown={handleEnterKey}
            size='caption'
            width='96%'
          />
        </InputWrapper>
        <InputWrapper>
          <AddressWrapper>
            <GoogleAddressAutoComplete
              name='location'
              label='Address'
              handleChange={(l: Location) => {
                handleLocationChange(l)
                setGoogleInput(l.formatted)
              }}
              value={googleInput}
              setValue={setGoogleInput}
              width='96%'
            />
            {errors.location && (
              <Text color='danger'>Address incomplete. Click &quot;Edit&quot; below to update</Text>
            )}
            {shipping.location && shipping.location?.formatted && (
              <ButtonWrapper>
                <Button type='button' size='inherit' variant='inline' onClick={handleEditAddress}>
                  Edit
                </Button>
              </ButtonWrapper>
            )}
          </AddressWrapper>
        </InputWrapper>
        {children && <InputWrapper>{children}</InputWrapper>}
      </InputsContainer>
    </Form>
  )
}

export type { UpdateShipping }
export { initShipping }
