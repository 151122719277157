import { Tooltip } from '@ubnt/ui-components/Tooltip'
import { TooltipProps } from '@ubnt/ui-components/Tooltip/Tooltip'
import React, { FC } from 'react'

interface Props extends TooltipProps {
  show: boolean
}

export const ConditionalTooltip: FC<Props> = ({ children, show, ...props }) => {
  if (!show) return <div style={{ width: '100%' }}>{children}</div>
  return <Tooltip {...props}>{children}</Tooltip>
}
