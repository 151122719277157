import type { Dispatch, SetStateAction } from 'react'
import type { FormStepProps as BaseProps } from 'components/OnboardingForm/types'

export interface FormStepProps extends BaseProps {
  setViewMap: Dispatch<SetStateAction<Map<Views, boolean>>>
  viewMap: Map<Views, boolean>
}

export enum Views {
  OVERVIEW = 1,
  START = 2,
  WIFI = 3,
  CAMERAS = 4,
  VOIP = 5,
  DOORS = 6,
  USER = 7,
}
