import { Navigate, useLocation } from 'react-router-dom'

import { useOverState } from 'store'

import type { FC } from 'react'

export interface PrivateRouteProps {
  sendOrigin?: boolean
}

export const PrivateRoute: FC<PrivateRouteProps> = ({ sendOrigin, children }) => {
  const { user } = useOverState().app
  const { pathname } = useLocation()

  const query = sendOrigin ? `?origin=${pathname}` : ''

  if (!user) return <Navigate replace to={`/login${query}`} />
  return <>{children}</>
}
