import { QuoteIcon } from '@ubnt/icons'
import { EmptyResult } from '@ubnt/ui-components'

interface Props {
  category: 'Quote' | 'Order'
}

export const EmptyComponent = ({ category }: Props) => (
  <EmptyResult
    className='empty--result'
    description={`${category} information will be shown here as they are generated.`}
    icon={<QuoteIcon />}
    size='large'
    title={`No ${category}s Yet`}
  />
)
