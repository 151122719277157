import { CardCvcElement } from '@stripe/react-stripe-js'

import { useActions, useOverState } from 'store'
import { CardLast4 } from 'components'
import { Label } from '../styles'

import type { FC } from 'react'

export const SavedCard: FC = () => {
  const { payment } = useOverState()
  const actions = useActions()

  return (
    <>
      <CardLast4 brand={payment.savedCard?.brand} number={payment.savedCard?.number} />
      <Label area={undefined}>
        <CardCvcElement onChange={actions.payment.setCompleteCvC} />
      </Label>
    </>
  )
}
