import { useEffect, VFC } from 'react'

import { useActions } from 'store'

import type { Pages } from 'shared'

interface Props {
  to: Pages
}

export const ChangePage: VFC<Props> = ({ to }) => {
  const { app } = useActions()

  useEffect(() => {
    app.updatePage(to)
  }, [to])

  return null
}
