import {
  adjust,
  always,
  anyPass,
  cond,
  endsWith,
  identity,
  ifElse,
  isEmpty,
  isNil,
  join,
  lt,
  map,
  pipe,
  replace,
  splitAt,
  T,
  take,
  when,
  zipObj,
} from 'ramda'

import { fromThisMonth, fromYearStart, isValidDate } from 'helpers'
import { DATE_KEYS, REGEX } from './constants'

import type { ExpiryDate } from 'types'

const addSlash = pipe(splitAt(2), join(' / '))

const addStartZero = pipe<[string], string, string>((x: string) => `0${x}`, take(4))

const firstIsGreaterThanOne = pipe<[string], string, number, boolean>(take(1), Number, lt(1))
const firstIsGreaterThanTwelve = pipe<[string], string, number, boolean>(take(2), Number, lt(12))

const makeYear = (num: number | null) => {
  if (isNil(num) || num < 10) return null

  const currentYear = fromYearStart({}).getFullYear()
  const currentLast2 = Number(currentYear.toString().slice(-2))
  const diff = num - currentLast2

  return diff < -50
    ? currentYear + diff + 100
    : diff <= 50
    ? currentYear + diff
    : currentYear + diff - 100
}

const removeSlash: (x: string | number) => string = pipe(
  String,
  when(endsWith('/'), replace(REGEX.SLASH_END, '')),
)

// Exported functions

export const isValidValue = (value: ExpiryDate) => {
  if (isNil(value.month) || isNil(value.year)) return false

  const date = `${value.year.toString().padStart(4, '0')}-${value.month}-28`
  return isValidDate(date) && new Date(date) >= fromThisMonth()
}

export const parseState = (state: string) => (state.length < 2 ? state : addSlash(state))

export const stateToValue: (x: string) => ExpiryDate = pipe(
  splitAt(2),
  map(ifElse(isEmpty, always(null), Number)),
  adjust(1, makeYear),
  zipObj(DATE_KEYS),
)

export const valueToState: (x: string | number) => string = pipe(
  removeSlash,
  replace(REGEX.NO_NUMBER, ''),
  cond<[string], string>([
    [(str: string) => str.length > 4, take(4)],
    [anyPass([firstIsGreaterThanOne, firstIsGreaterThanTwelve]), addStartZero],
    [T, identity],
  ]),
)

export const isValidState = anyPass([isEmpty, pipe(stateToValue, isValidValue)])
