import { Route, Routes, useParams } from 'react-router-dom'
import { Tab, Tabs as UITabs } from '@ubnt/ui-components'
import styled from 'styled-components'

import { AccordionWrapper } from './AccordionWrapper'
import { BackNavigate, ChangePage } from 'components'
import { FAQ, TABS } from './constants'
import { Pages } from 'shared'
import { useBackNavigate } from 'hooks'

import type { VFC } from 'react'

export const Faq: VFC = () => {
  const { tab } = useParams<{ tab: string }>()
  const navigate = useBackNavigate()

  const handleTabSelection = (selectedTab: Tab) => navigate(`/help/faq/${selectedTab.id}`)

  return (
    <Container>
      <Tabs tabs={TABS} tabClassName='tabs' onSelectTab={handleTabSelection} selectedTab={tab} />

      <Routes>
        <Route path='customer-portal' element={<AccordionWrapper items={FAQ.customerPortal} />} />
        <Route
          path='general-questions'
          element={<AccordionWrapper items={FAQ.generalQuestions} />}
        />
        <Route path='order-questions' element={<AccordionWrapper items={FAQ.orderQuestions} />} />
        <Route path='*' element={<BackNavigate replace to='/help/faq/customer-portal' />} />
      </Routes>

      <ChangePage to={Pages.FAQ} />
    </Container>
  )
}

const Container = styled.div`
  padding: 5px 33px;
  width: 100%;
`

const Tabs = styled(UITabs)`
  margin-bottom: 20px;
  justify-content: start;
  div {
    margin: 0;
  }

  .tabs:focus {
    outline: none;
  }
`
