import { FC, Fragment } from 'react'
import { Text } from '@ubnt/ui-components/Text'
import { Button } from '@ubnt/ui-components/Button'

import { parseText } from './helpers'

import type { BoxTextProps, PSContract } from './types'

export const BoxText: FC<BoxTextProps> = ({ data, map }) => {
  const [before, after] = data.acceptance_language.split('{{contracts}}')

  const renderLink = ({ key, title }: PSContract, idx: number, arr: PSContract[]) => (
    <Fragment key={key}>
      <Button<'a'>
        component='a'
        href={`${data.legal_center_url}?g=${data.group}#${key}`}
        target='_blank'
        variant='inline'
      >
        {(map && map[key]) ?? title}
      </Button>
      {idx === arr.length - 2 && <>&nbsp;and&nbsp;</>}
      {idx < arr.length - 2 && <>,&nbsp;</>}
    </Fragment>
  )

  if (!data.acceptance_language.includes('{{contracts}}'))
    return <Text size='label'>{parseText(before)}</Text>

  return (
    <Text size='label'>
      {parseText(before)}&nbsp;
      {Object.values(data.contract_data).map(renderLink)}
      {parseText(after)}
    </Text>
  )
}
