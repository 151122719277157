import { useOverState } from 'store'
import { StepProgress } from 'components'
import { makeQuoteStep, makeSteps } from './helpers'

import type { FC } from 'react'
import type { StepsProps } from './types'

export const Steps: FC<StepsProps> = ({ quote }) => {
  const { quotes } = useOverState()
  const opts = {
    ...quotes.current,
    checkout: quotes.checkout,
    quote,
  }
  const steps = makeSteps(opts)

  return <StepProgress current={makeQuoteStep(opts)} steps={steps} />
}
