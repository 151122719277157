import { useScreenWidth, Screen } from 'hooks'
import { ImgWrapper } from './styles'

import type { FC } from 'react'
import type { FormAsideProps } from './types'

export const FormAside: FC<FormAsideProps> = ({ children, className }) => {
  const screenWidth = useScreenWidth()

  return (
    <>{screenWidth > Screen.MOBILE && <ImgWrapper className={className}>{children}</ImgWrapper>}</>
  )
}
