import { defaultTo, ifElse, isNil, pipe, replace, test } from 'ramda'

const MAX_SCALE = 'maximum-scale=1.0'

const regEx = {
  hasMaximumScale: /maximum-scale=[0-9.]+/g,
  isIos: /iPad|iPhone|iPod/,
}

const makeContent = pipe<[Element], string | null, string, string>(
  (tag) => tag.getAttribute('content'),
  defaultTo(''),
  ifElse(
    test(regEx.hasMaximumScale),
    replace(regEx.hasMaximumScale, MAX_SCALE),
    (x) => `${x}, ${MAX_SCALE}`,
  ),
)

const setMaximumScale = () => {
  const meta = document.querySelector('meta[name=viewport]')
  if (isNil(meta)) return

  meta.setAttribute('content', makeContent(meta))
}

export const checkIsIos = () => regEx.isIos.test(navigator.userAgent) && !window.MSStream

export const disableIosFormZoom = () => {
  if (checkIsIos()) {
    setMaximumScale()
  }
}
