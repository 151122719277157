import type { Context } from 'store'
import type {
  CreateProOptions,
  LoadingSection,
  WithAddress,
  WithExpiryDate,
  WithPaymentRequest,
  WithSecret,
} from './types'
import type { WithPaymentType } from 'types'
import type { WithQuote } from 'store/quotes/types'

export const createPro = <A extends CreateProOptions>(
  { effects }: Context,
  value: A,
): Promise<A & WithSecret> =>
  effects.payment.api
    .createPro(value) //
    .then(({ method, secret, type }) => ({
      ...value,
      method,
      secret,
      type,
    }))

export const getCard = ({ effects, state }: Context, value: WithPaymentType) =>
  effects.payment.api
    .getCard(value) //
    .then((data) => {
      if (!data.id) return
      state.payment.savedCard = data
      state.payment.useSaved = true
    })

export const runRequest = (
  _: Context,
  { method, request, secret, type }: WithPaymentRequest & WithSecret,
) => request(secret, type, method ?? undefined)

export const setLoadingSection =
  <A>(section: LoadingSection) =>
  ({ state }: Context, value: A): A => {
    state.payment.loadingSection = section
    return value
  }

export const setShippingFromQuote = ({ state }: Context, { quote }: WithQuote) => {
  state.payment.shipping.address = quote.shipTo.details
}

export const uncompleteShipping = ({ state }: Context) => {
  state.payment.complete.shipping = false
}

export const unsetFinished = ({ state }: Context) => {
  state.payment.checkout.finished = false
}

export const updateCard = ({ effects }: Context, value: WithAddress & WithExpiryDate) =>
  effects.payment.api.updateCard(value)
