import { FC, useCallback, useState } from 'react'

interface VideoProps {
  autoPlay?: boolean
  className?: string
  loop?: boolean
  muted?: boolean
  sources: string[]
}

export const Video: FC<VideoProps> = ({
  autoPlay = true,
  className = '',
  loop = false,
  muted = true,
  sources,
}) => {
  const [current, setCurrent] = useState(0)

  const next = useCallback(() => {
    setCurrent((prev) => (prev >= sources.length - 1 ? 0 : prev + 1))
  }, [setCurrent])

  return (
    <video
      autoPlay={autoPlay}
      className={className}
      loop={loop}
      muted={muted}
      src={sources[current]}
      onEnded={next}
    />
  )
}
