import { ChangeEvent, FormEvent, useCallback, useContext } from 'react'
import { useForm, Controller } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Text } from '@ubnt/ui-components/Text'
import { lensPath, set } from 'ramda'

import { Footer, FormSection, Note, ProjectFormContext } from 'components'
import { Checkbox, CheckboxImg, CheckboxImgContainer, Form, NumberSelector } from './styles'

import DoorImg from 'assets/png/DoorCheckbox.png'

import type { FormStepProps } from './types'
import type { BuildingDoors, NewProject, UpdateProject } from 'store/projects/types'

const schema = yup.object().shape({
  qty: yup.number().min(0).required(),
})

export const DoorForm = <A extends NewProject | UpdateProject>({
  handleNext,
  handlePrev,
  isLoading = false,
  updateProject,
  project: { doors },
}: FormStepProps<A>) => {
  const formContext = useContext(ProjectFormContext)
  const {
    control,
    handleSubmit,

    formState: { errors },
  } = useForm<BuildingDoors>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  })

  const nextIsDisabled = !schema.isValidSync(doors)
  const isReadOnly = !formContext.editable || isLoading

  const onSubmit = (event: FormEvent<HTMLFormElement> | MouseEvent) => {
    event.preventDefault()
    if (isReadOnly) return handleNext(event)

    return handleSubmit(() => handleNext(event))()
  }

  const handleChangeNote = (_: any, value: string) => {
    if (isReadOnly) return

    return updateProject(set(lensPath(['doors', 'note']), value))
  }

  const handleChangeQty = useCallback(
    (changeFn: (...event: any[]) => void) => (event: ChangeEvent<HTMLInputElement>) => {
      if (isReadOnly) return

      const value = Number(event.target.value)
      if (Number.isNaN(value) || value < 0) return
      changeFn(value)
      return updateProject(set(lensPath(['doors', 'qty']), value))
    },
    [updateProject],
  )

  return (
    <Form className={isLoading ? 'transparent' : undefined} onSubmit={onSubmit}>
      <FormSection main>
        <Text size='body'>Number of Entry/Exit Points *</Text>
        <Checkbox
          id='doors'
          checked={doors.qty > 0}
          full
          location={formContext.location}
          pointer='default'
          readOnly
          type='big'
        >
          <CheckboxImgContainer checked={doors.qty > 0} location={formContext.location}>
            <CheckboxImg src={DoorImg} alt='' location={formContext.location} />
          </CheckboxImgContainer>
          <Text size='caption'>Entry/Exit Points</Text>
          <Controller
            control={control}
            defaultValue={doors.qty}
            name='qty'
            render={({ field: { onChange, onBlur } }) => (
              <NumberSelector
                fixedWidth={25}
                integer
                invalid={errors.qty !== undefined}
                min={0}
                name='qty'
                onBlur={onBlur}
                onChange={handleChangeQty(onChange)}
                readOnly={isReadOnly}
                value={doors.qty}
              />
            )}
          />
        </Checkbox>
        {errors.qty && (
          <Text size='caption' color='danger'>
            {errors.qty?.message}
          </Text>
        )}
        <Note editable={formContext.editable} onChange={handleChangeNote} value={doors.note} />
      </FormSection>
      <Footer isLoading={isLoading} nextDisabled={nextIsDisabled} onPrev={handlePrev} />
    </Form>
  )
}
