import { clamp } from 'ramda'
import type { ComponentProps, FC, MouseEvent, ReactNode } from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components/macro'
import { StarIcon } from './StarIcon'

const HeightMap = {
  small: '12px',
  medium: '16px',
  large: '24px',
}

export type Props = {
  className?: string
  readonly?: boolean
  width?: string
  height?: string
  size?: 'small' | 'medium' | 'large'
  placeholder?: ReactNode
  Icon?: FC<ComponentProps<typeof StarIcon>>
  count?: number
  value?: number
  onHover?: (isHovered: boolean) => void
  onRatingHover?: (value: number) => void
  onChange?: (value: number) => void
}

const StyledStarIcon = styled(StarIcon)<{ actionable?: boolean }>`
  ${(props) =>
    props.actionable &&
    `
    && {
      &:hover {
        color: ${props.theme.colors.primary};
      }
    }`}
`

const getBoundValue = clamp(0, 1)

export const Rating = ({
  className,
  readonly = false,
  width = '100%',
  height = '100%',
  size,
  placeholder,
  Icon = StyledStarIcon,
  count = 5,
  value = 0,
  onHover = () => null,
  onRatingHover = () => null,
  onChange = () => null,
}: Props) => {
  const [isHovered, setIsHovered] = useState(false)
  const [hoveredRating, setHoveredRating] = useState(0.0)

  const boundRating = getBoundValue(value)
  const [rating, setRating] = useState(boundRating)

  useEffect(() => {
    setRating(getBoundValue(value))
  }, [value])

  const isInitialRating = useRef(true)
  useEffect(() => {
    // Skip change triggering on initial value.
    if (isInitialRating.current) {
      isInitialRating.current = false

      return
    }

    if (!readonly) {
      onChange(rating)
    }
  }, [readonly, rating, onChange])

  const onMouseEnter = useCallback(() => {
    if (!readonly) {
      setIsHovered(true)
      onHover(true)
    }
  }, [readonly, onHover])

  const onMouseLeave = useCallback(() => {
    if (!readonly) {
      setIsHovered(false)
      onHover(false)
    }
  }, [readonly, onHover])

  const onRatingMouseLeave = useCallback(() => {
    if (!readonly) {
      setHoveredRating(0)
      onRatingHover(0)
    }
  }, [readonly, onRatingHover])

  const onRatingClick = useCallback(
    (e: MouseEvent<SVGElement>) => {
      if (!readonly) {
        const newValue = parseFloat(e.currentTarget.dataset.value || '0')

        setRating((oldValue) => {
          const reset = newValue.toFixed(2) === oldValue.toFixed(2)

          return reset ? 0 : newValue
        })
      }
    },
    [readonly],
  )

  const onRatingMouseEnter = useCallback(
    (e: MouseEvent<SVGElement>) => {
      if (!readonly) {
        const newValue = parseFloat(e.currentTarget.dataset.value || '0.0')

        setHoveredRating(newValue)
        onRatingHover(newValue)
      }
    },
    [readonly, onRatingHover],
  )

  const isRatingVisible = !placeholder || isHovered

  return (
    <RatingStyled
      className={className}
      width={width}
      height={(size && HeightMap[size]) || height}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {placeholder && (
        <PlaceholderWrapper visible={!isRatingVisible}>{placeholder}</PlaceholderWrapper>
      )}
      <IconsWrapper visible={isRatingVisible}>
        <IconsStyled actionable={!readonly} onMouseLeave={onRatingMouseLeave}>
          {Array(count)
            .fill(0)
            .map((_, i) => {
              const val = (i + 1) / count
              const isActive =
                readonly || hoveredRating === 0 ? val <= rating : val <= hoveredRating

              return (
                <Icon
                  style={size ? {} : { flex: 1, width: 'auto', height: 'auto' }}
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  actionable={!readonly}
                  size={size}
                  data-value={val}
                  isActive={isActive}
                  onClick={onRatingClick}
                  onMouseEnter={onRatingMouseEnter}
                />
              )
            })}
        </IconsStyled>
      </IconsWrapper>
    </RatingStyled>
  )
}

const RatingStyled = styled.div.withConfig<{ width?: string; height?: string }>({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['height', 'width'].includes(prop) && defaultValidatorFn(prop),
})`
  position: relative;

  width: ${({ width }) => width};
  height: ${({ height }) => height};

  display: flex;
  align-items: center;
`

// Using opacity as ui-components <Table /> otherwise changes cell width (look into this).
// This probably unnecessarily triggers onHover (instead of just onRatingHover).
const PlaceholderWrapper = styled.div<{ visible: boolean }>`
  // display: ${({ visible }) => (visible ? 'block' : 'none')};
  opacity: ${({ visible }) => (visible ? 1 : 0)};
`

const IconsWrapper = styled.div<{ visible: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
`

const IconsStyled = styled.div<{ actionable?: boolean }>`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: space-around;

  cursor: ${({ actionable }) => (actionable ? 'pointer' : 'default')};
`
