import type { FC } from 'react'
import type { Hardware, Quote } from 'store/quotes/types'
import styled from 'styled-components'
import { MEDIA } from 'styles/constants'
import QuoteCheckout from './QuoteCheckout'
import Services from './Services'

interface Props {
  quote: Quote
  outOfStock: boolean
  handleContactPro: () => void
  hardware: Hardware
}

const SidePanel: FC<Props> = ({ quote, outOfStock, handleContactPro, hardware }) => {
  return (
    <Container>
      <QuoteCheckout handleContactPro={handleContactPro} outOfStock={outOfStock} quote={quote} />
      <FillerDiv />
      <Services hardware={hardware} />
    </Container>
  )
}

export default SidePanel

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media ${MEDIA.TABLET} {
    width: 100%;
  }
`

const FillerDiv = styled.div`
  height: 20px;
  background-color: ${({ theme }) => `${theme.colors.Light.color.neutral.n3}`};
  z-index: 20;
`
