import { FC, memo, MouseEventHandler } from 'react'
import { Text } from '@ubnt/ui-components/Text'
import { isEmpty, pipe, prop } from 'ramda'

import {
  Card,
  CardBody,
  CardContainer,
  Container,
  DropdownWrapper,
  ProjectName,
  ProjectPicker,
} from './styles'
import { Loader } from 'components'
import { toLocaleCurrency } from 'helpers'
import { AsyncFuncState } from 'shared'

import { ReactComponent as DocumentIcon } from 'assets/icons/Document.svg'

import type { QuoteListItem } from 'store/quotes/types'
import type { CardViewProps } from './types'
import type { DropdownOption } from '@ubnt/ui-components/Dropdown'

const makeCard =
  (
    onClick: (x: QuoteListItem) => void,
    renderFooter: CardViewProps['renderFooter'],
    renderStatus: CardViewProps['renderStatus'],
  ) =>
  (quote: QuoteListItem) =>
    (
      <Card
        key={quote.id}
        left={<DocumentIcon />}
        onClick={() => onClick(quote)}
        right={renderStatus(quote)}
        title={quote.quoteName}
      >
        <CardBody>
          <Text size='header-xs' weight='bold'>
            {toLocaleCurrency(quote.total)}
          </Text>
          <Text size='caption'>{renderFooter(quote)}</Text>
        </CardBody>
      </Card>
    )

export const QuoteCardView: FC<CardViewProps> = memo(
  ({
    onClickProject,
    onClickQuote,
    onSelectProject,
    projects,
    quotes,
    renderFooter,
    renderStatus,
    status,
  }) => {
    const options = projects.list.map((x) => ({ label: x.name, value: x.id }))
    const handleChange: (x: DropdownOption) => void = pipe(prop('value'), String, onSelectProject)
    const projectName = projects.hash[projects.current as string]?.name

    const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
      event.preventDefault()
      event.stopPropagation()
      if (projects.current && onClickProject) onClickProject(projects.current)
    }

    return (
      <Container>
        <DropdownWrapper>
          <ProjectName onClick={handleClick}>
            <Text size='body' truncate>
              {projectName}
            </Text>
          </ProjectName>
          <ProjectPicker
            inputClassName='input'
            onChange={handleChange}
            options={options}
            value={projects.current ?? ''}
            width='100%'
          />
        </DropdownWrapper>
        <Loader status={status} cond={isEmpty(quotes)}>
          <CardContainer className={status === AsyncFuncState.LOADING ? 'transparent' : undefined}>
            {quotes.map(makeCard(onClickQuote, renderFooter, renderStatus))}
          </CardContainer>
        </Loader>
      </Container>
    )
  },
)
