import styled, { css } from 'styled-components'
import { Modal as BaseModal, Text } from '@ubnt/ui-components'
import { CheckmarkIconL, CloseWithCircleIconL } from '@ubnt/icons'

const icon = css`
  margin-top: 16px;
  margin-bottom: 20px;
  height: 40px;
  width: 40px;
`

export const CheckIcon = styled(CheckmarkIconL)`
  color: ${({ theme }) => theme.colors.Light.color.green.g6};
  ${icon}
`

export const FailIcon = styled(CloseWithCircleIconL)`
  color: ${({ theme }) => theme.colors.Light.color.red.r6};
  ${icon}
`

export const Modal = styled(BaseModal)`
  > .order-placed__footer > div {
    flex-grow: 1;
  }

  > .order-placed__footer > div > div {
    flex-grow: 1;
  }
`

export const Message = styled(Text)`
  text-align: center;
  width: 246px;
`
