import { Text, TextProps } from '@ubnt/ui-components/Text'

import { FlexColumn } from 'components'
import { toLocaleCurrency } from 'helpers'

import type { FC } from 'react'
import type { PriceProps } from './types'

export const Price: FC<PriceProps> = ({ price, qty, size = 'medium' }) => {
  const [normal, small]: TextProps['size'][] =
    size === 'medium' ? ['body', 'caption'] : ['caption', 'label']

  return (
    <FlexColumn alignItems='flex-end'>
      <Text size={normal} weight='bold'>
        {toLocaleCurrency(price)}
      </Text>
      {qty && <Text size={small}>x {qty}</Text>}
    </FlexColumn>
  )
}
