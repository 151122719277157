import { FC, Fragment, useMemo } from 'react'
import { Accordion, Text, Tooltip } from '@ubnt/ui-components'
import { InfoIcon } from '@ubnt/icons'
import styled from 'styled-components'
import { sum } from 'ramda'

import { AccordionLabel } from 'components'
import { MEDIA } from 'styles/constants'
import AccordionContent from './AccordionContent'
import { CategoryData, CategoryOrder } from './constants'

import type { Hardware } from 'store/quotes/types'

const tooltipText =
  'This quote is for UniFi hardware only and does not include the cost of other services or products your Professional may provide.'

interface Props {
  hardware: Hardware
}

const Services: FC<Props> = ({ hardware }) => {
  const items = useMemo(() => {
    const newItems = {
      'UniFi Base System': [
        ...hardware.Routing,
        ...hardware.Switching,
        ...hardware.Cabling,
        ...hardware.Other,
      ],
      Wireless: [...hardware.Wireless],
      Talk: [...hardware.Talk],
      Access: [...hardware.Access],
      Protect: [...hardware.Protect],
    }
    return Object.entries(newItems).sort(
      (a, b) =>
        CategoryOrder[a[0] as keyof typeof CategoryOrder] -
        CategoryOrder[b[0] as keyof typeof CategoryOrder],
    )
  }, [hardware])

  return (
    <Fragment>
      <HeaderWrapper>
        <Text style={{ marginRight: '5px' }} size='body'>
          Hardware
        </Text>
        <Tooltip description={tooltipText} position='bottom' width={180}>
          <InfoIcon isActive />
        </Tooltip>
      </HeaderWrapper>
      <List>
        <Accordion
          multiOpen
          contentClassName='content'
          contentContainerClassName='content-container'
          className='ul'
          items={items.map((cat, index) => ({
            id: index,
            openByDefault: false,
            renderLabel: (itemExpanded, togglePanel) => (
              <AccordionLabel
                itemExpanded={itemExpanded}
                togglePanelOpen={() => togglePanel(String(index))}
                label={CategoryData[cat[0] as keyof typeof CategoryData].newName}
                price={sum(
                  cat[1].map((x) => Number(x.price) * Number(x.adjusted.qty ?? x.quantity)),
                )}
                icon={CategoryData[cat[0] as keyof typeof CategoryData].icon}
              />
            ),
            renderContent: () => (
              <AccordionContent category={cat[0] as keyof typeof CategoryData} products={cat[1]} />
            ),
          }))}
        />
      </List>
    </Fragment>
  )
}

export default Services

const List = styled.ul`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;
  scrollbar-width: none;
  background-color: ${({ theme }) => `${theme.colors.Light.color.neutral.n0}`};
  border-left: ${({ theme }) => `1px solid ${theme.colors.Light.color.neutral.n3}`};
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.Light.color.neutral.n3}`};
  z-index: 10;

  ::-webkit-scrollbar {
    display: none;
  }

  .ul {
    display: flex;
    flex-direction: column;
  }

  .ul > li {
    margin: 0;
    border-bottom: ${({ theme }) => `1px solid ${theme.borderColor}`};
  }

  .content {
    padding: 0;
    margin: 0;
  }

  .content-container {
    transition: height 0.5s cubic-bezier(0.7, 0, 0.3, 1), margin 0.5s cubic-bezier(0.7, 0, 0.3, 1);
  }
`

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-bottom: ${({ theme }) => `1px solid ${theme.borderColor}`};
  border-left: ${({ theme }) => `1px solid ${theme.colors.Light.color.neutral.n3}`};
  border-top: ${({ theme }) => `1px solid ${theme.colors.Light.color.neutral.n3}`};
  border-radius: 0 8px 0 0;
  height: 43px;
  background-color: ${({ theme }) => `${theme.colors.Light.color.neutral.n0}`};
  z-index: 20;

  @media ${MEDIA.TABLET} {
    border-radius: 0;
  }
`
