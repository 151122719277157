import { FC, createContext, useState, useCallback } from 'react'

import { toDisplayUnits, toServerUnits } from 'helpers/unitSystem'

import type { Unit } from 'types'
import type { Context, ProjectContextProviderProps } from './types'

export const ProjectFormContext = createContext<Context>({
  buttons: {},
  editable: true,
  location: 'form',
  toDisplay: () => 1,
  toServer: () => 1,
  unit: 'sqft',
  updateUnit: () => undefined,
})

export const ProjectContextProvider: FC<ProjectContextProviderProps> = ({
  buttons = {},
  children,
  editable = true,
  location,
}) => {
  const [unit, setUnit] = useState<Unit>('sqft')

  const toDisplay = useCallback(
    (value: number) => toDisplayUnits(value, { to: unit, precision: 2 }),
    [unit],
  )
  const toServer = useCallback(
    (value: number) => toServerUnits(value, { from: unit, precision: 6 }),
    [unit],
  )

  const value: Context = {
    buttons,
    editable,
    location,
    toDisplay,
    toServer,
    unit,
    updateUnit: setUnit,
  }

  return <ProjectFormContext.Provider value={value}>{children}</ProjectFormContext.Provider>
}
