import CheckoutNotice from 'app/quotes/QuoteModal/CheckoutNotice'
import TopologyView from 'app/topology/components/TopologyView'
import { FullscreenLoader } from 'components'
import { checkHardware } from 'helpers'
import { isNil, mapObjIndexed, pathEq, reject } from 'ramda'
import { FC, useEffect, useMemo } from 'react'
import { AsyncFuncState } from 'shared'
import { useActions, useOverState } from 'store'
import type { ModalType, ProductInfoSidebar as SidebarType } from 'store/modal/types'
import type { Hardware } from 'store/quotes/types'
import styled from 'styled-components'
import { MEDIA } from 'styles/constants'
import { ProductInfoSidePanel } from './ProductInfoSidePanel'
import SidePanel from './SidePanel'

interface Props {
  quoteId: string
}

const QuoteView: FC<Props> = ({ quoteId }) => {
  const { quotes } = useOverState()
  const { sidebarProps } = useOverState().modal as ModalType<unknown, SidebarType>
  const { getQuote } = useActions().quotes
  const actions = useActions()

  useEffect(() => {
    getQuote({ quoteId })
  }, [])

  const itemsOutOfStock = useMemo(() => {
    if (isNil(quotes.current.quote)) return false

    return !checkHardware(quotes.current.quote.hardwareList)
  }, [quotes.current.quote])

  if (
    (quotes.status === AsyncFuncState.LOADING && isNil(quotes.loadingSection)) ||
    isNil(quotes.current.quote)
  )
    return <FullscreenLoader />

  const handleContactPro = () => {
    if (isNil(quotes.current.quote)) return
    const proUser = {
      ...quotes.current.quote.professionalUser,
      phone: quotes.current.quote.professional.phone,
    }
    actions.modal.setSidebar({
      sidebar: 'contact_pro',
      props: { professionalUser: proUser },
    })
    actions.modal.closeModal()
  }

  const filterHardware = mapObjIndexed(reject(pathEq('removed', ['adjusted', 'type'])))

  const hardware = filterHardware(quotes.current.quote.productVariantQuotes) as Hardware

  return (
    <Container>
      <SidePanel
        handleContactPro={handleContactPro}
        outOfStock={itemsOutOfStock}
        quote={quotes.current.quote}
        hardware={hardware}
      />
      <Wrapper>
        <TopologyView topologyData={hardware}>
          <CheckoutNotice
            itemsOutOfStock={itemsOutOfStock}
            handleContactPro={handleContactPro}
            currentQuote={quotes.current.quote}
          />
          <ProductInfoSidePanel product={sidebarProps?.product} />
        </TopologyView>
      </Wrapper>
    </Container>
  )
}

export default QuoteView

const Container = styled.div`
  display: grid;
  grid-template-columns: minmax(275px, 1fr) 4fr;
  height: 100%;
  background-color: ${({ theme }) => `${theme.colors.Light.color.neutral.n3}`};

  @media ${MEDIA.TABLET} {
    display: block;
  }
`
const Wrapper = styled.div`
  display: flex;
  overflow: hidden;
`
