import {
  AccessElementsIconL,
  ProtectElementIconL,
  InternetIconL,
  DevicesTalkIconL,
  WiFiPlainIconL,
  LanIconL,
} from '@ubnt/icons'
import { cssVariables } from '@ubnt/ui-components'
import { HierarchyPointNode } from 'd3-hierarchy'
import { select } from 'd3-selection'
import React, { useEffect, useRef, useState } from 'react'
import { Transition } from 'react-transition-group'
import styled from 'styled-components'

import type { TreeDatum } from '../models/topology/types'

const CategoryIcons = {
  Talk: <DevicesTalkIconL size='large' variant='fill' color='WHITE' x={-103} y={-14} />,
  Routing: <LanIconL variant='fill' color='WHITE' x={-99} y={-10} />,
  Switching: <LanIconL variant='fill' color='WHITE' x={-99} y={-10} />,
  Access: <AccessElementsIconL variant='fill' color='WHITE' x={-99} y={-10} />,
  Protect: <ProtectElementIconL variant='fill' color='WHITE' x={-99} y={-9} />,
  Wireless: <WiFiPlainIconL variant='fill' color='WHITE' x={-99} y={-7} />,
}

export const ICON_HEIGHT = 100
export const ICON_WIDTH = 100

const StyledDeviceImage = styled.picture<{ internet: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${ICON_HEIGHT}px;
  width: ${ICON_WIDTH}px;
  object-fit: contain;
  margin: 0 auto;
  border-radius: 8px;
  background-color: ${({ internet }) => (!internet ? '#fff' : '#ededf0')};
  border: ${({ internet, theme }) =>
    !internet ? '1px solid #ededf0' : `2px dashed ${theme.colors.Light.color.blue.b4}`};
  img {
    width: auto;
  }
  :hover {
    border-color: ${cssVariables['blue-3']};
    background-color: #e5f1ff;
  }
`

interface NodeImageProps {
  nodeData: HierarchyPointNode<TreeDatum>
}

const renderNodeImage = (node: TreeDatum) => {
  switch (node.id) {
    case 'internet':
      return (
        <GlobeWrapper>
          <InternetIconL size='large' transparent />
        </GlobeWrapper>
      )
    case 'Routing':
    case 'Switching':
      return <p>{node.name}</p>
    default:
      return <img height={ICON_HEIGHT} width={ICON_WIDTH} src={node.thumbnail} alt='device_image' />
  }
}

const QuantityWrapper = styled.div`
  /* Helps with safari bug*/
  position: fixed;
  white-space: nowrap;
  transform: translate(-50%, -100%) scale(var(--d3-zoom-scale));
  transform-origin: bottom center;
  z-index: 1;
`

const GlobeWrapper = styled.div`
  /* Helps with safari bug*/
  right: 26px;
  top: 62px;
  position: fixed;
  white-space: nowrap;
  transform: translate(-50%, -100%) scale(var(--d3-zoom-scale));
  transform-origin: bottom center;
  z-index: 1;
`

const Quantity = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ededf0;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  background-color: #fff;
  position: absolute;
  color: ${({ theme }) => theme.colors.textColor.black.b65};
  right: -60px;
  top: -60px;
`

const NodeImage: React.FC<NodeImageProps> = ({ nodeData: { data } }) => {
  return (
    <StyledDeviceImage internet={['internet', 'Routing', 'Switching'].includes(data.name)}>
      <QuantityWrapper>
        {Boolean(data.quantity) && <Quantity>{data.quantity}</Quantity>}
      </QuantityWrapper>
      {renderNodeImage(data)}
    </StyledDeviceImage>
  )
}

const NodeTextWrapper = styled('g')`
  cursor: pointer;

  text {
    fill: ${({ theme }) => theme.colors.textColor.black.b65};
    transition: fill 0.2s linear;
  }

  &:hover {
    text {
      fill: ${cssVariables['blue-3']};
    }
  }
`

const getNodeTransform = (
  nodeData: HierarchyPointNode<TreeDatum>,
  shouldTranslateToParent = false,
) => {
  const { x, y, parent } = nodeData

  if (shouldTranslateToParent) {
    const originX = parent?.x ?? 0
    const originY = parent?.y ?? 0
    return `translate(${originY},${originX})`
  }
  return `translate(${y},${x})`
}

interface NodeBaseProps {
  nodeData: HierarchyPointNode<TreeDatum>
  onClick: (id: string, event: React.MouseEvent<SVGForeignObjectElement, MouseEvent>) => void
  transitionDuration: number
}

const Node: React.FC<NodeBaseProps> = ({ nodeData, onClick, transitionDuration, ...rest }) => {
  const [transform] = useState(getNodeTransform(nodeData, true))
  const [initialStyle] = useState({ opacity: 0 })
  const nodeRef = useRef(null)
  const firstRender = useRef(true)
  const ongoingAnimation = useRef(true)
  const { x, y } = nodeData

  const applyTransform = (transform: string, transitionDuration: number, opacity = 1) => {
    select(nodeRef.current)
      .transition()
      .duration(transitionDuration)
      .attr('transform', transform)
      .style('opacity', opacity)
      .on('end', () => {
        ongoingAnimation.current = false
      })
  }

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
    } else {
      applyTransform(`translate(${y},${x})`, transitionDuration, 1)
    }
  }, [x, y, transitionDuration])

  const onExitCb = (nodeData: HierarchyPointNode<TreeDatum>, transitionDuration: number) => {
    const transform = getNodeTransform(nodeData, true)
    applyTransform(transform, transitionDuration, 0)
  }

  const onEnterCb = (nodeData: HierarchyPointNode<TreeDatum>, transitionDuration: number) => {
    const transform = getNodeTransform(nodeData)
    applyTransform(transform, transitionDuration, 1)
  }

  const hasChildren = (nodeData?.data?.internalChildren?.length ?? -1) > 0

  // we filter the invisible node here, because we need it in center
  // calculation in the zoom reset
  //if (nodeData.data.type === 'invisibleNodeType') return null

  return (
    <Transition
      {...rest}
      timeout={{ enter: transitionDuration, exit: transitionDuration }}
      onExit={() => {
        onExitCb(nodeData, transitionDuration)
      }}
      onEnter={() => {
        onEnterCb(nodeData, transitionDuration)
      }}
      unmountOnExit
      mountOnEnter
    >
      <g
        id={nodeData.data.id}
        ref={nodeRef}
        style={{ ...initialStyle, cursor: 'inherit' }}
        transform={transform}
      >
        <foreignObject
          width={ICON_WIDTH}
          height={ICON_HEIGHT}
          x={-ICON_WIDTH / 2}
          y={-ICON_HEIGHT / 2}
          style={{ overflow: 'visible', cursor: hasChildren ? 'pointer' : 'inherit' }}
          onClick={(evt) => onClick(nodeData.data.id, evt)}
        >
          <NodeImage nodeData={nodeData} />
        </foreignObject>
        <NodeTextWrapper>
          <text y={ICON_HEIGHT * 0.75} textAnchor='middle' fill='red'>
            {nodeData.data.name || nodeData.data.id}
          </text>
        </NodeTextWrapper>
        {nodeData.children && (
          <circle cx={ICON_WIDTH / 2} cy={0} r='5' fill={cssVariables['blue-3']} />
        )}
        {nodeData.parent && (
          <circle cx={-ICON_WIDTH / 2} cy={0} r='5' fill={cssVariables['blue-3']} />
        )}
        {nodeData.data.category !== 'Internet' && (
          <rect x={-102} y={-12} width={22} height={22} rx={4} fill={cssVariables['blue-3']} />
        )}
        {CategoryIcons[nodeData.data.category as keyof typeof CategoryIcons]}
      </g>
    </Transition>
  )
}

export default Node
