import { Text } from '@ubnt/ui-components'
import { v4 as uuid } from 'uuid'

import { BubbleRowWide, WifiData } from './styles'

import type { VFC } from 'react'

interface WiFiHeadersProps {
  labels: {
    first: string[]
    rest?: string[]
  }
  show?: boolean
}

const makeLabel = (x: string) => (
  <Text key={uuid()} size='label'>
    {x}
  </Text>
)

export const WiFiHeaders: VFC<WiFiHeadersProps> = ({
  labels: { first, rest = [] },
  show = true,
}) => {
  if (!show) return null
  return (
    <BubbleRowWide>
      <WifiData>{first.map(makeLabel)}</WifiData>
      {rest.map(makeLabel)}
    </BubbleRowWide>
  )
}
