import { Transition } from 'react-transition-group'
import { path } from 'd3-path'
import React, { MutableRefObject, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { TreeDatum } from '../models/topology/types'
import { HierarchyPointLink, HierarchyPointNode } from 'd3-hierarchy'
import { cssVariables } from '@ubnt/ui-components'

import { select as d3Select } from 'd3-selection'
import { transition as d3Transition } from 'd3-transition'
d3Select.prototype.transition = d3Transition

export const CURVE_RADIUS = 16

const LinkPath = styled('path')`
  stroke: ${cssVariables['blue-3']};
  stroke-width: 1.5;
  fill: none;
  stroke-dasharray: 0;
`

const drawDiagonalPath = (sx0: number, sy0: number, tx1: number, ty1: number): string => {
  const x1 = sy0
  const y1 = sx0
  const x2 = ty1
  const y2 = tx1

  const mpx = (x1 + x2) / 2

  const p = path()

  p.moveTo(x1, y1)

  if (y2 !== y1) {
    p.lineTo(mpx - CURVE_RADIUS, y1)
    if (y2 > y1) {
      p.bezierCurveTo(
        mpx - CURVE_RADIUS / 2,
        y1,
        mpx,
        y1 + CURVE_RADIUS / 2,
        mpx,
        y1 + CURVE_RADIUS,
      )

      p.lineTo(mpx, y2 - CURVE_RADIUS)

      p.bezierCurveTo(
        mpx,
        y2 - CURVE_RADIUS / 2,
        mpx + CURVE_RADIUS / 2,
        y2,
        mpx + CURVE_RADIUS,
        y2,
      )
    } else {
      p.bezierCurveTo(
        mpx - CURVE_RADIUS / 2,
        y1,
        mpx,
        y1 - CURVE_RADIUS / 2,
        mpx,
        y1 - CURVE_RADIUS,
      )

      p.lineTo(mpx, y2 + CURVE_RADIUS)

      p.bezierCurveTo(
        mpx,
        y2 + CURVE_RADIUS / 2,
        mpx + CURVE_RADIUS / 2,
        y2,
        mpx + CURVE_RADIUS,
        y2,
      )
    }
  } else {
    // This is needed for the straight lines and lines that end up to a single point.
    // However, to make animations work, we need equal segments for the line so we
    // have to repeat the steps that are above.
    p.lineTo(mpx, y1)
    p.bezierCurveTo(mpx, y1, mpx, y1, mpx, y1)
    p.lineTo(mpx, y2)
    p.bezierCurveTo(mpx, y2, mpx, y2, mpx, y2)
  }

  p.lineTo(x2, y2)

  return p.toString()
}

interface LinkBaseProps {
  transitionDuration: number
  linkData: HierarchyPointLink<TreeDatum>
  nextNodeListRef: MutableRefObject<HierarchyPointNode<TreeDatum>[] | null>
  prevNodeListRef: MutableRefObject<HierarchyPointNode<TreeDatum>[] | null>
}

const Link: React.FC<LinkBaseProps> = ({
  transitionDuration,
  linkData,
  nextNodeListRef,
  prevNodeListRef,
  ...rest
}) => {
  const [initialStyle] = useState({ opacity: 1 })
  const linkGroupRef = useRef(null)
  const linkPathRef = useRef(null)
  const firstRender = useRef(true)
  const { source, target } = linkData

  useEffect(() => {
    // Don't do anything on the first render because we need to collect all the refs
    if (firstRender.current) {
      firstRender.current = false
    } else {
      d3Select(linkPathRef.current)
        .transition()
        .duration(transitionDuration)
        .attr('d', () => {
          return drawDiagonalPath(source.x, source.y, target.x, target.y)
        })
    }
  }, [source.x, source.y, target.x, target.y, transitionDuration])

  const pathId = `pathId-${source.data.id}-${target.data.id}`

  return (
    <Transition
      {...rest}
      timeout={{ enter: transitionDuration, exit: transitionDuration }}
      onExit={() => {
        // On exit we want to do kinda the opposite of what happens in enter.
        // We want to transition the path to the source nodes future position.
        // Since enter and exit is controlled by React-transition-group, we must
        // find the  "future" position through the reference passed down from
        // the parent component.
        const results = nextNodeListRef?.current?.find((n) => n.data.id === source.data.id)
        // In case there are no results, such as a parent component up the chain has been collapsed
        const s = results || source

        d3Select(linkGroupRef.current).transition().duration(transitionDuration).style('opacity', 0)

        d3Select(linkPathRef.current)
          .transition()
          .duration(transitionDuration)
          .attr('d', () => {
            return drawDiagonalPath(s.x, s.y, s.x, s.y)
          })
      }}
      onEnter={() => {
        // When the Link enters we want the path's starting state to be at the Link source
        // old position. We want this because the source itself might move.
        const prevSource = prevNodeListRef?.current?.find((n) => n.data.id === source.data.id)
        // In case this is the first render, we can (and must) simply use the source which is going
        // to be safe.
        const s = prevSource || source
        // Start animating the groups opacity
        d3Select(linkGroupRef.current).transition().duration(transitionDuration).style('opacity', 1)
        d3Select(linkPathRef.current)
          // we set the path with d3 to fully control the animation,
          // we don't render the d attribute with React
          .attr('d', drawDiagonalPath(s.x, s.y, s.x, s.y))
          .transition()

          .duration(transitionDuration)
          // Path's end state
          .attr('d', () => {
            return drawDiagonalPath(source.x, source.y, target.x, target.y)
          })
      }}
    >
      <g style={{ ...initialStyle }} ref={linkGroupRef}>
        <LinkPath ref={linkPathRef} id={pathId} />
      </g>
    </Transition>
  )
}

export default Link
