import { FC, useEffect } from 'react'
import { clone, isNotNil } from 'ramda'

import { useActions, useOverState } from 'store'
import { Details } from './Details'
import { PaymentProvider } from './PaymentForm'
import { Products } from './Products'
import { Container } from './styles'

import type { CheckoutProps } from './types'

export const Checkout: FC<CheckoutProps> = ({ quote, type }) => {
  const { payment } = useOverState()
  const actions = useActions()

  const {
    professionalUser: { firstName, lastName },
    shipTo: { choice, recommendation },
  } = quote

  const isCompleteProShipping =
    recommendation === 'pro' && isNotNil(firstName) && isNotNil(lastName)

  useEffect(() => {
    actions.payment.setShippingAddress({ address: clone(quote.shipTo.details) })
    return actions.payment.reset
  }, [])

  useEffect(() => {
    actions.payment.preparePaymentForm({ quoteId: quote.id, type }).then(() => {
      actions.payment.setShippingType({
        type:
          recommendation === 'pro' && !isCompleteProShipping
            ? 'customer'
            : choice ?? recommendation,
      })
    })
  }, [type])

  useEffect(() => {
    if (payment.savedCard?.billing) {
      actions.payment.setBillingAddress({ address: clone(payment.savedCard.billing) })
    }
  }, [payment.savedCard?.billing])

  return (
    <PaymentProvider quoteId={quote.id} type={type}>
      <Container>
        <Details quote={quote} type={type} useSaved={payment.useSaved} />
        <Products quote={quote} type={type} />
      </Container>
    </PaymentProvider>
  )
}
