import { always, cond, equals, isNotNil, T, where } from 'ramda'

import { UseCard } from './constants'

import type { MakeUseCardOptions } from './types'

export const makeUseCard = cond<[MakeUseCardOptions], UseCard>([
  [where({ savedCard: isNotNil, useSaved: equals(true) }), always(UseCard.USE_SAVED)],
  [where({ savedCard: isNotNil, useSaved: equals(false) }), always(UseCard.ANOTHER_CARD)],
  [T, always(UseCard.EMPTY)],
])
