interface Config {
  API_URL: string
  AWS_URL: string
  BASE_URL: string
  BUILD: string
  CREATE_DC: string
  ENV: 'local' | 'proto' | 'qa' | 'prod'
  GA_MEASURING: string
  LOCALE: string
  PLACES_API_KEY: string
  PS_ACCESS: string
  RECAPTCHA: string
  SENTRY_DSN: string
  STRIPE_URL: string
  SHORT_POLLING_INTERVAL: number
  SSO: string
  SSO_BASE: string
  STRIPE_KEY: string
  USERBACK_TOKEN: string
  VERSION: string
}

const ENV = (process.env.REACT_APP_ENV || 'local') as Config['ENV']
const fields = require(`./${ENV}.json`)

const { hostname, protocol } = window.location
if (ENV === 'local') {
  if (hostname.endsWith('ui.com')) {
    fields.API_URL = fields.API_URL.replace('localhost', 'pro.local.ui.com')
    fields.BASE_URL = fields.BASE_URL.replace('localhost', hostname)
  }
  if (protocol.startsWith('https')) {
    fields.API_URL = fields.API_URL.replace('http://', 'https://')
    fields.BASE_URL = fields.BASE_URL.replace('http://', 'https://')
  }
}

const config: Config = {
  ...fields,
  BUILD: process.env.REACT_APP_BUILD,
  ENV,
  VERSION: process.env.REACT_APP_VERSION,
}

export default config
