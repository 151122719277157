import { FC, FormEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Text } from '@ubnt/ui-components/Text'
import { any, isEmpty, isNil, memoizeWith, where } from 'ramda'
import styled from 'styled-components'

import { Anchor, CheckboxSimple, ReactLink } from './styles'
import { Footer } from 'components/ProjectForm/Footer'
import { FullscreenLoader } from 'components/FullscreenLoader'
import { ReCaptcha } from 'components/ReCaptcha'
import { Form, FormSection, Input, SubSection } from 'components/ProjectForm/styles'
import { PasswordFeedback } from 'components/PasswordFeedback'
import { api } from 'store/projects/effects'

import type { CreateUserFormFields, CreateUserFormProps } from './types'

export const validateRegex = /^[\w-.]+$/

export const usernameNotTaken = memoizeWith(toString, (username: string | undefined) => {
  if (!username) return false

  return api
    .checkAccountExists({ username }) //
    .then(({ exists }) => exists === false)
})

const schema = yup.object().shape({
  username: yup
    .string()
    .required()
    .test(
      'allowed characters',
      'only letters, numbers, .-_ are allowed',
      (username) => !isNil(username) && validateRegex.test(username),
    )
    .test('not-taken', 'sorry, this username is already taken', usernameNotTaken),
  password: yup
    .string()
    .min(12)
    .required()
    .test(
      'different-from-username',
      'your password should be different from your username',
      (password, { parent }) => password !== parent.username,
    ),
})

export const CreateUserForm: FC<CreateUserFormProps> = ({
  account,
  generateUsername,
  handleNext,
  handlePrev,
  isLoading: globalLoading = false,
  login,
  updateAccount,
}) => {
  const [isLoading, setIsLoading] = useState(generateUsername && account.username === '')
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateUserFormFields>({
    resolver: yupResolver(schema),
    mode: 'onTouched',
  })

  useEffect(() => {
    if (isLoading && generateUsername) {
      generateUsername()
        .then(({ username }) => updateAccount((prev) => ({ ...prev, username })))
        .catch(() => undefined)
        .finally(() => setIsLoading(false))
    }
  }, [isLoading])

  const nextIsDisabled = useMemo(
    () =>
      !account.token ||
      !account.termsAndConditions ||
      any(isEmpty, [account.password, account.username]) ||
      !where({ username: isNil, password: isNil }, errors),
    [account, errors.username, errors.password],
  )

  const handleExpired = useCallback(
    () => updateAccount((prev) => ({ ...prev, token: undefined })),
    [updateAccount],
  )

  const handleVerify = useCallback(
    (token: string) => updateAccount((prev) => ({ ...prev, token })),
    [updateAccount],
  )

  const onSubmit = useCallback(
    (event: FormEvent<HTMLFormElement> | MouseEvent) => {
      event.preventDefault()
      return handleSubmit(() => handleNext(event))()
    },
    [handleNext, handleSubmit],
  )

  const toggleMailing = () =>
    updateAccount((prev) => ({ ...prev, mailingList: !account.mailingList }))

  const toggleTerms = () =>
    updateAccount((prev) => ({ ...prev, termsAndConditions: !account.termsAndConditions }))

  return (
    <Form onSubmit={onSubmit}>
      <FormSection main>
        {isLoading && <FullscreenLoader />}
        {isLoading || (
          <>
            <Text size='body'>Log in to manage your project</Text>
            <Controller
              control={control}
              defaultValue={account.username}
              name='username'
              render={({ field: { onChange, value, onBlur } }) => (
                <Input
                  autoComplete='chrome-off'
                  label='Username'
                  invalid={errors.username?.message}
                  name='username'
                  onBlur={onBlur}
                  onChange={({ target }) => {
                    onChange(target.value)
                    updateAccount((prev) => ({
                      ...prev,
                      username: target.value,
                    }))
                  }}
                  size='header-s'
                  value={value}
                  width={352}
                />
              )}
            />
            <Controller
              control={control}
              defaultValue={account.password}
              name='password'
              render={({ field: { onChange, value, onBlur } }) => (
                <Input
                  label='Password'
                  invalid={errors.password?.message}
                  name='password'
                  onBlur={onBlur}
                  onChange={({ target }) => {
                    onChange(target.value)
                    updateAccount((prev) => ({
                      ...prev,
                      password: target.value,
                    }))
                  }}
                  passwordToggle
                  size='header-s'
                  type='password'
                  value={value}
                  width={352}
                />
              )}
            />
            <PasswordFeedback password={account?.password} userInputs={[account?.username]} />
            <Captcha onExpired={handleExpired} onVerify={handleVerify} />
            <CheckboxSimple
              id='terms-conditions'
              checked={account.termsAndConditions}
              onChange={toggleTerms}
            >
              <Text size='caption'>
                {"By creating your account, you agree to Ubiquiti Inc.'s "}
                <Anchor href='https://www.ui.com/legal/termsofservice/' rel='noopener'>
                  Terms of Service
                </Anchor>
                {' and '}
                <Anchor href='https://www.ui.com/legal/privacypolicy/' rel='noopener'>
                  Privacy Policy
                </Anchor>
                .
              </Text>
            </CheckboxSimple>
            <CheckboxSimple
              id='receive-emails'
              checked={account.mailingList}
              onChange={toggleMailing}
            >
              <Text size='caption'>
                Register to receive our newsletter, as well as periodic product, software, and
                service updates.
              </Text>
            </CheckboxSimple>
          </>
        )}

        <LoginOption login={login} />
      </FormSection>
      <Footer
        isLoading={globalLoading}
        nextDisabled={nextIsDisabled}
        nextLabel='Continue'
        onPrev={handlePrev}
      />
    </Form>
  )
}

type LoginOptionProps = Pick<CreateUserFormProps, 'login'>

const LoginOption = ({ login }: LoginOptionProps) => {
  if (!login) return null
  return (
    <SubSection marginTop={30}>
      <Text size='caption'>I have a Ubiquiti account already.</Text>
      &nbsp;
      <Text size='caption' weight='bold'>
        <ReactLink to={login}>Sign in</ReactLink>
      </Text>
    </SubSection>
  )
}

const Captcha = styled(ReCaptcha)`
  margin: 8px 0;
`
