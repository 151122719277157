import { headOrNull } from 'helpers'
import { makeHardwareList, parseAndIndexQuotes, parseQuoteStatus } from './helpers'
import toast from 'store/helpers'

import type { Context } from 'store'
import type {
  DisputeOptions,
  GetQuotesByProjectOptions,
  LoadingSection,
  PostFeedbackOptions,
  UpdateDisputeOptions,
  WithIncomingQuote,
  WithQuote,
} from './types'
import type { WithQuoteId } from 'store/types'

export const dispute = ({ effects, state }: Context, value: DisputeOptions) =>
  effects.quotes.api
    .dispute(value) //
    .then((data) => {
      if (state.quotes.current.quote) state.quotes.current.quote.disputes = [data]
    })

export const getQuote = <A extends WithQuoteId>(
  { effects }: Context,
  value: A,
): Promise<A & WithIncomingQuote> => {
  return effects.quotes.api
    .getQuote(value.quoteId) //
    .then((quote) => ({ ...value, quote }))
}

export const getQuotesByProject = <A extends GetQuotesByProjectOptions>(
  { effects, state }: Context,
  { projectId, status = '', withInventory = false }: A,
) => {
  return effects.quotes.api
    .getQuotesByProject(projectId, status, withInventory)
    .then(parseAndIndexQuotes)
    .then((data) => (state.quotes.hash = data))
}

export const parseQuote = <A extends WithIncomingQuote>(
  _: Context,
  value: A,
): Omit<A, 'quote'> & WithQuote => {
  const data = 'data' in value.quote ? value.quote.data : value.quote
  const error = 'data' in value.quote ? value.quote.metaData.error : undefined

  return {
    ...value,
    error,
    quote: {
      ...data,
      hardwareList: makeHardwareList(data),
      feedback: headOrNull(data.feedback),
      status: parseQuoteStatus(data.status),
    },
  }
}

export const postOrderFeedback = <A extends PostFeedbackOptions>(
  { effects }: Context,
  { quoteId, feedback, rating }: A,
) => effects.quotes.api.postFeedback(quoteId, { feedback, rating })

export const setIsStale =
  (x: boolean) =>
  ({ state }: Context) => {
    state.quotes.current.isStale = x
  }

export const setLoadingSection =
  <A>(section: LoadingSection) =>
  ({ state }: Context, value: A): A => {
    state.quotes.loadingSection = section
    return value
  }

export const toastOnError = <A extends { error?: string }>({ state }: Context, value: A) => {
  if (value.error) toast(state, 'Error', value.error, 'danger', 6000)
}

export const updateDispute = ({ effects, state }: Context, value: UpdateDisputeOptions) =>
  effects.quotes.api
    .updateDispute(value) //
    .then((data) => {
      if (state.quotes.current.quote) state.quotes.current.quote.disputes = [data]
    })

// Store Checkout Operators

export const getCheckoutUrl = ({ effects, state }: Context, quoteId: string) =>
  effects.quotes.api.getCheckoutUrl(quoteId).then((url) => {
    state.quotes.storeCheckout.url = url
  })

export const initiateCheckout = ({ state }: Context) => {
  state.quotes.storeCheckout.inCheckout = true
}
