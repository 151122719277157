import { isNotNil } from 'ramda'
import { Table } from '@ubnt/ui-components/Table'
import { Text } from '@ubnt/ui-components/Text'
import { Tooltip } from '@ubnt/ui-components/Tooltip'

import { useOverState } from 'store'
import { BubbleContainer, FlexColumn, FlexRow } from 'components'
import { ItemsMobileView } from '../MobileView'
import { PaidBadge } from './PaidBadge'
import { ProItemButtons } from './ProItemButtons'
import { Screen, useScreenWidth } from 'hooks'
import { Accordion, InfoIcon, WrenchesIcon } from './styles'
import {
  isEmptyItemList,
  makeHardwareRows,
  makeRowClassName,
  makeServiceRows,
  renderAccordionLabel,
} from './helpers'
import { makeQuoteStep } from '../helpers'
import texts from '../../texts.json'
import { hardwareColumns, serviceColumns } from './columns'

import type { FC } from 'react'
import type { ProItemPanelProps } from './types'
import type { AccordionItem } from '@ubnt/ui-components'

export const ProItemPanel: FC<ProItemPanelProps> = ({ quote }) => {
  const { quotes } = useOverState()
  const screenWidth = useScreenWidth()

  const { proHardware, proServices, priceBreakdown } = quote
  const hardwareItems = makeHardwareRows(proHardware, priceBreakdown.proHardware)
  const serviceItems = makeServiceRows(proServices, priceBreakdown.proServices)
  const step = makeQuoteStep({ ...quotes.current, checkout: quotes.checkout, quote })

  const proHardwareColumns = quote.hasBeenAdjusted.proHardware
    ? hardwareColumns
    : hardwareColumns.filter((c) => c.id !== 'adjustment')

  const proServiceColumns = quote.hasBeenAdjusted.proServices
    ? serviceColumns
    : serviceColumns.filter((c) => c.id !== 'adjustment')

  const makeAccordion = (
    type: 'proHardware' | 'proServices',
    fn?: () => JSX.Element,
  ): AccordionItem => ({
    id: type,
    openByDefault: false,
    renderContent: fn as () => JSX.Element,
    renderLabel: renderAccordionLabel({
      id: type,
      label: texts.proItemPanel[type],
      price: priceBreakdown[type].total,
      step,
      isVisibleAdjustment: screenWidth > Screen.TABLET,
      adjustment: priceBreakdown[type].diff.total,
    }),
  })

  const proHardwareAccordionObj = isEmptyItemList(quote.proHardware)
    ? makeAccordion('proHardware')
    : screenWidth > Screen.TABLET
    ? makeAccordion('proHardware', () => (
        <Table
          columns={proHardwareColumns}
          disableColumnFilters
          disableSelection
          disableSort
          items={hardwareItems}
          rowClassName={makeRowClassName}
          rowHeight={48}
        />
      ))
    : makeAccordion('proHardware', () => <ItemsMobileView items={quote.proHardware} />)

  const proServiceAccordionObj = isEmptyItemList(quote.proServices)
    ? makeAccordion('proServices')
    : screenWidth > Screen.TABLET
    ? makeAccordion('proServices', () => (
        <Table
          columns={proServiceColumns}
          disableColumnFilters
          disableSelection
          disableSort
          items={serviceItems}
          rowClassName={makeRowClassName}
          rowHeight={48}
        />
      ))
    : makeAccordion('proServices', () => <ItemsMobileView items={quote.proServices} />)

  return (
    <BubbleContainer variant='secondary' verticalSpacing={screenWidth < Screen.PC ? 8 : undefined}>
      <FlexRow>
        <FlexColumn alignItems='flex-start'>
          <WrenchesIcon isActive size='large' variant='fill' />
          <FlexRow>
            <Tooltip description={texts.proItemPanel.tooltip} position='bottom' width={208}>
              <Text size='header-xs' weight='bold'>
                {texts.proItemPanel.title}
                <InfoIcon />
              </Text>
            </Tooltip>
            {step === 3 && <PaidBadge />}
          </FlexRow>
        </FlexColumn>
        <ProItemButtons quote={quote} step={step} />
      </FlexRow>

      <Accordion
        items={[proHardwareAccordionObj, proServiceAccordionObj].filter((x) =>
          isNotNil(x.renderContent),
        )}
        multiOpen
        variant='secondary'
      />
    </BubbleContainer>
  )
}
