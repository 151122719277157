import { Input } from '@ubnt/ui-components'

import { InputContainer, UnitContainer } from './styles'

import type { InputProps } from '@ubnt/ui-components/Input/Input'
import type { VFC } from 'react'

export interface InputWithUnitProps extends Partial<InputProps> {
  containerClassName?: string
  labelSize?: number
  unit?: string
}

export const InputWithUnit: VFC<InputWithUnitProps> = ({
  containerClassName,
  labelSize,
  unit,
  forwardedRef,
  ...rest
}) => {
  const padding = unit ? labelSize : undefined

  return (
    <InputContainer className={containerClassName} padding={padding}>
      <Input forwardedRef={forwardedRef as undefined} {...rest} />
      {unit && <UnitContainer size='caption'>{unit}</UnitContainer>}
    </InputContainer>
  )
}
