import { Button } from '@ubnt/ui-components/Button'

import { useActions } from 'store'
import { FlexColumn, FlexRow } from 'components'
import { dateOrEmpty, isNotEmpty } from 'helpers'
import { QuoteSteps } from '../constants'
import { DisputeText } from './styles'
import texts from '../../texts.json'

import type { FC } from 'react'
import type { ProItemButtonsProps } from './types'

export const ProItemButtons: FC<ProItemButtonsProps> = ({ quote, step }) => {
  const actions = useActions()

  const isDisputed = isNotEmpty(quote.disputes)

  if (step < QuoteSteps.CONFIRM) return null
  if (step === QuoteSteps.COMPLETED)
    return (
      <Button
        onClick={() =>
          actions.modal.setSidebar({
            sidebar: 'order_details',
            props: { from: 'quote', quote, type: 'professional' },
          })
        }
        variant='secondary'
      >
        {texts.proItemPanel.buttons.view}
      </Button>
    )

  return (
    <FlexColumn alignItems='flex-end'>
      <FlexRow justify='flex-end'>
        <Button
          onClick={() =>
            actions.modal.setModal({ modal: 'quote_dispute', props: { quoteId: quote.id } })
          }
          style={{ marginRight: 16 }}
          variant='secondary'
        >
          {texts.proItemPanel.buttons.dispute}
        </Button>
        {quote.handlePayment && (
          <Button onClick={() => actions.quotes.setCheckout('professional')} variant='primary'>
            {texts.proItemPanel.buttons.buy}
          </Button>
        )}
      </FlexRow>
      {isDisputed && (
        <DisputeText size='body'>
          Disputed on {dateOrEmpty(quote.disputes[0].lastEdit, 'ff')}
        </DisputeText>
      )}
    </FlexColumn>
  )
}
