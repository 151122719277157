import { noop, OperatorContextFunction, pipe } from 'overmind'
import { loadStripe as _loadStripe, Stripe } from '@stripe/stripe-js'

import { whenPropIs } from 'store/operators'
import config from 'config'

import type { Context } from 'store'
import type { WithQuoteId } from 'store/types'
import type { MakeStripeOptions, WithStripeId } from './types'

const loadStripe = (_: Context, opts: Partial<WithStripeId> = {}) =>
  _loadStripe(config.STRIPE_KEY, { stripeAccount: opts.stripeId ?? undefined })

// Exported Functions

export const getStripeId = <A extends WithQuoteId>(
  { effects }: Context,
  value: A,
): Promise<A & WithStripeId> =>
  effects.stripe.api
    .getStripeId(value) //
    .then(({ stripeId }) => ({
      ...value,
      stripeId,
    }))

export const makeStripe = pipe(
  whenPropIs('type', 'professional', {
    true: pipe(getStripeId, loadStripe),
    false: loadStripe as OperatorContextFunction<MakeStripeOptions, Promise<Stripe | null>>,
  }),
  // Without this, TS wrongly sets the return type of the action as Promise<Promise<Stripe | null>>
  noop(),
)
