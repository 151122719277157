import styled from 'styled-components'
import { Dropdown } from '@ubnt/ui-components/Dropdown'

import { Card as BaseCard } from 'components'
import { MEDIA } from 'styles/constants'

export const Card = styled(BaseCard)`
  cursor: pointer;

  height: 160px;
  width: 285px;
`

export const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
`

export const CardContainer = styled.section`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;

  margin-top: 12px;
  margin-left: 12px;

  @media ${MEDIA.MOBILE} {
    justify-content: center;
  }
`

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  width: 100%;
  height: 100%;
`

export const DropdownWrapper = styled.div`
  position: relative;
`

export const ProjectName = styled.button`
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 70px;
  border: none;
  background-color: white;

  height: 47px;
  width: calc(100% - 70px);
  cursor: pointer;
  z-index: 1;
`

export const ProjectPicker = styled(Dropdown)`
  .input {
    justify-content: center;
    height: 48px;
    padding: 12px 15px;
  }

  > div > div > div:last-child {
    margin-right: 20px;
  }
`
