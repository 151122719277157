import { defaultTo, match, nth, pipe } from 'ramda'

import { CreateUser } from './CreateUser'
import { Login as NormalLogin } from './Login'
import { useQuery } from 'hooks'

import type { VFC } from 'react'

const quoteRegEx = /^\/quotes\/([\w-]+)/
type GetQuoteId = (x: string | null) => string | undefined
const getQuoteId: GetQuoteId = pipe(defaultTo(''), match(quoteRegEx), nth(1))

export const Login: VFC = () => {
  const { origin } = useQuery(['origin'])
  const quoteId = getQuoteId(origin)

  if (!quoteId) return <NormalLogin />
  return <CreateUser quoteId={quoteId} />
}
