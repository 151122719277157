import { createElement } from 'react'
import { always, cond, isNil, pipe, prop, T, test } from 'ramda'

import { http } from 'helpers'
import { Emphasis } from './styles'
import config from 'config'
import conf from './config.json'

import type {
  CaptureDataCustom,
  CaptureDataOpts,
  CaptureDataParams,
  PSAction,
  PSGroup,
} from './types'
import type { Maybe } from 'types'

const detectBrowserLocale = () =>
  !window.navigator?.language ? '' : window.navigator.language.toLowerCase()

const detectEnv = () =>
  test(/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i, window.navigator.userAgent)
    ? 'mobile'
    : 'desktop'

const detectOS = pipe(
  always(window.navigator),
  prop('userAgent'),
  cond([
    [isNil, always('Unknown')],
    [test(/win/i), always('Windows')],
    [test(/mac/i), always('MacOs')],
    [test(/android/i), always('Android')],
    [test(/cros/i), always('Chrome OS')],
    [test(/ip(?:hone|ad|od)/i), always('iOS')],
    [test(/linux|x11/i), always('Linux')],
    [test(/unix/i), always('UNIX')],
    [test(/googlebot/i), always('GoogleBot')],
    [T, always('Unknown')],
  ]),
)

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

// Description of all parameters to send: https://clickwrap-developer.ironcladapp.com/reference/url-parameters-for-send
export const captureData = (
  { action, signerId }: CaptureDataParams,
  opts: CaptureDataOpts,
  custom: CaptureDataCustom,
) => ({
  bl: detectBrowserLocale(),
  btz: new Date().getTimezoneOffset() / 60,
  cid: opts.contracts.join(','),
  cus: {
    ssoId: custom.ssoId,
    userAgent: window.navigator.userAgent ?? 'unknown',
  },
  env: detectEnv(),
  et: action,
  gid: opts.group,
  gkey: opts.key,
  hn: encodeURI(window.location.hostname),
  os: detectOS(),
  pad: encodeURI(window.location.host),
  pap: encodeURI(window.location.pathname),
  pat: encodeURI(document.title),
  pau: encodeURI(window.location.href),
  ref: encodeURI(document.referrer),
  res: `${window.screen.width}x${window.screen.height}`,
  scd: `${window.screen.colorDepth}-bit`,
  sid: config.PS_ACCESS,
  sig: encodeURI(signerId),
  tm: config.ENV !== 'prod',
  vid: opts.versions.join(','),
})

export const load = (group: string) =>
  http.get<PSGroup>(`${conf.endpoints.load}?sid=${config.PS_ACCESS}&gkey=${group}`, headers)

export const parseText = (str: string) =>
  str
    .split(/(?={{em}})|{{\/em}}/) //
    .map((x) => {
      const match = x.match(/{{em}}(.+)/)
      return match ? createElement(Emphasis, null, match[1]) : x
    })

export const send =
  (action: PSAction, opts: Maybe<CaptureDataOpts>, signerId?: string, ssoId?: string) => () =>
    opts && signerId && ssoId
      ? http.post<void>(
          `${conf.endpoints.send}`,
          captureData({ action, signerId }, opts, { ssoId }),
          headers,
        )
      : Promise.resolve(undefined)
