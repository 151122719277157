import { TextArea } from '@ubnt/ui-components/TextArea'

import { FlexColumn } from 'components'
import { Text } from './styles'
import texts from './texts.json'

import type { FC } from 'react'
import type { DisputeFormProps } from './types'

export const DisputeForm: FC<DisputeFormProps> = ({ onChange, value }) => {
  const handleChange = (_: any, value: string) => onChange(value)

  return (
    <FlexColumn alignItems='center'>
      <Text size='body'>{texts.message}</Text>
      <TextArea
        height={176}
        maxWidth={380}
        minWidth={380}
        onChange={handleChange}
        size='body'
        value={value}
        width={380}
      />
    </FlexColumn>
  )
}
