import { Button, Text } from '@ubnt/ui-components'

import { useActions } from 'store'
import { Container } from './styles'

import type { QuoteListItem } from 'store/quotes/types'
import type { VFC } from 'react'

interface Props {
  order: QuoteListItem
  quoteId: string
}

export const FeedbackSection: VFC<Props> = (props) => {
  const { setModal } = useActions().modal

  const handleFeedback = () => {
    setModal({
      modal: 'feedback',
      props,
    })
  }

  return (
    <Container className='column padding_h n2 borderless'>
      <Text size='body' weight='bold'>
        We value your feedback!
      </Text>
      <Text size='body' style={{ paddingBottom: '8px' }}>
        Please give us feedback about your experience.
      </Text>
      <Button onClick={handleFeedback} variant='primary'>
        Give Feedback
      </Button>
    </Container>
  )
}
