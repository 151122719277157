import { Button } from '@ubnt/ui-components'
import { MouseEventHandler, VFC, useContext } from 'react'

import { FormSection } from 'components'
import { ProjectFormContext } from './Context'

interface FooterProps {
  isLoading?: boolean
  nextDisabled?: boolean
  nextLabel?: string
  onPrev?: MouseEventHandler
  prevLabel?: string
}

const nextLabelDefaults = {
  form: 'Next',
  sidebar: 'Done',
}

const prevLabelDefaults = {
  form: 'Back',
  sidebar: 'Cancel',
}

export const Footer: VFC<FooterProps> = ({
  isLoading = false,
  nextDisabled = false,
  nextLabel,
  onPrev,
  prevLabel,
}) => {
  const formContext = useContext(ProjectFormContext)

  const next = nextLabel ?? formContext.buttons.next ?? nextLabelDefaults[formContext.location]
  const prev = prevLabel ?? formContext.buttons.prev ?? prevLabelDefaults[formContext.location]

  return (
    <FormSection type='footer' border={formContext.location === 'form'}>
      <Button
        disabled={nextDisabled || isLoading}
        loader={isLoading ? 'loading' : undefined}
        style={{ minWidth: '100px' }}
        type='submit'
        variant='primary'
      >
        {next}
      </Button>
      {onPrev && (
        <Button onClick={onPrev} type='button' variant='tertiary'>
          {prev}
        </Button>
      )}
    </FormSection>
  )
}
