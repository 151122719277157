import { Route, Routes, useMatch } from 'react-router-dom'

import { Faq } from './Faq'
import { HELP_PAGES } from './constants'
import { BackNavigate, Main } from 'components'
import { NavigationPanel } from './NavigationPanel'
import { Support } from './Support'
import { useBackNavigate } from 'hooks'

import type { VFC } from 'react'

const Help: VFC = () => {
  const navigate = useBackNavigate()
  const isFaq = useMatch('/help/faq/*')

  const selectedPage = HELP_PAGES.find(({ id }) => (isFaq ? id === 'faq' : id === 'support'))

  const handleSelect = (pageId: string) => {
    navigate(`/help/${pageId}`)
  }

  return (
    <Main>
      <NavigationPanel
        pages={HELP_PAGES}
        handleSelect={handleSelect}
        selectedPage={selectedPage?.id ?? null}
      />

      <Routes>
        <Route path='faq/*' element={<Faq />} />
        <Route path='support' element={<Support />} />
        <Route path='*' element={<BackNavigate replace to='/help/support' />} />
      </Routes>
    </Main>
  )
}

export default Help
