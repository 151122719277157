import { FC, useEffect } from 'react'

import { useActions, useOverState } from 'store'
import { ProjectContextProvider } from 'components'
import { Wrapper } from './Wrapper'
import { buttonLabels } from './constants'

import type { ModalType, ProjectSidebar as SidebarType } from 'store/modal/types'

export const ProjectSidebar: FC = () => {
  const { sidebarProps } = useOverState().modal as ModalType<unknown, SidebarType>
  const { projects } = useOverState()
  const { projects: actions } = useActions()

  useEffect(() => {
    actions.getProject({ projectId: sidebarProps.projectId })

    return actions.resetCurrent
  }, [])

  return (
    <ProjectContextProvider
      buttons={buttonLabels}
      editable={projects.current?.createdBy === 'customer'}
      location='sidebar'
    >
      <Wrapper />
    </ProjectContextProvider>
  )
}
