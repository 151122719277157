import { Navigate } from 'react-router-dom'

import { useOverState } from 'store'
import { useQuery } from 'hooks'

import type { FC } from 'react'

export const PublicRoute: FC = ({ children }) => {
  const { user } = useOverState().app
  const { redirect } = useQuery(['redirect'])

  if (user) return <Navigate replace to={redirect ?? '/'} />
  return <>{children}</>
}
