import { VFC, useEffect, useContext, lazy } from 'react'
import { Route, Routes } from 'react-router-dom'
import { BasicToast, ToastContext } from '@ubnt/ui-components/Toast'

import { useOverState, useActions } from 'store'
import { PrivateRoute, PublicRoute, ToastMessage } from 'components'
import { PublicSite, Site } from 'pages'
import { Login } from './Login'

import type { ToastType } from 'types'

const Onboarding = lazy(() => import('app/onboarding'))

export const PreSite: VFC = () => {
  const { toast } = useOverState().app
  const { resetToast } = useActions().app

  useEffect(() => {
    if (toast.message) {
      renderToast(toast.message, toast.title, toast.type)
      resetToast()
    }
  }, [toast.message])

  const { createNotification } = useContext(ToastContext)
  const renderToast = (message: string | string[], toastTitle: string, type: ToastType) => {
    const details = Array.isArray(message) ? message : new Array(message)

    createNotification(
      <BasicToast
        details={<ToastMessage messages={details} />}
        duration={type === 'danger' ? 6000 : 3000}
        stateIndicator={type}
        title={toastTitle}
      />,
    )
  }

  return (
    <Routes>
      <Route
        path='/onboard/:token'
        element={
          <PublicSite>
            <Onboarding />
          </PublicSite>
        }
      />
      <Route
        path='/login'
        element={
          <PublicSite>
            <PublicRoute>
              <Login />
            </PublicRoute>
          </PublicSite>
        }
      />
      <Route
        path='*'
        element={
          <PrivateRoute sendOrigin={true}>
            <Site />
          </PrivateRoute>
        }
      />
    </Routes>
  )
}

export default PreSite
