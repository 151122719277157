import { Input as UIInput, InputProps } from '@ubnt/ui-components'
import { isNotNil } from 'ramda'
import { FieldValues, useController, UseControllerProps } from 'react-hook-form'
import styled from 'styled-components'

type Props<A extends FieldValues = FieldValues> = UseControllerProps<A> &
  InputProps & {
    handleChange?: <B extends string | number>(value: B) => [boolean, B]
    formatValue?: (s: string | number) => string | number
    marginBottom?: number
  }

export const ControlledInput = <A extends FieldValues = FieldValues>({
  control,
  name,
  formatValue,
  handleChange,
  marginBottom,
  ...restProps
}: Props<A>) => {
  const {
    field: { onChange, value, ...inputProps },
  } = useController<A>({
    name,
    control,
  })

  return (
    <Input
      marginbottom={marginBottom}
      {...restProps}
      {...inputProps}
      value={formatValue ? formatValue(value) : value}
      onChange={(_event, value) => {
        if (!handleChange) return onChange(value)
        const [change, val] = handleChange(value)
        if (change) return onChange(val)
      }}
    />
  )
}

const Input = styled(UIInput)<{ marginbottom?: number }>`
  ${({ marginbottom }) => (isNotNil(marginbottom) ? `margin-bottom: ${marginbottom}px;` : '')}
`
