import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

import { overmind } from 'store'
import config from 'config'

const isOwn = (url: string) => !url.startsWith('http')

const makeOptions = (
  url: string,
  headers?: Record<string, string>,
  data?: any,
): AxiosRequestConfig => {
  const own = isOwn(url)

  return {
    data,
    headers: own
      ? {
          ...headers,
          'X-App-Build': config.BUILD,
        }
      : headers,
    withCredentials: own,
  }
}

const makeUrl = (url: string) => (isOwn(url) ? `${config.API_URL}/api/${url}` : url)

const parseBuild =
  (url: string) =>
  ({ data, headers }: AxiosResponse) => {
    if (isOwn(url) && headers['x-build'] === 'outdated') overmind.actions.app.setBuild('outdated')
    return data
  }

export const http = {
  get: <A>(url: string, headers?: Record<string, string>): Promise<A> =>
    axios.get(makeUrl(url), makeOptions(url, headers)).then(parseBuild(url)),
  post: <A>(url: string, data?: any, headers?: Record<string, string>): Promise<A> =>
    axios.post(makeUrl(url), data, makeOptions(url, headers)).then(parseBuild(url)),
  put: <A>(url: string, data?: any, headers?: Record<string, string>): Promise<A> =>
    axios.put(makeUrl(url), data, makeOptions(url, headers)).then(parseBuild(url)),
  delete: <A>(url: string, data?: any, headers?: Record<string, string>): Promise<A> =>
    axios.delete(makeUrl(url), makeOptions(url, headers, data)).then(parseBuild(url)),
}

export const makeAwsUrl = (key: string) => `${config.AWS_URL}/${key}`
