import { always, cond, equals, includes, propEq, T, where, whereEq, __ } from 'ramda'

import { CheckmarkIcon, DocumentIcon } from '../styles'
import { QuoteStatus } from 'shared'
import texts from '../../texts.json'

import type { FC } from 'react'
import type { GetInfoTextOptions, InfoTexts } from './types'
import type { QuoteCheck } from '../types'

const getInfoPanel = cond<[GetInfoTextOptions], InfoTexts>([
  [
    whereEq({ checkout: 'ui', hasUiItems: true, status: QuoteStatus.SENT }),
    always(texts.infoPanel[1]),
  ],
  [whereEq({ hasUiItems: true, status: QuoteStatus.SENT }), always(texts.infoPanel[0])],
  [
    where({
      hasProItems: equals(true),
      status: includes(__, [
        QuoteStatus.SENT,
        QuoteStatus.PENDING_FULFILMENT,
        QuoteStatus.PARTIALLY_COMPLETED,
      ]),
    }),
    always(texts.infoPanel[2]),
  ],
  [propEq(QuoteStatus.FINAL_PAYMENT_PENDING, 'status'), always(texts.infoPanel[3])],
  [T, always(texts.infoPanel[4])],
])

export const getInfoData = ({
  checkout,
  hasProItems,
  hasUiItems,
  quote,
}: QuoteCheck): [FC, InfoTexts] => {
  const opts = {
    checkout,
    hasProItems,
    hasUiItems,
    status: quote.status,
  }

  const Icon =
    quote.status === QuoteStatus.COMPLETED ||
    (!hasProItems && quote.status === QuoteStatus.PENDING_FULFILMENT)
      ? CheckmarkIcon
      : DocumentIcon

  return [Icon, getInfoPanel(opts)]
}
