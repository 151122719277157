import { Text } from '@ubnt/ui-components/Text'
import styled from 'styled-components'

import { toLocaleCurrency } from 'helpers'
import { formatDate } from './helpers'
import { isNil } from 'ramda'
import { Container } from './styles'
import { FlexRow, UserAvatar } from 'components'
import { ShippingAddress } from './ShippingAddress'

import type { FC } from 'react'
import type { QuoteListItem, SectionPrice } from 'store/quotes/types'

interface Props {
  order: QuoteListItem
}

export const PaymentDetails: FC<Props> = ({ order }) => {
  const orderData = order.order

  const { productVariants: price } = order.priceBreakdown

  return (
    <Container className='column borderless no_padding'>
      <Container className='column'>
        <Text weight='bold' size='body'>
          Order Number
        </Text>
        <Text size='body'>{orderData?.orderNumber ?? '-'}</Text>
      </Container>
      <Container>
        <Text weight='bold' size='body'>
          Date Placed
        </Text>
        <Text size='body'>{formatDate(order.order?.createdAt)}</Text>
      </Container>
      <Container>
        <Text weight='bold' size='body'>
          Professional
        </Text>
        <Text size='body'>
          <UserAvatar fallbackUrl={order.professional.avatar} user={order.professionalUser} />
        </Text>
      </Container>
      <ShippingAddress shippingData={orderData?.shipping} />
      <Container className='column borderless'>
        <Container className='borderless'>
          <Text weight='bold' size='body'>
            Items Subtotal
          </Text>
          <Subtotal price={price} />
        </Container>
        {price.refunded && (
          <Container className='borderless'>
            <Text weight='bold' size='body'>
              Refunded
            </Text>
            <Text size='body'>- {toLocaleCurrency(price.refunded)}</Text>
          </Container>
        )}
        <Container className='borderless'>
          <Text weight='bold' size='body'>
            Shipping
          </Text>
          <Text size='body'>{price.shipping ? toLocaleCurrency(price.shipping) : 'Free'}</Text>
        </Container>
        <Container className='borderless'>
          <Text weight='bold' size='body'>
            Tax
          </Text>
          <Text size='body'>{toLocaleCurrency(price.tax)}</Text>
        </Container>
        <Container className='borderless'>
          <Text weight='bold' size='body'>
            Order Total
          </Text>
          <Text size='body'>{toLocaleCurrency(price.total)}</Text>
        </Container>
      </Container>
    </Container>
  )
}

interface SubtotalProps {
  price: SectionPrice
}

const Subtotal = ({ price }: SubtotalProps) => {
  if (isNil(price.recalculated)) return <Text size='body'>{toLocaleCurrency(price.base)}</Text>

  return (
    <FlexRow gap={8}>
      <Text size='body'>{toLocaleCurrency(price.recalculated)}</Text>
      <StrikeThrough size='body'>{toLocaleCurrency(price.base)}</StrikeThrough>
    </FlexRow>
  )
}

const StrikeThrough = styled(Text)`
  text-decoration: line-through;
`
