import { Button, Text } from '@ubnt/ui-components'
import { SidePanel } from '@ubnt/ui-components/SidePanel'
import styled from 'styled-components'

import { addVariantTitle, renderBadge } from 'helpers'
import { useActions, useOverState } from 'store'
import { useOutsideClick } from 'hooks'

import type { BadgeProps } from 'components'
import type { FC } from 'react'
import type { Product } from 'store/quotes/types'

interface Props {
  product?: Product
}

export const ProductInfoSidePanel: FC<Props> = ({ product }) => {
  const { sidebar } = useOverState().modal
  const {
    modal: { closeSidebar },
  } = useActions()

  const statusDict: Record<string, BadgeProps['type']> = {
    'In Stock': 'green',
    'Out of Stock': 'yellow',
  }

  const ref = useOutsideClick(closeSidebar, { checkModal: false })

  return (
    <SidePanel
      style={{ border: 'none' }}
      header='Product Detail'
      in={sidebar === 'product_info'}
      onClose={closeSidebar}
      ref={ref}
    >
      {product && (
        <Container>
          <Text size='body' style={{ width: `${product.inStock ? '58px' : '80px'}` }}>
            {renderBadge(statusDict)(product.inStock ? 'In Stock' : 'Out of Stock')}
          </Text>
          <ImageWrapper>
            <StyledImage src={product.thumbnail} alt='' />
          </ImageWrapper>
          <Row>
            <Title color='header' weight='bold' size='body' truncate>
              {product.title}
            </Title>
            <Text size='body'>${product.price}</Text>
          </Row>
          <Text style={{ margin: '16px 0' }} color='header' weight='bold' size='body'>
            {`What is ${product.title}`}
          </Text>
          <Text>
            {product.shortDescription ? (
              <Text size='caption'>{addVariantTitle(product, 'shortDescription')}</Text>
            ) : (
              `No description available.`
            )}
          </Text>
          <ButtonWrapper>
            <Button onClick={closeSidebar}>Cancel</Button>
            <Button variant='primary' onClick={() => window.open(product.url, '_blank')}>
              View at UI.com
            </Button>
          </ButtonWrapper>
        </Container>
      )}
    </SidePanel>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 28px;
`

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 28px 0 0 0;
`

const StyledImage = styled.img`
  height: 205px;
  width: 205px;
`

const Title = styled(Text)`
  width: 200px;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  border-bottom: ${({ theme }) => `1px solid ${theme.borderColor}`};
  border-top: ${({ theme }) => `1px solid ${theme.borderColor}`};
`
