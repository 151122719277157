import React, { FC } from 'react'
import { Text } from '@ubnt/ui-components'
import styled from 'styled-components'

interface Props {
  messages: string[]
}

export const ToastMessage: FC<Props> = ({ messages }) => {
  return (
    <Container>
      {messages.map((message) => (
        <Text key={message}>{message}</Text>
      ))}
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`
