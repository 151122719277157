import { Loader as UILoader } from '@ubnt/ui-components'

import { FullscreenLoader } from './FullscreenLoader'
import { AsyncFuncState } from 'shared'

import type { FC } from 'react'

export interface LoaderProps {
  cond?: boolean
  otherwise?: string | JSX.Element | null
  status: AsyncFuncState
  type?: 'full' | 'small'
}

export const Loader: FC<LoaderProps> = ({
  cond = true,
  otherwise,
  status,
  type = 'full',
  children,
}) => {
  if (status !== AsyncFuncState.LOADING || !cond) return <>{children}</>
  if (status === AsyncFuncState.LOADING && !cond) return <>{otherwise}</>
  if (type === 'full') return <FullscreenLoader />
  return <UILoader />
}
