import { FC, useMemo, useState } from 'react'
import { Tab, Tabs } from '@ubnt/ui-components/Tabs'
import { Text } from '@ubnt/ui-components/Text'
import styled from 'styled-components'

import { CategoryData } from './constants'
import { addVariantTitle, toLocaleCurrency } from 'helpers'
import { useActions } from 'store'

import type { Product } from 'store/quotes/types'

interface Props {
  products: Product[]
  category: keyof typeof CategoryData
}

export const tabs = [
  { id: 'overview', label: 'Overview' },
  { id: 'itemList', label: 'Item List' },
]

const AccordionContent: FC<Props> = ({ products, category }) => {
  const [tab, setTab] = useState(tabs[0])
  const actions = useActions()

  const handleTabSelection = (tab: Tab) => {
    setTab(tabs.find((t) => t.id === tab.id) as { id: string; label: string; disabled: boolean })
  }

  const openProductInfo = (product: Product) =>
    actions.modal.setSidebar({
      sidebar: 'product_info',
      props: {
        product,
      },
    })

  const accordionProducts = useMemo(() => {
    return products.map((p) => (
      <ProductWrapper key={p.id} onClick={() => openProductInfo(p)}>
        <Text className='ellipsis'>{addVariantTitle(p, 'title')}</Text>
        <TotalWrapper>
          <Text weight='bold'>{toLocaleCurrency(Number(p.price))}</Text>
          <Text>{`x ${p.adjusted.qty ?? p.quantity}`}</Text>
        </TotalWrapper>
      </ProductWrapper>
    ))
  }, [products])

  const categoryDesc = useMemo(() => {
    return (
      <OverviewWrapper>
        <StyledImg src={CategoryData[category].image} alt='category image' />
        <TextWrapper>
          <Text>{CategoryData[category].desc}</Text>
        </TextWrapper>
      </OverviewWrapper>
    )
  }, [category])

  return (
    <Container>
      <TabsHolder>
        <Tabs
          className='tab'
          variant='tertiary'
          selectedTab={tab?.id}
          tabs={tabs.filter((t) => (products.length < 1 ? t.id !== 'itemList' : true))}
          onSelectTab={(o) => handleTabSelection(o)}
        />
      </TabsHolder>
      {tab === tabs[0] && categoryDesc}
      {tab === tabs[1] && accordionProducts}
    </Container>
  )
}

export default AccordionContent

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ProductWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 100%;
  padding: 8px 20px;
  margin: 5px 0;

  .ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: 170px;
  }

  :hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.Light.color.blue.b1};
  }

  .link {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.textColor.black.b65};

    :hover {
      color: ${({ theme }) => theme.colors.Light.color.blue.b6};
    }
  }
`

const TabsHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 48px;
  padding: 5px 20px;
  margin-bottom: 5px;
  border-bottom: ${({ theme }) => `1px solid ${theme.borderColor}`};

  > div {
    display: flex;
    justify-content: flex-start;
  }

  .tab > div {
    margin: 0;
  }

  .tab button {
    &:focus {
      outline: none;
    }
  }
`

const OverviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px 15px;
`

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
`

const StyledImg = styled.img`
  margin: 10px;
`
