import { FC } from 'react'
import { Text } from '@ubnt/ui-components/Text'
import { FlexColumn, FlexRow } from 'components'
import type { Product, QuoteProHardware, QuoteProService } from 'store/quotes/types'
import { toLocaleCurrency } from 'helpers'
import HardwareIcon from 'assets/icons/Hardware.svg'
import ServiceIcon from 'assets/icons/Service.svg'

interface Props {
  item: Product | QuoteProHardware | QuoteProService
}

const renderTile = (image: string, name: string, amount: number, quantity?: number) => {
  return (
    <FlexRow height={64} verticalSpacing={8}>
      <FlexRow>
        <img
          src={image}
          alt='Item thumbnail'
          style={{ width: '40px', height: '40px', marginRight: '8px' }}
        />
        <Text size='body'>{name}</Text>
      </FlexRow>

      <FlexColumn alignItems='flex-end'>
        <Text weight='bold'>{toLocaleCurrency(amount)}</Text>
        <Text>{quantity && `x ${quantity}`}</Text>
      </FlexColumn>
    </FlexRow>
  )
}

const renderHardware = (item: Product) => {
  const { thumbnail, name, price, quantity, adjusted } = item
  const _quantity = adjusted.qty ?? quantity

  if (adjusted.type === 'removed') return null
  return renderTile(thumbnail, name, price, _quantity)
}

const renderProHardware = (item: QuoteProHardware) => {
  const { name, price, qty, adjusted } = item

  if (adjusted.type === 'removed') return null
  return renderTile(HardwareIcon, name, adjusted.price ?? price, adjusted.qty ?? qty)
}

const renderProService = (item: QuoteProService) => {
  const { name, price, adjusted } = item

  if (adjusted.type === 'removed') return null
  return renderTile(ServiceIcon, name, adjusted.price ?? price)
}

export const ItemTile: FC<Props> = ({ item }) => {
  return 'inStock' in item
    ? renderHardware(item)
    : 'qty' in item
    ? renderProHardware(item)
    : renderProService(item)
}
