import type { AsyncFuncState } from 'shared'
import { WithOnSuccess } from 'store/types'
import type { Base, Location, Professional } from 'types'

export interface ProjectsState {
  current: Project | null
  error: null | {
    code: number
    message: string
  }
  loadingSection: LoadingSection
  professionalUser: ProfessionalUser | null
  names: {
    current: null | string
    hash: Record<string, ProjectName>
    list: ProjectName[]
  }
  status: AsyncFuncState
  submitModal: null | SubmitModal
  tokenData: ProjectTokenData['data'] | null
}

export interface AddProjectResult {
  path: string
}

export interface BuildingCameras {
  qty: number
  note: string
}

export interface BuildingDoors {
  qty: number
  note: string
}

export enum BuildingType {
  HOME = 'Home',
  OFFICE = 'Office',
  SCHOOL = 'School',
  HOTEL = 'Hotel',
  STADIUM = 'Stadium',
  OTHER = 'Other',
}

export interface BuildingVoIP {
  qty: number
  note: string
}

export interface BuildingWiFi {
  id: string
  name: string
  area: number
  roomQty: number
  note: string
  plans: FloorPlan[]
}

export interface NewWifi extends BuildingWiFi {
  plan?: Plan
  size?: number
}

export interface CloseModalOptions {
  closeModal?: boolean
}

export interface CreateAccountOptions {
  account: {
    password: string
    username: string
    mailingList: boolean
    termsAndConditions: boolean
  }
  token: string
}

export type CreateAccountAndProjectOptions = CreateAccountOptions &
  CreateProjectOptions &
  WithOnSuccess

export interface CreateProject {
  name: string
  location: Location
  buildingType: BuildingType
  buildingSize: ProjectSize
  userQty: Size
  wifiIndoor: BuildingWiFi[]
  wifiOutdoor: BuildingWiFi[]
  doors: BuildingDoors
  cameras: BuildingCameras
  voIP: BuildingVoIP
}

export interface CreateProjectOptions {
  project: CreateProject
}

export interface UploadFilesInput<A extends CreateProjectOptions> {
  payload: A
  indoor: Plan[]
  outdoor: Plan[]
}

export interface DeleteFloorOptions {
  projectId: string
  floorId: string
}

export interface FilesName {
  name: string
  url: string
}

export interface Files {
  data: FilesName[]
}

export interface Plan {
  name: string
  file: File
}

export interface GetProjectNamesOptions {
  current: string | null
}

export interface GetProjectsOptions {
  onlyNames?: boolean
}

export type LoadingSection = 'form' | 'full' | 'sidebar' | 'table' | null

export interface FloorPlan {
  plan: string
  thumb?: string
}

export interface ParseTokenOptions {
  token: string
}

export type CreateProjectTokenOptions = CreateProjectOptions & ParseTokenOptions

export interface Project extends Base {
  clientId: string
  name: string
  location: Location
  buildingType: BuildingType
  buildingSize: ProjectSize
  userQty: Size
  wifiIndoor: BuildingWiFi[]
  wifiOutdoor: BuildingWiFi[]
  doors: BuildingDoors
  cameras: BuildingCameras
  voIP: BuildingVoIP
  quoteCount: number
  createdBy: 'customer' | 'professional'
}

export type PutProjectOptions = CreateProjectOptions & WithProjectId & WithOnSuccess

export type NewProject = Pick<
  Project,
  'name' | 'location' | 'wifiIndoor' | 'wifiOutdoor' | 'cameras' | 'doors' | 'voIP'
> &
  Partial<Pick<Project, 'buildingType' | 'buildingSize' | 'userQty'>>

export type UpdateProject = Pick<
  Project,
  | 'name'
  | 'location'
  | 'buildingType'
  | 'buildingSize'
  | 'userQty'
  | 'wifiIndoor'
  | 'wifiOutdoor'
  | 'cameras'
  | 'doors'
  | 'voIP'
>

export interface ProfessionalUser {
  avatar: string | null
  email: string
  firstName: string
  lastName: string
  username: string
  phone: string
  proCompanyAvatar: Professional['avatar']
}

export interface ProjectName {
  id: string
  clientId: string
  name: string
  buildingType: BuildingType
  createdAt: string
}

export enum ProjectSize {
  SMALL = 'Small',
  MEDIUM = 'Medium',
  LARGE = 'Large',
  XLARGE = 'Extra Large',
}

export interface ProjectTokenData {
  token: string
  type: 'project'
  data: {
    clientEmail: string
    clientId: string
    clientName: string
    professionalId: string
    professionalName: string
    professionalUserId: string | null
  }
  spent: boolean
  createdAt: string
  updatedAt: string
}

export enum Size {
  XSMALL = 'Extra Small',
  SMALL = 'Small',
  MEDIUM = 'Medium',
  LARGE = 'Large',
  XLARGE = 'Extra Large',
}

export interface SubmitModal {
  professionalId: string
  professionalName: string
}

export interface UpdateProjectOptions {
  projectId: string
  project: Project
}

export type UploadPlanResult = Promise<{ plan: string; url: string }[]>

export interface WithNames {
  names: ProjectName[]
}

export interface WithMaybeProjectId {
  projectId: string | null
}

export interface WithProjectId {
  projectId: string
}

export interface WithProject {
  project: Project
}

export interface WithProjectId {
  projectId: string
}
