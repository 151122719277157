import { FC, useCallback, useEffect } from 'react'
import { Modal } from '@ubnt/ui-components/Modal'
import { isNil, map } from 'ramda'

import { useActions, useOverState } from 'store'
import { Badge, Loader, UserAvatar, getUserName } from 'components'
import { BubbleContainer, BubbleHeader, AvatarWrapper, CheckIcon, Text, Wrapper } from './styles'
import { text } from './content'

const makeContent = map<string, JSX.Element>((x) => (
  <Text key={x.slice(0, 12)} size='body'>
    {x}
  </Text>
))

export const SubmitModal: FC = () => {
  const {
    modal: { status },
    projects: { professionalUser },
  } = useOverState()
  const actions = useActions()

  useEffect(() => {
    actions.projects.getProfessionalUser()
    return actions.projects.resetProfessionalUser
  }, [])

  const close = useCallback(() => {
    actions.modal.closeModal()
    actions.projects.resetToken()
  }, [])

  return (
    <Modal
      size='small'
      headerClassName='hide-svg'
      isOpen
      onRequestClose={close}
      title='Your project has been submitted'
      shouldCloseOnOverlayClick={false}
      actions={[
        {
          text: 'Done',
          onClick: close,
          variant: 'primary',
        },
      ]}
    >
      <Wrapper>
        <CheckIcon />
        {makeContent(text)}
        <BubbleContainer headerContentClassName='hidden' headerTitle=''>
          <Loader status={status}>
            {isNil(professionalUser) && (
              <Text size='body'>Sorry, we couldn&apos;t find a professional user.</Text>
            )}
            {isNil(professionalUser) || (
              <>
                <BubbleHeader>
                  <AvatarWrapper
                    avatar={professionalUser.avatar || professionalUser.proCompanyAvatar || null}
                  >
                    <UserAvatar
                      fallbackUrl={professionalUser.proCompanyAvatar}
                      hideName
                      size='medium'
                      user={professionalUser}
                    />
                  </AvatarWrapper>
                  <Badge size='medium' type='blue'>
                    Your Professional
                  </Badge>
                </BubbleHeader>
                <Text size='body'>{getUserName(professionalUser)}</Text>
                <Text size='body'>{professionalUser.email}</Text>
                <Text size='body'>{professionalUser.phone}</Text>
              </>
            )}
          </Loader>
        </BubbleContainer>
      </Wrapper>
    </Modal>
  )
}
