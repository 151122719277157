// @ts-nocheck
import { useEffect } from 'react'

import useAsyncSafeState from './useAsyncSafeState'

/**
 * TODO: consider moving to ResizeObserver
 * `calculationKey` is an advanced argument prompting the re-calculation of size when the `calculationKey` changes
 */
export default function useSize(widthRef, heightRef = widthRef, calculationKey?) {
  const [width, setWidth] = useAsyncSafeState(null)
  const [height, setHeight] = useAsyncSafeState(null)

  useEffect(() => {
    const handleResize = () => {
      if (widthRef && widthRef.current) {
        setWidth(widthRef.current.getBoundingClientRect().width)
      }

      if (heightRef && heightRef.current) {
        setHeight(heightRef.current.getBoundingClientRect().height)
      }
    }

    /* fix a bug related to width being incorrect until 1st resize. similar to
       this issue: https://github.com/dixonandmoe/rellax/issues/115 */
    setTimeout(handleResize, 0)

    if (widthRef && widthRef.current) {
      setWidth(widthRef.current.getBoundingClientRect().width)
    }

    if (heightRef && heightRef.current) {
      setHeight(heightRef.current.getBoundingClientRect().height)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [calculationKey])

  return { width, height }
}
