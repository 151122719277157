import { http } from 'helpers'
import type { ResponseWithMetaData } from 'store/types'
import type { Dispute } from 'types'
import type {
  DisputeOptions,
  Feedback,
  IncomingQuote,
  IncomingQuoteListItem,
  UpdateDisputeOptions,
} from './types'

export const api = {
  dispute: ({ quoteId, dispute }: DisputeOptions) =>
    http.post<Dispute>(`quotes/${quoteId}/dispute`, { dispute }),
  getCheckoutUrl: (quoteId: string) => http.post<string>(`stores/checkout`, { quoteId }),
  getQuote: (quoteId: string) =>
    http.get<IncomingQuote | ResponseWithMetaData<IncomingQuote>>(`quotes/${quoteId}?list=false`),
  getQuotesByProject: (projectId: string, status: string, withInventory: boolean) =>
    http.get<IncomingQuoteListItem[]>(
      `quotes/?projectId=${projectId}&quote=${status}&withInventory=${withInventory}`,
    ),
  postFeedback: (quoteId: string, feedback: Feedback) =>
    http.post(`quotes/${quoteId}/feedback`, feedback),
  updateDispute: ({ disputeId, dispute }: UpdateDisputeOptions) =>
    http.put<Dispute>(`disputes/${disputeId}`, { dispute }),
}
