import { TableColumnConfig } from '@ubnt/ui-components'

import { UserAvatar } from 'components'
import { formatDate, renderStatus } from './helpers'
import { toLocaleCurrency } from 'helpers'

import type { OrderRow } from './useOrderRows'

export const ORDERS_COLUMNS: TableColumnConfig<OrderRow>[] = [
  {
    id: 'quoteName',
    label: 'Order',
    minWidth: 100,
  },
  {
    id: 'total',
    label: 'Total',
    minWidth: 100,
    renderCell: ({ total }) => toLocaleCurrency(total),
  },
  {
    id: 'professionalUser',
    label: 'Professional',
    minWidth: 100,
    renderCell: ({ professionalUser, professional }) => (
      <UserAvatar fallbackUrl={professional.avatar} user={professionalUser} />
    ),
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 100,
    renderCell: ({ displayStatus }) => renderStatus(displayStatus),
  },
  {
    id: 'createdAt',
    label: 'Order Placed',
    minWidth: 100,
    renderCell: ({ orderPurchaseDateTime }) => formatDate(orderPurchaseDateTime),
  },
]
