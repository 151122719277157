import { branch, pipe } from 'overmind'

import * as op from './operators'
import { redirectToPath, resetCaptcha } from 'store/app/actions'
import {
  handleAsyncAction,
  rethrowError,
  runOnSuccess,
  setStatus,
  whenUserIsLoggedIn,
} from 'store/operators'
import { indexById } from 'helpers'
import { handleModalAction } from 'store/modal/helpers'
import { handleError } from 'store/helpers'
import { AsyncFuncState } from 'shared'

import type {
  CreateAccountAndProjectOptions,
  GetProjectNamesOptions,
  LoadingSection,
  ParseTokenOptions,
  PutProjectOptions,
  SubmitModal,
  UpdateProjectOptions,
  WithMaybeProjectId,
  WithNames,
  WithProjectId,
} from './types'
import type { Context } from 'store'
import type { AsyncError } from 'store/types'

const handleProjectAction = handleAsyncAction('projects')

export const addProject = handleProjectAction(pipe(op.addProject, redirectToPath))

export const setCurrentProjectName = ({ state }: Context, { projectId }: WithMaybeProjectId) => {
  state.projects.names.current = projectId
}

export const setCurrentProject = ({ state }: Context, { project }: UpdateProjectOptions) => {
  state.projects.current = project
}

export const setProjectNames = ({ state }: Context, { names }: WithNames) => {
  state.projects.names.hash = indexById(names)
}

export const setError = ({ state }: Context, { error }: AsyncError) => {
  state.projects.error = {
    code: error.response?.status ?? 500,
    message: handleError(error),
  }
}

export const setLoadingSection =
  (section: LoadingSection) =>
  ({ state }: Context) => {
    state.projects.loadingSection = section
  }

export const createAccountAndProject = handleProjectAction<CreateAccountAndProjectOptions>({
  after: branch(setLoadingSection(null)),
  before: branch(setLoadingSection('form')),
  action: pipe(
    whenUserIsLoggedIn({
      true: branch(
        branch(op.extractFiles, op.uploadFiles, op.createProjectWithToken),
        op.getProjectNames,
        branch(setProjectNames),
        op.passFirstProjectName,
        setCurrentProjectName,
      ),
      false: branch(
        branch<CreateAccountAndProjectOptions, void>(op.createAccount),
        op.createProjectWithToken,
      ),
    }),
    runOnSuccess(),
  ),
  onError: resetCaptcha,
})

export const getProfessionalUser = handleModalAction<void>(op.getProfessionalUser)

export const getProject = handleProjectAction<WithProjectId, void>(
  pipe(op.getProject, setCurrentProject),
)

export const getProjectNames = handleProjectAction<GetProjectNamesOptions, void>(
  pipe(
    op.getProjectNames,
    branch<GetProjectNamesOptions & WithNames, void>(setProjectNames),
    op.whenCurrentIsInProjects({
      true: () => undefined,
      false: pipe(op.passFirstProjectName, setCurrentProjectName),
    }),
  ),
)

export const parseToken = handleProjectAction<ParseTokenOptions, void>({
  after: branch(setLoadingSection(null)),
  before: branch(setLoadingSection('full')),
  action: whenUserIsLoggedIn<ParseTokenOptions, void>({
    true: op.parseToken,
    false: pipe(branch(op.parseToken), op.validateTokenEmail),
  }),
  onError: setError,
  toastOnError: false,
})

export const resetCurrent = ({ state }: Context) => {
  state.projects.current = null
}

export const resetProfessionalUser = ({ state }: Context) => {
  state.projects.professionalUser = null
}

export const resetToken = ({ state }: Context) => {
  state.projects.tokenData = null
}

export const setSubmitModal = ({ state }: Context, value: SubmitModal | null) => {
  state.projects.submitModal = value
}

export const updateProject = handleProjectAction<
  PutProjectOptions,
  PutProjectOptions & UpdateProjectOptions
>({
  action: pipe(
    op.extractFiles,
    op.uploadFiles,
    op.updateProject,
    branch<PutProjectOptions & UpdateProjectOptions, void>(setCurrentProject),
    branch(op.updateProjectName),
    runOnSuccess(),
  ),
  after: branch(setLoadingSection(null)),
  before: branch(setLoadingSection('sidebar')),
  onError: pipe(setStatus('projects', AsyncFuncState.ERROR), rethrowError),
})
