import { AsyncFuncState, Pages } from 'shared/constants'
import { AppState } from './types'

export const state: AppState = {
  build: 'current',
  error: null,
  loadingSection: null,
  page: Pages.QUOTES,
  status: AsyncFuncState.NULL,
  toast: {
    message: '',
    title: '',
    type: 'info',
    duration: null,
  },
  recaptcha: null,
  user: null,
}
