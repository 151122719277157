import { Text, TextProps } from '@ubnt/ui-components/Text'

import { FlexColumn } from 'components'
import { Container } from './styles'
import texts from './texts.json'

import type { FC } from 'react'
import type { TileProps } from './types'

export const Tile: FC<TileProps> = ({
  backorder,
  border = false,
  icon,
  margin = 0,
  name,
  size = 'medium',
  value,
}) => {
  const [normal, small]: TextProps['size'][] =
    size === 'medium' ? ['body', 'caption'] : ['caption', 'label']

  const main = typeof name === 'string' ? <Text size={normal}>{name}</Text> : name
  const secondary = typeof value === 'string' ? <Text size={normal}>{value}</Text> : value

  return (
    <Container border={border} margin={margin}>
      {icon}
      <FlexColumn grow={1} justify='center' style={{ marginLeft: 8 }}>
        {main}
        {backorder && (
          <Text color='warning' size={small}>
            {texts.backorder}
          </Text>
        )}
      </FlexColumn>
      {secondary}
    </Container>
  )
}
