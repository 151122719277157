import { useRef, useCallback, useEffect, useState } from 'react'

export default function useAsyncSafeState<S = any>(
  initialState: S,
): [S, (nextState: S | ((currentState: S) => S)) => void] {
  const [state, setState] = useState<S>(initialState)
  const isMounted = useRef<boolean>(false)

  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  const callback = useCallback<(nextState: S | ((currentState: S) => S)) => void>(
    (nextState) => {
      if (isMounted.current) {
        setState(nextState)
      }
    },
    [isMounted.current, setState],
  )

  return [state, callback]
}
