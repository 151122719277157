import { VFC, useEffect, useState } from 'react'
import { Button, Tab } from '@ubnt/ui-components'
import styled from 'styled-components'

import { useOverState, useActions } from 'store'
import { tabs } from './tabs'
import { Container, SidePanel, Tabs } from './styles'
import { ProPaymentDetails } from './ProPaymentDetails'
import { PaymentDetails } from './PaymentDetails'
import { Tracking } from './Tracking'
import { FeedbackSection } from './FeedbackSection'
import { useScreenWidth, useSlider, Screen, useBackNavigate } from 'hooks'
import { getQuoteId } from './helpers'

import type { OrderSidebarA, OrderSidebarB } from 'store/modal/types'
import type { Quote } from 'store/quotes/types'

export const OrderDetails: VFC = () => {
  const props = useOverState().modal.sidebarProps as OrderSidebarA | OrderSidebarB
  const actions = useActions()
  const [tab, setTab] = useState('payment')
  const navigate = useBackNavigate()
  const { Slider, closeSlider } = useSlider(actions.modal.closeSidebar)
  const width = useScreenWidth() <= Screen.MOBILE ? 352 : undefined

  const item = 'quote' in props ? props.quote : props.order
  const id = getQuoteId(item.id)

  useEffect(() => {
    props.type === 'professional' && actions.orderDetails.getCard({ quoteId: id })
    return actions.orderDetails.reset
  }, [])

  const handleTabSelection = (selectedTab: Tab) => {
    setTab(selectedTab.id)
  }

  const handleView = () => {
    if (props.from === 'quote') return closeSlider()
    navigate(`/quotes/${id}`)
    actions.modal.closeSidebar()
  }

  return (
    <Slider width={width}>
      <SidePanel
        header={props.type === 'ui' ? 'Order Details' : 'Additional Hardware and Services'}
        in
        onClose={closeSlider}
        width={width}
      >
        {props.type === 'ui' && (
          <Tabs
            tabClassName='tabs'
            selectedTabClassName='selected'
            selectedTab={tab}
            tabs={tabs(tab)}
            onSelectTab={handleTabSelection}
          />
        )}

        <Container
          className={`column borderless main no_padding color ${
            props.type === 'ui' ? '' : 'withoutTabs'
          }`}
        >
          <FeedbackSection order={item} quoteId={id} />

          <Container className='column borderless height100 no_padding padding_h'>
            {tab === 'payment' && props.type === 'ui' && <PaymentDetails order={item} />}
            {tab === 'shipping' && props.type === 'ui' && <Tracking order={item.order} />}
            {props.type === 'professional' && <ProPaymentDetails quote={item as Quote} />}

            <Container className='borderless justify_end'>
              <Button onClick={closeSlider} variant='tertiary'>
                Close
              </Button>
              <PrimaryButton onClick={handleView} variant='primary'>
                {props.from === 'quote' ? 'Done' : 'View Item List'}
              </PrimaryButton>
            </Container>
          </Container>
        </Container>
      </SidePanel>
    </Slider>
  )
}

const PrimaryButton = styled(Button)`
  margin-left: 5px;
`
