import * as yup from 'yup'
import { Button, Modal as UIModal } from '@ubnt/ui-components'
import { FC, useMemo, useState } from 'react'
import { AddPrimaryIconL, TrashIcon as UITrashIcon } from '@ubnt/icons'
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form'
import { any, flatten, isEmpty, map, pipe, values } from 'ramda'
import styled from 'styled-components'
import { yupResolver } from '@hookform/resolvers/yup'

import { ControlledInput } from 'components/ControlledInput'
import { emailRegex, Pages } from 'shared'
import { isNotEmpty } from 'helpers'
import { parseContacts, removePrefix } from './helpers'
import { useActions, useEffects, useOverState } from 'store'

type FormValues = {
  contactList: Contact[]
}

export interface Contact {
  firstName: string
  lastName: string
  email: string
}

const emptyContact = {
  firstName: '',
  lastName: '',
  email: '',
}

export const InviteContacts: FC = () => {
  const { closeModal } = useActions().modal
  const { addContacts } = useActions().client
  const {
    app: { page, user },
    modal: { modal },
    quotes,
  } = useOverState()
  const { app } = useEffects()

  const [emailErrMessage, setErrMessage] = useState('')

  const validateEmail = (email?: string) => {
    if (!email || !emailRegex.test(email.toLowerCase())) return false
    return app.api
      .validateEmail({ email, client: true, pro: true, user: true }) //
      .then(({ isInvalid, message }) => {
        setErrMessage(message)
        return !isInvalid
      })
  }

  const formSchema = useMemo(
    () => ({
      firstName: yup.string().min(2).required(),
      lastName: yup.string().min(2).required(),
      email: yup.string().email().required().test('valid-email', '', validateEmail),
    }),
    [validateEmail],
  )

  const fieldSchema = useMemo(
    () =>
      yup.object().shape({
        contactList: yup.array().of(yup.object().shape(formSchema)),
      }),
    [formSchema],
  )

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<FormValues>({
    defaultValues: { contactList: [emptyContact] },
    resolver: yupResolver(fieldSchema),
    mode: 'onChange',
  })

  const { fields, append, remove } = useFieldArray<FormValues>({
    control,
    name: 'contactList',
  })

  const handleEnterKey = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') handleSubmit(onSubmit)()
  }

  const onSubmit: SubmitHandler<any> = (event) => {
    if ('key' in event && event.key !== 'Enter') return

    if (user?.clientId)
      addContacts({
        contacts: parseContacts(getValues().contactList),
        refetchUsers: page === Pages.PROJECT_PERMISSION,
        clientId: user.clientId,
        invitationEmail: [Pages.PROJECT_PERMISSION, Pages.QUOTE_SUMMARY].includes(page),
        quoteId: quotes.current.quote?.id,
      })
  }

  const isInvalidOrEmptyFields =
    isNotEmpty(errors) ||
    pipe<[Contact[]], string[][], string[], boolean>(
      map(values),
      flatten,
      any(isEmpty),
    )(getValues().contactList)

  return (
    <Modal
      title='Invite People'
      isOpen={modal === 'invite_contacts'}
      onRequestClose={closeModal}
      size='small'
      contentClassName='modal_body'
      shouldCloseOnOverlayClick={false}
      actions={[
        {
          text: 'Cancel',
          onClick: closeModal,
          variant: 'tertiary',
        },
        {
          text: 'Done',
          onClick: handleSubmit(onSubmit),
          variant: 'primary',
        },
      ]}
    >
      <div>
        {fields.map((field, index) => (
          <ContactContainer key={field.id}>
            <Row>
              <ControlledInput
                name={`contactList.${index}.firstName`}
                control={control}
                autoComplete='chrome-off'
                label='First Name'
                autoFocus
                width='49%'
                invalid={removePrefix(errors?.contactList?.[index]?.firstName?.message)}
                invalidProps={{ size: 'caption' }}
                onKeyDown={handleEnterKey}
                size='caption'
              />
              <ControlledInput
                name={`contactList.${index}.lastName`}
                control={control}
                autoComplete='chrome-off'
                label='Last Name'
                width='49%'
                invalid={removePrefix(errors?.contactList?.[index]?.lastName?.message)}
                invalidProps={{ size: 'caption' }}
                onKeyDown={handleEnterKey}
                size='caption'
              />
            </Row>

            <Row>
              <ControlledInput
                name={`contactList.${index}.email`}
                control={control}
                autoComplete='chrome-off'
                label='Email'
                width='100%'
                invalid={
                  errors?.contactList?.[index]?.email?.message === ''
                    ? emailErrMessage
                    : removePrefix(errors?.contactList?.[index]?.email?.message)
                }
                invalidProps={{ size: 'caption' }}
                onKeyDown={handleEnterKey}
                size='caption'
              />
              <IconWrapper>
                <TrashIcon onClick={() => remove(index)} />
              </IconWrapper>
            </Row>
          </ContactContainer>
        ))}
        <ButtonWrapper>
          <Button
            variant='link'
            onClick={() => append(emptyContact)}
            disabled={isInvalidOrEmptyFields}
            size='small'
            Icon={<AddPrimaryIconL />}
          >
            Add More
          </Button>
        </ButtonWrapper>
      </div>
    </Modal>
  )
}

const Modal = styled(UIModal)`
  height: 580px;

  .modal_body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.Light.color.neutral.n1};
  padding: 0 12px 12px;
  height: 165px;
  justify-content: space-around;
  margin-bottom: 8px;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`

const TrashIcon = styled(UITrashIcon)`
  width: 20px;
  height: 20px;
  margin-left: 12px;

  &:hover {
    cursor: pointer;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;
  padding-left: 15px;
`
