import { FC, useState } from 'react'
import { SidePanel as UISidePanel } from '@ubnt/ui-components/SidePanel'
import { isNil } from 'ramda'
import styled from 'styled-components'

import { useActions, useOverState } from 'store'
import { Loader } from 'components'
import { SidebarForm } from './SidebarForm'
import { Screen, useScreenWidth, useSlider } from 'hooks'
import { SidebarBody, Tabs } from './styles'
import { makeTabs, rejectDisabledTabs } from './helpers'
import { AsyncFuncState } from 'shared'

import type { Tab } from '@ubnt/ui-components/Tabs'
import type { ModalType, ProjectSidebar as SidebarType } from 'store/modal/types'

export const Wrapper: FC = () => {
  const { sidebar, sidebarProps } = useOverState().modal as ModalType<unknown, SidebarType>
  const { projects } = useOverState()
  const {
    modal: { closeSidebar },
  } = useActions()

  const tabs = makeTabs(projects.current)
  const [selectedTab, setSelectedTab] = useState<Tab>(tabs[0])

  const handleClose = () => {
    sidebarProps.onClose()
    closeSidebar()
  }

  const handleSelectTab = (x: Tab) => {
    if (projects.status === AsyncFuncState.LOADING) return
    return setSelectedTab(x)
  }

  const { Slider, closeSlider } = useSlider(handleClose, {
    disabled: projects.status === AsyncFuncState.LOADING,
  })

  const width = useScreenWidth() === Screen.MOBILE ? 352 : undefined

  if (!projects.current) return null

  return (
    <Slider width={width}>
      <SidePanel
        header={projects.current.name}
        in={sidebar === 'project_details'}
        onClose={closeSlider}
        width={width}
      >
        <Tabs
          onSelectTab={handleSelectTab}
          selectedTab={selectedTab.id}
          selectedTabClassName='project-sidebar__selected'
          tabClassName='project-sidebar__tab'
          tabsClassName='project-sidebar__tab-container'
          tabs={tabs}
        />
        <SidebarBody>
          <Loader status={projects.status} cond={isNil(projects.current)}>
            {projects.current && (
              <SidebarForm
                closeSlider={closeSlider}
                project={projects.current}
                selectedTab={selectedTab.id}
                tabs={rejectDisabledTabs(tabs)}
                updateSelectedTab={setSelectedTab}
              />
            )}
          </Loader>
        </SidebarBody>
      </SidePanel>
    </Slider>
  )
}

const SidePanel = styled(UISidePanel)`
  > div:nth-child(2) {
    display: flex;
    flex-direction: column;
  }
`
