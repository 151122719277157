import type { Step } from 'components/StepProgress/types'

export enum QuoteSteps {
  SUMMARY,
  REVIEW,
  CONFIRM,
  COMPLETED,
}

export const QUOTE_STEPS: Step[] = [
  { label: 'Quote Summary', value: QuoteSteps.SUMMARY },
  { label: 'Review Hardware and Services from Professional', value: QuoteSteps.REVIEW },
  { label: 'Confirm Final Payment', value: QuoteSteps.CONFIRM },
  { label: 'Rate and Review', value: QuoteSteps.COMPLETED },
]
