import { initShipping } from 'components'
import { AsyncFuncState } from 'shared'
import type { PaymentState } from './types'

export const state: PaymentState = {
  billing: {
    address: initShipping,
    type: null,
  },
  checkout: {
    contact: false,
    finished: false,
    success: false,
  },
  complete: {
    backorders: false,
    billing: false,
    cvc: false,
    expiry: false,
    number: false,
    shipping: false,
    terms: false,
  },
  errors: {
    shipping: '',
  },
  loadingSection: null,
  save: false,
  savedCard: null,
  shipping: {
    address: initShipping,
    type: null,
  },
  status: AsyncFuncState.NULL,
  useSaved: false,
}
