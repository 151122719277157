import { branch, filter, OperatorContextFunction, pipe } from 'overmind'
import { isNil, isNotNil } from 'ramda'
import { v4 as uuid } from 'uuid'

import { handleAsyncAction, runOnSuccess, whenPropIs } from 'store/operators'
import * as op from './operators'
import { AsyncFuncState, Pages } from 'shared'

import type { User } from 'types'
import type { WithLink, WithOnSuccess, WithWindow } from 'store/types'
import type {
  AppLoadingSection,
  ToastOptions,
  WithMaybePath,
  WithPath,
  WithRedirect,
  WithUser,
} from './types'
import type { Context } from 'store'

const handleAppAction = handleAsyncAction('app')

export const resetCaptcha = ({ state }: Context) => (state.app.recaptcha = uuid())

const initialiseSocket = pipe(
  filter(({ state }: Context) => isNotNil(state.app.user)),
  ({ effects }: Context) => effects.app.socket.initialize(),
)

export const automTryCreateUser = handleAppAction<WithOnSuccess>({
  action: pipe(branch(op.createUser), runOnSuccess()),
  toastOnError: false,
})

export const createSsoAccountFromQuote = handleAppAction<
  op.CreateSsoAccountFromQuote & WithOnSuccess
>({ action: pipe(branch(op.createSsoAccount), runOnSuccess()), onError: resetCaptcha })

export const tryCreateUser = handleAppAction<WithOnSuccess>(
  pipe(branch(op.createUser), runOnSuccess()),
)

export const goToLogin = ({ effects }: Context, { path }: WithMaybePath) =>
  effects.app.location.goToLogin(path ?? undefined)

export const login = handleAppAction<WithMaybePath, void>({
  action: pipe(
    op.login,
    whenPropIs<WithMaybePath & WithUser, void>('user', isNil, {
      true: goToLogin,
      false: pipe(op.setUser, initialiseSocket),
    }),
  ),
  after: op.setLoadingSection(null),
  before: branch(op.setLoadingSection('login')),
  onError: ({ effects }: Context) => effects.app.location.goToLogin(),
  toastOnError: false,
})

export const logout = handleAppAction<WithRedirect, void>(
  pipe(
    branch(
      ({ effects }: Context) => effects.app.api.logout(),
      ({ state }: Context) => {
        state.app.user = null
      },
    ),
    whenPropIs('redirect', true, {
      true: goToLogin as OperatorContextFunction<WithRedirect, void>,
      false: () => undefined,
    }),
  ),
)

export const redirectToPath = ({ effects }: Context, { path }: WithPath) =>
  effects.app.location.goTo(path)

export const redirectWindow = (_: Context, { link, window }: WithLink & WithWindow) => {
  if (window) window.location.assign(link)
}

export const resetError = ({ state }: Context) => {
  state.app.status = AsyncFuncState.SUCCESS
  state.app.error = null
}

export const resetToast = ({ state }: Context) => {
  state.app.toast.message = ''
  state.app.toast.title = ''
  state.app.toast.type = 'info'
}

export const setBuild = ({ state }: Context, build: string) => {
  state.app.build = build
}

export const setLoadingSection =
  (value: AppLoadingSection) =>
  ({ state }: Context) => {
    state.app.loadingSection = value
  }

export const setUserTo =
  (value: User | null) =>
  ({ state }: Context) => {
    state.app.user = value
  }

export const verifyUser = handleAppAction<Promise<void> | void>({
  after: branch(setLoadingSection(null)),
  before: setLoadingSection('full'),
  action: op.getUser,
  onError: pipe(branch(setUserTo(null)), op.parseError),
  toastOnError: false,
})

export const toast = ({ state }: Context, { title, message, type = 'success' }: ToastOptions) => {
  state.app.toast.title = title
  state.app.toast.message = message
  state.app.toast.type = type
}

export const updatePage = ({ state }: Context, value: Pages) => {
  state.app.page = value
}

export const onInitializeOvermind = pipe(
  ({ actions }: Context) => {
    if (new URLSearchParams(window.location.search).get('show') === 'reloaded') {
      actions.app.toast({
        message: [
          'We had an issue processing your activity.',
          'Portal refreshed to use the latest version.',
          'Please try again.',
        ],
        title: 'Updated App Version',
        type: 'warning',
      })
    }
  },
  verifyUser,
  initialiseSocket,
)
