import styled from 'styled-components'
import { Text } from '@ubnt/ui-components/Text'

interface InputContainerProps {
  padding?: number
}

export const InputContainer = styled.div<InputContainerProps>`
  position: relative;

  ${({ padding }) => (typeof padding === 'number' ? `input { padding-right: ${padding}px; }` : '')}
`

export const UnitContainer = styled(Text)`
  color: ${({ theme }) => theme.colors.textColor.black.b100};
  position: absolute;
  top: 5px;
  right: 9px;
`
