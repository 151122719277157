import { AppState } from 'store/app/types'
import { ToastType } from 'types'

const toast = (
  state: { app: AppState },
  title: string,
  message: string | string[],
  type: ToastType,
  duration: number | null = null,
) => {
  state.app.toast.title = title
  state.app.toast.message = message
  state.app.toast.type = type
  state.app.toast.duration = duration
}

export const handleError = (error: any) => {
  if (error.response) return error.response.data.message || new Array(error.response.data)
  if (error.message) return error.message
  return error
}

export default toast
