import { Loader as GoogleMapsLoader } from '@googlemaps/js-api-loader'
import config from 'config'
import { Location } from 'types'

let client: GoogleMapsLoader

const initGoogleApi = () => {
  if (client) return

  client = new GoogleMapsLoader({
    libraries: ['places'],
    language: 'en',
    apiKey: config.PLACES_API_KEY,
  })

  return client.load()
}

const geocodeByAddress = (address: string): Promise<google.maps.GeocoderResult[] | null> => {
  const geocoder = new window.google.maps.Geocoder()
  const OK = window.google.maps.GeocoderStatus.OK

  return new Promise((resolve) => {
    geocoder.geocode({ address }, (results, status) => {
      resolve(status === OK ? results : [])
    })
  })
}

export const googlePlacesAutoComplete = (
  text: string,
): Promise<google.maps.places.AutocompletePrediction[] | null> =>
  new Promise((resolve, reject) => {
    if (!text) {
      return reject('Need valid text input')
    }

    // for use in things like GatsbyJS where the html is generated first
    if (typeof window === 'undefined') {
      return reject('Need valid window object')
    }

    try {
      return new window.google.maps.places.AutocompleteService().getPlacePredictions(
        { input: text, componentRestrictions: { country: 'us' }, types: ['address'] },
        resolve,
      )
    } catch (e) {
      reject(e)
    }
  })

export const geocodeByPlaceId = (placeId: string): Promise<google.maps.GeocoderResult[] | null> => {
  const geocoder = new window.google.maps.Geocoder()
  const OK = window.google.maps.GeocoderStatus.OK

  return new Promise((resolve, reject) => {
    geocoder.geocode({ placeId }, (results, status) => {
      if (status !== OK) {
        reject(status)
      }
      resolve(results)
    })
  })
}

const geocodeParser = (result: google.maps.GeocoderResult): Location => {
  const location = {
    formatted: result.formatted_address,
  } as Location

  result.address_components.forEach((comp) => {
    if (comp.types.includes('street_number')) {
      location.streetNumber = comp.long_name
    }

    if (comp.types.includes('route')) {
      location.street = comp.long_name
    }

    if (
      (comp.types.includes('locality') && comp.types.includes('political')) ||
      comp.types.includes('postal_town')
    ) {
      location.city = comp.long_name
    }

    if (comp.types.includes('country')) {
      location.country = comp.long_name
    }

    if (comp.types.includes('administrative_area_level_1') && comp.types.includes('political')) {
      location.state = comp.long_name
    }

    if (comp.types.includes('postal_code')) {
      location.zipCode = comp.short_name
    }
  })

  return location
}

const addressHelpers = {
  initGoogleApi,
  geocodeByAddress,
  googlePlacesAutoComplete,
  geocodeByPlaceId,
  geocodeParser,
}
export default addressHelpers
