import { OrderDetails } from './OrderDetails'
import { ProjectSidebar } from 'app/quotes/ProjectSidebar'
import ContactPro from 'app/quotes/QuoteModal/ContactPro'
import { SubmitModal as ProjectSubmitModal } from 'app/quotes/SubmitModal'
import { NoteModal as ProjectNoteModal } from 'components'
import type { FC } from 'react'
import { useOverState } from 'store'
import { Feedback } from './FeedbackModal'
import { InviteContacts } from './InviteContacts'
import { TopologyModal } from './Topology'
import { OrderPlaced } from './OrderPlaced'
import { QuoteDispute } from './QuoteDispute'
import Subscriptions from './Subscriptions'
import { CardDetails } from './CardDetails'
import AddressForm from './AddressModal'

const RootModal: FC = () => {
  const { modal, sidebar } = useOverState().modal

  return (
    <>
      {modal === 'card_details' && <CardDetails />}
      {modal === 'feedback' && <Feedback />}
      {modal === 'order_placed' && <OrderPlaced />}
      {modal === 'project_note' && <ProjectNoteModal />}
      {modal === 'project_submit' && <ProjectSubmitModal />}
      {modal === 'quote_dispute' && <QuoteDispute />}
      {modal === 'invite_contacts' && <InviteContacts />}
      {modal === 'topology' && <TopologyModal />}
      {modal === 'address' && <AddressForm />}

      {sidebar === 'contact_pro' && <ContactPro />}
      {sidebar === 'order_details' && <OrderDetails />}
      {sidebar === 'project_details' && <ProjectSidebar />}
      {sidebar === 'subscriptions' && <Subscriptions />}
    </>
  )
}

export default RootModal
