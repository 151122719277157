import * as Sentry from '@sentry/react'
import { CaptureConsole } from '@sentry/integrations'
import { Provider } from 'overmind-react'
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  Route,
  Routes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'
import { render } from 'react-dom'
import { useEffect } from 'react'

import App from './App'
import { ErrorComponent } from 'components'
import { GlobalStyle } from 'styles'
import config from 'config'
import { disableIosFormZoom } from 'helpers'
import { overmind } from './store'

import './index.css'

disableIosFormZoom()

if (config.ENV !== 'local') {
  Sentry.init({
    dsn: config.SENTRY_DSN,
    environment: config.ENV,
    release: `customer-portal@${config.BUILD}`,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
      new CaptureConsole({ levels: ['error'] }),
    ],
    tracesSampleRate: 1,
  })
  Sentry.setTag('portal', 'customer')
}

if (config.ENV !== 'local') {
  window.Userback.access_token = config.USERBACK_TOKEN
}

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

const ReactApp = () => (
  <Provider value={overmind}>
    <Sentry.ErrorBoundary
      beforeCapture={(scope) => scope.setTag('context', 'app')}
      fallback={(props) => <ErrorComponent {...props} />}
    >
      <GlobalStyle />
      <BrowserRouter>
        <SentryRoutes>
          <Route path='*' element={<App />} />
        </SentryRoutes>
      </BrowserRouter>
    </Sentry.ErrorBoundary>
  </Provider>
)

render(<ReactApp />, document.getElementById('root'))
