import { FC } from 'react'
import { Text } from '@ubnt/ui-components/Text'

export interface LabelContainerProps {
  className?: string
  label: string
  showLabel?: boolean
}

export const LabelContainer: FC<LabelContainerProps> = ({
  className,
  children,
  label,
  showLabel = true,
}) => (
  <div className={className}>
    {showLabel && <Text size='label'>{label}</Text>}
    {children}
  </div>
)
