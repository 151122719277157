import { compose, lensIndex, lensProp, pipe, replace, times } from 'ramda'
import { v4 as uuid } from 'uuid'

import { truncateStart } from 'helpers/strings'

import type { BuildingWiFi } from 'store/projects/types'

const makeNew = (type: 'wifiIndoor' | 'wifiOutdoor', index: number): BuildingWiFi => {
  const name = type === 'wifiIndoor' ? `${index + 1}` : ''

  return {
    id: uuid(),
    name,
    area: 0,
    roomQty: 0,
    note: '',
    plans: [],
  }
}

export const truncatePlanName = pipe<[string], string, string>(
  replace(/^.+\//, ''),
  truncateStart(12),
)

export const updateWifiArray =
  (type: 'wifiIndoor' | 'wifiOutdoor', length: number) =>
  (list: BuildingWiFi[]): BuildingWiFi[] =>
    times((idx) => list[idx] ?? makeNew(type, idx), length)

interface WiFiLens {
  wifiIndoor: BuildingWiFi[]
  wifiOutdoor: BuildingWiFi[]
}

export const wifiLens = <
  A extends WiFiLens,
  B extends keyof WiFiLens,
  C extends 'id' | 'name' | 'area' | 'roomQty' | 'plans' | 'note',
>(
  type: B,
  index: number,
  prop: C,
) => compose(lensProp<A, B>(type), lensIndex<BuildingWiFi>(index), lensProp<BuildingWiFi, C>(prop))

wifiLens.index = <A extends WiFiLens, B extends keyof WiFiLens>(type: B, index: number) =>
  compose(lensProp<A, B>(type), lensIndex<BuildingWiFi>(index))
