import { isNotNil } from 'ramda'

import { Badge, FlexRow } from 'components'
import { ItemText } from './styles'

import type { FC } from 'react'
import type { ItemQtyProps } from './types'

export const ItemQty: FC<ItemQtyProps> = ({ item }) => {
  const _qty = 'qty' in item ? item.qty : item.quantity

  const isAdded = item.adjusted.type === 'added'
  const isAdjusted = isNotNil(item.adjusted.qty) && item.adjusted.qty !== _qty

  return (
    <FlexRow>
      {isAdjusted && <ItemText type='box'>{item.adjusted.qty}</ItemText>}
      <ItemText type={isAdjusted ? 'strike-through' : isAdded ? 'box' : undefined}>{_qty}</ItemText>
      {isAdded && (
        <Badge size='xsmall' type='purple'>
          New
        </Badge>
      )}
    </FlexRow>
  )
}
