import styled from 'styled-components'
import Button from '@ubnt/ui-components/Button/Button'

export const HoverButton = styled(Button)`
  &:hover {
    background-color: transparent;
  }
`

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
  margin-bottom: 4px;
  padding: 8px 25px;

  min-height: 53px;
`

export const TitleButton = styled(Button)`
  border: 1px solid ${({ theme }) => theme.borderColor};
`

export const TableWrapper = styled.section`
  display: flex;
  width: 100%;
  height: calc(100vh - var(--app-header-height) - var(--top-bar-height));
  overflow-y: auto;

  .table {
    margin: 20px 50px 0;
  }
`
