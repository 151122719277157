import { ReactComponent as HandIcon } from 'assets/icons/Hand.svg'
import { ReactComponent as SpeechBalloonIcon } from 'assets/icons/SpeechBalloon.svg'
import { Avatar } from 'components'
import { ProfessionalUser } from 'types'

export const getCardInfo = (professionalUser: ProfessionalUser | null) => [
  {
    icon: <HandIcon />,
    title: 'Visit Our Help Center',
    text: 'Review our extensive support article directory to troubleshoot technical issues.',
    link: 'https://help.ui.com/hc/en-us',
  },
  {
    icon: <SpeechBalloonIcon />,
    title: 'Contact Support',
    text: 'Connect with a support agent instantly via live chat or email to resolve technical issues.',
    link: 'https://help.ui.com/hc/en-us/requests/new?ticket_form_id=360000076634',
  },
  {
    icon: (
      <Avatar
        name={
          professionalUser
            ? [professionalUser?.firstName ?? '', professionalUser?.lastName || '']
            : ['']
        }
        logoUrl={professionalUser?.avatar || professionalUser?.proCompanyAvatar || null}
        imgSize={40}
        fontSize={16}
        fontWeight='bold'
      />
    ),
    title: 'Your Professional',
    text: `Name: ${professionalUser?.firstName} ${professionalUser?.lastName}
      Email: ${professionalUser?.email}
      Phone: ${professionalUser?.phone}`,
    link: null,
  },
]
