import { createOvermind, IContext } from 'overmind'
import { namespaced } from 'overmind/config'
import {
  createActionsHook,
  createEffectsHook,
  createReactionHook,
  createStateHook,
} from 'overmind-react'

import { app } from './app'
import { client } from './client'
import { modal } from './modal'
import { orderDetails } from './orderDetails'
import { payment } from './payment'
import { projects } from './projects'
import { professionalUser } from './professionalUser'
import { quotes } from './quotes'
import { stripe } from './stripe'

import appConfig from 'config'

export const config = namespaced({
  app,
  client,
  modal,
  orderDetails,
  payment,
  projects,
  professionalUser,
  quotes,
  stripe,
})

export type Context = IContext<{
  state: typeof config.state
  actions: typeof config.actions
  effects: typeof config.effects
}>

export const overmind = createOvermind(config, {
  devtools: appConfig.ENV === 'local',
})

export const useOverState = createStateHook<Context>()
export const useActions = createActionsHook<Context>()
export const useEffects = createEffectsHook<Context>()
export const useReaction = createReactionHook<Context>()
