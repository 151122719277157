import { http } from 'helpers'
import { Client } from 'types/client.type'
import { User } from 'types/user.type'

export const api = {
  addContacts: (contacts: Partial<Client>[], invitationEmail?: boolean, contractId?: string) => {
    const _invitationEmail =
      typeof invitationEmail === 'boolean' ? `invitationEmail=${invitationEmail}` : {}

    return http.post<void>(`users/invite/customer?${_invitationEmail}`, {
      contacts,
      contractId,
    })
  },
  getInvitedUsers: (clientId: string) =>
    http.get<Partial<Client & User>[]>(`clients/${clientId}/contacts?avatar=true&contactId=true`),
}
