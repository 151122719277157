import { FC, useEffect } from 'react'
import { Button as UIButton } from '@ubnt/ui-components/Button'

import { ReactComponent as SVGUbiquitiLogo } from 'assets/icons/UbiquitiLogo_blue.svg'
import Hero from 'assets/png/Hero.png'
import TextLogo from 'assets/png/TextLogo.png'
import { AsyncFuncState } from 'shared'
import { useActions, useOverState } from 'store'
import styled from 'styled-components'
import { useQuery } from 'hooks/useQuery'
import { MEDIA } from 'styles'
import { LoginError } from './LoginError'

export const errorTexts: Record<string, string> = {
  uninvited: 'The UI SSO Account you are using is not authorized to access.',
  expired: 'Your session has expired. Please, log in again.',
  default: 'Please check your invite status or use different SSO account to log in.',
}

export const Login: FC = () => {
  const { error, status } = useOverState().app
  const { app: actions } = useActions()
  const { error: queryError, origin, redirect } = useQuery(['error', 'origin', 'redirect'])

  useEffect(() => actions.resetError, [])

  const showError =
    (status === AsyncFuncState.ERROR && error?.message === 'Wrong user type') || queryError
  const isWrongUser = showError && queryError !== 'expired'
  const buttonText = 'Sign In with UI Account'
  const errorText = errorTexts[queryError || 'default'] || errorTexts.default

  const handleLogin = () =>
    isWrongUser ? actions.logout({ redirect: true }) : actions.login({ path: redirect ?? origin })

  if (showError) return <LoginError errorText={errorText} handleLogin={handleLogin} />

  return (
    <Container>
      <LoginArea>
        <div />
        <div>
          <TextWrapper>
            <TextLogoImg src={TextLogo} alt='UniFi Professionals' />
          </TextWrapper>
          <Button size='large' variant='primary' full onClick={handleLogin}>
            {buttonText}
          </Button>
        </div>
        <LinksWrapper>
          <StyledA href='https://www.ui.com/legal/termsofservice/' target='_blank' rel='noopener'>
            Terms of Service
          </StyledA>
          <StyledA href='https://www.ui.com/legal/privacypolicy/' target='_blank' rel='noopener'>
            Privacy Policy
          </StyledA>
        </LinksWrapper>
      </LoginArea>
      <ImageWrapper>
        <UbiquitiLogo />
        <StyledImg src={Hero} alt='Hero Image' />
      </ImageWrapper>
    </Container>
  )
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 400px 1fr;
  justify-content: flex-end;
  align-items: flex-end;
  height: 100vh;

  @media ${MEDIA.MOBILE} {
    display: flex;
    flex-direction: column-reverse;
  }
`

const ImageWrapper = styled.section`
  display: flex;
  align-items: flex-end;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.Light.color.neutral.n3};

  @media ${MEDIA.MOBILE} {
    background-color: ${({ theme }) => theme.colors.Light.color.neutral.n0};
    height: auto;
  }
`

const LoginArea = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  padding: 32px;

  @media ${MEDIA.MOBILE} {
    width: 100vw;
  }
`
const TextLogoImg = styled.img`
  width: 290px;
  align-self: center;
  margin-bottom: 150px;
`

const Button = styled(UIButton)`
  width: 336px;
  position: fixed;
  top: 50%;
  @media ${MEDIA.MOBILE} {
    width: 326px;
  }
`

const StyledImg = styled.img`
  width: 100%;
  max-width: 100%;
  max-height: 100vh;
  object-fit: contain;
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-contet: center;

  @media ${MEDIA.MOBILE} {
    width: 290px;
    position: absolute;
    top: 6px;
    right: 50px;
    left: 50px;
  }
`

const LinksWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 32px;
`

const StyledA = styled.a`
  text-decoration: none;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.textColor.black.b65};

  :hover {
    color: ${({ theme }) => theme.colors.textColor.black.b100};
  }
`
const UbiquitiLogo = styled(SVGUbiquitiLogo)`
  position: absolute;
  right: 32px;
  top: 32px;
  height: 64px;
  width: 64px;
  border-radius: 8px;

  @media ${MEDIA.MOBILE} {
    display: none;
  }
`
