import styled from 'styled-components'

import type { ContainerProps } from './types'

export const Container = styled.section<ContainerProps>`
  border-bottom: ${({ border, theme }) =>
    border ? `1px solid ${theme.colors.Light.color.neutral.n3}` : 'none'};
  margin-bottom: ${({ border }) => (border ? 24 : 0)}px;
  padding-bottom: ${({ border }) => (border ? 24 : 0)}px;
`
