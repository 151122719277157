import { curry, map, pipe, split, takeLast } from 'ramda'
import { User } from 'types'

const regEx = {
  escape: /[.*+?^${}()|[\]\\]/g,
  number: /^\d+(?:\.\d*)?$/,
  startZeroes: /^0+(\d)/,
}

export const length = (str: string) => str.length

export const isValidNumber = (x: string | number) => typeof x === 'number' || regEx.number.test(x)

export const removeStartZeroes = (x: string) => x.replace(regEx.startZeroes, '$1')

export const stringWithNewlinesToHtml = pipe(
  split('\n'),
  map((line) => <p key={line}>{line}</p>),
)

const _truncateStart = (length: number, str: string) =>
  str.length <= length ? str : `...${takeLast(length, str)}`

export const truncateStart = curry(_truncateStart)

export const buildName = ({ firstName, lastName, username }: User) =>
  firstName && lastName ? [firstName, lastName] : firstName ? [firstName] : [username]
