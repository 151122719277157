import { FC, useCallback, useState } from 'react'
import { Modal } from '@ubnt/ui-components/Modal'

import { useActions, useOverState } from 'store'
import { Note } from '.'

import type { ModalType, ProjectNoteModal } from 'store/modal/types'

export const NoteModal: FC = () => {
  const { modalProps } = useOverState().modal as ModalType<ProjectNoteModal>
  const { modal: actions } = useActions()
  const [text, setText] = useState(modalProps.note)

  const handleChange = useCallback((_: any, value: string) => setText(value), [setText])
  const handleSubmit = useCallback(() => {
    modalProps.onSubmit(text)
    actions.closeModal()
  }, [text])

  return (
    <Modal
      size='small'
      isOpen
      onRequestClose={actions.closeModal}
      shouldCloseOnOverlayClick={false}
      title='Enter Note'
      actions={[
        {
          text: 'Done',
          onClick: handleSubmit,
          variant: 'primary',
        },
      ]}
    >
      <Note editable={modalProps.editable} hideCaption onChange={handleChange} value={text} />
    </Modal>
  )
}
