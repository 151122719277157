import { VFC, FormEvent, useEffect, useState } from 'react'
import { Text } from '@ubnt/ui-components/Text'
import { useNavigate } from 'react-router'
import { assoc } from 'ramda'

import { AsyncFuncState } from 'shared'
import { CreateUserForm, DoneForm, FormSection, FullscreenLoader, FormAside } from 'components'
import { Container, FormWrapper } from 'app/onboarding/styles'
import { User } from 'app/onboarding/Form/Media'
import config from 'config'
import { useActions, useEffects, useOverState } from 'store'

const initState = {
  mailingList: false,
  password: '',
  termsAndConditions: false,
  username: '',
}

type View = 'form' | 'done'

interface CreateUserProps {
  quoteId: string
}

export const CreateUser: VFC<CreateUserProps> = ({ quoteId }) => {
  const { app } = useActions()
  const { api } = useEffects().app
  const { status } = useOverState().app
  const [account, setAccount] = useState(initState)
  const [isLoading, setIsLoading] = useState(true)
  const [view, setView] = useState<View>('form')
  const navigate = useNavigate()

  useEffect(() => {
    api
      .checkCanCreateUser(quoteId)
      .then((data) =>
        data.exists ? Promise.reject() : setAccount(assoc('username', data.username)),
      )
      .then(() => setIsLoading(false))
      .catch(() => navigate(`/login?redirect=quotes/${quoteId}`))
  }, [])

  const handleNext = (event?: FormEvent<HTMLFormElement> | MouseEvent, userTriggered = true) => {
    event && event.preventDefault()

    if (view === 'form')
      return app.createSsoAccountFromQuote({
        quoteId,
        data: account,
        onSuccess: () => setView('done'),
      })

    if (view === 'done') {
      const fn = userTriggered ? app.tryCreateUser : app.automTryCreateUser
      return fn({ onSuccess: () => navigate(`/quotes/${quoteId}`) })
    }
  }

  const title = view === 'form' ? 'Create an account to track your project' : 'Verify your account'
  const globalLoading = status === AsyncFuncState.LOADING

  if (isLoading) return <FullscreenLoader />

  return (
    <Container>
      <FormAside>
        <User />
      </FormAside>
      <FormWrapper>
        <FormSection type='header'>
          <Text size='header-m' weight='bold'>
            {title}
          </Text>
        </FormSection>
        {view === 'form' && (
          <CreateUserForm
            account={account}
            handleNext={handleNext}
            isLoading={globalLoading}
            login='/login'
            updateAccount={setAccount}
          />
        )}
        {view === 'done' && (
          <DoneForm
            handleNext={handleNext}
            interval={config.SHORT_POLLING_INTERVAL}
            isLoading={globalLoading}
            label='To Quotes'
          />
        )}
      </FormWrapper>
    </Container>
  )
}
