import { FC } from 'react'
import styled from 'styled-components'
import { Text } from '@ubnt/ui-components'
import { stringWithNewlinesToHtml } from 'helpers'

interface Props {
  icon: JSX.Element
  title: string
  text: string
  link: string | null
}

export const SupportCard: FC<Props> = ({ icon, title, text, link }) => {
  const handleClick = () => {
    if (link) window.open(link, '_blank')
  }

  return (
    <Container onClick={handleClick}>
      <IconWrapper>{icon}</IconWrapper>
      <Text size='header-xs' weight='bold' style={{ paddingBottom: '16px' }}>
        {title}
      </Text>
      <Text style={{ padding: '0 18px', textAlign: 'center' }}>
        {stringWithNewlinesToHtml(text)}
      </Text>
    </Container>
  )
}

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.Light.color.neutral.n2};
  padding: 24px;
  width: 252px;
  height: 288px;
  margin: 12px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:hover {
    box-shadow: 0px 12px 48px rgba(0, 0, 0, 0.15);
    cursor: pointer;
  }
`

const IconWrapper = styled.div`
  padding: 26px 0;
`
