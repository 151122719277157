import * as yup from 'yup'
import { isNil } from 'ramda'

import type { Shipping } from 'types'
import { emptyLocation, locationSchema } from 'shared'

export const initShipping: Shipping = {
  firstName: '',
  lastName: '',
  phone: '',
  location: emptyLocation,
}

export const schema = yup.object().shape({
  firstName: yup
    .string()
    .transform((_current, original) => (isNil(original) ? '' : original)) // Workaround since .nonNullable() does not work...
    .min(2, 'Must be a minimum 2 characters')
    .max(120)
    .required('First name is required'),
  lastName: yup
    .string()
    .transform((_current, original) => (isNil(original) ? '' : original)) // Workaround since .nonNullable() does not work...
    .min(2, 'Must be a minimum 2 characters')
    .max(120)
    .required('Last name is required'),
  phone: yup.string().matches(/^\+?\d{9,12}$/, {
    message: 'Please enter valid phone number.',
    excludeEmptyString: false,
  }),
  location: locationSchema,
})
