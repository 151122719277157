import { filter, isNotNil, map, pipe } from 'ramda'

import { FulfillmentStatus } from 'store/orderDetails/constants'
import { QuoteStatus } from 'shared'
import { pickStatus } from './helpers'
import { useOverState } from 'store'

import type { QuoteListItem } from 'store/quotes/types'

export interface OrderRow extends QuoteListItem {
  displayStatus: FulfillmentStatus
  children: OrderRow[]
}

export const useOrderRows = () => {
  const {
    quotes: { list },
  } = useOverState()

  return buildRows(list)
}

const itemsToChildren = (quote: QuoteListItem): OrderRow[] => {
  const { proHardware, proServices, productVariants } = quote.priceBreakdown
  return [
    quote.hardwareList.length > 0 && {
      ...quote,
      id: `${quote.id}-ui-hardware`,
      displayStatus: pickStatus(quote),
      quoteName: 'UniFi Hardware',
      total: productVariants.total,
    },
    (quote.proHardware.length > 0 || quote.proServices.length > 0) && {
      ...quote,
      id: `${quote.id}-pro-items`,
      displayStatus: statusToDisplay(quote.status),
      quoteName: 'Additional Hardware and Services',
      total: proHardware.total + proServices.total,
    },
  ].filter((value): value is OrderRow => value !== false)
}

const statusToDisplay = (status: QuoteStatus) =>
  status === QuoteStatus.COMPLETED
    ? FulfillmentStatus.SHIPPED
    : FulfillmentStatus.PENDING_PROCESSING

const buildRows: (input: QuoteListItem[]) => OrderRow[] = pipe(
  filter<QuoteListItem>(({ orderId }) => isNotNil(orderId)),
  map(
    (quote): OrderRow => ({
      ...quote,
      children: itemsToChildren(quote),
      displayStatus: pickStatus(quote),
    }),
  ),
)
