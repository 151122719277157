import { Text } from '@ubnt/ui-components'

import { useOverState } from 'store'
import { BubbleContainer } from 'components'
import texts from '../../texts.json'
import { hasBackorder, uiHardwareToCheckout } from 'helpers'

import type { FC } from 'react'

export const ProductInfo: FC = () => {
  // TODO: Revert when re-enabling multiple payments
  const { quotes } = useOverState()

  if (
    quotes.current.quote &&
    !hasBackorder(uiHardwareToCheckout(quotes.current.quote.hardwareList))
  ) {
    return null
  }
  // const { payment } = useOverState()

  // if (!payment.shipments?.hasNext) return null

  return (
    <BubbleContainer icon iconSize='original' radius='small' type='warn' variant='info'>
      <Text size='caption'>{texts.checkout.backorder}</Text>
    </BubbleContainer>
  )
}
