import * as yup from 'yup'
import { locationSchema, nameSchema, phoneSchema } from 'shared'

export const schema = yup.object().shape({
  card: yup.boolean().isTrue().required(),
  firstName: nameSchema(),
  lastName: nameSchema(),
  location: locationSchema,
  phone: phoneSchema(),
})
