import { TableItemWithContext } from '@ubnt/ui-components/Table/TableTypes'
import { FC, useCallback, useEffect } from 'react'

import { OrderTable } from 'components/QuotesOrdersPages/OrderTable'
import { ProjectsList } from 'components/QuotesOrdersPages/ProjectsList'
import { useActions, useOverState } from 'store'
import { QUOTES_COLUMNS, renderFooter, renderStatus } from './data'
import { ChangePage, Main, QuoteCardView } from 'components'
import { Screen, useBackNavigate, useScreenWidth } from 'hooks'
import { Pages } from 'shared'

import type { QuoteListItem } from 'store/quotes/types'

const Quotes: FC = () => {
  const { projects, quotes } = useOverState()
  const actions = useActions()
  const navigate = useBackNavigate()
  const screenWidth = useScreenWidth()

  useEffect(() => {
    actions.projects.getProjectNames({ current: projects.names.current })

    if (projects.tokenData) actions.modal.setModal({ modal: 'project_submit', props: null })
  }, [])

  useEffect(() => {
    if (projects.names.current)
      actions.quotes.getQuotesByProject({
        projectId: projects.names.current,
      })
  }, [projects.names.current])

  const handleClickMore = (projectId: string) => {
    actions.modal.setSidebar({
      sidebar: 'project_details',
      props: {
        projectId,
        onClose: () => undefined,
      },
    })
  }

  const handleSelect = useCallback(
    (projectId: string) =>
      actions.projects.setCurrentProjectName({
        projectId: projectId in projects.names.hash ? projectId : 'unassigned',
      }),
    [projects.names.hash],
  )

  const handleClick = useCallback(
    (x: TableItemWithContext<QuoteListItem> | QuoteListItem) => {
      navigate(`/quotes/${typeof x === 'string' ? x : x.id}`)
    },
    [history],
  )

  return (
    <Main>
      {screenWidth <= Screen.TABLET && (
        <QuoteCardView
          onClickQuote={handleClick}
          onSelectProject={handleSelect}
          projects={projects.names}
          quotes={quotes.list}
          renderFooter={renderFooter}
          renderStatus={renderStatus}
          status={quotes.status}
        />
      )}
      {screenWidth > Screen.TABLET && (
        <>
          <ProjectsList
            projects={projects.names.list}
            handleAdd={actions.projects.addProject}
            handleClickMore={handleClickMore}
            handleSelect={handleSelect}
            selectedProject={projects.names.current}
            status={projects.status}
          />
          <OrderTable //
            handleClick={handleClick}
            status={quotes.status}
            quotes={quotes.list}
            columns={QUOTES_COLUMNS}
            category='Quote'
          />
        </>
      )}
      <ChangePage to={Pages.QUOTES} />
    </Main>
  )
}

export default Quotes
