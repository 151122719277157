import { derived } from 'overmind'
import { AsyncFuncState } from 'shared'
import { QuoteState } from './types'

export const state: QuoteState = {
  checkout: null,
  current: {
    hasProItems: false,
    hasUiItems: false,
    isStale: false,
    quote: null,
  },
  hash: {},
  list: derived(({ hash }: QuoteState) => Object.values(hash)),
  loadingSection: null,
  status: AsyncFuncState.NULL,
  storeCheckout: {
    inCheckout: false,
    url: null,
  },
}
