import { makeAwsUrl } from 'helpers'
import { pipe, map, head, join, toUpper, take } from 'ramda'

const getWordInitial = pipe((word: string) => head(word), toUpper)

const getInitials = pipe((words: string[]) => take(2, words), map(getWordInitial), join(''))

const renderLogo = (logo: string): JSX.Element => {
  const source = logo.startsWith('http') ? logo : makeAwsUrl(logo)
  return <img src={source} alt='Company logo' />
}

const handleAvatar = (name: string[], logo: string | null): JSX.Element => {
  return <>{logo ? renderLogo(logo) : getInitials(name)}</>
}

export default handleAvatar
