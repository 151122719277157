import { Link, LinkProps, Navigate, NavigateProps } from 'react-router-dom'
import { useEffect, VFC } from 'react'

import { useNextNavigationStack } from 'hooks'

export const BackLink: VFC<LinkProps> = ({ state, ...rest }) => {
  const from = useNextNavigationStack()

  return <Link {...rest} state={{ ...state, from }} />
}

export const BackNavigate: VFC<NavigateProps> = ({ state, ...rest }) => {
  const from = useNextNavigationStack()

  return <Navigate {...rest} state={{ ...state, from }} />
}

export const ResetNavigate: VFC = () => {
  useEffect(() => {
    window.history.replaceState({ from: [] }, '')
  }, [])

  return null
}
