import styled from 'styled-components'
import { Text } from '@ubnt/ui-components/Text'

export const CardHeader = styled.header`
  display: flex;
  justify-content: space-between;

  margin-bottom: 20px;
`

export const CardTitle = styled(Text)`
  color: ${({ theme }) => theme.colors.textColor.black.b100};
`

export const CardWrapper = styled.article`
  display: flex;
  flex-direction: column;

  border: 1px solid ${({ theme }) => theme.colors.Light.color.neutral.n4};
  border-radius: 8px;

  margin: 4px;
  padding: 16px;
`
