import { http } from 'helpers'

import type { CreateAccount } from 'store/types'
import type {
  CreateProject,
  Files,
  FilesName,
  GetProjectsOptions,
  NewProject,
  NewWifi,
  Plan,
  ProfessionalUser,
  Project,
  ProjectTokenData,
  WithProjectId,
} from './types'

interface CheckAccountA {
  email: string
}

interface CheckAccountB {
  username: string
}

export const api = {
  checkAccountExists: (opts: CheckAccountA | CheckAccountB) => {
    const paramString = 'username' in opts ? `username/${opts.username}` : `email/${opts.email}`

    return http.get<{ exists: boolean }>(`oauth/check/${paramString}`)
  },
  createAccountWithToken: (token: string, opts: CreateAccount) =>
    http.post(`oauth/sso-users/token/${token}`, opts),
  createProjectToken: () => http.post<ProjectTokenData>('projects', {}),
  createProjectWithToken: (token: string, opts: CreateProject) =>
    http.post<Project>(`projects/${token}`, parseProject(opts)),
  generateUsername: (token: string): Promise<{ username: string }> =>
    http.post(`oauth/sso-users/token/${token}/generate`, {}),
  getProfessionalUser: () => http.get<ProfessionalUser>('clients/professional-user'),
  getProject: ({ projectId }: WithProjectId) => http.get<Project>(`projects/${projectId}`),
  getProjects: ({ onlyNames = false }: GetProjectsOptions) =>
    http.get<Project[]>(`projects?names=${onlyNames}`),
  getUploadUrls: (files: Plan[]) =>
    http.post<Files>(`projects/files/upload/client`, { files: files.map((file) => file.name) }),
  uploadFile: ([{ file }, { name, url }]: [Plan, FilesName]) =>
    http.put(url, file).then(() => name),
  getUploadUrlsByToken: (token: string) => (files: Plan[]) =>
    http.post<Files>(`projects/token/${token}/files/upload`, {
      files: files.map((file) => file.name),
    }),
  updateProject: (projectId: string, body: CreateProject) =>
    http.put<Project>(`projects/${projectId}`, parseProject(body)),
  validateToken: (token: string) =>
    http.post<ProjectTokenData>('projects/validate-token', { token }),
}

const parseWifi = (wifi: NewWifi[]) => wifi.map((item) => ({ ...item, plan: item.plan?.name }))
const parseProject = (project: NewProject) => ({
  ...project,
  wifiIndoor: parseWifi(project.wifiIndoor),
  wifiOutdoor: parseWifi(project.wifiOutdoor),
})
