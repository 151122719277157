import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js'
import { Text } from '@ubnt/ui-components/Text'

import { useActions } from 'store'
import { Label } from '../styles'

import type { FC } from 'react'

export const CardFields: FC = () => {
  const actions = useActions()

  return (
    <>
      <Label area='card'>
        <Text size='label'>Card Number</Text>
        <CardNumberElement onChange={actions.payment.setCompleteNumber} />
      </Label>
      <Label area='expiry'>
        <Text size='label'>Expiration Date</Text>
        <CardExpiryElement onChange={actions.payment.setCompleteExpiry} />
      </Label>
      <Label area='cvc'>
        <Text size='label'>CVC</Text>
        <CardCvcElement onChange={actions.payment.setCompleteCvC} />
      </Label>
    </>
  )
}
