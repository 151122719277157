import {
  allPass,
  anyPass,
  equals,
  evolve,
  isEmpty,
  isNil,
  map,
  pipe,
  prop,
  propEq,
  reject,
  where,
} from 'ramda'
import {
  AboutIconL,
  DevicesTalkIconL,
  DoorsIconL,
  ProtectElementIconL,
  WiFiPausedIconL,
} from '@ubnt/icons'

import { TabItem, TabText } from './styles'

import type { Tab } from '@ubnt/ui-components'
import type { WithWiFi } from './types'
import type { BuildingWiFi, Project } from 'store/projects/types'
import type { UnitFunction } from 'types'

const checkCreatedByPro = propEq('professional', 'createdBy')
const checkEmptyWifi = where({ wifiIndoor: isEmpty, wifiOutdoor: isEmpty })
const checkEmptyCameras = pipe(
  prop('cameras'),
  where({ indoor: equals(0), outdoor: equals(0), note: isEmpty }),
)
const checkEmptyQty = where({ qty: equals(0), note: isEmpty })
const checkEmptyDoors = pipe(prop('doors'), checkEmptyQty)
const checkEmptyPhones = pipe(prop('voIP'), checkEmptyQty)

const checkSection = (checkFn: (x: Project) => boolean, project: Project | null) =>
  anyPass<any>([isNil, allPass([checkCreatedByPro, checkFn])])(project)

const mapWifi = (fn: UnitFunction) =>
  map<BuildingWiFi, BuildingWiFi>(
    evolve({
      area: pipe(Number, fn),
      roomQty: Number,
    }),
  )

export const makeTabs = (project: Project | null): Tab[] => [
  {
    id: 'overview',
    disabled: isNil(project),
    label: (
      <TabItem>
        <AboutIconL />
        <TabText size='label'>Overview</TabText>
      </TabItem>
    ),
  },
  {
    id: 'wifi',
    disabled: checkSection(checkEmptyWifi, project),
    label: (
      <TabItem>
        <WiFiPausedIconL />
        <TabText size='label'>Wi-Fi</TabText>
      </TabItem>
    ),
  },
  {
    id: 'camera',
    disabled: checkSection(checkEmptyCameras, project),
    label: (
      <TabItem>
        <ProtectElementIconL />
        <TabText size='label'>Camera</TabText>
      </TabItem>
    ),
  },
  {
    id: 'door',
    disabled: checkSection(checkEmptyDoors, project),
    label: (
      <TabItem>
        <DoorsIconL />
        <TabText size='label'>Door Access</TabText>
      </TabItem>
    ),
  },
  {
    id: 'voIP',
    disabled: checkSection(checkEmptyPhones, project),
    label: (
      <TabItem>
        <DevicesTalkIconL />
        <TabText size='label'>Phone System</TabText>
      </TabItem>
    ),
  },
]

export const parseProjectUnits = <A extends WithWiFi>(fn: UnitFunction, project: A) =>
  evolve(
    {
      wifiIndoor: mapWifi(fn),
      wifiOutdoor: mapWifi(fn),
    },
    project,
  )

export const rejectDisabledTabs = reject<Tab>(where({ disabled: equals(true) }))
