import { VFC, useEffect, useState } from 'react'
import { isNil } from 'ramda'

import { ChangePage, Main, QuoteCardView } from 'components'
import { OrderTable } from 'components/QuotesOrdersPages/OrderTable'
import { ProjectsList } from 'components/QuotesOrdersPages/ProjectsList'
import { useScreenWidth, Screen } from 'hooks'
import { useActions, useOverState } from 'store'
import { renderCardStatus, renderFooter } from './helpers'
import { ORDER_STATUSES, Pages } from 'shared/constants'
import { ORDERS_COLUMNS } from './columns'
import { useOrderRows } from './useOrderRows'

import type { TableItemWithContext } from '@ubnt/ui-components/Table/TableTypes'
import type { Quote, QuoteListItem } from 'store/quotes/types'

const GetData: VFC = () => {
  const { projects } = useOverState()
  const { getProjectNames } = useActions().projects
  const { getQuotesByProject } = useActions().quotes

  useEffect(() => {
    getProjectNames({ current: projects.names.current })
  }, [])

  useEffect(() => {
    if (projects.names.current)
      getQuotesByProject({
        projectId: projects.names.current,
        status: ORDER_STATUSES.join(','),
      })
  }, [projects.names.current])

  return null
}

const Orders: VFC = () => {
  const { setSidebar } = useActions().modal
  const {
    modal: { sidebar },
    projects,
    quotes: { status },
  } = useOverState()
  const { setCurrentProjectName } = useActions().projects
  const screenWidth = useScreenWidth()
  const rows = useOrderRows()

  const [selected, setSelected] = useState<string[]>([])

  useEffect(() => {
    if (isNil(sidebar)) setSelected([])
  }, [sidebar])

  const handleSelect = (projectId: string) =>
    setCurrentProjectName({
      projectId: projectId in projects.names.hash ? projectId : 'unassigned',
    })

  const handleClick = (row: TableItemWithContext<QuoteListItem | Quote> | QuoteListItem) => {
    if (!('child' in row) || !row.child) return

    setSelected([row.id])
    const type = row.quoteName === 'Additional Hardware and Services' ? 'professional' : 'ui'
    setSidebar({
      sidebar: 'order_details',
      props: { from: 'orders', quote: row, type },
    })
  }

  return (
    <Main>
      {screenWidth <= Screen.TABLET && (
        <QuoteCardView
          onClickQuote={handleClick}
          onSelectProject={handleSelect}
          projects={projects.names}
          quotes={rows}
          renderFooter={renderFooter}
          renderStatus={renderCardStatus}
          status={status}
        />
      )}
      {screenWidth > Screen.TABLET && (
        <>
          <ProjectsList
            projects={projects.names.list}
            handleSelect={handleSelect}
            selectedProject={projects.names.current}
            status={projects.status}
          />
          <OrderTable
            handleClick={handleClick}
            status={status}
            quotes={rows}
            columns={ORDERS_COLUMNS}
            selected={selected}
            category='Order'
          />
        </>
      )}
      <GetData />
      <ChangePage to={Pages.ORDERS} />
    </Main>
  )
}

export default Orders
