import { Accordion as BaseAccordion } from '@ubnt/ui-components/Accordion'
import styled, { css } from 'styled-components'

import { MEDIA } from 'styles'

import { ReactComponent as DocumentSvg } from 'assets/icons/Document-blue.svg'
import { ReactComponent as GreenCheckSvg } from 'assets/icons/GreenCheckmark.svg'

const infoIcon = css`
  margin-right: 27px;
`

export const Accordion = styled(BaseAccordion)`
  margin-top: 16px;

  > li {
    margin-top: 0px !important;
  }
`

export const CheckmarkIcon = styled(GreenCheckSvg)`
  ${infoIcon}
`

export const DocumentIcon = styled(DocumentSvg)`
  ${infoIcon}
`

export const QuoteContainer = styled.section`
  display: flex;
  flex-direction: column;

  margin: 32px 186px 32px 212px;

  @media ${MEDIA.PC} {
    margin: 32px 86px 32px 112px;
  }

  @media ${MEDIA.TABLET} {
    margin: 8px 16px;
  }

  @media ${MEDIA.MOBILE} {
    margin: 4px 12px;
  }
`
