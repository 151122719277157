import styled from 'styled-components'

export const Main = styled.main`
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  overflow-y: auto;

  .nav,
  .empty--result {
    margin-right: auto;
  }
`
