import { addIndex, filter, fromPairs, map, mergeRight, pipe } from 'ramda'

import { initViews } from './Form/state'
import { ProjectSize, Size } from 'store/projects/types'

import type { Unit } from 'types'
import type { Views } from './types'

const BUILDING_SIZE_DICT: Record<Unit, Record<ProjectSize, string>> = {
  sqm: {
    [ProjectSize.SMALL]: '<200 m²',
    [ProjectSize.MEDIUM]: '200-500 m²',
    [ProjectSize.LARGE]: '500-1,000 m²',
    [ProjectSize.XLARGE]: '>1,000 m²',
  },
  sqft: {
    [ProjectSize.SMALL]: '<2,000 sq ft',
    [ProjectSize.MEDIUM]: '2,000-5,000 sq ft',
    [ProjectSize.LARGE]: '5,000-10,000 sq ft',
    [ProjectSize.XLARGE]: '>10,000 sq ft',
  },
}

const USER_DICT: Record<Size, string> = {
  [Size.XSMALL]: '<25',
  [Size.SMALL]: '25-50',
  [Size.MEDIUM]: '50-100',
  [Size.LARGE]: '100-250',
  [Size.XLARGE]: '>250',
}

const mapIndexed = addIndex<[Views, boolean], [number, Views]>(map)

export const makeNewViews = pipe<
  [Map<Views, boolean>],
  IterableIterator<[Views, boolean]>,
  [Views, boolean][],
  [Views, boolean][],
  [number, Views][],
  Record<number, Views>,
  Record<number, Views>
>(
  (x: Map<Views, boolean>) => x.entries(),
  Array.from,
  filter(([, bool]) => bool),
  mapIndexed(([x], i) => [i + 3, x]),
  fromPairs,
  mergeRight(initViews) as any,
)

export const sizeToBuildingSize = (size: ProjectSize, unit: Unit = 'sqm') =>
  BUILDING_SIZE_DICT[unit][size]

export const sizeToUserQty = (size: Size) => USER_DICT[size]
