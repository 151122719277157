export const HELP_PAGES = [
  {
    id: 'support',
    label: 'Ubiquiti Support',
  },
  {
    id: 'faq',
    label: 'FAQ',
  },
]

export const TABS = [
  {
    id: 'customer-portal',
    label: 'Customer Portal',
  },
  {
    id: 'general-questions',
    label: 'General Questions',
  },
  {
    id: 'order-questions',
    label: 'Order Questions',
  },
]

export const FAQ = {
  customerPortal: [
    {
      Q: 'How much does it cost to use the UniFi Customer Portal?',
      A: 'Nothing! It’s completely free to plan your project in the UniFi Customer Portal. It’s our way of making your project planning experience even more enjoyable. Your Professional will be compensated on a commission-basis from Ubiquiti for sales of UniFi products.',
    },
    {
      Q: 'Why do you collect my project details?',
      A: 'We collect project details to better understand what you want from your UniFi system. These details allow your Professional to suggest ideal products as they work to create the most suitable deployment for you.',
    },
    {
      Q: 'How can I request a quote?',
      A: (
        <>
          Check your email for a message that will help you submit your project details. Once you
          do, please give your Professional a couple of days to prepare your quote. Once they have,
          you’ll receive a follow-up email linking to your quote details in the portal.
          <br />
          <br />
          <strong>If you would like a revised quote</strong>, please reach out to your Professional.
          <br />
          <br />
          <strong>If you would like to start a new project</strong>, select the{' '}
          <strong>Projects</strong> tab and then click the <strong>Add New Project</strong> link
          located in the top-right corner of the screen.
        </>
      ),
    },
    {
      Q: 'How can I see my quote?',
      A: (
        <>
          You will receive an email when your quote is ready to be viewed in the portal. You can
          also see all of your current and past quotes in the <strong>Quotes</strong> menu.
        </>
      ),
    },
    {
      Q: 'How can I contact my Professional?',
      A: (
        <>
          Once you submit your project details, we’ll share your Professional’s contact information
          with you. You can also view their contact information by opening any quote and clicking{' '}
          <strong>Contact</strong> in the top-left corner of the screen.
        </>
      ),
    },
    {
      Q: 'How can I give feedback to Ubiquiti?',
      A: (
        <>
          We’d love to hear any feedback you have about the UniFi Customer Portal! To share your
          thoughts about your Professional and Ubiquiti, select the <strong>Give Feedback</strong>{' '}
          button on the top-right corner of the screen. Additionally, you will have the opportunity
          to rate and provide feedback about your Professional after a completed sale.
        </>
      ),
    },
  ],
  generalQuestions: [
    {
      Q: 'Will my products cost more since I’m working with a UniFi Professional?',
      A: 'No, you’ll pay the same price as you would if you were shopping in the Ubiquiti Store. Professionals are strictly prohibited from marking up any of our products.',
    },
    {
      Q: 'Do Professionals work for Ubiquiti? How are they compensated?',
      A: 'No. Professionals are unaffiliated third parties and do not work for Ubiquiti. They receive a flat 10% commission on completed sales of the products.',
    },
    {
      Q: 'How does Ubiquiti make money?',
      A: 'We don’t profit from UniFi Professionals. Our intention is to simplify customer-professional collaborations and create wholly enjoyable UniFi experiences that extend beyond technology and into shopping and implementation.',
    },
    {
      Q: 'Why should I order here instead of the Ubiquiti Store?',
      A: 'As a customer, there is no difference. However, when you place orders in the UniFi Customer Portal, we ensure that your Professional will receive compensation for the sale.',
    },
  ],
  orderQuestions: [
    {
      Q: 'Where can I see my order history?',
      A: (
        <>
          The <strong>Orders</strong> tab lists your order history.
        </>
      ),
    },
    {
      Q: 'How can I track my order?',
      A: (
        <>
          Open to the <strong>Orders</strong> menu, select the order, and click{' '}
          <strong>Tracking</strong> located at the top of the pop-up window.
        </>
      ),
    },
    {
      Q: 'What is your return policy?',
      A: (
        <>
          The return policy is the same as Ubiquiti’s US Store policy, which you can view{' '}
          {/* ESLint complains about not using noreferrer, but there is not security issue here since the link is linking to ui.com... */}
          {/* eslint-disable-next-line */}
          <a href='https://www.ui.com/legal/ui-store/termsandconditions/#returns' target='_blank'>
            here
          </a>
          .
        </>
      ),
    },
  ],
}
