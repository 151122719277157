import { handleError } from 'store/helpers'

import type { Context } from 'store'
import type { AsyncError } from 'store/types'
import type { Account, AppLoadingSection, WithUser } from './types'
import type { WithLink, WithQuoteId, WithWindow } from 'store/types'

export interface CreateSsoAccountFromQuote extends WithQuoteId {
  data: Account
}

export const createSsoAccount = (
  { effects }: Context,
  { data, quoteId }: CreateSsoAccountFromQuote,
) => effects.app.api.createAccountWithQuote(quoteId, data)

export const createUser = ({ state, effects }: Context) =>
  effects.app.api
    .createUser() //
    .then((data) => {
      state.app.user = data
    })

export const getUser = ({ state, effects }: Context) =>
  effects.app.api
    .getUser() //
    .then((data) => {
      state.app.user = data
    })

export const login = <A>({ effects }: Context, value: A): Promise<A & WithUser> =>
  effects.app.api
    .login() //
    .then((user) => ({
      ...value,
      user,
    }))

export const openWindow =
  (target?: string) =>
  <A extends Partial<WithLink>>({ effects }: Context, value: A): A & WithWindow => ({
    ...value,
    window: effects.app.location.open(value.link ?? 'about:blank', target),
  })

export const parseError = ({ state }: Context, { error }: AsyncError) => {
  const errorMesage = handleError(error)

  state.app.error = {
    code: error?.response?.status || 0,
    message: Array.isArray(errorMesage) ? errorMesage.join(', ') : errorMesage,
  }
}

export const redirectTo =
  (path: string, search?: string) =>
  ({ effects }: Context) =>
    effects.app.location.goTo(path, search)

export const refresh =
  (search?: string) =>
  ({ effects }: Context) =>
    effects.app.location.goTo(window.location.pathname, search)

export const setLoadingSection =
  (section: AppLoadingSection) =>
  ({ state }: Context) => {
    state.app.loadingSection = section
  }

export const setUser = ({ state }: Context, { user }: WithUser) => {
  state.app.user = user
}
