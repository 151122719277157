import { FC } from 'react'
import styled from 'styled-components'
import { Accordion as UIAccordion, Text } from '@ubnt/ui-components'

interface Props {
  items: { Q: string; A: string | JSX.Element }[]
}

export const AccordionWrapper: FC<Props> = ({ items }) => {
  return (
    <Accordion
      panelClassName='panel'
      variant='tertiary'
      multiOpen
      items={items.map(({ Q, A }) => ({
        id: Q,
        label: (
          <Text size='body' weight='bold'>
            {Q}
          </Text>
        ),
        renderContent: () => <Text size='body'>{A}</Text>,
      }))}
    />
  )
}

const Accordion = styled(UIAccordion)`
  li {
    border-color: ${({ theme }) => theme.colors.Light.color.neutral.n4} !important;
  }
  .panel {
    height: 64px;
  }
`
