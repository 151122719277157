import { isNotNil } from 'ramda'

import { Badge, FlexRow } from 'components'
import { toLocaleCurrency } from 'helpers'
import { ItemText } from './styles'

import type { FC } from 'react'
import type { ItemPriceProps } from './types'

export const ItemPrice: FC<ItemPriceProps> = ({ item, withNew = false }) => {
  const isAdjusted = 'price' in item.adjusted && isNotNil(item.adjusted.price)

  return (
    <FlexRow>
      {isAdjusted && (
        <ItemText type='box'>
          {toLocaleCurrency(
            'price' in item.adjusted ? item.adjusted.price ?? item.price : item.price,
          )}
        </ItemText>
      )}
      <ItemText type={isAdjusted ? 'strike-through' : undefined}>
        {toLocaleCurrency(item.price)}
      </ItemText>
      {item.adjusted.type === 'added' && withNew && (
        <Badge size='xsmall' type='purple'>
          New
        </Badge>
      )}
    </FlexRow>
  )
}
