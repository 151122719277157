import type { Context } from 'store'
import type { WithQuoteId } from 'store/types'
import type { WithClientId, WithContactList, WithInvitationEmail } from './types'

export const addContacts = (
  { effects }: Context,
  {
    contacts,
    invitationEmail,
    quoteId,
  }: WithContactList & WithInvitationEmail & Partial<WithQuoteId>,
) => effects.client.api.addContacts(contacts, invitationEmail, quoteId)

export const getInvitedUsers = ({ effects, state }: Context, { clientId }: WithClientId) =>
  effects.client.api
    .getInvitedUsers(clientId) //
    .then((contacts) => {
      state.client.users = contacts
    })
