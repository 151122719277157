import { VFC, useState } from 'react'

import { IconWrapper, Item as BaseItem, ItemText } from './styles'

import type { ItemProps } from './types'

export const Item: VFC<ItemProps> = ({ isSelected, item, onClick, renderHover }) => {
  const [isHover, setIsHover] = useState(false)

  return (
    <BaseItem
      key={item.id}
      className={isSelected ? 'selected' : ''}
      onClick={() => onClick(item.id)}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      {item.icon && <IconWrapper>{item.icon}</IconWrapper>}
      <ItemText size='body' truncate weight={isSelected ? 'bold' : 'normal'}>
        {item.label}
      </ItemText>
      {isHover && renderHover && renderHover(item.id)}
    </BaseItem>
  )
}
