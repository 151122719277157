import { useOverState } from 'store'
import { HardwarePanel } from './HardwarePanel'
import { ProItemPanel } from './ProItemPanel'

import type { FC } from 'react'
import type { SummaryProps } from './types'

export const Summary: FC<SummaryProps> = ({ quote }) => {
  const { quotes } = useOverState()

  return (
    <>
      {quotes.current.hasUiItems && <HardwarePanel quote={quote} />}
      {quotes.current.hasProItems && <ProItemPanel quote={quote} />}
    </>
  )
}
