import { Button } from '@ubnt/ui-components/Button'
import { Checkbox } from '@ubnt/ui-components/Checkbox'

import { useActions, useOverState } from 'store'
import { UseCard } from '../constants'

import type { FC } from 'react'
import type { SaveMethodProps } from '../types'
import { FlexColumn } from 'components'

export const SaveMethod: FC<SaveMethodProps> = ({ type, use }) => {
  const { payment } = useOverState()
  const actions = useActions()

  if (use === UseCard.EMPTY) return null
  if (use === UseCard.CHECKBOX)
    return (
      <Checkbox
        id='save'
        checked={payment.save}
        onChange={() => actions.payment.setSave({ save: !payment.save })}
      >
        Save this payment card
      </Checkbox>
    )

  const text = use === UseCard.ANOTHER_CARD ? 'Use Saved Card' : 'Use Another Card'

  return (
    <FlexColumn>
      {use === UseCard.USE_SAVED && (
        <Button
          contentClassName='flex-start'
          onClick={() => actions.modal.setModal({ modal: 'card_details', props: { type } })}
          size='small'
          style={{ marginBottom: 6, marginTop: 6 }}
          variant='inline'
        >
          Update Billing Details
        </Button>
      )}
      <Button
        contentClassName='flex-start'
        onClick={() => actions.payment.setUseSaved(!payment.useSaved)}
        size='medium'
        variant='inline'
        weight='bold'
      >
        {text}
      </Button>
    </FlexColumn>
  )
}
