import { useEffect, useState } from 'react'
import { useOverState } from 'store'

import config from 'config'

const CONTAINER_ID = 'recaptcha'
const SCRIPT_URL = `https://www.google.com/recaptcha/enterprise.js?render=explicit`

interface Props {
  className?: string
  onExpired?: () => void
  onVerify: (token: string) => void
}

export const ReCaptcha = ({ className, onExpired, onVerify }: Props) => {
  const recaptcha = useReCaptcha()
  const { recaptcha: resetter } = useOverState().app

  useEffect(() => {
    resetter && recaptcha?.reset()
  }, [resetter])

  useEffect(() => {
    if (!recaptcha) return
    recaptcha.render(CONTAINER_ID, {
      callback: onVerify,
      'expired-callback': onExpired,
      sitekey: config.RECAPTCHA,
    })
  }, [onExpired, onVerify, recaptcha])

  return <div id={CONTAINER_ID} className={className} />
}

export const useReCaptcha = () => {
  const [recaptcha, setRecaptcha] = useState<ReCaptchaV2.ReCaptcha>()

  useEffect(() => {
    if (recaptcha) return

    const handleLoad = () => {
      window.grecaptcha.enterprise.ready(() => setRecaptcha(window.grecaptcha.enterprise))
    }

    if (window.grecaptcha) return handleLoad()

    const script = document.createElement('script')
    script.src = SCRIPT_URL
    script.async = true
    script.defer = true
    script.addEventListener('load', handleLoad)
    document.body.appendChild(script)

    return () => script.removeEventListener('load', handleLoad)
  }, [])

  return recaptcha
}
