import styled from 'styled-components'
import { SidePanel as UISidePanel } from '@ubnt/ui-components/SidePanel'
import { Tabs as UITabs } from '@ubnt/ui-components/Tabs'

export const SidePanel = styled(UISidePanel)`
  > header {
    font-weight: bold;
  }
`

export const Tabs = styled(UITabs)`
  width: 100%;

  border-bottom: ${({ theme }) => `1px solid ${theme.borderColor}`};
  background-color: ${({ theme }) => theme.colors.Light.color.neutral.n1};

  .tabs {
    height: 47px;
  }

  .tabs > span {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 10px;
  }

  .selected > span {
    color: ${({ theme }) => theme.pallette.blue.main};
  }

  .tabs:focus {
    outline: none;
  }

  .tabs svg {
    width: 18px;
    height: 18px;
  }
`

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 28px;
  justify-content: space-between;
`

export const Container = styled.div`
  display: flex;
  border-bottom: ${({ theme }) => `1px solid ${theme.borderColor}`};
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px 0;

  &.column {
    flex-direction: column;
    align-items: flex-start;
  }

  &.borderless {
    border: none;
  }

  &.fit_content {
    width: fit-content;
  }

  &.align_end {
    align-items: flex-end;
  }

  &.justify_end {
    justify-content: flex-end;
  }

  &.no_padding {
    padding: 0;
  }

  &.main {
    justify-content: flex-start;
    height: calc(100% - 47px);
    overflow-y: auto;
  }

  &.withoutTabs {
    height: 100%;
  }

  &.text_align {
    text-align: end;
  }

  &.padding_h {
    padding-left: 28px;
    padding-right: 28px;
  }

  &.n2 {
    background-color: ${({ theme }) => theme.colors.Light.color.neutral.n3};
  }

  &.margin_top {
    margin-top: 3px;
  }

  &.height100 {
    height: 100%;
  }

  &.stretch {
    align-items: stretch;
  }
`

export const Link = styled.a`
  color: ${({ theme }) => theme.pallette.blue.main};
  text-decoration: none;
`
