import { cond, equals, always, T, identity, pipe } from 'ramda'

import { capitalise } from 'helpers'
import { FulfillmentStatus } from 'store/orderDetails/constants'

export const getShippingStatus = pipe(
  cond<any, string>([
    [equals(FulfillmentStatus.PENDING_PROCESSING), always('unfulfilled')],
    [equals(FulfillmentStatus.SHIPPED), always('fulfilled')],
    [equals(FulfillmentStatus.PARTIALLY_SHIPPED), always('partial')],
    [T, identity],
  ]),
  capitalise,
)
