import { T } from 'ramda'
import { Text } from '@ubnt/ui-components'
import styled from 'styled-components'

import { Avatar } from 'components'
import { renderItemAdjustment, renderNote } from './helpers'

import { ReactComponent as HardwareIcon } from 'assets/icons/Hardware.svg'
import { ReactComponent as ServiceIcon } from 'assets/icons/Service.svg'

import type { TableColumnConfig } from '@ubnt/ui-components/Table'
import type { HardwareItem, ServiceItem } from './types'

const renderHardwareIcon = ({ thumbnail }: HardwareItem) => {
  if (!thumbnail) return <HardwareIcon height={24} width={24} />
  return (
    <Avatar
      name={['Unifi']}
      logoUrl={thumbnail || null}
      imgSize={24}
      fontSize={9}
      fontWeight='bold'
      className='avatar'
      backgroundColor='inherit'
    />
  )
}

const renderServiceIcon = () => <ServiceIcon height={24} width={24} />

export const hardwareColumns: TableColumnConfig<HardwareItem>[] = [
  {
    id: 'name',
    label: 'Hardware',
    minWidth: 180,
    renderCell: (row) =>
      row.name && (
        <>
          {renderHardwareIcon(row)}
          <Label size='body'>{row.name}</Label>
        </>
      ),
  },
  { id: 'qty', label: 'Quantity', minWidth: 70 },
  { id: 'price', label: 'Price', minWidth: 80 },
  { id: 'amount', label: 'Amount', minWidth: 80 },
  { id: 'adjustment', label: 'Change', minWidth: 70, renderCell: renderItemAdjustment },
  {
    id: 'note',
    growthFactor: 0,
    label: '',
    minWidth: 30,
    omitHideCellOverflow: T,
    renderCell: renderNote,
  },
]

export const serviceColumns: TableColumnConfig<ServiceItem>[] = [
  {
    id: 'name',
    label: 'Service',
    minWidth: 250,
    renderCell: ({ name }) =>
      name && (
        <>
          {renderServiceIcon()}
          <Label size='body'>{name}</Label>
        </>
      ),
  },
  { id: 'servicePrice', label: 'Price', minWidth: 80 },
  { id: 'amount', label: 'Amount', minWidth: 80 },
  { id: 'adjustment', label: 'Change', minWidth: 70, renderCell: renderItemAdjustment },
  {
    id: 'note',
    growthFactor: 0,
    label: '',
    minWidth: 30,
    omitHideCellOverflow: T,
    renderCell: renderNote,
  },
]

const Label = styled(Text)`
  margin-left: 8px;
`
