import { Rating } from 'components'
import { RatingWrapper } from './styles'

import type { FC } from 'react'
import type { ConditionalRatingProps } from './types'

export const ConditionalRating: FC<ConditionalRatingProps> = ({ rating = 0, show }) => {
  if (!show) return null
  return (
    <RatingWrapper>
      <Rating readonly size='large' value={rating} width='200px' />
    </RatingWrapper>
  )
}
