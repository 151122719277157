import { CloseWithCircleIconL } from '@ubnt/icons'
import {
  BubbleContainer as UIBbubbleContainer,
  Checkbox as UICheckbox,
  Input as UIInput,
  NumberSelector as UINumberSelector,
  Radio as UIRadio,
  Text as UIText,
  TextArea as UITextArea,
} from '@ubnt/ui-components'
import styled, { css } from 'styled-components'

import { InputWithUnit as BInputWithUnit, LabelContainer } from 'components'
import { MEDIA } from 'styles/constants'

export const BubbleContainer = styled(UIBbubbleContainer)`
  display: flex;
  flex-direction: column;

  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.colors.Light.color.neutral.n3};
  margin: 16px 0px;
  padding: 0px;
`

const bubbleRowStyle = ({ wide }: { wide?: boolean } = { wide: false }) => css`
  padding: 12px 10px;

  .bubble-field {
    margin: ${wide ? '0' : '8px'};
  }

  .input-field {
    margin: 0px;
  }

  .note-button {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid ${({ theme }) => theme.colors.Light.color.neutral.n4};

    display: flex;
    align-items: flex-end;
    padding: 3px;
    margin-right: 10px;

    &:hover {
      border-color: ${({ theme }) => theme.colors.Light.color.neutral.n8};
      cursor: pointer;
    }
  }
`

export const BubbleRowWide = styled.article`
  display: grid;
  grid-template-columns: 310px 135px 160px;
  grid-template-rows: auto;
  justify-items: stretch;
  align-items: center;
  gap: 8px;
  grid-gap: 8px;

  ${bubbleRowStyle({ wide: true })}
`

export const BubbleRowCompact = styled.article`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  border-bottom: 1px solid ${({ theme }) => theme.colors.Light.color.neutral.n3};

  &:last-child {
    border-bottom: none;
  }

  ${bubbleRowStyle()}
`

interface CheckboxProps {
  full?: boolean
  location?: 'sidebar' | 'form'
  pointer?: 'default'
  type?: 'small' | 'big'
}

export const Checkbox = styled(UICheckbox).attrs(({ checked, theme }) => ({
  border: checked ? theme.colors.Light.color.blue.b6 : theme.colors.Light.color.neutral.n3,
}))<CheckboxProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ border }) => border};
  border-radius: 8px;
  margin: 3px;
  overflow: hidden;

  height: ${({ type }) => (type === 'big' ? 160 : 120)}px;
  width: ${({ type, location }) =>
    type !== 'big' ? 120 : location === 'form' ? 180 : 165}px !important;

  position: relative;

  @media ${MEDIA.TABLET} {
    width: ${({ type }) => (type === 'big' ? 165 : 120)}px;
  }

  @media ${MEDIA.MOBILE} {
    width: ${({ type }) => (type === 'big' ? 140 : 120)}px;
  }

  > div:first-child {
    position: absolute;
    top: 4px;
    right: 0px;
    z-index: 1;

    ${({ pointer }) => (pointer ? `> input { cursor: ${pointer} !important; }` : '')}
    > input {
      background-color: white;
    }
  }

  > div:nth-child(2) > div > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: ${({ full }) => (full ? '0px 0px 16px' : '16px 0px')};
    ${({ pointer }) => (pointer ? `cursor: ${pointer} !important;` : '')}
    width: 100%;
  }
`

interface CheckboxImgProps {
  location?: 'form' | 'sidebar'
}

export const CheckboxImg = styled.img<CheckboxImgProps>`
  height: auto;
  width: ${({ location }) => (location === 'form' ? 180 : 165)}px;

  @media ${MEDIA.TABLET} {
    width: 164px;
  }

  @media ${MEDIA.MOBILE} {
    width: 140px;
  }
`

interface CheckboxImgContainerProps {
  checked?: boolean
  location?: 'sidebar' | 'form'
}

export const CheckboxImgContainer = styled.div<CheckboxImgContainerProps>`
  border: 1px solid
    ${({ checked, theme }) =>
      checked ? theme.colors.Light.color.blue.b6 : theme.colors.Light.color.neutral.n3};
  border-bottom: none;
  border-left: none;
  border-radius: 8px 8px 0px 0px;
  width: ${({ location }) => (location === 'form' ? 180 : 164)}px;
  height: 92px;
  overflow: hidden;
  margin-bottom: 12px;

  @media ${MEDIA.TABLET} {
    width: 164px;
  }

  @media ${MEDIA.MOBILE} {
    width: 140px;
  }
`

interface FileProps {
  isForm?: boolean
}

export const CloseIcon = styled(CloseWithCircleIconL)<FileProps>`
  position: absolute;
  top: -4px;
  top: ${({ isForm }) => (isForm ? '-6px' : '2px')};
  right: -6px;

  cursor: pointer;
  fill: ${({ theme }) => theme.colors.Light.color.blue.b6};
`

interface FloorContainerProps {
  border?: boolean
}

export const FloorContainer = styled.div<FloorContainerProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;

  ${({ border, theme }) => (border ? `border-bottom: 1px solid ${theme.borderColor};` : '')}
`

export const FloorImg = styled.img<FileProps>`
  border-radius: 4px;
  object-fit: cover;
  border: 1px solid ${({ theme }) => theme.colors.Light.color.neutral.n8};
  font-size: 10px;
  margin-top: ${({ isForm }) => (isForm ? 'auto' : '10px')};

  max-height: 40px;
  max-width: 50px;
  height: 36px;
  width: 36px;
`

export const FloorImgContainer = styled.div`
  position: relative;
  margin-right: 5px;
`

export const FloorItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
`

export const FloorWrapper = styled(LabelContainer)`
  display: flex;
  flex-direction: column;
`

export const FileTitle = styled(UIText)`
  padding: 0px 2px;
  width: 100%;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-decoration: none;
`

export const FileWrapper = styled.div<FileProps>`
  padding: 0px 1px;
  width: 36px;
  margin-top: ${({ isForm }) => (isForm ? '0px' : '10px')};
  border-radius: 4px;
  object-fit: cover;

  border: 1px solid ${({ theme }) => theme.colors.Light.color.neutral.n8};
  background: ${({ theme }) => theme.colors.Light.color.neutral.n3};
  font-size: 10px;
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .radio-label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;

    > div {
      margin: 0;
    }
  }

  .radio-check {
    height: 75px;
    width: 75px;
    display: flex;
    align-items: center;
    justify-content: center;

    > div:first-child {
      display: none;
    }
  }

  .remove-radio {
    > div:first-child {
      display: none;
    }
  }
`

export interface FormSectionProps {
  border?: boolean
  bottomSpace?: number
  renderContext?: 'form' | 'sidebar'
  type?: 'header' | 'footer'
  main?: boolean
}

const mainFormSection = css`
  flex-grow: 1;
  flex-shrink: 1;
`

export const FormSection = styled.section<FormSectionProps>`
  display: flex;
  flex-direction: ${({ type }) => (type === 'footer' ? 'row-reverse' : 'column')};
  justify-content: ${({ renderContext, type }) =>
    type === 'footer' && renderContext === 'form' ? 'space-between' : 'flex-start'};
  align-items: flex-start;

  ${({ main }) => main && mainFormSection}
  ${({ main, renderContext }) =>
    main &&
    renderContext === 'sidebar' &&
    `max-height: calc(100vh - 200px);
    overflow-y: auto;`}


  min-width: ${({ renderContext }) => (renderContext === 'form' ? '50vw' : 'auto')};

  margin-bottom: ${({ bottomSpace }) => bottomSpace || 0}px;
  padding-top: ${({ type }) => (type === 'footer' || type === 'header' ? 28 : 20)}px;
  padding-bottom: ${({ type }) => (type !== 'header' ? 28 : 0)}px;
  padding-left: ${({ renderContext }) => (renderContext === 'sidebar' ? 28 : 16)}px;
  padding-right: ${({ renderContext }) => (renderContext === 'sidebar' ? 28 : 16)}px;

  ${({ border, theme }) =>
    border ? `border-top: 1px solid ${theme.colors.Light.color.neutral.n4};` : ''};

  > div:first-child {
    margin-top: 0;
  }

  @media ${MEDIA.TABLET} {
    min-width: auto;
    max-width: 100vw;

    .input__name {
      max-width: 345px;
    }
  }

  @media ${MEDIA.MOBILE} {
    padding-left: ${({ renderContext, type }) =>
      renderContext === 'form' || type === 'header' ? 16 : 28}px;

    .input__name {
      max-width: 324px;
    }
  }
`

export interface InputProps {
  width?: number | string
}

export const Input = styled(UIInput)<InputProps>`
  margin: 15px 0px;
  min-width: ${({ width }) => (typeof width === 'number' ? `${width}px` : width || '520px')};

  @media ${MEDIA.TABLET} {
    min-width: auto;
    max-width: 90vw;
  }
`

export const InputContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-width: 300px;

  @media ${MEDIA.MOBILE} {
    min-width: 200px;
  }
`

export const InputExtra = styled.div`
  display: flex;
`

export const InputHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;

  .first-badge {
    margin-right: 4px;
  }
`

export const InputSection = styled.section`
  margin-top: 18px;
`

export const InputWithUnit = styled(BInputWithUnit)<InputProps>`
  margin: 15px 0px;
  min-width: ${({ width }) => width || 520}px;
`
export const NoteText = styled(UIText)`
  text-align: start;
`
export const NumberSelector = styled(UINumberSelector)`
  height: 24px;
  width: 85px;

  > button {
    height: 24px;
    width: 24px;
    cursor: ${({ readOnly }) => (readOnly ? 'default' : 'pointer')};
  }
`

export const PlanWrapper = styled.a`
  text-decoration: none;
  cursor: pointer;
  font-size: 10px;
`

export const Radio = styled(UIRadio).attrs(({ checked, theme }) => ({
  border: checked ? theme.colors.Light.color.blue.b6 : theme.colors.Light.color.neutral.n3,
  bg: checked ? theme.colors.Light.color.blue.b1 : theme.colors.Light.color.neutral.n0,
}))`
  background-color: ${({ bg }) => bg};
  border: 1px solid ${({ border }) => border};
  border-radius: 8px;
  margin: 4px 8px 4px 0px;
  padding: 14px 12px;

  > div:first-child {
    height: 0px;
    width: 0px;
    margin: 0px;
    padding: 0px;
    visibility: hidden;
  }

  > div:first-child > input {
    position: fixed !important;
    opacity: 0;
    pointer-events: none;
  }

  > div:first-child > div {
    display: none;
  }

  > label {
    display: flex;
    flex-direction: column;
    align-items: center;

    min-width: 82px;
    width: 100%;
  }
`

export const SidebarSection = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: flex-end;
  flex-grow: 1;
`

interface SubSectionProps {
  marginTop?: number
}

export const SubSection = styled.section<SubSectionProps>`
  margin: ${({ marginTop }) => marginTop || 0}px 0px 32px;
  min-width: auto;
  width: 100%;
`

export const TextArea = styled(UITextArea)`
  font-size: 14px;
  border: 1px solid ${({ theme }) => theme.borderColor};
  border-radius: 4px;
  width: 100% !important;

  .note-textarea {
    padding: 12px;
    min-height: 132px;
    resize: vertical;
  }
`

export const UploadText = styled(UIText)`
  color: ${({ theme }) => theme.colors.Light.color.blue.b6};
  font-weight: bold;
  text-align: center;
  width: 100%;
`

export const WifiData = styled.div`
  display: grid;
  grid-template-columns: 90px 94px 1fr;
  grid-gap: 8px;
  gap: 8px;
  align-items: center;

  width: 100%;

  > .last-two {
    grid-column: 2 / 4;
  }
`

export const ButtonWrapper = styled.div`
  font-size: 10px;
  margin-left: 1px;
`
