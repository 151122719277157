import { propOr } from 'ramda'
import styled from 'styled-components'
import type { FlexContainerProps } from './types'

export const FlexColumn = styled.section<FlexContainerProps>`
  display: flex;
  flex-direction: column;
  flex-grow: ${propOr(0, 'grow')};
  align-items: ${propOr('stretch', 'alignItems')};
  justify-content: ${propOr('flex-start', 'justify')};

  margin-bottom: ${({ verticalSpacing }) => (verticalSpacing ? `${verticalSpacing}px` : 'none')};
  margin-top: ${({ verticalSpacing }) => (verticalSpacing ? `${verticalSpacing}px` : 'none')};
`
