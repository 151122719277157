import { FC, ChangeEvent } from 'react'
import { Text } from '@ubnt/ui-components/Text'

import { SubSection, TextArea } from './styles'

interface NoteProps {
  caption?: string
  editable?: boolean
  hideCaption?: boolean
  onChange: (event: ChangeEvent<HTMLTextAreaElement>, value: string) => void
  placeholder?: string
  value: string
}

const _caption = 'Any notes?'
const _placeholder =
  'Please add any additional information to help your Professional plan your project.'

export const Note: FC<NoteProps> = ({
  caption = _caption,
  editable = true,
  hideCaption = false,
  onChange,
  placeholder = _placeholder,
  value,
}) => {
  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>, value: string) => {
    if (!editable) return
    return onChange(event, value)
  }

  return (
    <SubSection marginTop={16}>
      {hideCaption || <Text size='caption'>{caption}</Text>}
      <TextArea
        inputClassName='note-textarea no-resize-x'
        onChange={handleChange}
        placeholder={placeholder}
        readOnly={!editable}
        value={value}
      />
    </SubSection>
  )
}
