import { ChatIcon } from '@ubnt/icons'
import { Text } from '@ubnt/ui-components/Text'
import { Tooltip } from '@ubnt/ui-components/Tooltip'
import { always, concat, isEmpty, isNil, isNotNil, map, pipe, prop } from 'ramda'

import { FlexColumn, FlexRow } from 'components'
import { ItemPrice } from './ItemPrice'
import { ItemQty } from './ItemQty'
import { TaxTooltip } from './TaxTooltip'
import {
  addVariantTitle,
  rejectRemoved,
  round,
  toAdjustedCurrency,
  toLocaleCurrency,
} from 'helpers'
import { HardwareLabel } from './HardwareLabel'
import { ArrowIcon, ChangeText, ItemText, LabelText, ProItemLabel } from './styles'

import type {
  Product,
  Quote,
  QuoteProHardware,
  QuoteProService,
  SectionPrice,
} from 'store/quotes/types'
import type { HardwareItem, RenderAccordionLabelOptions, SalesRow, ServiceItem } from './types'
import type { TableItemWithContext } from '@ubnt/ui-components/Table/TableTypes'

const makeSalesRow = (label: string, value: number | string, adjustment: number): SalesRow => ({
  id: `${label}-${value}`,
  amount: typeof value === 'number' ? toLocaleCurrency(value) : <TaxTooltip />,
  name: '',
  price: '',
  qty: <Text weight='bold'>{label}</Text>,
  adjustment: adjustment === 0 ? null : adjustment,
  note: '',
  servicePrice: <Text weight='bold'>{label}</Text>,
})

const makeShippingRow = (shipping: number, diff: number): SalesRow => ({
  id: 'shipping',
  amount: shipping === 0 ? 'Free' : toLocaleCurrency(shipping),
  name: '',
  price: '',
  qty: <Text weight='bold'>Shipping</Text>,
  adjustment: diff,
  note: '',
  servicePrice: <Text weight='bold'>Shipping</Text>,
})

const parseOneHardware = (x: QuoteProHardware): HardwareItem => {
  const _price = x.adjusted.price ?? x.price
  const price = <ItemPrice item={x} />

  const _qty = x.adjusted.qty ?? x.qty
  const qty = <ItemQty item={x} />

  const adjustment =
    'adjusted' in x && x.adjusted.type === 'removed'
      ? round(_price * _qty * -1)
      : 'adjusted' in x && (isNotNil(x.adjusted.price) || isNotNil(x.adjusted.qty))
      ? round(_price * _qty - x.price * x.qty)
      : 'adjusted' in x && x.adjusted.type === 'added'
      ? round(_price * _qty)
      : null

  const _amount = 'adjusted' in x && x.adjusted.type === 'removed' ? 0 : round(_price * _qty)
  return {
    id: x.id,
    name: x.name,
    price,
    qty,
    amount: toLocaleCurrency(_amount),
    adjustment,
    note: x.note,
    data: {
      adjusted: x.adjusted,
      tax: x.tax,
    },
  }
}

const parseOneService = (x: QuoteProService): ServiceItem => {
  const adjustment =
    x.adjusted.type === 'removed'
      ? x.price * -1
      : x.adjusted.type === 'added'
      ? x.adjusted.price ?? x.price
      : !isNil(x.adjusted.price)
      ? round(x.adjusted.price - x.price)
      : null

  const _amount = x.adjusted.type === 'removed' ? 0 : x.adjusted.price ?? x.price

  return {
    id: x.id,
    amount: toLocaleCurrency(_amount),
    name: x.name,
    servicePrice: <ItemPrice item={x} withNew />,
    adjustment,
    note: x.note,
    data: {
      adjusted: x.adjusted,
      tax: x.tax,
    },
  }
}

const parseOneUIHardware = (x: Product): HardwareItem => {
  const _price = x.price
  const price = <ItemPrice item={x} />

  const _qty = x.adjusted.qty ?? x.quantity
  const qty = <ItemQty item={x} />

  const adjustment =
    'adjusted' in x && x.adjusted.type === 'removed'
      ? round(_price * _qty * -1)
      : isNotNil(x.adjusted.qty)
      ? round(_price * _qty - x.price * x.quantity)
      : x.adjusted.type === 'added'
      ? round(_price * _qty)
      : null

  const _amount = 'adjusted' in x && x.adjusted.type === 'removed' ? 0 : round(_price * _qty)

  return {
    id: x.id,
    name: (
      <>
        <p>{addVariantTitle(x, 'title')}</p>
        {!x.inStock && x.backorderable && <Text color='warning'>Extended Lead Time</Text>}
        {!x.inStock && !x.backorderable && <Text color='danger'>Out of Stock</Text>}
      </>
    ),
    price,
    qty,
    amount: toLocaleCurrency(_amount),
    thumbnail: x.thumbnail,
    adjustment,
    note: '',
    data: {
      adjusted: x.adjusted,
    },
  }
}

const parseHardware: (x: QuoteProHardware[]) => HardwareItem[] = map(parseOneHardware)

const parseSalesData = (
  section: SectionPrice,
  taxer: (value: SectionPrice) => string | number,
): SalesRow[] => {
  const shippingRow = section.shipping !== undefined ? [makeShippingRow(section.shipping, 0)] : []

  return [
    makeSalesRow('Subtotal', section.base, section.diff.base),
    makeSalesRow('Tax', taxer(section), section.diff.tax),
    ...shippingRow,
    makeSalesRow('Total', section.total, section.diff.total),
  ]
}

const parseServices: (x: QuoteProService[]) => ServiceItem[] = map(parseOneService)

const parseUIHardware: (x: Product[]) => HardwareItem[] = map(parseOneUIHardware)

// Exports

export const isEmptyItemList: (x: Product[] | QuoteProHardware[] | QuoteProService[]) => boolean =
  pipe<any[], any[], boolean>(rejectRemoved, isEmpty)

export const makeHardwareRows = (a: QuoteProHardware[], b: SectionPrice) =>
  concat(parseHardware(a), parseSalesData(b, prop('tax')))

export const makeRowClassName = ({
  data,
}: TableItemWithContext<HardwareItem> | TableItemWithContext<ServiceItem>) =>
  data?.adjusted?.type === 'removed' ? 'transparent' : ''

export const makeServiceRows = (a: QuoteProService[], b: SectionPrice) =>
  concat(parseServices(a), parseSalesData(b, prop('tax')))

export const makeUIHardwareRows = (quote: Quote) => {
  const taxer = quote.orderId ? prop('tax') : always('-')
  return concat(
    parseUIHardware(quote.hardwareList),
    parseSalesData(quote.priceBreakdown.productVariants, taxer),
  )
}

export const renderAccordionLabel =
  ({ adjustment, id, isVisibleAdjustment, label, price, step }: RenderAccordionLabelOptions) =>
  (itemExpanded: boolean, togglePanel: (id: string) => void) =>
    (
      <ProItemLabel
        itemExpanded={itemExpanded}
        showArrow={false}
        togglePanelOpen={() => togglePanel(id)}
      >
        <FlexRow alignItems='stretch' grow={1} justify='space-between'>
          <LabelText size='body' weight='bold'>
            {label}
          </LabelText>
          <FlexRow alignItems='flex-start' style={{ marginTop: 24 }}>
            <FlexColumn alignItems='flex-end'>
              <Text size='header-xs' weight='bold'>
                {toLocaleCurrency(price)}
              </Text>
              {isVisibleAdjustment && (
                <>
                  {!isNil(adjustment) && adjustment !== 0 ? (
                    <ChangeText size='body' strike>
                      {toLocaleCurrency(round(price - (adjustment as number)))}
                    </ChangeText>
                  ) : (
                    <ChangeText size='body'>{step < 3 ? 'Estimated Cost' : 'No Change'}</ChangeText>
                  )}
                </>
              )}
            </FlexColumn>
            <ArrowIcon className={itemExpanded ? 'expanded' : undefined} size='large' />
          </FlexRow>
        </FlexRow>
      </ProItemLabel>
    )

export const renderHardwareLabel =
  (id: string, quote: Quote) => (expanded: boolean, toggle: (id: string) => void) =>
    <HardwareLabel expanded={expanded} quote={quote} toggle={() => toggle(id)} />

export const renderItemAdjustment = ({ adjustment }: HardwareItem | ServiceItem) =>
  isNil(adjustment) ? '' : <ItemText type='highlight'>{toAdjustedCurrency(adjustment)}</ItemText>

export const renderNote = ({ note }: HardwareItem | ServiceItem) =>
  isEmpty(note) ? (
    ''
  ) : (
    <Tooltip description={note} position='bottomEnd' width={200}>
      <ChatIcon />
    </Tooltip>
  )
