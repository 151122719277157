import type { PriceOptions } from 'types'

const _capitalise = (str: string) => str.replace(/^./, (x) => x.toUpperCase())
const _capitaliseAll = (str: string) => str.split(/\s|-/).map(_capitalise).join(' ')

export const capitalise = (str: string, opts: { every?: boolean } = {}) =>
  opts.every ? _capitaliseAll(str) : _capitalise(str)

export const escapeRegExp = (str: string) => {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export const pluralise =
  (unit: string, plural = 's') =>
  (num: number) =>
    `${num} ${unit}${Math.abs(num) === 1 ? '' : plural}`

export const setPriceModelLabel = (pricingModel: PriceOptions) => {
  return `Number of ${pricingModel.replace('Per ', '')}(s)`
}
