import { branch, catchError, filter, noop, OperatorContextFunction, pipe, when } from 'overmind'
import { isNil, isNotNil } from 'ramda'
import * as Sentry from '@sentry/react'

import { AsyncFuncState } from 'shared'
import * as app from 'store/app/operators'
import { handleError as handleAxiosError } from 'store/helpers'
import { ERROR_TOKEN } from './constants'

import type { Context } from 'store'
import type { ToastType } from 'types'
import type { AsyncError, HandleAsyncActionOptions, StateSection, WithOnSuccess } from './types'

const expireSession = when<any, void>(
  ({ state }: Context, value: any) =>
    isNotNil(state.app.user) &&
    value &&
    typeof value === 'object' &&
    'error' in value &&
    value.error?.response?.status === 401,
  {
    true: app.redirectTo('login', 'error=expired'),
    false: noop(),
  },
)

const ifNotLoading = (section: StateSection) =>
  filter<any>(({ state }: Context) => {
    const _section = state[section]
    return 'status' in _section && _section.status !== AsyncFuncState.LOADING
  })

const ifOutdated = filter(({ state }: Context) => state.app.build === 'outdated')

const isError = (_: Context, value: any) =>
  typeof value === 'object' &&
  Object.prototype.hasOwnProperty.call(value, ERROR_TOKEN) &&
  value[ERROR_TOKEN] === true

const makeError = (_: Context, error: any): AsyncError => ({
  [ERROR_TOKEN]: true,
  error,
})

const reload = when(isError, {
  true: app.refresh('show=reloaded'),
  false: app.refresh(),
})

export const setStatus =
  (section: StateSection, status: AsyncFuncState) =>
  <A>({ state }: Context, value: A) => {
    const _section = state[section]
    if ('status' in _section) _section.status = status
    return value
  }

export const ifPropIs = <A>(prop: string | number, test: any) =>
  filter<A>((_: Context, value: any) => {
    if (typeof value !== 'object' || isNil(value)) return false
    if (typeof test === 'function') return test(value[prop]) === true
    return prop in value && value[prop] === test
  })

export const rethrowError = (_: Context, { error }: any) => {
  if (error) throw error
}

export const runOnSuccess = <A extends WithOnSuccess>() =>
  branch<A, A>((_: Context, { onSuccess }: WithOnSuccess) => onSuccess && onSuccess())

export const toast =
  (title: string, message: string | string[], type: ToastType = 'success') =>
  ({ state }: Context) => {
    state.app.toast.title = title
    state.app.toast.message = message
    state.app.toast.type = type
  }

export const toastError =
  (title = 'Error loading data', message?: string, show = true) =>
  ({ state }: Context, { error }: AsyncError) => {
    if (show) {
      state.app.toast.title = title
      state.app.toast.message = message || handleAxiosError(error)
      state.app.toast.type = 'danger'
    }
  }

export const whenPropIs = <A, B = A>(
  prop: string | number,
  test: any,
  paths: {
    true: OperatorContextFunction<A, B>
    false: OperatorContextFunction<A, B>
  },
) =>
  when<A, B>((_: Context, value: any) => {
    if (typeof value !== 'object' || isNil(value)) return false
    if (typeof test === 'function') return test(value[prop]) === true
    return prop in value ? value[prop] === test : false
  }, paths)

export const whenUserIsLoggedIn = <A, B = A>(paths: {
  true: OperatorContextFunction<A, B>
  false: OperatorContextFunction<A, B>
}) => when<A, B>(({ state }) => !isNil(state.app.user), paths)

export const handleAsyncAction =
  (section: StateSection) =>
  <A, B = A>(opts: OperatorContextFunction<A, B> | HandleAsyncActionOptions<A, B>) => {
    const {
      action,
      after = noop() as OperatorContextFunction<AsyncError | B, B>,
      before = noop() as OperatorContextFunction<A, A>,
      errorDescription = undefined,
      errorTitle = undefined,
      onError = noop<AsyncError>(),
      toastOnError = true,
    } = 'action' in opts ? opts : { action: opts }

    return pipe(
      before,
      ifNotLoading(section),
      setStatus(section, AsyncFuncState.LOADING),
      action,
      after,
      catchError<any>(makeError),
      when(isError, {
        true: pipe(
          branch(toastError(errorTitle, errorDescription, toastOnError)),
          branch((_: Context, error) => Sentry.captureException(error.error)),
          branch(onError),
          after,
          setStatus(section, AsyncFuncState.ERROR),
          branch(expireSession),
        ),
        false: setStatus(section, AsyncFuncState.SUCCESS),
      }),
      toastOnError ? branch<any, void, void>(ifOutdated, reload) : noop(),
    )
  }
