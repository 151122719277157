import { always, defaultTo, head, ifElse, isNil, isNotNil, pipe, prop, when } from 'ramda'

import type { Dispute } from 'types'
import type { Quote } from 'store/quotes/types'

export const getDisputeText: (x: Quote | null) => string = pipe(
  defaultTo({ disputes: [{ dispute: '' }] }),
  prop('disputes'),
  head,
  ifElse<[Dispute], string, string>(isNil, always(''), prop('dispute')),
)

export const getDisputeId = pipe<
  [Quote | null],
  Pick<Quote, 'disputes'>,
  Quote['disputes'],
  any,
  string | undefined
>(defaultTo({ disputes: [] }), prop('disputes'), head, when(isNotNil, prop('id')))
