import { Text } from '@ubnt/ui-components/Text'
import { concat, isNil } from 'ramda'

import { useOverState } from 'store'
import { CardLast4, FlexRow, Loader, OrderSummary, UserAvatar } from 'components'
import { Container } from './styles'
import {
  dateOr,
  proHardwareToCheckout,
  proServicesToCheckout,
  round,
  toLocaleCurrency,
} from 'helpers'

import type { FC } from 'react'
import type { ProPaymentDetailsProps } from './types'

export const ProPaymentDetails: FC<ProPaymentDetailsProps> = ({ quote }) => {
  const { orderDetails } = useOverState()

  const items = concat(
    proHardwareToCheckout(quote.proHardware),
    proServicesToCheckout(quote.proServices),
  )
  const subtotal = round(
    quote.priceBreakdown.proHardware.base + quote.priceBreakdown.proServices.base,
  )
  const tax = round(quote.priceBreakdown.proHardware.tax + quote.priceBreakdown.proServices.tax)
  const total = round(
    quote.priceBreakdown.proHardware.total + quote.priceBreakdown.proServices.total,
  )

  return (
    <Container className='column borderless no_padding'>
      <Container className='column'>
        <Text size='body' weight='bold'>
          Quote Name
        </Text>
        <Text size='body'>{quote.quoteName}</Text>
      </Container>
      <Container>
        <Text size='body' weight='bold'>
          Date Placed
        </Text>
        <Text size='body'>{dateOr('-')(quote.completedAt)}</Text>
      </Container>
      <Container>
        <Text size='body' weight='bold'>
          Professional
        </Text>
        <Text size='body'>
          <UserAvatar fallbackUrl={quote.professional.avatar} user={quote.professionalUser} />
        </Text>
      </Container>
      <Container>
        <Text size='body' weight='bold'>
          Payment method
        </Text>
        <Loader status={orderDetails.status} cond={isNil(orderDetails.card)} type='small'>
          <CardLast4 brand={orderDetails.card?.brand} number={orderDetails.card?.number} />
        </Loader>
      </Container>
      <Container className='column stretch'>
        <Text size='body' weight='bold'>
          Order Summary
        </Text>
        <OrderSummary items={items} />
      </Container>
      <Container className='column stretch'>
        <FlexRow>
          <Text size='body' weight='bold'>
            Order Total
          </Text>
          <Text size='body'>{toLocaleCurrency(subtotal)}</Text>
        </FlexRow>
        <FlexRow verticalSpacing={9}>
          <Text size='body' weight='bold'>
            Tax
          </Text>
          <Text size='body'>{toLocaleCurrency(tax)}</Text>
        </FlexRow>
        <FlexRow>
          <Text size='body' weight='bold'>
            Total
          </Text>
          <Text size='body'>{toLocaleCurrency(total)}</Text>
        </FlexRow>
      </Container>
    </Container>
  )
}
