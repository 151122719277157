import { StepCircle } from './StepCircle'
import { Container, InfoText, Line, Step, StepText } from './styles'
import { makeStatus } from './helpers'

import type { FC } from 'react'
import type { StepProgressProps } from './types'

export const StepProgress: FC<StepProgressProps> = ({ current, steps }) => (
  <Container alignItems='flex-start'>
    <InfoText>{`Step ${Number(current) + 1} of ${steps.length}`}</InfoText>
    {steps.map((x, i) => {
      const status = makeStatus(current, x.value)
      return (
        <Step key={`${x.value}-${i}`} status={status}>
          {i > 0 && <Line area='line-1' />}
          <StepCircle status={status} step={i + 1} />
          {i < steps.length - 1 && <Line area='line-2' />}
          <StepText size='body'>{x.label}</StepText>
        </Step>
      )
    })}
  </Container>
)
