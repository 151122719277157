import {
  reject,
  pathEq,
  all,
  allPass,
  pipe,
  propEq,
  props,
  flatten,
  prop,
  map,
  anyPass,
  any,
} from 'ramda'

import { Avatar } from 'components'
import { isNotEmpty } from 'helpers'

import { ReactComponent as HardwareIcon } from 'assets/icons/Hardware.svg'
import { ReactComponent as ServiceIcon } from 'assets/icons/Service.svg'

import type { Product, Quote, QuoteProHardware, QuoteProService } from 'store/quotes/types'
import type { CheckoutItem } from 'components/OrderSummary/types'

const itemToCheckout =
  (def?: JSX.Element) =>
  (x: Product | QuoteProHardware | QuoteProService): CheckoutItem => {
    const price = ('price' in x.adjusted ? x.adjusted.price : x.price) ?? x.price
    const qty = 'quantity' in x ? x.quantity : 'qty' in x ? x.qty : undefined

    return {
      id: x.id,
      backorder: 'backorderable' in x && !x.inStock && x.backorderable,
      icon:
        'thumbnail' in x ? (
          <Avatar
            name={['Unifi']}
            logoUrl={x.thumbnail || null}
            imgSize={40}
            fontSize={9}
            fontWeight='bold'
            className='avatar'
            backgroundColor='inherit'
          />
        ) : (
          def
        ),
      name: x.name,
      price,
      qty: x.adjusted.qty ?? qty,
    }
  }

const flattenAdjustments = (
  def?: JSX.Element,
): (<A extends Product | QuoteProHardware | QuoteProService>(x: A[]) => CheckoutItem[]) =>
  pipe(rejectRemoved, map(itemToCheckout(def)))

// Exported functions

export const rejectRemoved: <A extends Product | QuoteProHardware | QuoteProService>(
  x: A[],
) => A[] = reject(pathEq('removed', ['adjusted', 'type']))

export const checkHardware: (x: Product[]) => boolean = pipe(
  rejectRemoved,
  allPass([
    isNotEmpty,
    all(anyPass<(x: Product) => boolean>([propEq(true, 'inStock'), propEq(true, 'backorderable')])),
  ]),
)

export const addVariantTitle = (item: Product, section: 'shortDescription' | 'title') => {
  if (!item.variantTitle || item.variantTitle === 'Default') return item[section]
  return section === 'title'
    ? `${item.title} ${item.variantTitle}`
    : `${item.variantTitle}. ${item.shortDescription}`
}

export const hasProItems: (x: Quote) => boolean = pipe<any, any, any, any, any>(
  props(['proHardware', 'proServices']),
  flatten,
  rejectRemoved,
  isNotEmpty,
)

export const hasUiItems: (x: Quote) => boolean = pipe(
  prop('hardwareList'),
  rejectRemoved,
  isNotEmpty,
)

export const proHardwareToCheckout = flattenAdjustments(<HardwareIcon height={40} width={40} />)
export const proServicesToCheckout = flattenAdjustments(<ServiceIcon height={40} width={40} />)
export const uiHardwareToCheckout = flattenAdjustments()

//TODO: Delete function when re-enabling multiple payments
export const hasBackorder: (xs: CheckoutItem[]) => boolean = any(propEq(true, 'backorder'))
