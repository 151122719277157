import { isNil } from 'ramda'
import { useEffect } from 'react'

import { useActions, useOverState } from 'store'

export const useCheckout = (quoteId: string) => {
  const { url } = useOverState().quotes.storeCheckout
  const actions = useActions().quotes

  let timer: NodeJS.Timer | undefined // For browser tab
  let timeout: NodeJS.Timeout | undefined // For loader
  let checkoutWindow: Window | null

  const clearTimer = () => timer && clearInterval(timer)

  useEffect(
    () => () => {
      clearTimer()
      timeout && clearTimeout(timeout)
      actions.resetCheckoutUrl()
      actions.resetStoreInCheckout()
      actions.unsubscribeCheckout({ quoteId })
    },
    [],
  )

  if (isNil(url)) return () => null

  const initiateCheckout = () => {
    actions.initiateCheckout({
      quoteId,
      onSuccess: () => {
        checkoutWindow = window.open(url, '_blank')
      },
    })

    timer = setInterval(() => {
      if (checkoutWindow?.closed) {
        clearTimer()

        // Give time to identify whether successful order or terminated checkout (i.e. closed checkout tab).
        timeout = setTimeout(() => actions.resetStoreInCheckout(), 1_000)
      }
    }, 500)
  }

  return initiateCheckout
}
