import { fromPairs } from 'ramda'
import { useLocation } from 'react-router'

export const useQuery = (args: string[]) => {
  const { search } = useLocation()
  const map = new URLSearchParams(search)
  const pairs: [string, string | null][] = args.map((x) => [x, map.get(x)])

  return fromPairs(pairs)
}
