import { FC, useState } from 'react'
import { Modal } from '@ubnt/ui-components/Modal'
import { Text } from '@ubnt/ui-components/Text'
import { TextArea } from '@ubnt/ui-components/TextArea'
import styled from 'styled-components'

import { Avatar, Rating } from 'components'
import { useActions, useOverState } from 'store'

import type { FeedbackModal } from 'store/modal/types'

export const Feedback: FC = () => {
  const { modal } = useOverState().modal
  const {
    message,
    order,
    quoteId,
    title = 'Rate your Professional',
  } = useOverState().modal.modalProps as FeedbackModal
  const {
    modal: { closeModal },
    quotes: { postOrderFeedback },
  } = useActions()
  const [rating, setRating] = useState(0)
  const [feedback, setFeedback] = useState('')

  const handleSend = () => postOrderFeedback({ quoteId, rating, feedback })

  const {
    professionalUser: { firstName, lastName, avatar },
    professional: { avatar: proCompanyAvatar },
  } = order

  return (
    <Modal
      title={title}
      isOpen={modal === 'feedback'}
      onRequestClose={closeModal}
      shouldCloseOnOverlayClick={false}
      size='small'
      actions={[
        {
          text: 'Cancel',
          onClick: closeModal,
          variant: 'secondary',
        },
        {
          text: 'Rate',
          onClick: handleSend,
          variant: 'primary',
        },
      ]}
    >
      <Container>
        {message && <Text size='body'>{message}</Text>}
        <Avatar
          name={firstName && lastName ? [firstName, lastName] : [firstName ?? lastName ?? '']}
          logoUrl={avatar || proCompanyAvatar}
          imgSize={40}
          fontSize={16}
          fontWeight='bold'
        />
        <Text weight='normal' size='body'>{`${firstName} ${lastName}`}</Text>
        <Rating height='25px' width='250px' onChange={setRating} />
        <TextArea
          height={104}
          inputClassName='no-resize'
          onChange={(e) => setFeedback(e.target.value)}
          placeholder='Leave your feedback (optional)'
          style={{ marginTop: '5px' }}
        />
      </Container>
    </Modal>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 250px;
`
