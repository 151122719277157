import { Accordion as BaseAccordion, Text } from '@ubnt/ui-components'
import {
  ArrowDownPrimaryIcon,
  ConnectElementIconL,
  ManageIconL,
  InfoIcon as BaseInfoIcon,
  PaymentMethodIconL as BasePaymentIcon,
  TopologyIcon as BaseTopologyIcon,
} from '@ubnt/icons'
import styled, { css } from 'styled-components'
import { always, anyPass, cond, propEq, T } from 'ramda'

import { AccordionLabel } from 'components'

import type { ChangeTextProps, ItemTextProps } from './types'

const boxIcon = css`
  margin-right: 10px;
`

const headerIcon = css`
  margin-bottom: 8px;
`

export const Accordion = styled(BaseAccordion)`
  margin-top: 16px;

  > li {
    margin-top: 0px !important;
    border-bottom: 1px solid ${({ theme }) => theme.colors.Light.color.neutral.n3};
  }

  > li:last-child {
    border-bottom: none;
  }
`

export const ArrowIcon = styled(ArrowDownPrimaryIcon)`
  color: ${({ theme }) => theme.colors.Light.color.neutral.n8};
  margin-left: 10px;
  transition: color 100ms ease, transform 200ms cubic-bezier(0.4, 0, 0, 1.4);
`

export const ChangeText = styled(Text)<ChangeTextProps>`
  color: ${({ theme }) => theme.colors.textColor.black.b45};
  text-decoration: ${({ strike }) => (strike ? 'line-through' : 'none')};
`

export const DisputeText = styled(Text)`
  color: ${({ theme }) => theme.colors.textColor.black.b45};
  margin-top: 8px;
`

export const HardwareIcon = styled(ConnectElementIconL)`
  ${headerIcon}
`

export const InfoIcon = styled(BaseInfoIcon)`
  margin: 0px 6px;
`
InfoIcon.defaultProps = {
  isActive: true,
}

export const ItemText = styled(Text)<ItemTextProps>`
  background-color: ${({ type, theme }) =>
    type === 'box' ? theme.colors.Light.color.blue.b1 : 'inherit'};
  color: ${cond<any, string>([
    [
      anyPass([propEq('highlight', 'type'), propEq('box', 'type')]),
      ({ theme }) => theme.colors.Light.color.blue.b6,
    ],
    [propEq('strike-through', 'type'), ({ theme }) => theme.colors.textColor.black.b45],
    [T, always('inherit')],
  ])};

  border-radius: 4px;
  margin-right: 6px;
  padding: 0px 4px;
  text-decoration: ${({ type }) => (type === 'strike-through' ? 'line-through' : 'none')};
`

export const LabelText = styled(Text)`
  margin-top: 24px;
  margin-bottom: 48px;
`

export const PaymentIcon = styled(BasePaymentIcon)`
  ${boxIcon}
`

export const ProItemLabel = styled(AccordionLabel)`
  height: auto;
  padding: 0;
  :hover {
    background-color: transparent;
  }
`

export const TopologyIcon = styled(BaseTopologyIcon)`
  ${boxIcon}
`

export const WrenchesIcon = styled(ManageIconL)`
  ${headerIcon}
`
