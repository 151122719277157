import styled from 'styled-components'
import { Loader } from '@ubnt/ui-components'
import { CloseWithCircleIconL } from '@ubnt/icons'

import type { FC } from 'react'

interface Props {
  failed?: boolean
}

export const FullscreenLoader: FC<Props> = ({ failed }) => {
  return (
    <Container>
      {failed ? <CloseWithCircleIconL size='logo' variant='fill' color='red' /> : <Loader />}
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
`
