import { Text, TextProps } from '@ubnt/ui-components/Text'

import { FlexRow } from 'components'
import { ProductSection } from './styles'
import { toLocaleCurrency } from 'helpers'

import type { FC } from 'react'
import type { PriceBreakdownProps } from './types'

export const PriceBreakdown: FC<PriceBreakdownProps> = ({ price, size = 'medium' }) => {
  const textSize: TextProps['size'] = size === 'medium' ? 'body' : 'caption'

  return (
    <ProductSection border>
      <FlexRow verticalSpacing={12}>
        <Text size={textSize}>Subtotal</Text>
        <Text size={textSize} weight='bold'>
          {toLocaleCurrency(price.base)}
        </Text>
      </FlexRow>
      <FlexRow verticalSpacing={12}>
        <Text size={textSize}>Tax</Text>
        <Text size={textSize} weight='bold'>
          {toLocaleCurrency(price.tax)}
        </Text>
      </FlexRow>
    </ProductSection>
  )
}
