import { useEffect, useState } from 'react'
import { MEDIA } from 'styles/constants'

export enum Screen {
  MOBILE,
  TABLET,
  PC,
  TV,
}

const getScreenSize = () =>
  window.matchMedia(MEDIA.MOBILE).matches
    ? Screen.MOBILE
    : window.matchMedia(MEDIA.TABLET).matches
    ? Screen.TABLET
    : window.matchMedia(MEDIA.PC).matches
    ? Screen.PC
    : Screen.TV

export const useScreenWidth = () => {
  const [screen, setScreen] = useState(getScreenSize())
  const updateState = () => setScreen(getScreenSize())

  useEffect(() => {
    window.addEventListener('resize', updateState, false)
    return () => window.removeEventListener('resize', updateState)
  }, [])

  return screen
}
