import styled from 'styled-components'

interface Props {
  width?: 'auto' | number
  height?: number
}

export const HorizontalDivider = styled.div<Props>`
  background: ${({ theme }) => theme.colors.Light.color.neutral.n3};
  height: ${({ height }) => (height ? `${height}px` : '1px')};
  width: ${({ width }) => (width ? `${width}px` : 'auto')};
`
