import { indexById } from 'helpers'
import { always, equals, evolve, flatten, map, pipe, prop, values, when } from 'ramda'
import { IncomingQuoteStatus, QuoteStatus } from 'shared'
import type { IncomingQuote, IncomingQuoteListItem, Product, QuoteListItem } from './types'

export const makeHardwareList: (x: IncomingQuote) => Product[] = pipe(
  prop('productVariantQuotes'),
  values,
  flatten,
)

export const parseQuoteStatus: (x: IncomingQuoteStatus) => QuoteStatus = when<any, any>(
  equals<IncomingQuoteStatus>(IncomingQuoteStatus.SENT),
  always(QuoteStatus.SENT),
)

export const parseAndIndexQuotes: (x: IncomingQuoteListItem[]) => Record<string, QuoteListItem> =
  pipe(map(evolve({ status: parseQuoteStatus })), indexById)
