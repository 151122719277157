import { derived } from 'overmind'
import { AsyncFuncState } from 'shared'
import type { ProjectsState } from './types'

export const state: ProjectsState = {
  current: null,
  error: null,
  loadingSection: null,
  professionalUser: null,
  names: {
    current: null,
    hash: {},
    list: derived(({ hash }: ProjectsState['names']) => Object.values(hash)),
  },
  status: AsyncFuncState.SUCCESS,
  submitModal: null,
  tokenData: null,
}
