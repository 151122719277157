import { Icon, IconProps } from '@ubnt/ui-components'
import type { FC, Ref, SVGProps } from 'react'

//type IconProps = ComponentProps<IconProps>

type SvgProps = {
  forwardedRef?: Ref<SVGSVGElement>
  fill?: string
  isActive?: boolean
  strokeWidth?: string
  stroke?: string
  actionable?: boolean
} & Pick<SVGProps<SVGElement>, 'onClick' | 'onMouseEnter'>

export type Props = IconProps & SvgProps

export const withIcon =
  (SVG: FC<SvgProps>): FC<Props> =>
  ({ stroke, strokeWidth, onClick, onMouseEnter, isActive, ...props }) =>
    (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Icon {...props} isActive={isActive}>
        <SVG
          isActive={isActive}
          stroke={stroke}
          strokeWidth={strokeWidth}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
        />
      </Icon>
    )
