import { add, concat, mergeWith, pipe } from 'ramda'

import { proHardwareToCheckout, proServicesToCheckout, round, uiHardwareToCheckout } from 'helpers'
import { Summary } from './Summary'
// import { SummaryWrapper } from './styles'

import type { FC } from 'react'
import type { ItemListProps } from './types'
import type { SectionPrice } from 'store/quotes/types'

export const ItemList: FC<ItemListProps> = ({ quote, type }) => {
  const items =
    type === 'professional'
      ? concat(proHardwareToCheckout(quote.proHardware), proServicesToCheckout(quote.proServices))
      : uiHardwareToCheckout(quote.hardwareList)

  const price: SectionPrice =
    type === 'professional'
      ? mergeWith(
          pipe<[number, number], number, number>(add, round),
          quote.priceBreakdown.proHardware,
          quote.priceBreakdown.proServices,
        )
      : quote.priceBreakdown.productVariants

  return (
    <>
      <Summary
        items={items}
        price={{
          ...price,
          shipping: 0,
          total: price.total,
        }}
        title={false}
      />
      {/* {payment.shipments?.hasNext && isNotNil(payment.shipments?.first) && (
        <SummaryWrapper>
          <Summary
            items={uiHardwareToCheckout(payment.shipments.next.hardware)}
            price={payment.shipments.next.total}
            size='small'
            title={multiple && texts.checkout.itemList.next}
            type='ui'
          />
        </SummaryWrapper>
      )} */}
    </>
  )
}
