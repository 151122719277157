import styled from 'styled-components'
import { Accordion } from '@ubnt/ui-components/Accordion'
import { Text } from '@ubnt/ui-components/Text'

import { BubbleContainer } from 'components'
import { MEDIA } from 'styles/constants'

export const CheckboxContainer = styled.div`
  > .ps-checkbox {
    margin: 16px 0px;
  }
`

export const Container = styled.section`
  display: grid;
  grid-template-columns: 7fr 5fr;
  grid-gap: 16px;
  align-items: start;

  @media ${MEDIA.TABLET} {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
`

export const DetailsSection = styled(Accordion)`
  > li {
    border-bottom: 1px solid ${({ theme }) => theme.colors.Dark.color.neutral.n5};
    padding-bottom: 24px;
  }

  > li:last-child {
    border-bottom: none;
  }

  > li > div:first-child {
    background-color: inherit;
    height: 40px;
    padding: 2px 0px;
  }

  > li > div:nth-child(2) {
    margin-bottom: 0px;
  }

  > li > div:nth-child(2) > div {
    padding: 0px;
  }
`

export const OrderTitle = styled(Text)`
  margin-bottom: 6px;
`

export const Section = styled(BubbleContainer)`
  border: none;
`
