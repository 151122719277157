import { CardHeader, CardTitle, CardWrapper } from './styles'

import type { FC } from 'react'
import type { CardProps } from './types'

export const Card: FC<CardProps> = ({ children, className, left, onClick, right, title }) => (
  <CardWrapper
    className={className}
    onClick={onClick}
    role={onClick ? 'button' : undefined}
    tabIndex={onClick ? 0 : undefined}
  >
    {(left || right) && (
      <CardHeader>
        {left ?? <div />}
        {right}
      </CardHeader>
    )}
    {title && <CardTitle size='body'>{title}</CardTitle>}
    {children}
  </CardWrapper>
)
