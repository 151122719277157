import { useActions, useOverState } from 'store'
import { RefreshBubble } from 'components'
import { Checkout } from './Checkout'
import { InfoPanel } from './InfoPanel'
import { Steps } from './Steps'
import { Summary } from './Summary'
import texts from '../texts.json'

import type { FC } from 'react'
import type { OptionWrapperProps } from './types'

export const OptionWrapper: FC<OptionWrapperProps> = ({ isStale, quote }) => {
  const { modal, quotes } = useActions()
  const {
    quotes: { checkout, status },
  } = useOverState()

  const handleFeedback = () => {
    modal.setModal({
      modal: 'feedback',
      props: { order: quote, quoteId: quote.id },
    })
  }

  return (
    <>
      <RefreshBubble
        buttonLabel='refresh.'
        refresh={() => quotes.getQuote({ quoteId: quote.id })}
        show={isStale}
        status={status}
        text={texts.refresh}
      />
      <Steps quote={quote} />
      <InfoPanel onFeedback={handleFeedback} quote={quote} />
      {!checkout && <Summary quote={quote} />}
      {checkout && <Checkout quote={quote} type={checkout} />}
    </>
  )
}
