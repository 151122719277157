import { ErrorBoundary } from '@sentry/react'
import { ArrowLeftPrimaryIcon, ShareIcon } from '@ubnt/icons'
import {
  AccountAction,
  AccountMenuPopover,
  AppHeader,
  Button,
  Navigation as UINavigation,
  NavigationGroup as UINavigationGroup,
  NavigationItem,
  NavigationItemDivider,
  Text,
} from '@ubnt/ui-components'
import { Fragment, MouseEventHandler, useCallback, useState, VFC } from 'react'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import { isNotNil } from 'ramda'
import styled from 'styled-components'

import { BackLink, ErrorComponent, FlexRow } from 'components'
import { buildName } from 'helpers'
import { Screen, useBackNavigate, useNavigationStack, useOutsideClick, useScreenWidth } from 'hooks'
import { Pages } from 'shared'
import { useActions, useOverState } from 'store'
import { MEDIA } from 'styles/constants'
import routes from './routes'

import type { User } from 'types'

const NO_BACK_PAGES = [Pages.FAQ, Pages.SUPPORT]

const handleUserbackClick: MouseEventHandler = (event) => {
  event.preventDefault()
  window.Userback.open()
}

const BackButton: VFC = () => {
  const { app } = useOverState()
  const navigate = useNavigate()
  const { head, tail } = useNavigationStack()

  const display = isNotNil(head) && !NO_BACK_PAGES.includes(app.page) ? undefined : 'none'

  return (
    <Button
      Icon={ArrowLeftPrimaryIcon}
      className='button'
      onClick={() => navigate(head, { state: { from: tail } })}
      style={{ display }}
    >
      Back
    </Button>
  )
}

export const Site = () => {
  const {
    app: { user, page },
  } = useOverState()
  const {
    current: { quote },
  } = useOverState().quotes
  const {
    modal: { setModal },
    app: { logout },
  } = useActions()

  const [isOpen, setIsOpen] = useState(false)
  const ref = useOutsideClick<HTMLDivElement>(() => setIsOpen(false))

  const navigate = useBackNavigate()
  const screenWidth = useScreenWidth()

  const closeAccountPopover: MouseEventHandler = (e) => {
    e.stopPropagation()
    setIsOpen(false)
  }

  const handleInviteColleague = () => {
    setModal({ modal: 'invite_contacts' })
  }

  const makeAccountMenuUser = (user: User) => ({
    ...user,
    avatar: user.avatar || undefined,
    name: buildName(user).join(' '),
  })

  const renderRoutesInNavBar = useCallback(
    () =>
      routes
        .filter((x) => x.icon !== null)
        .map((route, key) => {
          return (
            <Fragment key={key}>
              {route.path.startsWith('/help') && <NavigationItemDivider />}
              <NavigationItem
                icon={route.icon as JSX.Element}
                onClick={() => navigate(route.link || route.path)}
                active={window.location.pathname.startsWith(route.link || route.path)}
                label={route.label}
              />
            </Fragment>
          )
        }),
    [navigate, routes],
  )

  const menuActions: AccountAction[] = [
    {
      className: 'account-link',
      href: 'https://account.ui.com',
      onClick: closeAccountPopover,
      text: 'Manage Your UI Account',
      variant: 'primary',
    },
    {
      onClick: () => logout({ redirect: false }),
      text: 'Sign Out',
      variant: 'tertiary',
    },
  ]

  return (
    <Wrapper>
      <StyledAppHeader size='classic'>
        <StyledButton variant='primary' onClick={handleUserbackClick}>
          GIVE FEEDBACK
        </StyledButton>
        {user && (
          <div onClick={() => setIsOpen((prev) => !prev)} ref={ref}>
            <AccountMenuPopover
              actions={menuActions}
              open={isOpen}
              popoverProps={{}}
              user={makeAccountMenuUser(user)}
            >
              <Button<typeof BackLink>
                className='no-decoration'
                component={BackLink}
                onClick={closeAccountPopover}
                to='/account/project-permission'
              >
                Manage Collaborators
              </Button>
            </AccountMenuPopover>
          </div>
        )}
      </StyledAppHeader>

      <Flex>
        <Navigation collapsible>
          <NavigationGroup>{renderRoutesInNavBar()}</NavigationGroup>
        </Navigation>

        <Content>
          <ClientNavigtionBar>
            <BackButton />

            <Text className='page-title' weight='bold'>
              {page === Pages.QUOTE_SUMMARY ? quote?.quoteName : page}
            </Text>

            <FlexRow justify='flex-end'>
              {page === Pages.QUOTE_SUMMARY && (
                <Button
                  onClick={handleInviteColleague}
                  style={screenWidth > Screen.MOBILE ? { marginRight: 20 } : undefined}
                  variant={screenWidth === Screen.MOBILE ? 'tertiary' : 'secondary'}
                >
                  {screenWidth === Screen.MOBILE ? <ShareIcon /> : '+ Share with Colleague'}
                </Button>
              )}

              {page === Pages.PROJECT_PERMISSION && (
                <Button onClick={handleInviteColleague} variant='secondary'>
                  Invite
                </Button>
              )}
            </FlexRow>
          </ClientNavigtionBar>

          <ErrorBoundary
            beforeCapture={(scope) => scope.setTag('context', 'site routes')}
            fallback={(props) => <ErrorComponent inRouter {...props} />}
          >
            <Routes>
              {routes.map(({ component: Component, exact, path }) => (
                <Route key={path} path={`${path}${exact ? '' : '/*'}`} element={<Component />} />
              ))}
              <Route path='*' element={<Navigate replace to='/quotes' />} />
            </Routes>
          </ErrorBoundary>
        </Content>
      </Flex>
    </Wrapper>
  )
}

export default Site

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: var(--app-header-height) auto;
  height: 100%;
`

const StyledAppHeader = styled(AppHeader)`
  width: 100%;
  padding-right: 10px;
`

const Flex = styled.main`
  display: flex;
  height: 100%;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`

const Navigation = styled(UINavigation)`
  height: 100%;
  width: var(--app-header-height);
  z-index: 21;
`

const NavigationGroup = styled(UINavigationGroup)`
  height: 100%;
  @media (max-width: 600px) {
    height: var(--top-bar-height);
    justify-content: space-around !important;
    width: 100%;
  }
`

const Content = styled.div`
  display: grid;
  grid-template-rows: var(--top-bar-height) 1fr;
  width: 100%;
  height: 100%;

  .page-title {
    font-size: 16px;
    justify-self: center;
  }

  @media ${MEDIA.MOBILE} {
    .page-title {
      font-size: 14px;
    }
  }
`

const ClientNavigtionBar = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  height: 100%;
  padding: 0 28px 0 10px;
  border-bottom: ${({ theme }) => `1px solid ${theme.borderColor}`};
  background-color: white;
  z-index: 20;
  > div:last-child {
    justify-content: flex-end;
  }
  @media ${MEDIA.TABLET} {
    grid-template-columns: 1fr 2fr 1fr;
  }
  > div:first-child {
    justify-content: flex-start;
  }
  .button {
    border: 1px solid #ebebeb;
    color: rgba(0, 0, 0, 0.85);
    padding: '0 16px 0 0';
    width: 75px;
    text-align: left;
    > span > svg {
      margin-right: 3px;
    }
  }
`

const StyledButton = styled(Button)`
  border-radius: 11px;
  height: 22px;
  width: 100px;
  font-size: x-small;
  margin-right: 20px;
`
