export const ERROR_TOKEN = '|@@---ERROR_TOKEN--@@|'

export const SOCKET_EVENTS = {
  order: {
    create: (quoteId: string) => `order_create:${quoteId}`,
  },
  payment: {
    update: (quoteId: string) => `payment_update:${quoteId}`,
  },
}
