import { CheckIcon } from '@ubnt/icons'

import { Circle, Text } from './styles'

import type { FC } from 'react'
import type { StepCircleProps } from './types'

export const StepCircle: FC<StepCircleProps> = ({ status = 'default', step }) => (
  <Circle status={status}>
    {status === 'completed' ? <CheckIcon size='medium' /> : <Text size='caption'>{step}</Text>}
  </Circle>
)
