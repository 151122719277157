import { Modal } from '@ubnt/ui-components/Modal'
import React, { FC, KeyboardEventHandler, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { ControlledInput } from 'components/ControlledInput'
import { FlexRow, GoogleAddressAutoComplete } from 'components'
import { emptyLocation, locationSchema } from 'shared'
import { useActions, useOverState } from 'store'

import type { Location } from 'types'

type FormFields = Location

const AddressForm: FC = () => {
  const {
    modal: { closeModal },
  } = useActions()
  const { modal, modalProps } = useOverState().modal

  const [googleInput, setGoogleInput] = useState('')

  const {
    control,
    formState: { errors },
    reset,
    handleSubmit,
    setValue,
    trigger,
  } = useForm<FormFields>({
    resolver: yupResolver(locationSchema),
    mode: 'onChange',
    defaultValues: emptyLocation,
  })

  useEffect(() => {
    if (modalProps.location) {
      reset({
        ...modalProps.location,
      })
      trigger()
      setGoogleInput(modalProps.location.formatted.split(',')[0])
    }
  }, [modalProps.location])

  const handleCancel = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault()
    closeModal()
  }

  const buildFormattedAddress = (l: Location) =>
    `${l.streetNumber} ${l.street}, ${l.other ? `${l.other}, ` : ''}${l.city}, ${l.zipCode}, ${
      l.state
    }, ${l.country}`

  const onSubmit = (data: FormFields) => {
    modalProps.updateLocation({
      ...data,
      formatted: buildFormattedAddress(data),
    })
    closeModal()
  }

  const handleEnterKey: KeyboardEventHandler = (event) => {
    if (event.key === 'Enter') handleSubmit(onSubmit)
  }

  const handleLocationChange = (location: Location) => {
    setValue('street', location.street ?? '', { shouldDirty: true })
    setValue('streetNumber', location.streetNumber ?? '', { shouldValidate: true })
    setValue('state', location.state ?? '', { shouldValidate: true })
    setValue('city', location.city ?? '', { shouldValidate: true })
    setValue('country', location.country, { shouldValidate: true })
    setValue('formatted', location.formatted, { shouldValidate: true })
    setValue('zipCode', location.zipCode ?? '', { shouldValidate: true })
    setValue('other', location.other, { shouldValidate: true })
    setGoogleInput(location.formatted.split(',')[0])
  }

  return (
    <Modal
      title='Address'
      isOpen={modal === 'address'}
      onRequestClose={closeModal}
      size='small'
      shouldCloseOnOverlayClick={false}
      actions={[
        {
          text: 'Cancel',
          onClick: handleCancel,
          variant: 'tertiary',
        },
        {
          text: 'Done',
          variant: 'primary',
          onClick: handleSubmit(onSubmit),
        },
      ]}
    >
      <Form>
        <GoogleAddressAutoComplete
          name='location'
          label='Address'
          handleChange={handleLocationChange}
          value={googleInput}
          setValue={setGoogleInput}
          onKeyDown={handleEnterKey}
          invalid={errors.streetNumber?.message ?? errors.street?.message}
          width={null}
          autoFocus
        />
        <ControlledInput
          control={control}
          name='other'
          label='PO Box, Apartment, Unit, Suite, or Floor'
          autoComplete='off'
          invalid={errors.other?.message}
          onKeyDown={handleEnterKey}
          width='100%'
        />
        <Row>
          <ControlledInput
            control={control}
            name='zipCode'
            label='Postal Code'
            autoComplete='off'
            invalid={errors.zipCode?.message}
            onKeyDown={handleEnterKey}
          />
          <ControlledInput
            control={control}
            name='city'
            label='City'
            autoComplete='off'
            invalid={errors.city?.message}
            onKeyDown={handleEnterKey}
          />
        </Row>
        <Row>
          <ControlledInput
            control={control}
            name='state'
            label='State'
            autoComplete='off'
            invalid={errors.state?.message}
            onKeyDown={handleEnterKey}
          />
          <ControlledInput
            control={control}
            name='country'
            label='Country'
            autoComplete='off'
            invalid={errors.country?.message}
            onKeyDown={handleEnterKey}
          />
        </Row>
      </Form>
    </Modal>
  )
}

export default AddressForm

export const Form = styled.form`
  font-family: Lato;
  font-style: normal;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  > div,
  section:not(:last-child) {
    margin-bottom: 20px;
  }
`

export const Row = styled(FlexRow)`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-grow: 1;
  width: 100%;
  gap: 8px;

  > div:not(:last-child) {
    margin-bottom: 15px;
  }
`
