export enum AsyncFuncState {
  LOADING = 'loading',
  SUCCESS = 'success',
  ERROR = 'error',
  NULL = 'null',
}

export enum IncomingQuoteStatus {
  SENT = 'Sent',
  PARTIALLY_COMPLETED = 'Partially Completed',
  FINAL_PAYMENT_PENDING = 'Final Payment Pending',
  COMPLETED = 'Completed',
}

export enum Pages {
  QUOTE_SUMMARY = 'Quote Summary',
  QUOTES = 'Projects & Quotes',
  ORDERS = 'Orders',
  SUPPORT = 'Ubiquiti Support',
  FAQ = 'FAQ',
  PROJECT_PERMISSION = 'Project Permission',
}

export enum PSGroup {
  UI_BACKORDERS = 'group-ppbackorders',
  UI_CHECKOUT = 'group-b1hvab4j9',
}

export enum QuoteStatus {
  SENT = 'Open',
  PENDING_FULFILMENT = 'Pending Fulfilment',
  PARTIALLY_COMPLETED = 'Partially Completed',
  FINAL_PAYMENT_PENDING = 'Final Payment Pending',
  COMPLETED = 'Completed',
}

export const ORDER_STATUSES = [
  QuoteStatus.PENDING_FULFILMENT,
  QuoteStatus.PARTIALLY_COMPLETED,
  QuoteStatus.FINAL_PAYMENT_PENDING,
  QuoteStatus.COMPLETED,
]

export const REGEX = {
  uuid: /[0-9a-f]{8}(?:-[0-9a-f]{4}){3}-[0-9a-f]{12}/,
}

export const talkSubsPrice = 9.99

export const emptyLocation = {
  formatted: '',
  street: '',
  streetNumber: '',
  city: '',
  state: '',
  zipCode: '',
  country: '',
  other: '',
}
