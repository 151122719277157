import { branch, filter, pipe, waitUntil } from 'overmind'
import { isNotNil } from 'ramda'

import * as op from './operators'
import { closeModal } from 'store/modal/actions'
import { setCurrentQuote } from 'store/quotes/actions'
import { getQuote, parseQuote } from 'store/quotes/operators'
import { handleAsyncAction, rethrowError } from 'store/operators'
import { AsyncFuncState } from 'shared'
import { initShipping } from 'components'
import { SOCKET_EVENTS } from 'store/constants'

import type { Context } from 'store'
import {
  CreateProOptions,
  SetShippingAddress,
  UpdateCardOptions,
  WithAddress,
  WithBillingType,
  WithComplete,
  WithSave,
  WithShippingType,
} from './types'
import type { WithQuoteId } from 'store/types'
import type { ModalFeedback } from 'store/modal/internal.type'
import type { WithPaymentType } from 'types'
import type { Quote } from 'store/quotes/types'

const setModal = (quote: Quote): ModalFeedback => ({
  modal: 'feedback',
  props: {
    message: 'Rate Your Professional',
    order: quote,
    quoteId: quote.id,
    title: 'Your Project is Completed!',
  },
})

const handleAction = handleAsyncAction('payment')

const subscribeFinished = ({ actions, effects }: Context, { quoteId }: WithQuoteId) => {
  effects.app.socket.subscribe(SOCKET_EVENTS.payment.update(quoteId), actions.payment.setFinished)
}

const unsubscribeFinished = ({ effects }: Context, { quoteId }: WithQuoteId) => {
  effects.app.socket.unsubscribe(SOCKET_EVENTS.payment.update(quoteId))
}

const handleAfterWebhook = pipe(branch(unsubscribeFinished), getQuote, parseQuote, setCurrentQuote)

export const createPro = handleAction<CreateProOptions, void>({
  action: pipe(
    branch<CreateProOptions, void>(subscribeFinished),
    branch(op.createPro, op.runRequest, rethrowError),
    waitUntil<CreateProOptions, Context>((state) => state.payment.checkout.finished),
    branch(
      ({ actions, state }: Context, { quote }) =>
        state.payment.checkout.success && actions.modal.setModal(setModal(quote)),
    ),
    handleAfterWebhook,
  ),
  after: op.unsetFinished,
  before: branch(op.unsetFinished, op.setLoadingSection('checkout')),
  errorTitle: 'Payment Error',
})

export const preparePaymentForm = handleAction<WithPaymentType & WithQuoteId, void>({
  action: op.getCard,
  after: op.setLoadingSection(null),
  before: op.setLoadingSection<WithPaymentType & WithQuoteId>('card'),
  onError: op.setLoadingSection(null),
})

export const refetch = handleAction<WithQuoteId, void>({
  action: pipe(getQuote, parseQuote, setCurrentQuote),
  after: op.setLoadingSection(null),
  before: op.setLoadingSection('checkout'),
})

export const reset = ({ state }: Context) => {
  state.payment = {
    billing: {
      address: initShipping,
      type: null,
    },
    checkout: {
      contact: false,
      finished: false,
      success: false,
    },
    complete: {
      backorders: false,
      billing: false,
      cvc: false,
      expiry: false,
      number: false,
      shipping: false,
      terms: false,
    },
    errors: {
      shipping: '',
    },
    loadingSection: null,
    save: false,
    savedCard: null,
    shipping: {
      address: initShipping,
      type: null,
    },
    status: AsyncFuncState.NULL,
    useSaved: false,
  }
}

export const setBillingAddress = ({ state }: Context, { address }: WithAddress) => {
  state.payment.billing.address = address
}

export const setBillingType = ({ state }: Context, { billing }: WithBillingType) => {
  state.payment.billing.type = billing
}

export const setCompleteBackorders = ({ state }: Context, { complete }: WithComplete) => {
  state.payment.complete.backorders = complete
}

export const setCompleteBilling = ({ state }: Context, { complete }: WithComplete) => {
  state.payment.complete.billing = complete
}

export const setCompleteCvC = ({ state }: Context, { complete }: WithComplete) => {
  state.payment.complete.cvc = complete
}

export const setCompleteExpiry = ({ state }: Context, { complete }: WithComplete) => {
  state.payment.complete.expiry = complete
}

export const setCompleteNumber = ({ state }: Context, { complete }: WithComplete) => {
  state.payment.complete.number = complete
}

export const setCompleteTerms = ({ state }: Context, { complete }: WithComplete) => {
  state.payment.complete.terms = complete
}

export const setFinished = ({ state }: Context, payload: any) => {
  state.payment.checkout = {
    contact: payload.contact,
    finished: true,
    success: payload.status === 'success',
  }
  state.quotes.checkout = null
}

export const setSave = ({ state }: Context, { save }: WithSave) => {
  state.payment.save = save
}

export const setShippingAddress = ({ state }: Context, { address }: SetShippingAddress) => {
  state.payment.shipping.address = address
}

export const setShippingType = ({ state }: Context, { type }: WithShippingType) => {
  state.payment.shipping.type = type
}

export const setUseSaved = pipe(
  filter(({ state }: Context, _value: boolean) => isNotNil(state.payment.savedCard)),
  ({ state }: Context, value) => {
    state.payment.useSaved = value
    state.payment.complete.cvc = false
    state.payment.complete.expiry = false
    state.payment.complete.number = false
  },
)

export const updateCard = handleAction<UpdateCardOptions, void>({
  action: pipe(branch<UpdateCardOptions, void>(op.updateCard), op.getCard, closeModal),
})
