import React, { useEffect, useRef, useState } from 'react'
import { TransitionGroup } from 'react-transition-group'
import { hierarchy, HierarchyPointLink, HierarchyPointNode, tree, TreeLayout } from 'd3-hierarchy'
import { interpolateString } from 'd3-interpolate'
import { scaleLinear } from 'd3-scale'
import { select, selectAll, Selection } from 'd3-selection'
import styled from 'styled-components'

import NodeLink from './Link'
import Node, { ICON_HEIGHT, ICON_WIDTH } from './Node'
import { TreeDatum } from '../models/topology/types'
import ZoomControls from './ZoomControls'
import { browser } from 'helpers/browser'
import { zoom, zoomIdentity, ZoomTransform } from 'd3-zoom'

const TOPOLOGY_SVG_CLASSNAME = 'topology-svg-container'
const TOPOLOGY_ZOOM_G_CLASSNAME = 'topology-g'

interface TreeTransform {
  x: number
  y: number
  k: number
}

interface ScaleExtent {
  min: number
  max: number
}

interface NodeSize {
  x: number
  y: number
}

interface Separation {
  siblings: number
  nonSiblings: number
}

interface ContainerSize {
  width: number
  height: number
}

interface TreeProps {
  data: TreeDatum
  transform: TreeTransform
  scaleExtent: ScaleExtent
  transitionDuration: number
  separation: Separation
  nodeSize: NodeSize
  containerSize: ContainerSize
}

export const center = (width: number, height: number, nodes: HierarchyPointNode<TreeDatum>[]) => {
  // used to deal with the multiple root situation
  //const normalizeValue = nodes[0].data.type === 'invisibleNodeType' ? nodeWidth : 0
  const normalizeValue = 0

  const treeExtent = nodes.reduce(
    (extent, node) => {
      // if (node.data.type === 'invisibleNodeType') {
      //   return extent
      // }
      extent.minX = Math.min(extent.minX, node.x)
      extent.maxX = Math.max(extent.maxX, node.x)
      extent.minY = Math.min(extent.minY, node.y)
      extent.maxY = Math.max(extent.maxY, node.y)
      return extent
    },
    {
      minX: 0,
      maxX: 0,
      minY: 0,
      maxY: 0,
    },
  )

  const minHeight = treeExtent.maxX - treeExtent.minX + ICON_HEIGHT + 45
  const minWidth = treeExtent.maxY - treeExtent.minY + ICON_WIDTH - normalizeValue
  const scaleTrim = scaleLinear().range([0.3, 2.5]).domain([0.3, 2.5]).clamp(true)

  const scaleMath = Math.min(height / minHeight, width / minWidth)
  //let scale = Math.min(height / minHeight, width / minWidth)

  //scale = scaleTrim(scale * 0.8) // use 80% of area for better user experience
  const scale = scaleTrim(scaleMath * 0.8) || 0 // use 80% of area for better user experience

  const x = (width - (treeExtent.maxY + normalizeValue) * scale) / 2
  const y = Math.max(0, height / 2 - (treeExtent.minX + (minHeight - ICON_HEIGHT) / 2) * scale)

  return { x, y, k: scale }
}

/**
 * assignInternalProperties - Assigns internal properties to each node in the
 * `data` set that are required for tree manipulation and returns
 * a new `data` array.
 */
const assignInternalProperties = (data: TreeDatum | TreeDatum[]): TreeDatum[] => {
  // Wrap the root node into an array for recursive transformations if it wasn't in one already.
  const d: TreeDatum[] = Array.isArray(data) ? data : [data]

  return d.map((node) => {
    // If there are children, recursively assign properties to them too for node toggling
    if (node?.children && node.children.length > 0) {
      node.children = assignInternalProperties(node.children)
      node.internalChildren = node.children
    }
    return node
  })
}

export const getTreeLayout = (
  nodeSize: NodeSize,
  separation: Separation,
): TreeLayout<TreeDatum> => {
  return tree<TreeDatum>()
    .nodeSize([nodeSize.y, nodeSize.x])
    .separation((a, b) =>
      a?.parent?.id === b?.parent?.id ? separation.siblings : separation.nonSiblings,
    )
}

export const getZoomFn = (
  scaleExtent: { min: number; max: number },
  onZoom: ({ transform }: { transform: ZoomTransform }) => void,
) => {
  return zoom<SVGElement, undefined>()
    .scaleExtent([scaleExtent.min, scaleExtent.max])
    .on('zoom', onZoom)
    .filter((event) => {
      return !event.butotn && event.type !== 'dblclick'
    })
}

type SVGContainerD3 = Selection<SVGElement, undefined, HTMLElement, undefined>
type TransformableNodesD3 = Selection<HTMLDivElement, undefined, HTMLElement, undefined>

/** This fixes weird Safari rendering bug where the transformed foreign objects left a trail
 * if the topology was zoomed out. By toggling a property we are (probably) forcing a repaint which
 * fixes this issue. */
const forceSvgRepaint = (svgSelection: SVGContainerD3): void => {
  const svgStrokeColor = svgSelection.attr('stroke')
  svgSelection.attr(
    'stroke',
    svgStrokeColor === 'rgba(0,0,0,0)' ? 'rgba(255,255,255,0)' : 'rgba(0,0,0,0)',
  )
  selectAll('nav').style(
    'stroke',
    svgStrokeColor === 'rgba(0,0,0,0)' ? 'rgba(255,255,255,0)' : 'rgba(0,0,0,0)',
  )
}

// /** Set transform property for passed nodes. Used when user zooms in/out with mousewheel */
// const scaleTransformedNodes = (nodes: TransformableNodesD3, scale: number) => {
//   nodes.style('transform', (_t, index, nodes) => {
//     // Using dataset for for storing transform data is 2 - 10x as fast as parsing computedStyle transform matrix
//     const { safariTransform } = nodes[index]?.dataset
//     return `${safariTransform} scale(${scale})`
//   })
// }

const setD3ScaleForTransformableNodes = (svgRef: SVGContainerD3, scale: number) => {
  svgRef.style('--d3-zoom-scale', scale.toString())
}

/**
 * bindZoomListener - Binds a listener for "zoom" events to the
 * SVG and sets scaleExtent to min/max specified in `scaleExtent`.
 * Also triggers Tooltip re-render if it is open.
 */
const bindZoomListener = (
  props: TreeProps,
  svgRef: React.MutableRefObject<SVGContainerD3 | undefined>,
  isSafari: boolean,
  transformRef: React.MutableRefObject<number>,
): void => {
  const { scaleExtent, transform } = props
  svgRef.current = select<SVGElement, undefined>(`.${TOPOLOGY_SVG_CLASSNAME}`)
  const g = select(`.${TOPOLOGY_ZOOM_G_CLASSNAME}`)

  const onCustomZoom = ({ transform }: { transform: ZoomTransform }) => {
    if (isSafari && svgRef.current) {
      forceSvgRepaint(svgRef.current)
      setD3ScaleForTransformableNodes(svgRef.current, transform.k)
      transformRef.current = transform.k
    }

    g.attr('transform', transform.toString())

    /** This is a bit of Dragons situation. In a gist - node tooltips are rendered
     * in a portal to avoid Safari/Firefox bugs. This leads to bad UX when panning/zooming because the
     * tooltip's position recalculates only on re-renders.
     * To remedy this situation we force a rerender by triggering a state change via this
     * renderTrigger ref object but don't kill the performance by mutating state and re-rendering the whole Tree. */
  }

  const customZoom = getZoomFn(scaleExtent, onCustomZoom)

  svgRef.current
    .call(customZoom)
    .call(customZoom.transform, zoomIdentity.translate(transform.x, transform.y).scale(transform.k))
}

const bound = (num: number, minNum: number, maxNum: number) => {
  return Math.min(Math.max(num, minNum), maxNum)
}

/** Uses D3 Animation to scale transformed nodes. Used when user clicks on zoom in/out/reset buttons. */
// const animateNodeScaling = (
//   svgD3Ref: React.MutableRefObject<SVGContainerD3 | undefined>,
//   transformableNodesRef: React.MutableRefObject<TransformableNodesD3 | undefined>,
//   newScale: number,
//   transformRef: React.MutableRefObject<number>,
// ) => {
//   transformableNodesRef.current &&
//     transformableNodesRef.current
//       .transition('zoom-out')
//       .styleTween('transform', (_t, index, nodes) => {
//         const { safariTransform } = nodes[index]?.dataset
//         const oldString = `${safariTransform} scale(${transformRef.current})`
//         const newString = `${safariTransform} scale(${newScale})`

//         return interpolateString(oldString, newString)
//       })
//       .call(() => svgD3Ref.current && forceSvgRepaint(svgD3Ref.current))
// }

const animateD3ZoomScaleVariable = (
  svgD3Ref: React.MutableRefObject<SVGContainerD3 | undefined>,
  newScale: number,
  transformRef: React.MutableRefObject<number>,
) => {
  const oldString = `${transformRef.current}`
  const newString = `${newScale}`

  svgD3Ref.current &&
    svgD3Ref.current
      .transition('zoom-out')
      .styleTween('--d3-zoom-scale', () => interpolateString(oldString, newString))
      .call(() => svgD3Ref.current && forceSvgRepaint(svgD3Ref.current))
}

const Tree: React.FC<TreeProps> = (props) => {
  const { data, containerSize, transform } = props
  const initialRenderRef = useRef(true)
  const isTransitioningRef = useRef(false)
  const safariTransformableNodesRef = useRef<TransformableNodesD3>()
  const transformRef = useRef<number>(transform.k)
  const svgD3Ref = useRef<SVGContainerD3>()
  const { width, height } = containerSize
  const { isSafari } = browser
  const safariGestureLastScale = useRef(null)

  const [collapsedNodes, setCollapsedNodes] = useState<Set<string>>(new Set<string>())

  const dataWithInternalProps = assignInternalProperties(data)

  // To have correct tween animations for links, we need to know
  // the nodeList "previous" and "next" positions.
  // When link enters we want the path to start at the
  // source's old position and finish the animation at the
  // actual state
  // When the link exits, we want the tween to start at the current
  // position and finish at the "future" position of the link
  // Since the entering and exit is controlled by the React transition
  // group, the only way how to communicate this with the link component
  // is through these refs which are set on each render when the nodes
  // and links are computed
  const nextNodeListRef = useRef<HierarchyPointNode<TreeDatum>[] | null>(null)
  const prevNodeListRef = useRef<HierarchyPointNode<TreeDatum>[] | null>(null)

  useEffect(() => {
    if (isSafari) {
      safariTransformableNodesRef.current = selectAll('[data-safari-transform]')
    }
  })

  useEffect(() => {
    bindZoomListener(props, svgD3Ref, isSafari, transformRef)
  }, [])

  const onZoomIn = () => {
    const { scaleExtent } = props

    const g = select(`.${TOPOLOGY_ZOOM_G_CLASSNAME}`)
    const svg = select<SVGElement, undefined>(`.${TOPOLOGY_SVG_CLASSNAME}`)

    const onCustomZoom = ({ transform }: { transform: ZoomTransform }) => {
      g.attr('transform', transform.toString())
      transformRef.current = transform.k
    }

    const customZoom = getZoomFn(scaleExtent, onCustomZoom)

    svg.transition().call(customZoom.scaleBy, 1.25)

    if (isSafari && svgD3Ref) {
      const newScale = bound(transformRef.current * 1.25, scaleExtent.min, scaleExtent.max)
      animateD3ZoomScaleVariable(svgD3Ref, newScale, transformRef)
    }
  }

  const onZoomOut = () => {
    const { scaleExtent } = props

    const g = select(`.${TOPOLOGY_ZOOM_G_CLASSNAME}`)
    const svg = select<SVGElement, undefined>(`.${TOPOLOGY_SVG_CLASSNAME}`)

    const onCustomZoom = ({ transform }: { transform: ZoomTransform }) => {
      g.attr('transform', transform.toString())
      transformRef.current = transform.k
    }

    const customZoom = getZoomFn(scaleExtent, onCustomZoom)

    svg
      .transition()
      .call(customZoom.scaleBy, 0.75)
      .on('end', () => isSafari && svgD3Ref.current && forceSvgRepaint(svgD3Ref.current))

    if (isSafari) {
      const newScale = bound(transformRef.current * 0.75, scaleExtent.min, scaleExtent.max)
      animateD3ZoomScaleVariable(svgD3Ref, newScale, transformRef)
    }
  }

  const onZoomReset = (nodes: HierarchyPointNode<TreeDatum>[]) => {
    const transform = center(width, height, nodes)
    const { scaleExtent } = props

    const g = select(`.${TOPOLOGY_ZOOM_G_CLASSNAME}`)
    const svg = select<SVGElement, undefined>(`.${TOPOLOGY_SVG_CLASSNAME}`)
    const onCustomZoom = ({ transform }: { transform: ZoomTransform }) => {
      g.attr('transform', transform.toString())
      transformRef.current = transform.k
    }

    const customZoom = getZoomFn(scaleExtent, onCustomZoom)

    svg
      .transition()
      .call(
        customZoom.transform,
        zoomIdentity.translate(transform.x, transform.y).scale(transform.k),
      )
      .on('end', () => isSafari && svgD3Ref.current && forceSvgRepaint(svgD3Ref.current))

    if (isSafari && svgD3Ref) {
      animateD3ZoomScaleVariable(svgD3Ref, transform.k, transformRef)
    }
  }

  /**
   * handleNodeToggle - Finds the node matching `nodeId` and
   * expands/collapses it, depending on the current state o
   * `setState` callback receives targetNode and handles
   * `props.onClick` if defined.
   */
  const handleNodeToggle = (nodeId: string, evt: React.MouseEvent): void => {
    const { transitionDuration } = props

    // Persist the SyntheticEvent for downstream handling by users.
    evt.persist()

    if (!isTransitioningRef.current) {
      const isCollapsed = collapsedNodes.has(nodeId)

      if (isCollapsed) {
        const newCollapsedNodes = new Set(collapsedNodes)
        newCollapsedNodes.delete(nodeId)
        // Lock node toggling while transition takes place
        isTransitioningRef.current = true
        setCollapsedNodes(newCollapsedNodes)
      } else {
        const newCollapsedNodes = new Set(collapsedNodes)
        newCollapsedNodes.add(nodeId)
        // Lock node toggling while transition takes place
        isTransitioningRef.current = true
        setCollapsedNodes(newCollapsedNodes)
      }

      // Await transitionDuration + 10 ms before unlocking node toggling again
      setTimeout(() => {
        isTransitioningRef.current = false
        if (isSafari && svgD3Ref.current) {
          forceSvgRepaint(svgD3Ref.current)
        }
      }, transitionDuration + 10)
    }
  }

  /**
   * generateTree - Generates tree elements (`nodes` and `links`) by
   * grabbing the rootNode from `state.data[0]`.
   */
  const generateTree = (): {
    nodes: HierarchyPointNode<TreeDatum>[]
    links: HierarchyPointLink<TreeDatum>[]
  } => {
    const { separation, nodeSize } = props

    const treeLayout = tree<TreeDatum>()
      .nodeSize([nodeSize.y, nodeSize.x])
      .separation((a, b) =>
        a?.parent?.id === b?.parent?.id ? separation.siblings : separation.nonSiblings,
      )

    const root = hierarchy<TreeDatum>(dataWithInternalProps[0], (d) =>
      d.id && collapsedNodes.has(d.id) ? null : d.internalChildren,
    )

    const treeRoot = treeLayout(root)
    const nodes = treeRoot.descendants()

    if (!initialRenderRef.current && nextNodeListRef.current) {
      prevNodeListRef.current = [...nextNodeListRef.current]
    }

    nextNodeListRef.current = [...nodes]

    // get rid of the invisible links
    //const links = treeRoot.links().filter((link) => link.source.data.mac !== 'invisibleNodeMac')
    const links = treeRoot.links()
    return { nodes, links }
  }

  const { nodes, links } = generateTree()
  const { transitionDuration } = props

  // useEffect(() => {
  //   if (isSafari && safariTransformableNodesRef.current && svgD3Ref.current) {
  //     scaleTransformedNodes(safariTransformableNodesRef.current, transformRef.current)
  //     forceSvgRepaint(svgD3Ref.current)
  //   }
  // })

  useEffect(() => {
    if (isSafari && svgD3Ref.current) {
      forceSvgRepaint(svgD3Ref.current)
    }
  }) // need to repaint on each render in safari

  useEffect(() => {
    if (browser.isSafari) {
      const { scaleExtent } = props
      const g = select(`.${TOPOLOGY_ZOOM_G_CLASSNAME}`)
      const svg = select<SVGElement, undefined>(`.${TOPOLOGY_SVG_CLASSNAME}`)

      const onCustomZoom = ({ transform }: { transform: ZoomTransform }) => {
        g.attr('transform', transform.toString())

        if (svgD3Ref.current) {
          setD3ScaleForTransformableNodes(svgD3Ref.current, transform.k)
          forceSvgRepaint(svgD3Ref.current)
        }

        transformRef.current = transform.k
        // if (triggerTooltipRerender.current) {
        //   triggerTooltipRerender.current()
        // }
      }

      const customZoom = getZoomFn(scaleExtent, onCustomZoom)

      const handleGestureChange = (e: any) => {
        e.preventDefault()

        /** Calculate how much the scale has changed from the previous call */
        const scaleDiff = e.scale - (safariGestureLastScale.current || 0)

        /** Comparing it to 0.085 comes from the fact that calling the scale function
         * on every event wasn't very performant due to how frequently the events were
         * called. Also calling repaint and changing d3 scale css variable somehow messed with
         * how smooth everything was. */
        if (safariGestureLastScale.current && Math.abs(scaleDiff) > 0.085) {
          const newScaleMultiplier = 1 + scaleDiff

          const newScale = bound(
            transformRef.current * newScaleMultiplier,
            scaleExtent.min,
            scaleExtent.max,
          )

          svg.call(customZoom.scaleTo, newScale)
          safariGestureLastScale.current = e.scale
        }
      }

      const handleGestureStart = (e: any) => {
        e.preventDefault()
        safariGestureLastScale.current = e.scale
      }

      const handleGestureEnd = (e: Event) => {
        e.preventDefault()
        safariGestureLastScale.current = null
      }

      document.addEventListener('gesturestart', handleGestureStart)
      document.addEventListener('gesturechange', handleGestureChange)
      document.addEventListener('gestureend', handleGestureEnd)

      return () => {
        document.removeEventListener('gesturestart', handleGestureStart)
        document.removeEventListener('gesturechange', handleGestureChange)
        document.removeEventListener('gestureend', handleGestureEnd)
      }
    }
    return () => true
  }, [])

  return (
    <>
      <Rd3TreeContainer>
        {/* Explicitly setting width and height helps with rendering artefacts in Safari
         * Stroke width set to 0 to not break fix for forcing Safari repaints. Setting Position */}
        <svg
          className={TOPOLOGY_SVG_CLASSNAME}
          width={`${width}px`}
          height={`${height}px`}
          strokeWidth={0}
          style={{ position: 'absolute' }}
        >
          {/* Fixes Safari zoom/pan issue when user zooms out really far and the topology is really
              small. Better explanation about this: https://github.com/d3/d3/issues/3035#issuecomment-266066432 */}
          <rect x={0} y={0} width='100%' height='100%' fill='transparent' />
          <g className={TOPOLOGY_ZOOM_G_CLASSNAME} strokeWidth={0}>
            <TransitionGroup appear exit component={null}>
              {links.map((linkData) => {
                return (
                  <NodeLink
                    key={`${linkData.source.data.id}-${linkData.target.data.id}`}
                    nextNodeListRef={nextNodeListRef}
                    prevNodeListRef={prevNodeListRef}
                    linkData={linkData}
                    transitionDuration={transitionDuration}
                  />
                )
              })}
            </TransitionGroup>
            <TransitionGroup appear exit component={null}>
              {nodes.map((node) => {
                return (
                  <Node
                    key={node.data.id}
                    transitionDuration={transitionDuration}
                    nodeData={node}
                    onClick={handleNodeToggle}
                  />
                )
              })}
            </TransitionGroup>
          </g>
        </svg>
        <BottomContainer>
          <ZoomControls
            onZoomInClick={() => onZoomIn()}
            onZoomOutClick={() => onZoomOut()}
            onResetZoomClick={() => onZoomReset(nodes)}
          />
        </BottomContainer>
      </Rd3TreeContainer>
    </>
  )
}

export default Tree

const Rd3TreeContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  overflow: hidden;

  cursor: move; // fallback if the grab cursor is not supported
  cursor: grab;

  &:active {
    cursor: grabbing;
  }

  /* d3-zoom-scale is used in safari to correctly scale transformed nodes
    inside foreignObject */
  .${TOPOLOGY_SVG_CLASSNAME} {
    --d3-zoom-scale: 1;
  }
`
const BottomContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin: 26px;
`
