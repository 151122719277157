import styled from 'styled-components'

import { Video as CVideo } from 'components'
import { MEDIA } from 'styles/constants'

export const Container = styled.div`
  padding-top: var(--app-header-height);
  display: flex;
  height: 100vh;

  @media ${MEDIA.TABLET} {
    padding-top: 60px;
  }
`

export const ErrorLeft = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-grow: 1;

  height: 170px;
  margin: 0px 85px;
`

export const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;

  height: 100%;

  .error-image {
    z-index: 600;

    height: 100%;
    max-width: 60%;
    width: auto;
  }

  @media ${MEDIA.MOBILE} {
    .error-image {
      display: none;
    }
  }
`

export const FormWrapper = styled.section`
  display: flex;
  flex-direction: column;

  @media ${MEDIA.TABLET} {
    width: 100vw;
  }
`

interface IconBoxProps {
  isActive: boolean
}

export const IconBox = styled.div<IconBoxProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 4px;
  border: 1px solid
    ${({ isActive, theme }) =>
      isActive ? theme.colors.Light.color.blue.b6 : theme.colors.Light.color.neutral.n6};
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.colors.Light.color.blue.b6 : 'inherit'};
  color: ${({ isActive, theme }) => (isActive ? 'white' : theme.colors.Light.color.neutral.n6)};

  margin-right: 8px;
  height: 32px;
  width: 32px;
`

export const IconImg = styled.img`
  max-height: 64px;
  max-width: 64px;
  height: auto;
  width: auto;
`

export const ProgressWrapper = styled.div`
  display: flex;
  margin: 16px 0px;
`

export const SideImg = styled.img`
  max-height: 268px;
  max-width: 268px;
  height: auto;
  width: auto;
`

export const Video = styled(CVideo)`
  width: 430px;
`
