import { VFC, useEffect } from 'react'
import { ToastProvider } from '@ubnt/ui-components/Toast'
import { ThemeProvider } from 'styled-components'

import RootModal from 'app/root-modal-holder'
import { FullscreenLoader, ResetNavigate } from 'components'
import { PreSite } from 'pages'
import { AsyncFuncState } from 'shared'
import { useOverState } from 'store'
import { theme } from 'styles'

import * as Sentry from '@sentry/react'

const App: VFC = () => {
  const { loadingSection, status, user } = useOverState().app

  useEffect(() => {
    const opts = user ? { id: user.ssoId } : { ip_address: '{{auto}}' }
    Sentry.setUser(opts)
  }, [user])

  if (status === AsyncFuncState.LOADING && loadingSection === 'full') return <FullscreenLoader />

  return (
    <ThemeProvider theme={theme}>
      <ToastProvider>
        <RootModal />
        <PreSite />
        <ResetNavigate />
      </ToastProvider>
    </ThemeProvider>
  )
}

export default App
