import styled, { css } from 'styled-components'
import { Checkbox as UICheckbox } from '@ubnt/ui-components/Checkbox'
import { Link } from 'react-router-dom'
import { MEDIA } from 'styles/constants'

const link = css`
  color: ${({ theme }) => theme.colors.Light.color.blue.b6};
  font-weight: bold;
  text-decoration: none;

  &:visited {
    color: ${({ theme }) => theme.colors.Light.color.blue.b6};
    text-decoration: none;
  }
`

export const Anchor = styled.a`
  ${link}
`

export const CheckboxSimple = styled(UICheckbox)`
  display: flex;
  align-items: flex-start;

  max-width: 350px;
  margin: 6px 0px;
`

export const ImgWrapper = styled.aside`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  border-right-width: 1px;
  border-right-style: solid;
  border-image: linear-gradient(
      to bottom,
      ${({ theme }) => theme.colors.Light.color.neutral.n0},
      ${({ theme }) => theme.colors.Light.color.neutral.n4},
      ${({ theme }) => theme.colors.Light.color.neutral.n0}
    )
    1 100%;

  width: 430px;

  @media ${MEDIA.TABLET} {
    display: none;
  }
`

export const ReactLink = styled(Link)`
  ${link}
`
