import { Nav, NavItems } from 'components'

import type { FC } from 'react'

interface Props {
  pages: {
    id: string
    label: string
  }[]
  handleSelect: (pageId: string) => void
  selectedPage: string | null
}

export const NavigationPanel: FC<Props> = ({ pages, handleSelect, selectedPage }) => {
  return (
    <Nav>
      <NavItems navigationItems={pages} onSelect={handleSelect} selectedItem={selectedPage} />
    </Nav>
  )
}
