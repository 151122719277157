import {
  all,
  cond,
  dropLast,
  equals,
  evolve,
  identity,
  omit,
  pick,
  pipe,
  prop,
  propEq,
  T,
  trim,
  values,
  whereEq,
} from 'ramda'
import { CardCvcElement, CardNumberElement } from '@stripe/react-stripe-js'

import { BillingAddress } from './Address'
import { CardDetails } from './PaymentForm'

import type { PaymentType, Shipping } from 'types'
import type { ConfirmCardPaymentData, PaymentMethodCreateParams } from '@stripe/stripe-js'
import type { AccordionItem } from '@ubnt/ui-components'
import type { ChooseBillingAddressOpts, IsCompleteOptions, MakeStripeRequestOptions } from './types'

const chooseBillingAddress = ({ billing, shipping, type }: ChooseBillingAddressOpts) =>
  type === 'shipping' ? shipping : billing

const parseBillingAddress = (address: Shipping): PaymentMethodCreateParams.BillingDetails => ({
  address: {
    city: address.location.city,
    line1: trim(`${address.location.streetNumber ?? ''} ${address.location.street ?? ''}`),
    line2: address.location.other,
    postal_code: address.location.zipCode,
    state: address.location.state,
  },
  name: `${address.firstName} ${address.lastName}`,
  phone: address.phone || undefined,
})

// Exports
export const isComplete: (x: IsCompleteOptions) => boolean = pipe<
  [IsCompleteOptions],
  any,
  any,
  boolean[],
  boolean
>(
  cond<[IsCompleteOptions], any>([
    [whereEq({ savedCard: true, type: 'professional' }), evolve({ obj: pick(['cvc']) })],
    [propEq('professional', 'type'), evolve({ obj: omit(['backorders', 'shipping', 'terms']) })],
    [T, identity],
  ]),
  prop('obj'),
  values,
  all(equals<boolean>(true)),
)

export const makeDetailItems = (type: PaymentType, useSaved: boolean): AccordionItem[] => {
  const items = [
    {
      id: 'payment',
      label: 'Payment Information',
      openByDefault: true,
      renderContent: () => <CardDetails type={type} />,
    },
    {
      id: 'billing',
      label: 'Billing Address',
      openByDefault: true,
      renderContent: () => <BillingAddress />,
    },
  ]

  if (type === 'professional' && useSaved) return [items[1]]
  if (useSaved) return dropLast(1, items)
  return items
}

export const makeStripeRequest = ({
  complete,
  elements,
  state,
  stripe,
}: MakeStripeRequestOptions) => {
  if (!stripe || !elements || !complete) return

  const card = elements.getElement(CardNumberElement)
  const cvc = elements.getElement(CardCvcElement)
  if (!cvc) return

  if (!card) {
    const payOpts: ConfirmCardPaymentData = {
      payment_method_options: {
        card: { cvc },
      },
    }

    return (secret: string, type: 'payment' | 'setup', payment_method?: string) =>
      type === 'payment'
        ? stripe.confirmCardPayment(secret, { ...payOpts, payment_method })
        : stripe.confirmCardSetup(secret, { payment_method })
  }

  const type = state.billing.type ?? 'other'

  const billing = chooseBillingAddress({
    billing: state.billing.address,
    shipping: state.shipping.address,
    type,
  })

  const opts: ConfirmCardPaymentData = {
    payment_method: {
      card,
      billing_details: parseBillingAddress(billing),
      metadata: {
        billingJson: JSON.stringify(billing),
        onFinish: !state.save ? 'delete' : null,
      },
    },
    save_payment_method: state.save,
    setup_future_usage: state.save ? 'off_session' : undefined,
  }

  return (secret: string, type: 'payment' | 'setup') =>
    type === 'payment'
      ? stripe.confirmCardPayment(secret, opts)
      : stripe.confirmCardSetup(secret, omit(['save_payment_method', 'setup_future_usage'], opts))
}
