import styled from 'styled-components'
import type { ContainerProps } from './types'

export const Container = styled.article<ContainerProps>`
  display: flex;
  align-items: center;

  border: ${({ border, theme }) => (border ? `1px solid ${theme.borderColor}` : 'none')};
  border-radius: 4px;
  margin: ${({ margin }) => (typeof margin === 'number' ? `${margin}px` : margin)};
  padding: 4px;
`
