import { withIcon } from './withIcon'

export const StarIcon = withIcon(
  ({ forwardedRef, isActive, strokeWidth = '0.4', stroke = 'currentColor', ...props }) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg viewBox='0 0 18 17' xmlns='http://www.w3.org/2000/svg' ref={forwardedRef} {...props}>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(-1.000000, -1.000000)'>
          <g>
            <rect x='0' y='0' width='20' height='20' />
            <path
              d='M14.9376046,17.1438094 L13.9947068,11.6845141 L17.9852577,7.82173167 L12.4700842,7.02590167 L10,2.05576047 L7.5299158,7.02590167 L2.01474231,7.82173167 L6.00529324,11.6845141 L5.06239545,17.1438094 L10,14.5660043 L14.9376046,17.1438094 Z'
              stroke={stroke}
              fill={isActive ? 'currentColor' : 'none'}
              strokeWidth={strokeWidth}
            />
          </g>
        </g>
      </g>
    </svg>
  ),
)
