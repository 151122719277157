import { ChangeEvent, FormEvent, useCallback, useContext, useMemo, useState } from 'react'
import { Text } from '@ubnt/ui-components/Text'
import { always, equals, includes, lensProp, over, pipe, set, when, __ } from 'ramda'

import { useActions } from 'store'
import { Footer, FormSection, FloorContainer, LabelContainer } from 'components'
import config from 'config'
import { Screen, useScreenWidth } from 'hooks'
import { WiFiHeaders } from './WiFiHeaders'
import { ProjectFormContext } from './Context'
import { countDecimals, displayDecimal, isValidNumber } from 'helpers'
import { removeStartZeroes } from 'helpers/strings'
import { updateWifiArray, wifiLens } from './helpers'
import {
  BubbleContainer,
  BubbleRowCompact,
  BubbleRowWide,
  CloseIcon,
  FileTitle,
  FileWrapper,
  FloorImg,
  FloorImgContainer,
  FloorItem,
  Form,
  Input,
  InputContainer,
  InputWithUnit,
  NoteText,
  NumberSelector,
  PlanWrapper,
  Radio,
  SubSection,
  WifiData,
} from './styles'

import type { ReactText } from 'react'
import type { FormStepProps } from './types'
import type { BuildingWiFi, NewProject, UpdateProject } from 'store/projects/types'

type WifiArea = 'wifiIndoor' | 'wifiOutdoor'
type Area = 'indoor' | 'both'
export interface DataUrl {
  url: string
  name: string
}
export const extractFileName = (path: string) => path.match(/[ \w-]+?(?=\.)/)

const isNumberField = includes(__, ['area', 'roomQty'])
const isAcceptableNumber = (x: number | string) =>
  x >= 0 && countDecimals(x) <= 2 && !Number.isNaN(Number(x)) && (isValidNumber(x) || x === '')
const formatNumber = pipe(removeStartZeroes, when(equals(''), always(0)))

export const WiFiForm = <A extends NewProject | UpdateProject>({
  handleNext,
  handlePrev,
  isLoading = false,
  updateProject,
  project,
}: FormStepProps<A>) => {
  const { modal } = useActions()

  const [areas, setAreas] = useState<Area | undefined>(
    project.wifiIndoor.length > 0 && project.wifiOutdoor.length > 0
      ? 'both'
      : project.wifiIndoor.length > 0
      ? 'indoor'
      : undefined,
  )
  const [indoorQty, setIndoorQty] = useState(project.wifiIndoor.length)
  const [outdoorQty, setOutdoorQty] = useState(project.wifiOutdoor.length)
  const [urls, setUrls] = useState<(DataUrl | undefined)[]>([])
  const formContext = useContext(ProjectFormContext)
  const screenWidth = useScreenWidth()

  const isReadOnly = !formContext.editable || isLoading
  const location = formContext.location

  const handleChangeArea =
    (type: WifiArea, index: number) => (prop: keyof BuildingWiFi) => (_: any, value: ReactText) => {
      if (isReadOnly) return

      const isNumber = isNumberField(prop)
      if (isNumber && !isAcceptableNumber(value)) return

      const _value = isNumber && typeof value === 'string' ? formatNumber(value) : value
      return updateProject(set(wifiLens(type, index, prop), _value))
    }

  const handleChangeAreaQty = (type: WifiArea) => (event: ChangeEvent<HTMLInputElement>) => {
    if (isReadOnly) return

    const value = Number(event.target.value)
    if (Number.isNaN(value) || value < 0) return
    if (type === 'wifiIndoor') setIndoorQty(value)
    if (type === 'wifiOutdoor') setOutdoorQty(value)

    return updateProject(over(lensProp(type), updateWifiArray(type, value)))
  }

  const handleRemovePlan = (type: WifiArea, index: number) => {
    if (isReadOnly) return

    setUrls((prev) => {
      const next = [...prev]
      next[index] = undefined
      return next
    })

    return updateProject(
      over(wifiLens.index(type, index), (previous) => ({
        ...previous,
        plans: [],
        plan: undefined,
        size: 0,
      })),
    )
  }

  const handleUploadPlan = (type: WifiArea, index: number) => (file: File, dataUrl?: string) => {
    if (isReadOnly) return
    if (dataUrl) {
      setUrls((prev) => {
        const next = [...prev]
        next[index] = { url: dataUrl, name: file.name }
        return next
      })
    }

    updateProject(
      over(wifiLens.index(type, index), (previous) => ({
        ...previous,
        plan: { name: file.name, file: file },
        size: file.size,
      })),
    )
  }

  const handleSetAreas = (event: ChangeEvent<HTMLInputElement>) => {
    if (isReadOnly) return

    const { value } = event.target
    setAreas(value as Area)
    if (value === 'indoor') {
      updateProject(set(lensProp('wifiOutdoor'), []))
      setOutdoorQty(0)
    }
  }

  const handleSubmit = (event: FormEvent<HTMLFormElement> | MouseEvent) => {
    event.preventDefault()
    handleNext()
  }

  const renderPlans = (type: WifiArea, index: number, plans: BuildingWiFi['plans']) => {
    const isForm = location === 'form'
    return urls[index] || plans.length > 0 ? (
      <FloorItem>
        <FloorImgContainer>
          <CloseIcon onClick={() => handleRemovePlan(type, index)} isForm={isForm} />
          {urls[index]?.url?.includes('application/pdf') ||
          plans[0]?.plan.toLowerCase().endsWith('pdf') ? (
            <PlanWrapper
              href={urls[index]?.url || `${config.API_URL}/api/projects/${plans[0].plan}`}
            >
              <FileWrapper isForm={isForm}>
                <FileTitle>{urls[index]?.name || extractFileName(plans[0].plan)}</FileTitle>
              </FileWrapper>
            </PlanWrapper>
          ) : (
            <PlanWrapper
              href={urls[index]?.url || `${config.API_URL}/api/projects/${plans[0].plan}`}
            >
              <FloorImg
                isForm={isForm}
                src={urls[index]?.url || `${config.API_URL}/api/projects/${plans[0].plan}`}
                alt='plan'
              />
            </PlanWrapper>
          )}
        </FloorImgContainer>
      </FloorItem>
    ) : (
      []
    )
  }

  const isSmall = formContext.location === 'sidebar' || screenWidth <= Screen.TABLET
  const BubbleRow = useMemo(() => (isSmall ? BubbleRowCompact : BubbleRowWide), [formContext])

  const areaUnit = useMemo(() => (formContext.unit === 'sqft' ? 'ft²' : 'm²'), [formContext.unit])

  const renderWifi = useCallback(
    (type: WifiArea, areas: BuildingWiFi[]) => {
      return areas.map((x, index) => {
        const update = handleChangeArea(type, index)

        return (
          <BubbleRow key={x.id}>
            <WifiData className='bubble-field'>
              <Input
                className='input-field'
                onChange={update('name')}
                readOnly={isReadOnly}
                size='caption'
                type={type === 'wifiIndoor' ? 'number' : 'text'}
                value={x.name}
                width='100%'
              />
              <InputWithUnit
                className='input-field'
                containerClassName={type === 'wifiOutdoor' ? 'last-two' : undefined}
                labelSize={10}
                onChange={update('area')}
                readOnly={isReadOnly}
                size='caption'
                unit={isSmall ? areaUnit : undefined}
                value={displayDecimal(x.area).toString()}
                width='100%'
              />
              {type === 'wifiIndoor' && (
                <InputWithUnit
                  className='input-field'
                  labelSize={35}
                  onChange={update('roomQty')}
                  readOnly={isReadOnly}
                  size='caption'
                  unit={isSmall ? 'Rooms' : undefined}
                  value={x.roomQty.toString()}
                  width='99%'
                />
              )}
            </WifiData>
            <LabelContainer
              className={`bubble-field w-${isSmall ? 'full' : '130'}`}
              label='Note'
              showLabel={formContext.location === 'sidebar'}
            >
              <button
                className='note-button w-full'
                onClick={(event) => {
                  event.preventDefault()
                  if (isLoading) return
                  modal.setModal({
                    modal: 'project_note',
                    props: {
                      editable: formContext.editable,
                      note: x.note,
                      onSubmit: (text) => update('note')(undefined, text),
                    },
                  })
                }}
                type='button'
              >
                <NoteText size='body' truncate>
                  {x.note || 'Note'}
                </NoteText>
              </button>
            </LabelContainer>
            {type === 'wifiIndoor' && (
              <FloorContainer
                label={isSmall}
                onChange={handleUploadPlan(type, index)}
                readOnly={isReadOnly}
              >
                {renderPlans(type, index, x.plans)}
              </FloorContainer>
            )}
          </BubbleRow>
        )
      })
    },
    [handleChangeArea, handleUploadPlan, isSmall, renderPlans, screenWidth],
  )

  return (
    <Form className={isLoading ? 'transparent' : undefined} onSubmit={handleSubmit}>
      <FormSection main>
        <SubSection>
          <Text size='body'>Where would you like Wi-Fi coverage?</Text>
          <InputContainer>
            <Radio
              id='indoor'
              checked={areas === 'indoor'}
              name='indoor'
              onChange={handleSetAreas}
              readOnly={isReadOnly}
              value='indoor'
            >
              <Text size='body'>Indoor</Text>
            </Radio>
            <Radio
              id='both'
              checked={areas === 'both'}
              name='both'
              onChange={handleSetAreas}
              readOnly={isReadOnly}
              value='both'
            >
              <Text size='body'>Indoor & Outdoor</Text>
            </Radio>
          </InputContainer>
        </SubSection>
        {(areas === 'indoor' || areas === 'both') && (
          <SubSection>
            <Text size='body'>How many floors would you like Wi-Fi coverage for?</Text>
            <NumberSelector
              integer
              min={0}
              name='indoorQty'
              onChange={handleChangeAreaQty('wifiIndoor')}
              readOnly={isReadOnly}
              value={indoorQty}
            />
            {project.wifiIndoor.length > 0 && (
              <BubbleContainer headerTitle='' headerContentClassName='hidden'>
                <WiFiHeaders
                  labels={{
                    first: ['Indoor', `Area size (${areaUnit})`, 'Number of Rooms'],
                    rest: ['Note'],
                  }}
                  show={!isSmall}
                />
                {renderWifi('wifiIndoor', project.wifiIndoor)}
              </BubbleContainer>
            )}
          </SubSection>
        )}
        {areas === 'both' && (
          <SubSection>
            <Text size='body'>How many outdoor areas would you like Wi-Fi coverage for?</Text>
            <NumberSelector
              integer
              min={0}
              name='outdoorQty'
              onChange={handleChangeAreaQty('wifiOutdoor')}
              readOnly={isReadOnly}
              value={outdoorQty}
            />
            {project.wifiOutdoor.length > 0 && (
              <BubbleContainer headerTitle='' headerContentClassName='hidden'>
                <WiFiHeaders
                  labels={{ first: ['Outdoor', `Area size (${areaUnit})`], rest: ['Note'] }}
                  show={!isSmall}
                />
                {renderWifi('wifiOutdoor', project.wifiOutdoor)}
              </BubbleContainer>
            )}
          </SubSection>
        )}
      </FormSection>
      <Footer isLoading={isLoading} onPrev={handlePrev} />
    </Form>
  )
}
