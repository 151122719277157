import * as op from './operators'
import { handleAsyncAction } from 'store/operators'
import { AsyncFuncState } from 'shared'

import type { Context } from 'store'
import type { WithQuoteId } from 'store/types'

const handleAction = handleAsyncAction('orderDetails')

export const getCard = handleAction(op.getCard)

export const reset = ({ state }: Context) => {
  state.orderDetails = {
    card: null,
    status: AsyncFuncState.SUCCESS,
    fulfillments: {
      fulfilled: [],
      unfulfilled: [],
    },
  }
}

export const getFulfillments = handleAction<WithQuoteId, void>(op.getFulfillments)
