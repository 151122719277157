import { Text } from '@ubnt/ui-components'
import { isNotNil, map } from 'ramda'

import { Loader, Nav, NavItems } from 'components'
import { HoverButton } from './HoverButton'
import { parseProject } from './helpers'
import { AsyncFuncState } from 'shared'
import { Title, TitleButton } from './styles'

import type { VFC } from 'react'
import type { ProjectsListProps } from './types'

export const ProjectsList: VFC<ProjectsListProps> = ({
  projects,
  handleAdd,
  handleClickMore,
  handleSelect,
  selectedProject,
  status,
}) => {
  const renderHover = handleClickMore
    ? (id: string) => <HoverButton onClick={handleClickMore} projectId={id} />
    : undefined

  return (
    <Nav className='nav'>
      <Title>
        <Text size='body'>Projects</Text>
        {isNotNil(handleAdd) && (
          <TitleButton onClick={handleAdd} variant='tertiary'>
            + New Project
          </TitleButton>
        )}
      </Title>
      <Loader status={status} cond={projects.length === 0}>
        <NavItems
          className={status === AsyncFuncState.LOADING ? 'transparent' : undefined}
          navigationItems={map(parseProject, projects)}
          onSelect={handleSelect}
          renderHover={renderHover}
          selectedItem={selectedProject}
        />
      </Loader>
    </Nav>
  )
}
