import styled from 'styled-components'
import { Tabs as UITabs } from '@ubnt/ui-components/Tabs'
import { Text } from '@ubnt/ui-components/Text'

export const TabItem = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: inherit;
`

export const Tabs = styled(UITabs)`
  background-color: ${({ theme }) => theme.colors.Light.color.neutral.n1};
  border-bottom: 1px solid ${({ theme }) => theme.colors.Light.color.neutral.n4};
  height: var(--sidepanel-project-tabs-height);
  flex: none;

  .project-sidebar__selected {
    color: ${({ theme }) => theme.colors.Light.color.blue.b6};

    > span {
      color: inherit;
    }
  }

  .project-sidebar__tab {
    padding: 0px 10px;

    height: 100%;
    min-width: 60px;
  }

  .project-sidebar__tab-container {
    height: 100%;
  }
`

export const TabText = styled(Text)`
  color: inherit;
  margin-top: 5px;
`

export const SidebarBody = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - var(--sidepanel-project-tabs-height));

  .sidebar-header {
    margin-bottom: 20px;
  }
`
