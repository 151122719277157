import { Tooltip } from '@ubnt/ui-components'
import { MoreHorizontalIconL } from '@ubnt/icons'

import { HoverButton as Button } from './styles'

import type { FC, MouseEventHandler } from 'react'
import type { HoverButtonProps } from './types'

export const HoverButton: FC<HoverButtonProps> = ({ onClick, projectId }) => {
  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault()
    event.stopPropagation()
    onClick && onClick(projectId)
  }

  return (
    <Tooltip description='Project details' position='bottomEnd' width={120}>
      <Button onClick={handleClick} size='small' variant='default'>
        <MoreHorizontalIconL size='large' />
      </Button>
    </Tooltip>
  )
}
