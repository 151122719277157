import { TableColumnConfig } from '@ubnt/ui-components'
import { BadgeProps, UserAvatar } from 'components'
import { dateOr, toLocaleCurrency, renderBadge } from 'helpers'
import { pipe, prop } from 'ramda'
import { QuoteStatus } from 'shared'
import type { QuoteListItem } from 'store/quotes/types'

export const formatDate = dateOr('undefined')

const statusDict: Record<QuoteStatus, BadgeProps['type']> = {
  [QuoteStatus.SENT]: 'navy',
  [QuoteStatus.PENDING_FULFILMENT]: 'blue',
  [QuoteStatus.PARTIALLY_COMPLETED]: 'blue',
  [QuoteStatus.FINAL_PAYMENT_PENDING]: 'blue',
  [QuoteStatus.COMPLETED]: 'green',
}

export const renderFooter = (quote: QuoteListItem) => `Updated ${formatDate(quote.updatedAt)}`

type RenderStatus = (x: QuoteListItem) => JSX.Element
export const renderStatus: RenderStatus = pipe(prop<any>('status'), renderBadge(statusDict)) as any

export const QUOTES_COLUMNS: TableColumnConfig<QuoteListItem>[] = [
  {
    id: 'quoteName',
    label: 'Quote Name',
    minWidth: 100,
  },
  {
    id: 'total',
    label: 'Total',
    minWidth: 100,
    renderCell: ({ total }) => toLocaleCurrency(total),
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 100,
    renderCell: renderStatus,
  },
  {
    id: 'professionalUser',
    label: 'Professional',
    minWidth: 100,
    renderCell: ({ professionalUser, professional }) => (
      <UserAvatar fallbackUrl={professional.avatar} user={professionalUser} />
    ),
  },
  {
    id: 'sentAt',
    label: 'Quote Sent',
    minWidth: 100,
    align: 'center',
    renderCell: ({ sentAt }) => formatDate(sentAt),
  },
]
