import { Button, SidePanel, Text } from '@ubnt/ui-components'
import { CheckmarkIconL, PaymentMethodIconL, TalkLogo } from '@ubnt/icons'
import styled from 'styled-components'

import { Screen, useScreenWidth, useSlider } from 'hooks'
import { talkSubsPrice } from 'shared'
import { useActions, useOverState } from 'store'

import type { FC } from 'react'
import type { ModalType, ContactSidebar as SidebarType } from 'store/modal/types'

const texts = [
  'A local phone number validated as spam-free',
  'Unlimited free calls to local extensions',
  '3,000 inbound and outbound minutes per line within the U.S., Canada, and Mexico',
  'Support for transferring your current phone numbers',
  'Emergency calling service',
]

const Subscriptions: FC = () => {
  const { sidebar } = useOverState().modal as ModalType<unknown, SidebarType>
  const {
    modal: { closeSidebar },
  } = useActions()

  const { Slider, closeSlider } = useSlider(closeSidebar)
  const width = useScreenWidth() === Screen.MOBILE ? 352 : undefined

  return (
    <Slider width={width}>
      <SidePanel
        header='Subscriptions'
        in={sidebar === 'subscriptions'}
        onClose={closeSlider}
        width={width}
      >
        <Container>
          <div>
            <Notice>
              <PaymentMethodIconL size='large' variant='fill' fillClassname='fill' />
              <Text style={{ marginLeft: '10px' }}>
                The service(s) below are needed to support UniFi devices in your order. You will not
                be charged until you subscribe to them.
              </Text>
            </Notice>
            <TalkSub>
              <TalkLogo size='logo' style={{ marginRight: '10px' }} variant='aqua' />
              <TalkSubDetails>
                <Text size='body' weight='bold'>
                  UniFi Talk
                </Text>
                <Text size='body' weight='bold'>
                  {`$${talkSubsPrice} per month / per line`}
                </Text>
                <Text weight='light'>UniFi Talk is a subscription-based VoIP service.</Text>
              </TalkSubDetails>
            </TalkSub>
            <ul>
              {texts.map((t, i) => (
                <BulletPoint key={i}>
                  <CheckmarkIconL
                    size='large'
                    style={{ marginRight: '10px' }}
                    isActive
                    variant='fill'
                  />
                  <Text size='body'>{t}</Text>
                </BulletPoint>
              ))}
            </ul>
          </div>
          <Footer>
            <Button onClick={closeSlider} variant='primary'>
              Done
            </Button>
          </Footer>
        </Container>
      </SidePanel>
    </Slider>
  )
}

export default Subscriptions

const Container = styled.section`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  padding: 16px 32px;
`

const Notice = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.Light.color.neutral.n2};
  border-left: 2px solid ${({ theme }) => theme.colors.Light.color.blue.b6};
  border-radius: 4px;
  padding: 16px 16px 16px 10px;
  margin-bottom: 16px;

  .fill {
    fill: #006fff;
  }
`

const TalkSub = styled.div`
  display: flex;
  padding: 16px 0;
  border-bottom: ${({ theme }) => `1px solid ${theme.borderColor}`};
  border-top: ${({ theme }) => `1px solid ${theme.borderColor}`};
  margin-bottom: 16px;
`

const TalkSubDetails = styled.div`
  display: flex;
  flex-direction: column;
`

const BulletPoint = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`
