import { round } from 'helpers'
import type { Unit } from 'types'

const FEET_TO_METER = 0.09290304
const METER_TO_FEET = 10.76391

const UNIT_DICT = {
  sqm: FEET_TO_METER,
  sqft: METER_TO_FEET,
}

interface UnitOptions {
  precision?: number
}

interface ToDisplayOptions {
  precision?: number
  to: Unit
}

interface ToServerOptions {
  from: Unit
  precision?: number
}

export const toUnit =
  (unit: Unit) =>
  (x: number, { precision }: UnitOptions = {}) =>
    round(UNIT_DICT[unit] * x, precision)

export const toFeet = toUnit('sqft')
export const toMeters = toUnit('sqm')

// Converts value from server units to given system
export const toDisplayUnits = (x: number, { precision, to }: ToDisplayOptions) =>
  to === 'sqm' ? x : toFeet(x, { precision })

// Converts value in given system to server units, which is always meters
export const toServerUnits = (x: number, { from, precision }: ToServerOptions) =>
  from === 'sqm' ? x : toMeters(x, { precision })
