import { Text } from '@ubnt/ui-components/Text'

import { FlexRow } from 'components'
import { CardImg } from './styles'
import { getCardIcon } from './helpers'

import type { FC } from 'react'
import type { CardLast4Props } from './types'

export const CardLast4: FC<CardLast4Props> = ({ brand, number }) => (
  <FlexRow justify='flex-start'>
    <CardImg src={getCardIcon(brand)} alt='card icon' />
    <Text size='body'>{number}</Text>
  </FlexRow>
)
