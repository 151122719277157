import styled from 'styled-components'
import { UploadButton as UIUploadButton, Text } from '@ubnt/ui-components'

import type { ChangeEvent } from 'react'

interface Props {
  className?: string
  onLoad: (file: File, dataUrl?: string) => void
  readOnly?: boolean
}

export const UploaderButton = ({ onLoad }: Props) => {
  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    return Array.from(target?.files ?? []).forEach((file) =>
      file.type.startsWith('image') || file.type.endsWith('pdf')
        ? readFile(onLoad, file)
        : onLoad(file),
    )
  }

  const label = 'Upload Floor Plan'

  return (
    <UploadButton onChange={handleChange}>
      <UploadText size='body'>{label}</UploadText>
    </UploadButton>
  )
}

const readFile = (onLoad: (file: File, dataUrl?: string) => void, file: File) => {
  const reader = new FileReader()
  const handleLoad = ({ target }: ProgressEvent<FileReader>) => {
    if (!target) return
    onLoad(file, target.result as string)
    reader.removeEventListener('load', handleLoad)
  }
  reader.addEventListener('load', handleLoad)
  reader.readAsDataURL(file)
}

const UploadButton = styled(UIUploadButton)`
  > div > span {
    padding: 0;
  }
`
const UploadText = styled(Text)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.Light.color.blue.b6};
  text-align: center;
  width: 100%;
`
