import { isNil } from 'ramda'

import { http } from 'helpers'

import type { CardDetailsExtended, WithPaymentType } from 'types'
import type { CreateProOptions, WithAddress, WithExpiryDate, WithSecret } from './types'

const createPro = ({ method, quoteId, version }: CreateProOptions) =>
  http.post<WithSecret>(`quotes/${quoteId}/order/professional`, { method, version })

const getCard = ({ type }: WithPaymentType) =>
  http.get<CardDetailsExtended>(`clients/payment?type=${type}`)

const updateCard = ({ address: billing, date }: WithAddress & WithExpiryDate) => {
  const body = {
    billing,
    date: isNil(date.month) || isNil(date.year) ? undefined : date,
  }

  return http.put<void>('clients/card', body)
}

export const api = {
  createPro,
  getCard,
  updateCard,
}
