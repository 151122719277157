import { handleAsyncAction } from 'store/operators'
import * as op from './operators'
import { AsyncFuncState } from 'shared'
import type { Context } from 'store'

const handleProfessionalUsersAction = handleAsyncAction('professionalUser')

export const getProfessionalUser = handleProfessionalUsersAction<void, void>(op.getProfessionalUser)

export const resetStatus = ({ state }: Context) => {
  state.professionalUser.status = AsyncFuncState.NULL
}
