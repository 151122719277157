import { useCallback, useMemo } from 'react'
import { NavigateOptions, To, useLocation, useNavigate } from 'react-router-dom'

export type NavigationState<A = object> = A & {
  from: string[]
}

/**
 * Wrapper over useNavigate hook.
 * It keeps track of pages visited within the app.
 */
export const useBackNavigate = () => {
  const from = useNextNavigationStack()
  const navigate = useNavigate()

  return useCallback(
    (to: To, { state = {}, ...rest }: NavigateOptions = {}) =>
      navigate(to, { ...rest, state: { ...state, from } }),
    [from, navigate],
  )
}

/**
 * Returns the navigation history within the app in a usable form.
 */
export const useNavigationStack = () => {
  const { state } = useLocation()
  return useMemo(() => {
    const stack = state?.from ?? []
    const [head = null, ...tail] = stack
    return { head, stack, tail }
  }, [state])
}

export const useNextNavigationStack = () => {
  const { pathname, state } = useLocation()
  return useMemo(() => {
    const prev = state?.from ?? []
    return prev[0] == pathname ? prev : [pathname, ...prev]
  }, [pathname, state])
}
