import { Item } from './Item'
import { List } from './styles'

import type { FC } from 'react'
import type { NavItemsProps } from './types'

export const NavItems: FC<NavItemsProps> = ({
  className,
  navigationItems,
  onSelect,
  renderHover,
  selectedItem,
}) => (
  <List className={className}>
    {navigationItems.map((item) => (
      <Item
        key={item.id}
        isSelected={item.id === selectedItem}
        item={item}
        onClick={onSelect}
        renderHover={renderHover}
      />
    ))}
  </List>
)
