import {
  HotelIcon,
  HomeIconL,
  IOsMenuIconL,
  OfficeIcon,
  SchoolIcon,
  StadiumIcon,
} from '@ubnt/icons'
import { RadioProps } from '@ubnt/ui-components/Radio/Radio'

import { sizeToBuildingSize, sizeToUserQty } from 'app/onboarding/helpers'
import { BuildingType, ProjectSize, Size } from 'store/projects/types'

import type { ReactElement } from 'react'
import type { Unit } from 'types'
import type { FormFields } from './types'

export interface RadioInput {
  label: string
  name: keyof FormFields
  options: RadioOption[]
  extra?: ReactElement
}

interface RadioOption extends RadioProps {
  value: BuildingType | ProjectSize | Size
}

export const buildingSize = (unit: Unit, extra?: ReactElement): RadioInput => ({
  label: 'Building Size *',
  name: 'buildingSize',
  extra,
  options: [
    {
      id: 'building-size-small',
      children: sizeToBuildingSize(ProjectSize.SMALL, unit),
      value: ProjectSize.SMALL,
      className: 'remove-radio',
    },
    {
      id: 'building-size-medium',
      children: sizeToBuildingSize(ProjectSize.MEDIUM, unit),
      value: ProjectSize.MEDIUM,
      className: 'remove-radio',
    },
    {
      id: 'building-size-large',
      children: sizeToBuildingSize(ProjectSize.LARGE, unit),
      value: ProjectSize.LARGE,
      className: 'remove-radio',
    },
    {
      id: 'building-size-xlarge',
      children: sizeToBuildingSize(ProjectSize.XLARGE, unit),
      value: ProjectSize.XLARGE,
      className: 'remove-radio',
    },
  ],
})

export const buildingType: RadioInput = {
  label: 'Building Type *',
  name: 'buildingType',
  options: [
    {
      id: 'building-type-home',
      labelProps: { icon: <HomeIconL transparent size='large' /> },
      children: 'Home',
      value: BuildingType.HOME,
      className: 'radio-check',
      labelClassName: 'radio-label',
    },
    {
      id: 'building-type-office',
      labelProps: { icon: <OfficeIcon transparent size='large' /> },
      children: 'Office',
      value: BuildingType.OFFICE,
      className: 'radio-check',
      labelClassName: 'radio-label',
    },
    {
      id: 'building-type-school',
      labelProps: { icon: <SchoolIcon transparent size='large' /> },
      children: 'School',
      value: BuildingType.SCHOOL,
      className: 'radio-check',
      labelClassName: 'radio-label',
    },
    {
      id: 'building-type-hotel',
      labelProps: { icon: <HotelIcon transparent size='large' /> },
      children: 'Hotel',
      value: BuildingType.HOTEL,
      className: 'radio-check',
      labelClassName: 'radio-label',
    },
    {
      id: 'building-type-stadium',
      labelProps: { icon: <StadiumIcon transparent size='large' /> },
      children: 'Stadium',
      value: BuildingType.STADIUM,
      className: 'radio-check',
      labelClassName: 'radio-label',
    },
    {
      id: 'building-type-others',
      labelProps: { icon: <IOsMenuIconL transparent size='large' /> },
      children: 'Others',
      value: BuildingType.OTHER,
      className: 'radio-check',
      labelClassName: 'radio-label',
    },
  ],
}

export const radios: RadioInput[] = [
  {
    label: 'Number of Users *',
    name: 'userQty',
    options: [
      {
        id: 'user-qty-xsmall',
        children: sizeToUserQty(Size.XSMALL),
        value: Size.XSMALL,
        className: 'remove-radio',
      },
      {
        id: 'user-qty-small',
        children: sizeToUserQty(Size.SMALL),
        value: Size.SMALL,
        className: 'remove-radio',
      },
      {
        id: 'user-qty-medium',
        children: sizeToUserQty(Size.MEDIUM),
        value: Size.MEDIUM,
        className: 'remove-radio',
      },
      {
        id: 'user-qty-large',
        children: sizeToUserQty(Size.LARGE),
        value: Size.LARGE,
        className: 'remove-radio',
      },
      {
        id: 'user-qty-xlarge',
        children: sizeToUserQty(Size.XLARGE),
        value: Size.XLARGE,
        className: 'remove-radio',
      },
    ],
  },
]
