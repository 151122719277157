import { DetailsSection, Section } from './styles'
import { makeDetailItems } from './helpers'

import type { FC } from 'react'
import type { DetailsProps } from './types'

export const Details: FC<DetailsProps> = ({ type, useSaved }) => (
  <Section variant='secondary' verticalSpacing={8}>
    <DetailsSection items={makeDetailItems(type, useSaved)} multiOpen />
  </Section>
)
