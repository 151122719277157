import { createElement, useEffect, useState } from 'react'

import { useOverState } from 'store'
import { Box } from './Box'
import { load, send } from './helpers'

import type { Maybe } from 'types'
import type { PSGroup, Status, UsePactSafeInput, UsePactSafeReturn } from './types'

export const usePactSafe = ({ group, shouldLoad = true }: UsePactSafeInput): UsePactSafeReturn => {
  const { app } = useOverState()

  const [data, setData] = useState<Maybe<PSGroup>>(null)
  const [status, setStatus] = useState<Status>(shouldLoad ? 'loading' : 'idle')
  const [seen, setSeen] = useState(false)

  useEffect(() => {
    if (shouldLoad) {
      load(group)
        .then((data) => {
          setData(data)
          setStatus('idle')
        })
        .catch(() => {
          setStatus('error')
        })
    }
  }, [shouldLoad])

  return {
    AgreementBox: (props) => createElement(Box, { ...props, data, seen, setSeen, status }),
    seen,
    sendAgreement: send('agreed', data, app.user?.email, app.user?.ssoId),
  }
}
