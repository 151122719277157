import { Tile } from 'components'
import { Price } from './Price'
import { Container } from './styles'

import type { FC } from 'react'
import type { OrderSummaryProps } from './types'

export const OrderSummary: FC<OrderSummaryProps> = ({ border, items, size }) => (
  <Container border={border}>
    {items.map((x) => (
      <Tile
        key={x.id}
        backorder={x.backorder}
        icon={x.icon}
        margin='6px 0px'
        name={x.name}
        size={size}
        value={<Price price={x.price} qty={x.qty} size={size} />}
      />
    ))}
  </Container>
)
