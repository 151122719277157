import { isNil } from 'ramda'

import amex from 'assets/cards/Amex.png'
import generic from 'assets/cards/Generic.png'
import mastercard from 'assets/cards/Mastercard.png'
import visa from 'assets/cards/Visa.png'

import type { CardBrand } from 'types'

const cardDict: Record<string, string> = {
  amex,
  default: generic,
  mastercard,
  visa,
}

export const getCardIcon = (brand?: CardBrand) => {
  if (isNil(brand)) return cardDict.default
  return cardDict[brand] ?? cardDict.default
}
