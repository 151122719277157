import { VFC, useEffect } from 'react'
import styled from 'styled-components'
import { useActions, useOverState } from 'store'
import { SupportCard } from './SupportCard'
import { getCardInfo } from './helpers'
import { ChangePage } from 'components'
import { Pages } from 'shared'

export const Support: VFC = () => {
  const { professionalUser } = useOverState().professionalUser
  const { getProfessionalUser, resetStatus } = useActions().professionalUser

  useEffect(() => {
    if (!professionalUser) getProfessionalUser()
    return resetStatus
  }, [])

  return (
    <Container>
      {getCardInfo(professionalUser).map(({ icon, title, text, link }) => (
        <SupportCard key={title} icon={icon} title={title} text={text} link={link} />
      ))}
      <ChangePage to={Pages.SUPPORT} />
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  display: flex;
  margin: 12px 20px;

  // Last card: Your Professional.
  > div:last-child {
    cursor: auto;
    > span:last-child {
      padding: 0 !important;
      text-align: left !important;
      width: 100%;
    }
  }
`
