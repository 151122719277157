import { Text } from '@ubnt/ui-components/Text'
import { FC } from 'react'

interface Props {
  title: string
  body: string
}

export const TitleAndBody: FC<Props> = ({ title, body }) => {
  return (
    <>
      <Text size='body' weight='bold'>
        {title}
      </Text>
      <Text size='body'>{body}</Text>
    </>
  )
}
