import { useEffect, useRef, useState } from 'react'
import { AsyncFuncState } from 'shared'

import { isResponse, statusToLoader } from './helpers'

import type { UseLoader } from './types'

export const useLoader: UseLoader = (status) => {
  const [state, setState] = useState(status)
  const skip = useRef(true)

  useEffect(() => {
    if (skip.current) {
      return () => {
        skip.current = false
      }
    } else {
      const timer = isResponse(status)
        ? setTimeout(() => setState(AsyncFuncState.NULL), 1200)
        : null
      setState(status)

      return () => {
        if (timer) clearTimeout(timer)
      }
    }
  }, [status])

  return [statusToLoader(state, skip.current), status === AsyncFuncState.LOADING]
}
