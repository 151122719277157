import { useOverState } from 'store'
import { FullscreenLoader } from 'components'
import { CardFields, SavedCard, SaveMethod } from './components'
import { Form } from './styles'
import { makeUseCard } from './helpers'
import { AsyncFuncState } from 'shared'
import { UseCard } from './constants'

import type { FC, FormEventHandler } from 'react'
import type { CardDetailsProps } from './types'

export const CardDetails: FC<CardDetailsProps> = ({ type }) => {
  const { payment, stripe } = useOverState()

  const isLoading =
    stripe.status === AsyncFuncState.LOADING ||
    (payment.status === AsyncFuncState.LOADING && payment.loadingSection === 'card')

  if (isLoading) return <FullscreenLoader />

  const use = makeUseCard({ savedCard: payment.savedCard, type, useSaved: payment.useSaved })

  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault()
  }

  return (
    <Form onSubmit={handleSubmit}>
      {use !== UseCard.USE_SAVED && <CardFields />}
      {use === UseCard.USE_SAVED && <SavedCard />}
      <SaveMethod type={type} use={use} />
    </Form>
  )
}
