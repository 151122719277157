import { useState } from 'react'

export function useDebounce(value: (...args: any[]) => void, delay?: number) {
  const [debouncedValue] = useState<(...args: any[]) => void>(() => value)
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null)

  const updateTimer = (...args: any[]) => {
    if (timer) clearTimeout(timer)
    setTimer(setTimeout(() => debouncedValue(...args), delay))
  }

  return updateTimer
}
