import { FC, useState } from 'react'
import { Input } from '@ubnt/ui-components/Input'

import { isValidState, isValidValue, parseState, stateToValue, valueToState } from './helpers'
import { PLACEHOLDER } from './constants'

import type { InputProps } from '@ubnt/ui-components'
import type { ExpiryDateInputProps } from './types'
import { isEmpty } from 'ramda'

export const ExpiryDateInput: FC<
  Omit<InputProps, 'onBlur' | 'onChange' | 'type' | 'value'> & ExpiryDateInputProps
> = ({ label = 'Expiration Date', onChange, ...rest }) => {
  const [state, setState] = useState('')
  const [invalid, setInvalid] = useState(false)

  const handleChange = (_: any, value: string | number) => {
    const val = valueToState(value)
    setState(val)
    setInvalid(false)

    const obj = stateToValue(val)
    onChange && onChange([obj, isEmpty(val) || isValidValue(obj)])
  }

  return (
    <Input
      {...rest}
      label={label}
      invalid={invalid}
      onBlur={() => setInvalid(!isValidState(state))}
      onChange={handleChange}
      placeholder={PLACEHOLDER}
      value={parseState(state)}
    />
  )
}
