import io, { Socket } from 'socket.io-client'
import { http } from 'helpers'
import config from 'config'
import { SocketEvents } from 'shared/sockets/constants'

import type { Maybe, User } from 'types'
import type { AccountCheck, CreateAccount } from 'store/types'
import type { ValidateEmail, SocketHandler } from './types'

const regex = {
  finalSlash: /\/$/,
  startSlash: /^\//,
}

export const api = {
  createAccountWithQuote: (quoteId: string, opts: CreateAccount) =>
    http.post<{ email: string }>(`oauth/sso-users/quote/${quoteId}`, opts),
  createUser: (): Promise<User> => http.post(`oauth`),
  login: () => http.post<Maybe<User>>('oauth/login'),
  getUser: () => http.get<User>(`users/me`),
  checkCanCreateUser: (quoteId: string) =>
    http.post<AccountCheck>(`oauth/sso-users/quote/${quoteId}/available`),
  logout: () => http.post<void>('oauth/logout'),
  validateEmail: ({ email, client = false, pro = false, user = false }: ValidateEmail) =>
    http.get<{ isInvalid: boolean; message: string }>(
      `validations/email/${email}?client=${client}&pro=${pro}&user=${user}`,
    ),
}

export const location = {
  goTo: (path: string, search = '') => {
    const _path = path.replace(regex.startSlash, '')
    window.location.href = `${config.BASE_URL}/${_path}?${search}`
  },
  goToLogin: (redirectPath = '') => {
    const redirect = redirectPath.replace(/^\/+/, '')
    window.location.href = `${config.API_URL}/api/oauth/login?redirect_path=${redirect}`
  },
  open: (url: string, target = '_blank') => window.open(url, target),
}

export const socket = (() => {
  let socket: Socket | null = null

  return {
    initialize() {
      socket = io(config.API_URL, { withCredentials: true })
    },
    send(event: SocketEvents, data: any) {
      if (socket) socket.emit(event, data)
    },
    subscribe(event: string, fn: SocketHandler) {
      if (socket) socket.on(event, fn)
    },
    unsubscribe(event: string, fn?: SocketHandler) {
      if (socket) socket.off(event, fn)
    },
  }
})()
