import { FC, useEffect, useState } from 'react'
import { Elements } from '@stripe/react-stripe-js'

import { useActions } from 'store'

import type { Stripe } from '@stripe/stripe-js'
import type { ProviderProps } from './types'

export const Provider: FC<ProviderProps> = ({ children, quoteId, type }) => {
  const [stripe, setStripe] = useState<Promise<Stripe | null> | null>(null)
  const actions = useActions()

  useEffect(() => {
    setStripe(actions.stripe.makeStripe({ quoteId, type }))
  }, [type])

  return <Elements stripe={stripe}>{children}</Elements>
}
