import { TableColumnConfig } from '@ubnt/ui-components'
import { Avatar } from 'components'
import { buildName } from 'helpers'
import { User } from 'types'

export const COLUMNS: TableColumnConfig<User & { id: string }>[] = [
  {
    id: 'lastName',
    label: '',
    minWidth: 30,
    maxWidth: 40,
    align: 'center',
    renderCell: (user) => (
      <Avatar
        name={buildName(user)}
        logoUrl={user.avatar}
        imgSize={24}
        fontSize={9}
        fontWeight='bold'
        className='avatar'
      />
    ),
  },
  {
    id: 'firstName',
    label: 'User',
    minWidth: 70,
    renderCell: ({ firstName, lastName }) => `${firstName} ${lastName}`,
  },
  {
    id: 'email',
    label: 'Email',
    minWidth: 100,
  },
]
