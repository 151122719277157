import { HorizontalDivider } from 'components/HorizontalDivider'
import { FC } from 'react'
import type { Product, QuoteProHardware, QuoteProService } from 'store/quotes/types'
import { ItemTile } from './ItemTile'

interface Props {
  items: Product[] | QuoteProHardware[] | QuoteProService[]
}

export const ItemsMobileView: FC<Props> = ({ items }) => {
  return (
    <>
      <HorizontalDivider />
      {items.map((item) => (
        <ItemTile key={item.id} item={item} />
      ))}
    </>
  )
}
