import { Text } from '@ubnt/ui-components'
import styled from 'styled-components'

import { TrackingLink } from './TrackingLink'

import type { FulfillmentItem } from 'types'
import type { VFC } from 'react'

interface Props {
  items: FulfillmentItem | FulfillmentItem[]
}
export const FulfillmentList: VFC<Props> = ({ items }) => {
  const _items = Array.isArray(items) ? items : [items]
  const { trackingCompany, trackingNumber, trackingUrl } = _items[0]

  return (
    <Container>
      <TrackingLink company={trackingCompany} number={trackingNumber} url={trackingUrl} />

      <ul>
        {_items.map((x) => (
          <Item key={x.productVariantId}>
            <Left>
              <Thumbnail src={x.thumbnail} alt='Line item thumbnail' />

              <TextContainer>
                <Text size='body'>{x.sku}</Text>
                {x.isBackorder && (
                  <Text color='warning' style={{ marginTop: '-2px' }}>
                    Extended Lead Time
                  </Text>
                )}
              </TextContainer>
            </Left>

            <Text style={{ width: 50, textAlign: 'right' }}>{`x ${x.qty}`}</Text>
          </Item>
        ))}
      </ul>
    </Container>
  )
}

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.Light.color.neutral.n1};
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  padding: 12px 22px 4px 16px;
  margin-bottom: 8px;
`

const Item = styled.li`
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`

const Left = styled.div`
  display: flex;
  align-items: center;
`

const Thumbnail = styled.img`
  width: 35px;
  height: 35px;
  margin-right: 12px;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`
