import { Button } from '@ubnt/ui-components/Button'
import { useElements, useStripe } from '@stripe/react-stripe-js'

import { useActions, useOverState } from 'store'
import { ItemList } from './ItemList'
import { ProductInfo } from './ProductsInfo'
import { useLoader, usePactSafe } from 'hooks'
import { CheckboxContainer, OrderTitle, Section } from './styles'
import { isComplete, makeStripeRequest } from './helpers'
import texts from '../../texts.json'
import { PSGroup } from 'shared'

import type { FC, MouseEventHandler } from 'react'
import type { ProductsProps } from './types'

export const Products: FC<ProductsProps> = ({ quote, type }) => {
  const { payment } = useOverState()
  const actions = useActions()
  const [loader, isLoading] = useLoader(payment.status)
  const stripe = useStripe()
  const elements = useElements()

  const isUiOrder = type === 'ui'

  const PSGeneral = usePactSafe({ group: PSGroup.UI_CHECKOUT, shouldLoad: isUiOrder })
  // const PSBackorder = usePactSafe({
  //   group: PSGroup.UI_BACKORDERS,
  //   shouldLoad: false,
  // })

  const method = payment.useSaved ? payment.savedCard?.id ?? undefined : undefined
  const complete = isComplete({
    obj: payment.complete,
    savedCard: Boolean(method),
    type,
  })

  const disabled = !stripe || !elements || !complete || isLoading

  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault()

    const request = makeStripeRequest({
      complete,
      elements,
      state: payment,
      stripe,
    })

    if (!request) return

    if (!isUiOrder) {
      return actions.payment.createPro({
        method,
        quote,
        quoteId: quote.id,
        request,
        version: quote.version,
      })
    }
  }

  return (
    <Section variant='secondary' verticalSpacing={8}>
      <OrderTitle size='body' weight='bold'>
        Order Summary
      </OrderTitle>
      {type === 'ui' && <ProductInfo />}
      <ItemList quote={quote} type={type} />
      <Button
        disabled={isUiOrder ? true : disabled}
        loader={loader({ showChange: payment.loadingSection === 'checkout' })}
        onClick={handleClick}
        variant='primary'
      >
        {texts.checkout.buttons.pay}
      </Button>
      {isUiOrder && (
        <CheckboxContainer>
          {/* {payment.shipments?.hasNext && (
            <PSBackorder.AgreementBox
              id='backorder-checkbox'
              checked={payment.complete.backorders}
              className='ps-checkbox'
              onChange={() =>
                actions.payment.setCompleteBackorders({ complete: !payment.complete.backorders })
              }
            />
          )} */}
          <PSGeneral.AgreementBox
            id='terms-checkbox'
            checked={payment.complete.terms}
            className='ps-checkbox'
            onChange={() => actions.payment.setCompleteTerms({ complete: !payment.complete.terms })}
          />
        </CheckboxContainer>
      )}
    </Section>
  )
}
