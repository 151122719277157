import type { FC } from 'react'
import { Text } from '@ubnt/ui-components/Text'
import { Container } from './styles'
import type { OrderShipping } from 'store/orderDetails/types'
import { capitalise } from 'helpers'
import { isNil } from 'ramda'

interface Props {
  shippingData: OrderShipping | undefined
}

export const ShippingAddress: FC<Props> = ({ shippingData }) => {
  if (isNil(shippingData)) return null

  const { address, city, country, firstName, lastName, postalCode, provinceCode } = shippingData

  return (
    <Container className='column'>
      <Text
        weight='bold'
        size='body'
        style={{ display: 'flex', alignSelf: 'flex-start', marginBottom: '8px' }}
      >
        Shipping Address
      </Text>

      <Container className='column borderless fit_content no_padding'>
        <Text size='body'>{`${capitalise(firstName)} ${capitalise(lastName)}`}</Text>
        <Text size='body' style={{ textAlign: 'right' }}>
          {address.toUpperCase()}
        </Text>
        <Text size='body' style={{ textAlign: 'right' }}>
          {`${city.toUpperCase()}, ${provinceCode} ${postalCode}`}
        </Text>
        <Text size='body'>{country.toUpperCase()}</Text>
      </Container>
    </Container>
  )
}
