import { LineLoader, Text } from '@ubnt/ui-components'
import { FC, useEffect, useState } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import * as yup from 'yup'

import config from 'config'
import { useDebounce } from 'hooks'

interface CheckPasswordResponse {
  is_acceptable_password: boolean
  guesses_log10: number
  score: number
  warning: string
  suggestions: string[]
}

export const checkPasswordApi = async (password: string, user_inputs?: string[]) => {
  const headers = { 'Content-Type': 'application/json' }
  const response = await axios.post<CheckPasswordResponse>(
    `${config.SSO_BASE}api/sso/v1/password-strength`,
    {
      password,
      user_inputs,
    },
    { headers },
  )
  return response.data
}

export const passwordScoreText = (passwordScore: number) => {
  switch (passwordScore) {
    case 0:
      return 'very weak'
    case 1:
      return 'weak'
    case 2:
      return 'intermediate'
    case 3:
      return 'strong'
    case 4:
      return 'very strong'
    default:
      return null
  }
}

export const passwordScoreColor = (passwordScore: number) => {
  switch (passwordScore) {
    case 0:
    case 1:
      return '#F03A3E'
    case 2:
      return '#F5A623'
    case 3:
      return '#39CC64'
    case 4:
      return '#006FFF'
    default:
      return null
  }
}

export const passwordScoreBarWidth = (passwordScore: number) => {
  if (passwordScore === 0) return 1
  return (passwordScore / 4) * 100
}

interface Props {
  password?: string
  userInputs?: string[]
}

export const PasswordFeedback: FC<Props> = ({ password, userInputs }) => {
  const [passwordData, setPasswordData] = useState<any | null>(null)
  const [passwordCheckLoading, setPasswordCheckLoading] = useState(false)

  const checkPassword = (password: string) => {
    const validator = yup.string().min(8)
    if (!password || !validator.isValidSync(password)) return

    checkPasswordApi(encodeURIComponent(password), userInputs)
      .then(({ is_acceptable_password, suggestions, warning, score }) => {
        setPasswordCheckLoading(false)
        warning && suggestions.unshift(warning)
        setPasswordData({
          is_acceptable_password: is_acceptable_password ?? null,
          suggestions: suggestions ?? null,
          score: score ?? null,
          barWidth: score !== undefined ? passwordScoreBarWidth(score) : null,
          barColor: score !== undefined ? passwordScoreColor(score) : null,
          text: score !== undefined ? passwordScoreText(score) : null,
          error: null,
        })
      })
      .catch((e) => {
        setPasswordCheckLoading(false)
        setPasswordData({
          is_acceptable_password: null,
          suggestions: null,
          score: null,
          barWidth: null,
          barColor: null,
          text: null,
          error: e.response?.data?.detail,
        })
      })
  }

  const debouncedCheckPassword = useDebounce(checkPassword, 300)

  useEffect(() => {
    if (password && password.length > 7) {
      setPasswordCheckLoading(true)
      debouncedCheckPassword(password, userInputs)
    }
  }, [password])

  return (
    <PasswordFeedbackContainer>
      {passwordCheckLoading && (
        <LineLoaderContainer>
          <LineLoader isLoading={true} inline={true} />
        </LineLoaderContainer>
      )}
      <PasswordFeedbackWrapper visible={!passwordCheckLoading}>
        {passwordData?.text && password && password?.length > 7 && (
          <PasswordScoreContainer>
            <PasswordScoreBarContainer>
              <PasswordScoreBar
                barColor={passwordData?.barColor}
                barWidth={passwordData?.barWidth}
              />
            </PasswordScoreBarContainer>
            <PasswordScoreText barColor={passwordData?.barColor}>
              <Text color={passwordData?.barColor} weight='light'>
                {'Password is '}
                <Text color={passwordData?.barColor} weight='bold'>{`${passwordData?.text}`}</Text>
              </Text>
            </PasswordScoreText>
          </PasswordScoreContainer>
        )}
        <ErrorMessageDiv>
          <StyledUL>
            {passwordData?.error
              ? passwordData?.error
              : passwordData?.suggestions?.map((suggestion: string, i: number) => (
                  <li key={i}>{suggestion}</li>
                ))}
          </StyledUL>
        </ErrorMessageDiv>
      </PasswordFeedbackWrapper>
    </PasswordFeedbackContainer>
  )
}

const PasswordScoreBarContainer = styled.div`
  background-color: #ededf0;
  border-radius: 5px;
  width: 352px;
`

const PasswordScoreText = styled.div<{ barColor: string }>`
  display: flex;
  margin-top: 5px;
  margin-bottom: 2px;
  font-size: 12px;
  color: ${(props) => props.barColor};
`

const PasswordScoreBar = styled.div<{ barWidth: number; barColor: string }>`
  background-color: ${({ barColor }) => barColor};
  height: 5px;
  width: ${({ barWidth }) => `${barWidth}%`};
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: ${(p) => (p.barWidth === 100 ? 5 : 0)}px;
  border-bottom-right-radius: ${(p) => (p.barWidth === 100 ? 5 : 0)}px;
`

const ErrorMessageDiv = styled.div`
  margin: 15px 0;
  position: relative;
  font-size: 12px;
  color: #7e8190;
  line-height: 1.6;
`

const PasswordFeedbackContainer = styled.div`
  margin-top: 10px;
  width: 352px;
`

const PasswordFeedbackWrapper = styled.div<{ visible?: boolean }>`
  width: 352px;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`

const PasswordScoreContainer = styled.div`
  width: 100%;
  min-height: 30px;
`

const StyledUL = styled.ul`
  padding-inline-start: 20px;
`

const LineLoaderContainer = styled('div')`
  position: relative;
  width: 100%;
`
