import * as op from './operators'
import { handleAsyncAction } from 'store/operators'

import type { MakeStripeOptions } from './types'
import type { Stripe } from '@stripe/stripe-js'

const handleAction = handleAsyncAction('stripe')

export const makeStripe = handleAction<MakeStripeOptions, Stripe | null>({
  action: op.makeStripe,
  onError: () => null,
})
