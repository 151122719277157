import { Modal } from '@ubnt/ui-components/Modal'
import QuoteView from 'app/quotes/QuoteModal'
import { FC } from 'react'
import { useActions, useOverState } from 'store'

export const TopologyModal: FC = () => {
  const { modal, modalProps } = useOverState().modal
  const {
    modal: { closeModal, closeSidebar },
  } = useActions()

  const closeModalAndSidebar = () => {
    closeModal()
    closeSidebar()
  }

  return (
    <Modal
      className='modal-topology'
      title='Topology'
      isOpen={modal === 'topology'}
      onRequestClose={closeModalAndSidebar}
      size='full'
      height='large'
      shouldCloseOnOverlayClick={false}
      actions={[
        {
          text: 'Cancel',
          onClick: closeModal,
          variant: 'tertiary',
        },
      ]}
    >
      <QuoteView quoteId={modalProps.quoteId} />
    </Modal>
  )
}
