import { SideImg, Video } from '../styles'

import SetupImg from 'assets/forms/SetupAccount.png'
import StartVideo from 'assets/videos/Start.mp4'
import CameraVideo from 'assets/videos/Camera.mp4'
import DoorVideo from 'assets/videos/DoorAccess.mp4'
import PhoneVideo from 'assets/videos/Phone.mp4'
import WifiVideo from 'assets/videos/Wifi.mp4'

export const Start = () => <Video loop sources={[StartVideo]} />
export const Camera = () => <Video loop sources={[CameraVideo]} />
export const Door = () => <Video loop sources={[DoorVideo]} />
export const Phone = () => <Video loop sources={[PhoneVideo]} />
export const Wifi = () => <Video loop sources={[WifiVideo]} />
export const User = () => <SideImg src={SetupImg} alt='' />
