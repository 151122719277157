import { Text } from '@ubnt/ui-components'
import { InfoIconL } from '@ubnt/icons'
import styled from 'styled-components'

import { QuoteStatus } from 'shared'

import type { FC } from 'react'
import type { Quote } from 'store/quotes/types'

interface Props {
  currentQuote: Quote
  handleContactPro: () => void
  itemsOutOfStock: boolean
}

const CheckoutNotice: FC<Props> = ({ handleContactPro, currentQuote, itemsOutOfStock }) => {
  if (!itemsOutOfStock || currentQuote.status !== QuoteStatus.SENT) return null

  return (
    <Container>
      <CheckoutNotification>
        {itemsOutOfStock && (
          <Flex>
            <StyledInfoIcon variant='fill' />
            <Text>
              {true && 'Checkout is disabled as some of the items in your quote are out of stock.'}
            </Text>
          </Flex>
        )}
        <StyledText weight='bold' color='info' onClick={handleContactPro}>
          Contact your Professional
        </StyledText>
      </CheckoutNotification>
    </Container>
  )
}

export default CheckoutNotice

const CheckoutNotification = styled.section`
  display: flex;
  flex-direction: column;
  width: 580px;
  background-color: ${({ theme }) => theme.colors.Light.color.neutral.n2};
  border-left: 2px solid orange;
  border-radius: 4px;
  padding: 16px;
`

const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 10px;
  left: 10px;
  z-index: 1;
`

const StyledInfoIcon = styled(InfoIconL)`
  color: orange;
  margin-right: 10px;
`

const StyledText = styled(Text)`
  margin-top: 5px;
  margin-left: 26px;
  :hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.Light.color.blue.b4};
  }
`

const Flex = styled.div`
  display: flex;
`
