import { branch, parallel, pipe } from 'overmind'
import { isNil } from 'ramda'

import * as op from './operators'
import * as modal from 'store/modal/actions'
import * as storeOp from '../operators'
import { handleAsyncAction, toast as opToast, whenPropIs } from 'store/operators'
import { hasProItems, hasUiItems } from 'helpers'
import { SOCKET_EVENTS } from 'store/constants'

import {
  GetQuotesByProjectOptions,
  PostFeedbackOptions,
  Quote,
  QuoteListItem,
  WithQuote,
} from './types'
import type { WithOnSuccess, WithQuoteId } from 'store/types'
import type { Context } from 'store'
import type { PaymentType } from 'types'

const handleQuotesAction = handleAsyncAction('quotes')

export const dispute = handleQuotesAction(
  pipe(
    whenPropIs('disputeId', isNil, {
      true: op.dispute,
      false: op.updateDispute as any,
    }),
    modal.closeModal,
  ),
)

export const getQuotesByProject = handleQuotesAction<GetQuotesByProjectOptions, void>(
  op.getQuotesByProject,
)

export const resetCurrentQuote = ({ state }: Context) => {
  state.quotes.current = {
    hasProItems: false,
    hasUiItems: false,
    isStale: false,
    quote: null,
  }
}

export const setCurrentQuote = ({ state }: Context, { quote }: WithQuote) => {
  state.quotes.current = {
    hasProItems: hasProItems(quote),
    hasUiItems: hasUiItems(quote),
    isStale: false,
    quote,
  }
}

export const getQuote = handleQuotesAction<WithQuoteId, void>(
  pipe(op.getQuote, op.parseQuote, parallel(op.toastOnError, setCurrentQuote)),
)

export const setQuotes = ({ state }: Context, quotes: Record<string, QuoteListItem>) => {
  state.quotes.hash = quotes
}

export const postOrderFeedback = handleQuotesAction<PostFeedbackOptions, void>({
  action: pipe(
    branch(op.postOrderFeedback, modal.closeModal, opToast('Success', 'Feedback Successful')),
    op.getQuote,
    op.parseQuote,
    setCurrentQuote,
  ),
  errorTitle: 'Feedback error',
})

export const setCheckout = ({ state }: Context, value: PaymentType | null) => {
  state.quotes.checkout = value
}

export const setStale = op.setIsStale(true)

// Storefront Checkout Actions

export const getCheckoutUrl = handleQuotesAction<string, void>({
  before: op.setLoadingSection('ui-checkout'),
  action: op.getCheckoutUrl,
  after: op.setLoadingSection(null),
  errorTitle: 'Checkout is not possible at the moment',
  errorDescription: 'Please try again later',
})

const subscribeCheckout = ({ actions, effects, state }: Context, { quoteId }: WithQuoteId) => {
  effects.app.socket.subscribe(SOCKET_EVENTS.order.create(quoteId), (payload) => {
    actions.quotes.resetCheckoutUrl()
    actions.quotes.resetStoreInCheckout()

    if (payload.status === 'success') {
      actions.quotes //
        .getQuote({ quoteId })
        .then(() => {
          const quote = state.quotes.current.quote as Quote

          return actions.modal.setModal({
            modal: 'feedback',
            props: {
              message: 'Rate Your Professional',
              order: quote,
              quoteId: quote.id,
              title: 'Your Project is Completed',
            },
          })
        })
      actions.quotes.unsubscribeCheckout({ quoteId })
    } else {
      actions.app.toast({
        title: 'Checkout error',
        message: 'Please try again later or contact your system admin for assistance.',
        type: 'danger',
      })
    }
  })
}

export const initiateCheckout = handleQuotesAction<{ quoteId: string } & WithOnSuccess>({
  action: pipe(branch(parallel(op.initiateCheckout, subscribeCheckout)), storeOp.runOnSuccess()),
  onError: ({ state }: Context) => (state.quotes.storeCheckout.inCheckout = false),
})

export const resetCheckoutUrl = ({ state }: Context) => {
  state.quotes.storeCheckout.url = null
}

export const resetStoreInCheckout = ({ state }: Context) => {
  state.quotes.storeCheckout.inCheckout = false
}

export const unsubscribeCheckout = ({ actions, effects }: Context, { quoteId }: WithQuoteId) => {
  effects.app.socket.unsubscribe(
    SOCKET_EVENTS.order.create(quoteId),
    actions.quotes.resetCheckoutUrl,
  )
}
