import { always, cond, propEq, T, whereEq } from 'ramda'

import { QuoteStatus } from 'shared'
import { QuoteSteps, QUOTE_STEPS } from './constants'

import type { MakeStepsOptions, QuoteCheck } from './types'
import type { Step } from 'components/StepProgress/types'

export const makeQuoteStep = ({ hasProItems, quote }: QuoteCheck): QuoteSteps => {
  if (quote.status === QuoteStatus.SENT) return QuoteSteps.SUMMARY
  if (quote.status === QuoteStatus.PENDING_FULFILMENT && hasProItems) return QuoteSteps.REVIEW
  if (quote.status === QuoteStatus.PARTIALLY_COMPLETED) return QuoteSteps.REVIEW
  if (quote.status === QuoteStatus.FINAL_PAYMENT_PENDING) return QuoteSteps.CONFIRM
  return QuoteSteps.COMPLETED
}

export const makeSteps = cond<[MakeStepsOptions], Step[]>([
  [whereEq({ hasProItems: true, hasUiItems: true }), always(QUOTE_STEPS)],
  [propEq(true, 'hasProItems'), always([QUOTE_STEPS[0], QUOTE_STEPS[2], QUOTE_STEPS[3]])],
  [propEq(true, 'hasUiItems'), always([QUOTE_STEPS[0], QUOTE_STEPS[3]])],
  [T, always([])],
])
