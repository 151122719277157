import styled from 'styled-components'
import { BubbleContainer as UIBubbleContaier } from '@ubnt/ui-components/BubbleContainer'
import { Text as UIText } from '@ubnt/ui-components/Text'

import { ReactComponent as Checkmark } from 'assets/icons/Checkmark.svg'

export const BubbleContainer = styled(UIBubbleContaier)`
  display: flex;
  flex-direction: column;

  padding: 16px;
  margin-top: 8px;
  margin-bottom: 25px;
`

export const BubbleHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-radius: 16px;
  margin-bottom: 12px;
`

export const CheckIcon = styled(Checkmark)`
  margin-bottom: 16px;
`

export const AvatarWrapper = styled.div<{ avatar: string | null }>`
  border-radius: 50%;
  ${({ avatar, theme }) =>
    !avatar ? `border: 1px solid ${theme.colors.Light.color.blue.b6}` : ''};
`

export const Text = styled(UIText)`
  line-height: 24px;
  margin-bottom: 8px;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > div:last-child {
    align-self: flex-end;
  }
`
