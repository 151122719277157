import styled from 'styled-components'

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`

export const AddressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 16px;
`

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`

export const InputWrapper = styled.div<{ error?: boolean }>`
  margin-bottom: 16px;
  ${({ error }) => (error ? 'margin-bottom: 32px;' : '')}
`

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 96%;
`
export const ButtonWrapper = styled.div`
  font-size: 10px;
  margin: 3px 0 0 1px;
`
