import { BoxIcon, PaymentMethodIconL } from '@ubnt/icons'
import { Icon } from '@ubnt/ui-components'
import styled from 'styled-components'

export const tabs = (tab: string) => [
  {
    id: 'payment',
    label: (
      <>
        <Icon width={24} height={24}>
          <StyledPaymentMethodIconL
            selected={tab === 'payment'}
            variant={tab === 'payment' ? 'fill' : 'twoTone'}
          />
        </Icon>
        Payment Details
      </>
    ),
  },
  {
    id: 'shipping',
    label: (
      <>
        <Icon width={24} height={24}>
          <StyledBoxIcon
            selected={tab === 'shipping'}
            variant={tab === 'shipping' ? 'fill' : 'twoTone'}
          />
        </Icon>
        Tracking
      </>
    ),
  },
]

const StyledPaymentMethodIconL = styled(PaymentMethodIconL)<{ selected: boolean }>`
  .filled_payment {
    fill: ${({ selected }) => (selected ? 'isActive' : 'transparent')};
  }
`

const StyledBoxIcon = styled(BoxIcon)<{ selected: boolean }>`
  .filled_shipping {
    fill: ${({ selected }) => (selected ? 'isActive' : 'transparent')};
  }
`
