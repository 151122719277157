import { ZoomOutIconL, MinusPrimaryIconL, AddPrimaryIconL } from '@ubnt/icons'
import { Button } from '@ubnt/ui-components'
import React from 'react'
import styled from 'styled-components'

const ZoomControlsWrapper = styled('div')`
  /* position: fixed;
  bottom: 36px;
  margin-left: 32px; */

  .fitToScreenButton {
    border-radius: 4px;

    /* &:hover {
      box-shadow: 0px 4px 12px 0px rgb(0 0 0 / 15%);
    } */
  }

  Button {
    border: 1px solid ${({ theme }) => theme.colors.Light.color.neutral.n4};
    width: 36px;
    height: 36px;
    color: ${({ theme }) => theme.colors.Light.color.blue.b6};
    background-color: ${({ theme }) => theme.colors.Light.color.neutral.n0};

    &:focus {
      color: ${({ theme }) => theme.colors.Light.color.neutral.n10};
    }
  }

  .plus Button {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .customGroup {
    border-radius: 4px;
    margin-top: 16px;
    position: relative;

    &:hover {
      //box-shadow: 0px 4px 12px 0px rgb(0 0 0 / 15%);

      &:after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        width: 100%;
        height: 1px;
        transform: translateY(-1px);
      }
    }
  }

  .minus Button {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: none;
  }
`

interface ZoomControlProps {
  onZoomInClick: () => void
  onZoomOutClick: () => void
  onResetZoomClick: () => void
}

const ZoomControls: React.FC<ZoomControlProps> = ({
  onZoomInClick,
  onZoomOutClick,
  onResetZoomClick,
}) => {
  return (
    <ZoomControlsWrapper>
      <Button
        variant='secondary'
        Icon={ZoomOutIconL}
        wrapClassName='fitToScreenButton'
        onClick={onResetZoomClick}
      />
      <div className='customGroup'>
        <Button
          variant='secondary'
          Icon={AddPrimaryIconL}
          wrapClassName='plus'
          onClick={onZoomInClick}
        />
        <Button
          variant='secondary'
          Icon={MinusPrimaryIconL}
          wrapClassName='minus'
          onClick={onZoomOutClick}
        />
      </div>
    </ZoomControlsWrapper>
  )
}

export default ZoomControls
