import { useActions, useOverState } from 'store'
import { FlexColumn } from 'components'
import { CheckIcon, FailIcon, Modal } from './styles'
import texts from './texts.json'

import type { FC } from 'react'
import type { ModalType, OrderModal } from 'store/modal/types'

// Seems to be unused. Delete?
export const OrderPlaced: FC = () => {
  const { modalProps } = useOverState().modal as ModalType<OrderModal>
  const { quotes } = useOverState()
  const actions = useActions()

  const quoteId = quotes.current.quote?.id
  const [Icon, title] = modalProps.success
    ? [CheckIcon, texts.ui.message, texts[modalProps.type].title]
    : modalProps.version
    ? [() => null, texts.version.message, texts.version.title]
    : [
        FailIcon,
        modalProps.contact ? texts.fail.contactMessage : texts.fail.message,
        texts.fail.title,
      ]

  const handleClose =
    quoteId && modalProps.version
      ? () => {
          actions.modal.closeModal()
          actions.payment.refetch({ quoteId })
        }
      : actions.modal.closeModal

  return (
    <Modal
      footerClassName='order-placed__footer'
      isOpen
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={false}
      size='small'
      title={title}
      actions={[
        {
          onClick: handleClose,
          text: modalProps.version ? texts.version.button : texts[modalProps.type].button,
          variant: 'primary',
        },
      ]}
    >
      <FlexColumn alignItems='center'>
        <Icon />
        {/* {(modalProps.type === 'ui' || !modalProps.success) && <Message size='body'>{text}</Message>} */}
      </FlexColumn>
    </Modal>
  )
}
