import { FC, Suspense } from 'react'
import { ErrorBoundary } from '@sentry/react'
import styled from 'styled-components'
import { Route, Routes } from 'react-router-dom'

import { ErrorComponent, FullscreenLoader } from 'components'

export const PublicSite: FC = ({ children }) => {
  return (
    <Content>
      <ErrorBoundary
        beforeCapture={(scope) => scope.setTag('context', 'public site routes')}
        fallback={(props) => <ErrorComponent inRouter {...props} />}
      >
        <Suspense fallback={<FullscreenLoader />}>
          <Routes>
            <Route path='/' element={children} />
          </Routes>
        </Suspense>
      </ErrorBoundary>
    </Content>
  )
}

export default PublicSite

const Content = styled.main`
  height: 100%;
  width: 100%;
`
