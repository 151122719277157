// @ts-nocheck
import { detect } from 'detect-browser'

interface BrowserInformation {
  name: string
  version: string
  os: string
}

interface Browser {
  name: BrowserInformation['name']
  version: BrowserInformation['version']
  os: BrowserInformation['os']
  isLinux: boolean
  isMacOS: boolean
  isWindows: boolean
  isBrowserDark: () => boolean
  isBrowserLight: () => boolean
  isBrowserNoPreference: () => boolean
  isSupported: () => boolean
  isSafari: boolean
}

const browserInformation: BrowserInformation = detect()

export const browser: Browser = {
  name: browserInformation?.name,
  version: browserInformation?.version,
  os: browserInformation?.os,
  isLinux: browserInformation?.os?.toLowerCase() === 'linux',
  isMacOS: browserInformation?.os?.toLowerCase() === 'mac os',
  isWindows: browserInformation?.os?.toLowerCase()?.includes('windows'),
  isBrowserDark: () => window.matchMedia('(prefers-color-scheme: dark)').matches,
  isBrowserLight: () => window.matchMedia('(prefers-color-scheme: light)').matches,
  isBrowserNoPreference: () => window.matchMedia('(prefers-color-scheme: no-preference)').matches,
  isSupported() {
    return this.isBrowserDark() || this.isBrowserLight() || this.isBrowserNoPreference()
  },
  isSafari: browserInformation?.name?.toLowerCase() === 'safari',
}
