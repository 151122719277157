import { FC, useContext, useEffect, useMemo, useState } from 'react'

import { useActions, useOverState } from 'store'
import { ProjectFormContext } from 'components'
import { parseProjectUnits } from './helpers'
import { formMap } from './constants'
import { AsyncFuncState } from 'shared'

import type { ModalType, ProjectSidebar as SidebarType } from 'store/modal/types'
import type { UpdateProject } from 'store/projects/types'
import type { SidebarFormProps } from './types'

export const SidebarForm: FC<SidebarFormProps> = ({
  closeSlider,
  project: initProject,
  selectedTab,
  tabs,
  updateSelectedTab,
}) => {
  const { sidebarProps } = useOverState().modal as ModalType<unknown, SidebarType>
  const { projects } = useOverState()
  const { projects: actions } = useActions()

  const projectId = sidebarProps.projectId
  const formContext = useContext(ProjectFormContext)

  const [project, setProject] = useState<UpdateProject>(
    parseProjectUnits(formContext.toDisplay, initProject),
  )

  useEffect(() => {
    if (projects.current) setProject(parseProjectUnits(formContext.toDisplay, projects.current))
  }, [formContext.toDisplay, projects.current])

  const handleNext = (next: boolean) => () => {
    const tabIndex = tabs.findIndex((x) => x.id === selectedTab)
    const nextTab = next && tabs[tabIndex + 1]

    const onSuccess = () => (nextTab ? updateSelectedTab(nextTab) : closeSlider())

    if (!formContext.editable) return onSuccess()

    const opts = {
      projectId,
      project: parseProjectUnits(formContext.toServer, project),
      onSuccess,
    }

    return actions.updateProject(opts)
  }

  const FormComponent = useMemo(() => formMap[selectedTab] || null, [selectedTab])

  return (
    <FormComponent
      handleNext={handleNext(false)}
      handlePrev={handleNext(true)}
      isLoading={projects.status === AsyncFuncState.LOADING}
      project={project}
      updateProject={setProject}
    />
  )
}
