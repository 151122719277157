import { FC, KeyboardEventHandler, useEffect, useState } from 'react'
import { Modal } from '@ubnt/ui-components/Modal'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'

import { useActions, useOverState } from 'store'
import { ExpiryDateInput, FlexForm, GoogleAddressAutoComplete } from 'components'
import { ControlledInput } from 'components/ControlledInput'
import { Row } from './styles'
import { useLoader } from 'hooks'
import { EMPTY_DATE } from './constants'
import { schema } from './schema'
import { emptyLocation } from 'shared'

import type { ExpiryDate, Location, WithPaymentType } from 'types'
import type { FormFields } from './types'

export const CardDetails: FC = () => {
  const { modal, payment } = useOverState()
  const actions = useActions()
  const [googleInput, setGoogleInput] = useState('')
  const [loader] = useLoader(payment.status)

  const props = modal.modalProps as WithPaymentType

  const { control, formState, ...methods } = useForm<FormFields>({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    defaultValues: {
      card: true,
      date: EMPTY_DATE,
      firstName: '',
      lastName: '',
      location: emptyLocation,
      phone: '',
    },
  })

  useEffect(() => {
    const { address } = payment.billing

    setGoogleInput(address.location.formatted.split(',')[0])
    methods.reset({
      ...methods.getValues(),
      ...address,
    })
  }, [payment.billing.address])

  const handleEnterKey: KeyboardEventHandler = (event) => {
    if (event.key === 'Enter') handleSubmit()
  }

  const handleExpiryChange = ([date, complete]: [ExpiryDate, boolean]) => {
    methods.setValue('card', complete)
    methods.setValue('date', date)
  }

  const handleLocationChange = (l: Location) => {
    methods.setValue('location.street', l.street ?? '')
    methods.setValue('location.streetNumber', l.streetNumber ?? '')
    methods.setValue('location.state', l.state ?? '')
    methods.setValue('location.city', l.city ?? '')
    methods.setValue('location.country', l.country)
    methods.setValue('location.formatted', l.formatted)
    methods.setValue('location.other', l.other ?? '')
    methods.setValue('location.zipCode', l.zipCode ?? '')
    setGoogleInput(l.formatted.split(',')[0])
  }

  const handleSubmit = methods.handleSubmit(({ card: _card, date, ...address }) =>
    actions.payment.updateCard({
      address,
      date,
      type: props.type,
    }),
  )

  return (
    <Modal
      isOpen
      onRequestClose={actions.modal.closeModal}
      shouldCloseOnOverlayClick={false}
      size='small'
      title='Update Billing Details'
      actions={[
        {
          onClick: actions.modal.closeModal,
          text: 'Cancel',
          variant: 'secondary',
        },
        {
          loader: loader(),
          onClick: handleSubmit,
          text: 'Submit',
          variant: 'primary',
        },
      ]}
    >
      <FlexForm>
        <Row>
          <ControlledInput
            autoComplete='off'
            control={control}
            invalid={formState.errors.firstName?.message}
            label='First Name'
            name='firstName'
            onKeyDown={handleEnterKey}
            size='caption'
            width='48%'
          />
          <ControlledInput
            autoComplete='off'
            control={control}
            invalid={formState.errors.lastName?.message}
            label='Last Name'
            name='lastName'
            onKeyDown={handleEnterKey}
            size='caption'
            width='48%'
          />
        </Row>
        <Row>
          <ControlledInput
            autoComplete='off'
            control={control}
            invalid={formState.errors.phone?.message}
            label='Phone'
            name='phone'
            onKeyDown={handleEnterKey}
            size='caption'
            width='100%'
          />
        </Row>
        <Row>
          <GoogleAddressAutoComplete
            label='Address'
            handleChange={handleLocationChange}
            invalid={
              formState.errors.location?.streetNumber?.message ||
              formState.errors.location?.street?.message
            }
            name='location'
            setValue={setGoogleInput}
            value={googleInput}
            width='100%'
          />
        </Row>
        <Row>
          <ControlledInput
            autoComplete='off'
            control={control}
            invalid={formState.errors.location?.other?.message}
            label='PO Box, Apartment, Unit, Suite, or Floor'
            name='location.other'
            onKeyDown={handleEnterKey}
            size='caption'
            width='100%'
          />
        </Row>
        <Row>
          <ControlledInput
            autoComplete='off'
            control={control}
            invalid={formState.errors.location?.zipCode?.message}
            label='Postal Code'
            name='location.zipCode'
            onKeyDown={handleEnterKey}
            size='caption'
            width='48%'
          />
          <ControlledInput
            autoComplete='off'
            control={control}
            invalid={formState.errors.location?.city?.message}
            label='City'
            name='location.city'
            onKeyDown={handleEnterKey}
            size='caption'
            width='48%'
          />
        </Row>
        <Row>
          <ControlledInput
            autoComplete='off'
            control={control}
            invalid={formState.errors.location?.state?.message}
            label='State'
            name='location.state'
            onKeyDown={handleEnterKey}
            size='caption'
            width='48%'
          />
          <ControlledInput
            autoComplete='off'
            control={control}
            invalid={formState.errors.location?.country?.message}
            label='Country'
            name='location.country'
            onKeyDown={handleEnterKey}
            size='caption'
            width='48%'
          />
        </Row>
        <Row>
          <ExpiryDateInput onChange={handleExpiryChange} width={70} />
        </Row>
      </FlexForm>
    </Modal>
  )
}
