import { FC } from 'react'
import styled from 'styled-components'

import { Text } from '@ubnt/ui-components/Text'
import { Link } from 'components'

interface Props {
  company: string | null
  number: string | null
  url: string | null
}

export const TrackingLink: FC<Props> = ({ company, number, url }) => {
  if (!url) return null

  return (
    <Container>
      {company && number && (
        <Text size='body'>
          {company.toUpperCase()}:{' '}
          <Link href={url} target='_blank' rel='noopener noreferrer'>
            {number}
          </Link>
        </Text>
      )}

      <Text size='body'>
        {!company && number && (
          <Link href={url} target='_blank' rel='noopener noreferrer'>
            {number}
          </Link>
        )}

        {company && !number && (
          <Link href={url} target='_blank' rel='noopener noreferrer'>
            {company.toUpperCase()}
          </Link>
        )}
      </Text>
    </Container>
  )
}

const Container = styled.div`
  margin-bottom: 5px;
`
