import { FC, MouseEventHandler, useEffect } from 'react'
import styled from 'styled-components'

import { Button } from '@ubnt/ui-components/Button'
import { Table } from '@ubnt/ui-components/Table'
import { Text } from '@ubnt/ui-components/Text'
import { Tooltip } from '@ubnt/ui-components/Tooltip'
import { BubbleContainer, ConditionalTooltip, FlexColumn, FlexRow } from 'components'
import { checkHardware } from 'helpers'
import { Screen, useCheckout, useScreenWidth } from 'hooks'
import { AsyncFuncState, QuoteStatus, talkSubsPrice } from 'shared'
import { useActions, useOverState } from 'store'
import texts from '../../texts.json'
import { QuoteSteps } from '../constants'
import { makeQuoteStep } from '../helpers'
import { ItemsMobileView } from '../MobileView'
import { CancelledBadge } from './CancelledBadge'
import { hardwareColumns } from './columns'
import { makeRowClassName, makeUIHardwareRows, renderHardwareLabel } from './helpers'
import { PaidBadge } from './PaidBadge'
import { Accordion, HardwareIcon, InfoIcon, TopologyIcon } from './styles'

import type { HardwarePanelProps } from './types'

export const HardwarePanel: FC<HardwarePanelProps> = ({ quote }) => {
  const { quotes } = useOverState()
  const actions = useActions()
  const screenWidth = useScreenWidth()
  const initiateCheckout = useCheckout(quote.id)

  const columns = quote.hasBeenAdjusted.hardware
    ? hardwareColumns
    : hardwareColumns.filter((c) => c.id !== 'adjustment')
  const items = makeUIHardwareRows(quote)
  const itemsOutOfStock = !checkHardware(quote.hardwareList)
  const step = makeQuoteStep({ ...quotes.current, checkout: null, quote })
  const style = step === QuoteSteps.REVIEW || step === QuoteSteps.CONFIRM ? { order: 1 } : undefined

  const handleViewDeployment: MouseEventHandler<HTMLButtonElement> = () => {
    actions.modal.setModal({
      modal: 'topology',
      props: { quoteId: quote.id, initiateCheckout },
    })
  }

  const handleClickSubs = () => {
    actions.modal.setSidebar({ sidebar: 'subscriptions', props: null })
  }

  const talkCost = quote.productVariantQuotes.Talk.reduce(
    (acc, curr) => acc + curr.talkSubsQty * talkSubsPrice,
    0,
  )

  useEffect(() => {
    const { quote } = quotes.current
    if (quote && quote.status === QuoteStatus.SENT && !quote.orderId && !itemsOutOfStock) {
      actions.quotes.getCheckoutUrl(quote.id)
    }
  }, [itemsOutOfStock])

  const renderSubscriptions = (
    <SubScriptionsContainer>
      <Text size='body' weight='bold'>
        Subscriptions
      </Text>
      <CostWrapper>
        <Text size='body'>$ {`${talkCost}`}/Month</Text>
        <Button onClick={handleClickSubs} size='small' variant='inline'>
          View More
        </Button>
      </CostWrapper>
    </SubScriptionsContainer>
  )

  const isLoading =
    quotes.loadingSection === 'ui-checkout' && quotes.status === AsyncFuncState.LOADING
  const isDisabled = itemsOutOfStock || isLoading || quotes.storeCheckout.inCheckout

  return (
    <BubbleContainer
      border={false}
      style={style}
      variant='secondary'
      verticalSpacing={screenWidth < Screen.PC ? 8 : undefined}
    >
      <FlexRow alignItems='stretch' grow={1} justify='space-between'>
        <FlexColumn justify='center' style={{ width: screenWidth < Screen.TABLET ? '131px' : '' }}>
          <HardwareIcon isActive size='large' variant='fill' />
          <FlexRow>
            <Text size='header-xs' weight='bold' color='primary'>
              <Tooltip
                description={<Text>{texts.hardwarePanel.tooltip}</Text>}
                position='bottom'
                width={208}
              >
                {texts.hardwarePanel.title}
                <InfoIcon />
              </Tooltip>
            </Text>
            {step > QuoteSteps.SUMMARY && !quote.cancelled && <PaidBadge />}
            {step > QuoteSteps.SUMMARY && quote.cancelled && <CancelledBadge />}
          </FlexRow>
        </FlexColumn>

        <FlexColumn alignItems='flex-end'>
          <FlexRow>
            {/* Style button for hover */}
            {screenWidth > Screen.TABLET && (
              <Button
                style={{ marginRight: 12 }}
                variant='secondary'
                onClick={handleViewDeployment}
              >
                <TopologyIcon />
                {texts.hardwarePanel.buttons.deployment}
              </Button>
            )}
            {step === QuoteSteps.SUMMARY && (
              <ConditionalTooltip
                show={itemsOutOfStock}
                description={texts.checkoutButtons.tooltip.outOfStock}
                position='bottom'
                width='200px'
              >
                <Button //
                  variant='primary'
                  disabled={isDisabled}
                  loader={isLoading || quotes.storeCheckout.inCheckout ? 'loading' : undefined}
                  onClick={initiateCheckout}
                >
                  {texts.hardwarePanel.buttons.buy}
                </Button>
              </ConditionalTooltip>
            )}
          </FlexRow>
        </FlexColumn>
      </FlexRow>
      <Accordion
        items={[
          {
            id: 'hardware',
            openByDefault: false,
            renderContent: () =>
              screenWidth > Screen.TABLET ? (
                <Table
                  columns={columns}
                  disableColumnFilters
                  disableSelection
                  disableSort
                  items={items}
                  rowClassName={makeRowClassName}
                  rowHeight={48}
                />
              ) : (
                <ItemsMobileView items={quote.hardwareList} />
              ),

            renderLabel: renderHardwareLabel('hardware', quote),
          },
        ]}
        variant='secondary'
      />
      {talkCost > 0 && renderSubscriptions}
    </BubbleContainer>
  )
}

const SubScriptionsContainer = styled.section`
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  border-top: ${({ theme }) => `1px solid ${theme.borderColor}`};
`

const CostWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 34px;

  .text:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.Light.color.blue.b7};
  }
`
