import type { Context } from 'store'
import type { SetModal, SetSidebar } from './types'

export const closeModal = ({ state }: Context) => {
  state.modal.modal = null
  state.modal.modalProps = {}
}

export const setModal = ({ state }: Context, { modal, props }: SetModal) => {
  state.modal.modal = modal
  state.modal.modalProps = { ...props }
}

export const closeSidebar = ({ state }: Context) => {
  state.modal.sidebar = null
  state.modal.sidebarProps = null
}

export const setSidebar = ({ state }: Context, { sidebar, props }: SetSidebar) => {
  state.modal.sidebar = sidebar
  state.modal.sidebarProps = props
}
