import styled from 'styled-components'

export const ErrorLeft = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-grow: 1;

  height: 170px;
  margin: 0px 85px;
`

export const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;

  height: 100%;

  .error-image {
    z-index: 600;

    height: 100%;
    max-width: 60%;
    width: auto;
  }
`
