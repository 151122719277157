import { ReactComponent as HomeIcon } from 'assets/icons/Home.svg'
import { ReactComponent as HotelIcon } from 'assets/icons/Hotel.svg'
import { ReactComponent as OfficeIcon } from 'assets/icons/Office.svg'
import { ReactComponent as OtherIcon } from 'assets/icons/Other.svg'
import { ReactComponent as SchoolIcon } from 'assets/icons/School.svg'
import { ReactComponent as StadiumIcon } from 'assets/icons/Stadium.svg'

import type { FC, SVGProps } from 'react'
import { BuildingType, ProjectName } from 'store/projects/types'

const BUILDING_TYPE_DICT: Record<BuildingType, FC<SVGProps<SVGSVGElement>>> = {
  [BuildingType.HOME]: HomeIcon,
  [BuildingType.HOTEL]: HotelIcon,
  [BuildingType.OFFICE]: OfficeIcon,
  [BuildingType.OTHER]: OtherIcon,
  [BuildingType.SCHOOL]: SchoolIcon,
  [BuildingType.STADIUM]: StadiumIcon,
}

const typeToIcon = (type: BuildingType) => BUILDING_TYPE_DICT[type]

export const parseProject = (x: ProjectName) => {
  const Icon = typeToIcon(x.buildingType)

  return {
    id: x.id,
    label: x.name,
    icon: <Icon />,
  }
}
