import { FC, useEffect } from 'react'
import { Table } from '@ubnt/ui-components/Table'
import styled from 'styled-components'

import { ChangePage } from 'components'
import { COLUMNS } from './columns'
import { Pages } from 'shared'
import { useActions, useOverState } from 'store'

import type { User } from 'types'

export const ProjectPermissions: FC = () => {
  const { getInvitedUsers } = useActions().client
  const clientId = useOverState().app.user?.clientId
  const { users } = useOverState().client

  useEffect(() => {
    if (clientId) getInvitedUsers({ clientId })
  }, [])

  return (
    <Wrapper>
      <Table
        columns={COLUMNS}
        items={users as (User & { id: string })[]}
        initialSortBy='firstName'
        disableColumnFilters
        rowHeight={48}
        rowClassName='table_row'
        initiallySortAscending
      />
      <ChangePage to={Pages.PROJECT_PERMISSION} />
    </Wrapper>
  )
}

const Wrapper = styled.main`
  padding: 32px;

  .table_row:hover .avatar {
    border: ${({ theme }) => `1px solid ${theme.colors.Light.color.blue.b6}`};

    img {
      padding: 1px;
    }
  }
`
