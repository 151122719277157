import React, { useMemo, useRef } from 'react'
import { hierarchy } from 'd3-hierarchy'
import { isEmpty, omit, pipe } from 'ramda'
import styled from 'styled-components'

import { Categories } from 'app/quotes/QuoteModal/constants'
import { MEDIA } from 'styles/constants'
import Tree, { center, getTreeLayout } from './Tree'
import { TreeDatum } from '../models/topology/types'
import useSize from '../useSize'

import type { Hardware, Product } from 'store/quotes/types'

const MIN_WIDTH = 101
const EXTENT = 600
const DEPTH_THRESHOLD = 5
const NODE_HEIGHT = 80
const SEPARATION = { siblings: 2, nonSiblings: 2 }

const StyledTopologyContainer = styled('div')`
  width: 100%;
  height: 100%;
  position: relative;

  @media ${MEDIA.TABLET} {
    display: none;
  }
`

interface TopologyTreeProps {
  topologyData: Hardware
  width: number
  height: number
}

const otherCategories = (array: Product[]) =>
  array.map((g) => ({
    name: g.title,
    id: g.id,
    category: g.type,
    quantity: g.adjusted.qty ?? g.quantity,
    thumbnail: g.thumbnail,
  }))

const mapNodes = (array: Product[], array2: Product[]) => {
  const children = { children: [...otherCategories(array2)] }
  const placement = Math.round(array.length / 2)
  return array.map((g, i, arr) => {
    const node = {
      name: g.title,
      id: g.id,
      category: g.type,
      quantity: g.adjusted.qty ?? g.quantity,
      thumbnail: g.thumbnail,
    }
    if (i === placement || arr.length === 1) {
      return {
        ...node,
        ...children,
      }
    }
    return { ...node }
  })
}

const placeholderNode = (category: string) => ({
  adjusted: {},
  backorderable: false,
  backordered: 0,
  id: category,
  inStock: true,
  name: category,
  price: 0,
  quantity: 0,
  shortDescription: '',
  talkSubsQty: 0,
  title: '',
  thumbnail: '',
  type: category as any,
  url: '',
  variantTitle: '',
})

const mapData = ({
  Routing = [placeholderNode('Routing')],
  Switching = [placeholderNode('Switching')],
  Wireless = [],
  Access = [],
  Protect = [],
  Cabling = [],
  Talk = [],
  Other = [],
}: Partial<Hardware>) => {
  const restCategories = [...Wireless, ...Access, ...Protect, ...Cabling, ...Talk, ...Other]
  return {
    id: 'internet',
    category: 'Internet',
    name: 'Internet',
    thumbnail: '',
    children: Routing.map((g, i, arr) => {
      const node = {
        name: g.title,
        id: g.id,
        category: g.type,
        quantity: g.adjusted.qty ?? g.quantity,
        thumbnail: g.thumbnail,
      }
      const children = { children: mapNodes(Switching, restCategories) }
      const placement = Math.round(arr.length / 2)
      if (i === placement || arr.length === 1) {
        return {
          ...node,
          ...children,
        }
      }
      return { ...node }
    }),
  }
}

const removeEmpties = (topologyData: Partial<Hardware>) =>
  Object.entries(topologyData).reduce(
    (acc: Partial<Hardware>, [category, products]) =>
      isEmpty(products) ? acc : { ...acc, [category]: products },
    {},
  )

const treeData = pipe(omit([Categories.OTHER]), removeEmpties, mapData)

const TopologyTree: React.FC<TopologyTreeProps> = ({ topologyData, width, height }) => {
  const { nodeWidth, nodeHeight, transform } = useMemo(() => {
    const root = hierarchy<TreeDatum>(treeData(topologyData))
    //const rootHeight = root.data.type === 'invisibleNodeType' ? root.height - 1 : root.height
    const rootHeight = root.height
    // this is how node width was calculated in the angular topology

    const legacyNodeWidth = MIN_WIDTH + EXTENT / Math.max(Math.min(rootHeight, DEPTH_THRESHOLD), 1)

    // In this version, because the link's description is not curved along the link's path
    // we must measure the longest label and use it as a reference for the node width.
    // This is the only way we can guarantee that the curve of link won't overlap the
    // label.
    const nodeWidth = legacyNodeWidth
    const treeRoot = getTreeLayout({ x: nodeWidth, y: NODE_HEIGHT }, SEPARATION)(root)
    const nodes = treeRoot.descendants()
    const transform = center(width, height, nodes)

    return {
      nodeWidth,
      nodeHeight: NODE_HEIGHT,
      rootHeight,
      transform,
    }
  }, [topologyData, width, height])

  return (
    <Tree
      scaleExtent={{ min: 0.3, max: 2.5 }}
      data={treeData(topologyData)}
      separation={SEPARATION}
      containerSize={{ width, height }}
      transitionDuration={300}
      transform={transform}
      nodeSize={{ x: nodeWidth, y: nodeHeight }}
    />
  )
}

interface Size {
  width: null | number
  height: null | number
}

interface TopologyViewProps {
  topologyData: Hardware
}

const TopologyView: React.FC<TopologyViewProps> = ({ topologyData, children }) => {
  const topologyContainer = useRef(null)
  const { width, height }: Size = useSize(topologyContainer)

  return (
    <>
      <StyledTopologyContainer ref={topologyContainer}>
        {children}
        {topologyContainer && width && height && (
          <TopologyTree width={width} height={height} topologyData={topologyData} />
        )}
      </StyledTopologyContainer>
    </>
  )
}

export default TopologyView
