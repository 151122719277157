import { FC, useEffect } from 'react'
import { Checkbox } from '@ubnt/ui-components/Checkbox'
import { Loader } from '@ubnt/ui-components'

import { useOverState } from 'store'
import { BoxText } from './BoxText'
import { send } from './helpers'

import type { BoxInnerProps } from './types'

export const Box: FC<BoxInnerProps> = ({ data, map, seen, setSeen, status, ...props }) => {
  const { app } = useOverState()

  useEffect(() => {
    if (!seen && status === 'idle' && data && app.user) {
      setSeen(true)
      send('visited', data, app.user.email, app.user.ssoId)()
    }
  }, [app.user, data, seen, status])

  if (status === 'loading') return <Loader />
  if (!data) return null

  return (
    <Checkbox {...props}>
      <BoxText data={data} map={map} />
    </Checkbox>
  )
}
