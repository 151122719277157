import styled from 'styled-components'

import type { ProductSectionProps } from './types'

export const ProductSection = styled.section<ProductSectionProps>`
  border-bottom: ${({ border, theme }) =>
    border ? `1px solid ${theme.colors.Light.color.neutral.n3}` : 'none'};
  margin-bottom: ${({ border }) => (border ? 24 : 0)}px;
  padding-bottom: ${({ border }) => (border ? 24 : 0)}px;
`

export const SummaryWrapper = styled.section`
  background-color: ${({ theme }) => theme.colors.Light.color.neutral.n2};
  border: 1px solid ${({ theme }) => theme.colors.Light.color.neutral.n3};
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 16px;
`
