import { CameraForm, DoorForm, OverviewForm, VoIPForm, WiFiForm } from 'components'

import type { FC } from 'react'
import type { FormStepProps } from 'components/ProjectForm/types'
import type { UpdateProject } from 'store/projects/types'

export const buttonLabels = {
  next: 'Done',
  prev: 'Add More Details',
}

export const formMap: Record<string, FC<FormStepProps<UpdateProject>>> = {
  overview: OverviewForm,
  wifi: WiFiForm,
  camera: CameraForm,
  door: DoorForm,
  voIP: VoIPForm,
}
