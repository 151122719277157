import { prop } from 'ramda'
import styled from 'styled-components'

import type { LabelProps } from './types'

export const Form = styled.form`
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'card card'
    'expiry cvc'
    'checkbox checkbox'
    '. .';

  .flex-start {
    justify-content: flex-start;
  }
`

export const Label = styled.label<LabelProps>`
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
  grid-area: ${prop('area')};
`
