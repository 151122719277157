import { Text, Button as UIButton } from '@ubnt/ui-components'
import { MEDIA } from 'styles'
import styled from 'styled-components'

import error404 from '../../assets/forms/404.png'
import { errorTexts } from './Login'

interface Props {
  errorText: string
  handleLogin: () => void
}

export const LoginError = ({ errorText, handleLogin }: Props) => {
  const isUninvited = errorText === errorTexts.uninvited
  return (
    <Container>
      <StyledImg src={error404} alt='error' />
      <TextContainer>
        <Wrapper>
          <Title size='header-xl' weight='bold'>
            Access Denied
          </Title>
          <ErrorText size='body'>{errorText}</ErrorText>
          {!isUninvited && (
            <Button size='large' variant='primary' onClick={handleLogin}>
              Sign In with UI Account
            </Button>
          )}
        </Wrapper>
      </TextContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row-reverse;
  height: 100vh;

  @media ${MEDIA.MOBILE} {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
`

const StyledImg = styled.img`
  width: 57vw;
  max-height: 100vh;
  object-fit: cover;

  @media ${MEDIA.TABLET} {
    width: 45vw;
  }

  @media ${MEDIA.MOBILE} {
    max-height: 325px;
    object-fit: cover;
    width: 100vw;
  }
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  padding: 0 0.8rem;

  @media ${MEDIA.TABLET} {
    padding: 0.2rem;
  }

  @media ${MEDIA.MOBILE} {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    padding: 60px 32px;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100vh;

  @media ${MEDIA.MOBILE} {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`
const Title = styled(Text)`
  font-size: 38px;
  color: #242634;
`

const ErrorText = styled(Text)`
  margin: 20px 0 28px;

  @media ${MEDIA.TABLET} {
    max-width: 280px;
  }

  @media ${MEDIA.MOBILE} {
    width: 230px;
    color: ${({ theme }) => theme.colors.Light.color.neutral.n8};
    margin: 20px 0 28px;
    text-align: center;
  }
`

const Button = styled(UIButton)`
  width: 336px;

  @media ${MEDIA.TABLET} {
    width: 280px;
  }

  @media ${MEDIA.MOBILE} {
    width: 326px;
  }
`
