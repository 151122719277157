import { Text, TextProps } from '@ubnt/ui-components/Text'

import { FlexRow, OrderSummary } from 'components'
import { PriceBreakdown } from './PriceBreakdown'
import { toLocaleCurrency } from 'helpers'

import type { FC } from 'react'
import type { SummaryProps } from './types'

export const Summary: FC<SummaryProps> = ({ items, price, size = 'medium', title }) => {
  const textSize: TextProps['size'] =
    size === 'medium' //
      ? 'header-xs'
      : 'body'

  return (
    <>
      {title && (
        <Text size='body' weight='bold'>
          {title}
        </Text>
      )}
      <OrderSummary border items={items} size={size} />
      <PriceBreakdown price={price} size={size} />
      <FlexRow verticalSpacing={16}>
        <Text size={textSize} weight='bold'>
          Total
        </Text>
        <Text size={textSize} weight='bold'>
          {toLocaleCurrency(price.total)}
        </Text>
      </FlexRow>
    </>
  )
}
