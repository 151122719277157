import { AsyncFuncState } from 'shared'
import type { OrderDetailsState } from './types'

export const state: OrderDetailsState = {
  card: null,
  status: AsyncFuncState.SUCCESS,
  fulfillments: {
    fulfilled: [],
    unfulfilled: [],
  },
}
