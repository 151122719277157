import { FC, useEffect, useState } from 'react'
import { Text } from '@ubnt/ui-components/Text'

import { useActions, useOverState } from 'store'
import { ShippingForm, UpdateShipping } from 'components'
import { Radio } from './styles'

export const BillingAddress: FC = () => {
  const {
    payment: { billing, shipping },
  } = useOverState()
  const actions = useActions()
  const [error, setError] = useState(true)

  useEffect(() => {
    if (shipping.type === 'pro') actions.payment.setBillingType({ billing: 'other' })
  }, [shipping.type, billing.type])

  useEffect(() => {
    actions.payment.setCompleteBilling({
      complete: billing.type === 'shipping' || !error,
    })
  }, [error, billing.type])

  const updateAddress: UpdateShipping = (key, value) =>
    actions.payment.setBillingAddress({ address: { ...billing.address, [key]: value } })

  const renderClientShippingOpts = () => (
    <>
      <Radio
        id='billing-shipping'
        checked={billing.type === 'shipping'}
        onChange={() => actions.payment.setBillingType({ billing: 'shipping' })}
        value='shipping'
      >
        <Text size='body'>Use My Shipping Address</Text>
      </Radio>
      <Radio
        id='billing-other'
        checked={billing.type === 'other'}
        onChange={() => actions.payment.setBillingType({ billing: 'other' })}
        value='other'
      >
        <Text size='body'>Use Another Address for Billing</Text>
      </Radio>
      {billing.type === 'other' && (
        <ShippingForm handleError={setError} onChange={updateAddress} shipping={billing.address} />
      )}
    </>
  )

  const renderProShippingOpts = () => (
    <ShippingForm handleError={setError} onChange={updateAddress} shipping={billing.address} />
  )

  return shipping.type === 'customer' ? renderClientShippingOpts() : renderProShippingOpts()
}
