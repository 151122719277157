import { FC, useEffect } from 'react'
import { Text } from '@ubnt/ui-components/Text'
import { isNil } from 'ramda'
import { Container } from '../styles'
import { ShippingAddress } from '../ShippingAddress'
import { isNotEmpty } from 'helpers'
import { useActions, useOverState } from 'store'
import { Fulfillments } from './Fulfillments'
import { AsyncFuncState } from 'shared'
import { FullscreenLoader } from 'components'
import { QuoteListItem } from 'store/quotes/types'
import { getShippingStatus } from './helper'

interface Props {
  order: QuoteListItem['order']
}

export const Tracking: FC<Props> = ({ order }) => {
  const actions = useActions()
  const {
    fulfillments: { fulfilled, unfulfilled },
    status,
  } = useOverState().orderDetails

  useEffect(() => {
    if (order) actions.orderDetails.getFulfillments({ quoteId: order.quoteId })
  }, [order])

  if (isNil(order)) return null

  return (
    <Container className='column borderless no_padding'>
      <ShippingAddress shippingData={order.shipping} />

      <Container>
        <Text weight='bold' size='body'>
          Shipment Status
        </Text>
        <Text size='body'>{getShippingStatus(order.fulfillmentStatus)}</Text>
      </Container>

      {status === AsyncFuncState.LOADING ? (
        <FullscreenLoader />
      ) : (
        (isNotEmpty(fulfilled) || isNotEmpty(unfulfilled)) && (
          <Fulfillments fulfilled={fulfilled} unfulfilled={unfulfilled} />
        )
      )}
    </Container>
  )
}
