import styled from 'styled-components'
import { MEDIA } from 'styles/constants'

export const IconWrapper = styled.div`
  width: 20px;
  margin-right: 27px;

  @media ${MEDIA.MOBILE} {
    display: none;
  }
`

export const RatingWrapper = styled.div`
  display: flex;
  margin-left: 8px;

  @media ${MEDIA.MOBILE} {
    justify-content: center;
    margin-top: 10px;
    margin-left: 0;
  }
`
