import { FC, useCallback, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { useOutsideClick } from './useOutsideClick'

/**
 * useSlider is a hook to handle slide-in and slide-out animation
 * It takes a function to be executed after it has slid out
 * and optionally an options object
 *
 * It returns a react functional component, an open function and a close function
 * The component is the wrapper for whatever needs to be animated
 * The functions are the actions to trigger the slide-in and slide-out
 *
 * Use Example:
 *
 * const App = () => {
 *    const { Slider, closeSlider, openSlider } = useSlider(
 *      () => console.log('It\'s closed!'),
 *      { initiallyOpen: false }
 *    )
 *
 *    return (
 *      <>
 *        <Slider>
 *          <aside>Your JSX goes here</aside>
 *        </Slider>
 *        <main>
 *          <p>This is outside of the slider</p>
 *          <button onClick={openSlider}>Click me!</button>
 *      </main>
 *      </>
 *    )
 * }
 */

interface SliderOptions {
  disabled?: boolean
  duration?: number
  initiallyOpen?: boolean
}

interface SliderOutput {
  Slider: FC<Partial<InnerProps>>
  closeSlider: () => void
  openSlider: () => void
}

type UseSlider = (onClose: () => void, options?: SliderOptions) => SliderOutput

export const useSlider: UseSlider = (
  onClose,
  { disabled = false, duration = 500, initiallyOpen = true } = {},
) => {
  const [isIn, setIsIn] = useState(initiallyOpen)

  const closeSlider = useCallback(() => {
    if (disabled) return
    setIsIn(false)
    return setTimeout(onClose, duration)
  }, [onClose])

  const ref = useOutsideClick<HTMLDivElement>(closeSlider, { disabled })

  const openSlider = useCallback(() => setIsIn(true), [])

  const Slider: FC<Partial<InnerProps>> = useCallback(
    ({ children, width = 400 }) => {
      const Inner = isIn ? SlideIn : SlideOut

      return (
        <Overlay>
          <Outer ref={ref} width={width}>
            <Inner duration={duration} width={width}>
              {children}
            </Inner>
          </Outer>
        </Overlay>
      )
    },
    [duration, isIn],
  )

  return {
    Slider,
    closeSlider,
    openSlider,
  }
}

interface InnerProps {
  duration: number
  width: number
}

interface OuterProps {
  width: number
}

const slideInFrames = keyframes`
  100% { right: 0px; }
`

const slideOutFrames = (width: number) => keyframes`
  100% { right: -${width}px; }
`

const Overlay = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background-color: rgba(33, 33, 36, 0.4);
  z-index: 1000;
`

const Outer = styled.div<OuterProps>`
  position: absolute;
  right: 0px;
  height: 100%;
  width: ${({ width }) => width}px;
  overflow-x: hidden;
`

const SlideIn = styled.div.attrs<InnerProps>((props) => ({
  duration: props.duration / 1_000,
}))<InnerProps>`
  position: relative;
  right: -${({ width }) => width}px;
  width: ${({ width }) => width}px;
  height: 100%;
  -webkit-animation: ${slideInFrames} ${({ duration }) => duration}s forwards;
  animation: ${slideInFrames} ${({ duration }) => duration}s forwards;
`

const SlideOut = styled.div.attrs<InnerProps>((props) => ({
  duration: props.duration / 1_000,
}))<InnerProps>`
  position: relative;
  right: 0px;
  width: ${({ width }) => width}px;
  height: 100%;
  -webkit-animation: ${({ width }) => slideOutFrames(width)} ${({ duration }) => duration}s forwards;
  animation: ${({ width }) => slideOutFrames(width)} ${({ duration }) => duration}s forwards;
`
