import { reject, trim } from 'ramda'

import { Avatar } from './Avatar'

import type { User } from 'types'
import type { VFC } from 'react'

type BaseUser = Pick<User, 'firstName' | 'lastName' | 'username' | 'avatar'>

export const getUserName = (user: BaseUser) =>
  trim(`${user.firstName ?? ''} ${user.lastName ?? ''}`) || user.username

const getUserNameArray = (user: BaseUser) => {
  const realName = reject((name): name is undefined => !name, [user.firstName, user.lastName])
  return realName.length === 0 ? [user.username] : realName
}

interface UserAvatarProps {
  fallbackUrl?: string | null
  hideName?: boolean
  size?: 'small' | 'medium'
  user: BaseUser
}

export const UserAvatar: VFC<UserAvatarProps> = ({
  fallbackUrl = null,
  hideName,
  size = 'small',
  user,
}) => {
  const [imgSize, fontSize] = size === 'small' ? [24, 9] : [40, 12]

  return (
    <span className='cell-logo'>
      <Avatar
        name={getUserNameArray(user)}
        logoUrl={user.avatar ?? fallbackUrl}
        imgSize={imgSize}
        fontSize={fontSize}
        fontWeight='bold'
      />
      {!hideName && <span className='cell-logo-name'>{getUserName(user)}</span>}
    </span>
  )
}
