import { yupResolver } from '@hookform/resolvers/yup'
import { RadioList, Text, Button } from '@ubnt/ui-components'
import { RadioProps } from '@ubnt/ui-components/Radio/Radio'
import {
  ChangeEvent,
  FormEvent,
  useCallback,
  useContext,
  useMemo,
  useState,
  useEffect,
} from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import * as yup from 'yup'

import { BadgeButton, Footer, FormSection, GoogleAddressAutoComplete } from 'components'
import { locationSchema } from 'shared'
import { useActions } from 'store'
import { BuildingType, NewProject, ProjectSize, Size, UpdateProject } from 'store/projects/types'
import { ProjectFormContext } from './Context'
import { buildingSize, buildingType, RadioInput, radios } from './overviewFields'
import {
  Form,
  Input,
  InputContainer,
  InputExtra,
  InputHeader,
  InputSection,
  ButtonWrapper,
} from './styles'

import type { FormFields, FormStepProps } from './types'
import type { Location } from 'types'

const schema = yup.object().shape({
  name: yup.string().min(2).max(255).required(),
  location: locationSchema,
  buildingType: yup.string().oneOf(Object.values(BuildingType)).required(),
  buildingSize: yup.string().oneOf(Object.values(ProjectSize)).required(),
  userQty: yup.string().oneOf(Object.values(Size)).required(),
})

export const OverviewForm = <A extends NewProject | UpdateProject>({
  handleNext,
  handlePrev,
  isLoading = false,
  project,
  updateProject,
}: FormStepProps<A>) => {
  const {
    modal: { setModal },
  } = useActions()
  const { pathname } = useLocation()

  const [googleInput, setGoogleInput] = useState('')

  const formContext = useContext(ProjectFormContext)

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<FormFields>({
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    if (project.location) {
      setGoogleInput(project.location.formatted)
      setValue('location', project.location, { shouldValidate: false })
    }
  }, [])

  const inputWidth = formContext.location === 'form' ? '520px' : 'auto'
  const isReadOnly = !formContext.editable || isLoading
  const nextIsDisabled = !schema.isValidSync(project)

  const onSubmit = (event: FormEvent<HTMLFormElement> | MouseEvent) => {
    event.preventDefault()
    if (isReadOnly) return handleNext(event)

    return handleSubmit(() => handleNext(event))()
  }

  const handleChange =
    (onChange: (...event: any[]) => void) => (event: ChangeEvent<HTMLInputElement>) => {
      if (isReadOnly) return

      onChange(event.target.value)
      updateProject((prev) => ({ ...prev, [event.target.name]: event.target.value }))
    }

  const handleLocationChange = (location: Location) => {
    if (isReadOnly) return
    setValue('location', location, { shouldValidate: true })
    updateProject((prev) => ({ ...prev, location }))
    setGoogleInput(location.formatted)
  }

  const handleEditAddress = () => {
    setModal({
      modal: 'address',
      props: {
        location: project.location,
        updateLocation: (l: Location) => handleLocationChange(l),
      },
    })
  }

  const onPrev = formContext.location === 'sidebar' ? handlePrev : undefined

  const renderInputFields = useCallback(
    () => (
      <Controller
        control={control}
        defaultValue={project.name}
        name='name'
        render={({ field: { onChange, onBlur } }) => (
          <Input
            autoComplete='chrome-off'
            className='input__name'
            label='Project Name'
            invalid={errors['name']?.message}
            invalidProps={{ size: 'caption' }}
            name='name'
            onBlur={onBlur}
            onChange={({ target }) => {
              onChange(target.value)
              updateProject((prev) => ({ ...prev, [target.name]: target.value }))
            }}
            readOnly={isReadOnly}
            value={project.name}
            size='caption'
            width={pathname.startsWith('/onboard') ? undefined : '100%'}
          />
        )}
      />
    ),
    [project],
  )

  const renderLocationDropdown = useCallback(
    () => (
      <>
        <GoogleAddressAutoComplete
          name='location'
          label='Address'
          handleChange={handleLocationChange}
          value={googleInput}
          setValue={setGoogleInput}
          width={inputWidth}
          className='input__address'
        />
        {errors.location && (
          <Text color='danger'>Address incomplete. Click &quot;Edit&quot; below to update</Text>
        )}
        {project.location && project.location?.formatted && (
          <ButtonWrapper>
            <Button
              style={{ margin: '3px 0 0 1px' }}
              type='button'
              size='inherit'
              variant='inline'
              onClick={handleEditAddress}
            >
              Edit
            </Button>
          </ButtonWrapper>
        )}
      </>
    ),
    [project, googleInput, errors.location],
  )

  const renderRadio = useCallback(
    (radio: RadioInput) => (
      <InputSection key={radio.name}>
        <InputHeader>
          <Text size='body'>{radio.label}</Text>
          {radio.extra || null}
        </InputHeader>
        <InputContainer>
          <Controller
            key={radio.name}
            control={control}
            defaultValue={project[radio.name] || undefined}
            name={radio.name}
            render={({ field: { onChange } }) => (
              <RadioList
                direction='row'
                items={radio.options.map((r: RadioProps) => ({
                  ...r,
                  onChange: handleChange(onChange),
                  checked: project[radio.name] === r.value,
                  name: radio.name,
                }))}
                variant='boxed'
              />
            )}
          />
        </InputContainer>
      </InputSection>
    ),
    [project],
  )

  const unitBadges = useMemo(
    () => (
      <InputExtra>
        <BadgeButton
          className='first-badge'
          type={formContext.unit === 'sqft' ? 'selected' : 'unselected'}
          size='medium'
          onClick={(event: MouseEvent) => {
            event.preventDefault()
            formContext.updateUnit('sqft')
          }}
        >
          Feet
        </BadgeButton>
        <BadgeButton
          type={formContext.unit === 'sqm' ? 'selected' : 'unselected'}
          size='medium'
          onClick={(event: MouseEvent) => {
            event.preventDefault()
            formContext.updateUnit('sqm')
          }}
        >
          Meters
        </BadgeButton>
      </InputExtra>
    ),
    [formContext.unit, formContext.updateUnit],
  )

  const renderBuildingType = useCallback(
    () => renderRadio(buildingType),
    [buildingType, renderRadio],
  )

  const renderBuildingSize = useCallback(
    () => renderRadio(buildingSize(formContext.unit, unitBadges)),
    [buildingSize, renderRadio, formContext.unit, unitBadges],
  )

  const renderOtherRadios = useCallback(() => radios.map(renderRadio), [radios, project])

  return (
    <Form className={isLoading ? 'transparent' : undefined} onSubmit={onSubmit}>
      <FormSection main>
        {renderInputFields()}
        {renderLocationDropdown()}
        {renderBuildingType()}
        {renderBuildingSize()}
        {renderOtherRadios()}
      </FormSection>
      <Footer isLoading={isLoading} nextDisabled={nextIsDisabled} onPrev={onPrev} />
    </Form>
  )
}
