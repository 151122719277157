import { add, curry, defaultTo, head, is, match, pipe, toString, when } from 'ramda'
import { length } from './strings'
import config from 'config'

const decimalRegEx = /\.\d+$/
export const countDecimals = pipe<
  [number | string],
  string,
  string[],
  string | undefined,
  string,
  number,
  number
>(when(is(Number), toString), match(decimalRegEx), head, defaultTo('.'), length, add(-1))

export const displayDecimal = (num: number, precision = 2) =>
  countDecimals(num) > precision ? round(num, precision) : num

export const toAdjustedCurrency = (x: number) => {
  const sign = x > 0 ? '+' : x < 0 ? '-' : ''
  return `${sign}${toLocaleCurrency(Math.abs(x))}`
}

export const toLocale =
  (locale = 'en-US') =>
  (num: number) =>
    num.toLocaleString(locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 })

export const toCurrentLocale = toLocale(config.LOCALE)

export const toLocaleCurrency = (num: number) => `$${toCurrentLocale(num)}`

const _round =
  (rounder: (x: number) => number) =>
  (number: number, precision = 2) => {
    const power = 10 ** precision
    return rounder(number * power) / power
  }

export const floor = _round(Math.floor)
export const round = _round(Math.round)

interface BoundOptions {
  min?: number
  max?: number
}

const _bound = ({ min, max }: BoundOptions, x: number) => {
  if (typeof min !== 'undefined' && min > x) return `-${min}`
  if (typeof max !== 'undefined' && max < x) return `${max}+`
  return String(x)
}

export const bound = curry(_bound)
