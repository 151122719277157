import { Button } from '@ubnt/ui-components/Button'
import { isEmpty } from 'ramda'

import type { VFC } from 'react'

interface Props {
  text: string
  onClick: () => void
}

export const InfoButton: VFC<Props> = ({ text, onClick }) => {
  if (isEmpty(text)) return null
  return (
    <Button onClick={onClick} variant='primary' style={{ marginTop: '10px' }}>
      {text}
    </Button>
  )
}
