import isNil from 'ramda/es/isNil'
import { Views } from '../types'

import type { Account } from 'store/app/types'
import type { NewProject } from 'store/projects/types'
import type { User } from 'types'

export const initAccount: Account = {
  mailingList: false,
  password: '',
  termsAndConditions: false,
  username: '',
}

export const initProject: NewProject = {
  name: '',
  location: {
    formatted: '',
    street: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
  },
  buildingSize: undefined,
  buildingType: undefined,
  userQty: undefined,
  wifiIndoor: [],
  wifiOutdoor: [],
  cameras: {
    qty: 0,
    note: '',
  },
  voIP: {
    qty: 0,
    note: '',
  },
  doors: {
    qty: 0,
    note: '',
  },
}

export const initSteps = (user: User | null) => {
  const map = new Map<Views, boolean>()
  map.set(Views.WIFI, false)
  map.set(Views.CAMERAS, false)
  map.set(Views.VOIP, false)
  map.set(Views.DOORS, false)
  map.set(Views.USER, isNil(user))
  return map
}

export const initViews = {
  1: Views.OVERVIEW,
  2: Views.START,
}
