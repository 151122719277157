import { Button, Text } from '@ubnt/ui-components'
import { SidePanel } from '@ubnt/ui-components/SidePanel'
import { getUserName, UserAvatar } from 'components'
import { Screen, useScreenWidth, useSlider } from 'hooks'
import type { FC } from 'react'
import { useActions, useOverState } from 'store'
import type { ContactSidebar as SidebarType, ModalType } from 'store/modal/types'
import styled from 'styled-components'

const ContactPro: FC = () => {
  const {
    sidebar,
    sidebarProps: { professionalUser },
  } = useOverState().modal as ModalType<unknown, SidebarType>
  const {
    modal: { closeSidebar },
  } = useActions()

  const { Slider, closeSlider } = useSlider(closeSidebar)
  const professionalName = getUserName(professionalUser)
  const width = useScreenWidth() === Screen.MOBILE ? 352 : undefined

  return (
    <Slider width={width}>
      <SidePanel
        header='Update Quote'
        in={sidebar === 'contact_pro'}
        onClose={closeSlider}
        width={width}
      >
        <Container>
          <TopHalf>
            <Top>
              <Text weight='bold' size='body'>
                Need your quote to be adjusted?
              </Text>
              <Text>Please contact your professional.</Text>
            </Top>
            <ContactDetails>
              <ContactLine>
                <Text color='header' weight='bold' size='body'>
                  Professional
                </Text>
                <ProfessionalWrapper>
                  <UserAvatar hideName size='medium' user={professionalUser} />
                  <Text style={{ marginLeft: '10px' }} size='body'>
                    {professionalName}
                  </Text>
                </ProfessionalWrapper>
              </ContactLine>
              <ContactLine>
                <Text color='header' weight='bold' size='body'>
                  Email
                </Text>
                <Text size='body'>{professionalUser.email}</Text>
              </ContactLine>
              <ContactLine>
                <Text color='header' weight='bold' size='body'>
                  Phone
                </Text>
                <Text size='body'>{professionalUser.phone}</Text>
              </ContactLine>
            </ContactDetails>
          </TopHalf>
          <Footer>
            <Button onClick={closeSlider} variant='primary'>
              Done
            </Button>
          </Footer>
        </Container>
      </SidePanel>
    </Slider>
  )
}

export default ContactPro

const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

const Top = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.Light.color.neutral.n2};
  padding: 16px 28px;
`

const ContactDetails = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 28px;
`

const ContactLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 57px;
  border-bottom: ${({ theme }) => `1px solid ${theme.borderColor}`};
`
const ProfessionalWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`

const TopHalf = styled.div`
  display: flex;
  flex-direction: column;
`

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 16px 28px;
`
