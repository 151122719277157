import { FC, useEffect } from 'react'
import { useParams } from 'react-router'
import { isNil } from 'ramda'

import { useActions, useOverState } from 'store'
import { ChangePage, Loader } from 'components'
import { OptionWrapper } from './OptionWrapper'
import { Pages } from 'shared'
import { QuoteContainer } from './styles'

export const QuoteView: FC = () => {
  const { quotes } = useOverState()
  const actions = useActions()
  const { quoteId } = useParams<{ quoteId: string }>()

  useEffect(() => {
    quoteId && actions.quotes.getQuote({ quoteId })

    return () => {
      actions.quotes.resetCurrentQuote()
      actions.quotes.setCheckout(null)
    }
  }, [quoteId])

  return (
    <QuoteContainer>
      <Loader
        cond={isNil(quotes.current.quote) && isNil(quotes.loadingSection)}
        status={quotes.status}
      >
        {quotes.current.quote && (
          <OptionWrapper isStale={quotes.current.isStale} quote={quotes.current.quote} />
        )}
      </Loader>
      <ChangePage to={Pages.QUOTE_SUMMARY} />
    </QuoteContainer>
  )
}
