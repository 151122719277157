import { LoaderState } from '@ubnt/ui-components/Button/utils'
import { includes, __ } from 'ramda'
import { AsyncFuncState } from 'shared'
import type { UseLoaderOptions } from './types'

const statusDict: Record<AsyncFuncState, LoaderState> = {
  [AsyncFuncState.ERROR]: 'error',
  [AsyncFuncState.LOADING]: 'loading',
  [AsyncFuncState.NULL]: undefined,
  [AsyncFuncState.SUCCESS]: 'success',
}

export const isResponse = includes(__, [AsyncFuncState.ERROR, AsyncFuncState.SUCCESS])

export const statusToLoader =
  (status: AsyncFuncState, skip: boolean) =>
  ({ showChange = true, showResponse = true }: UseLoaderOptions = {}): LoaderState => {
    if (!showChange) return
    if (showResponse && !skip) return statusDict[status]

    return isResponse(status) ? undefined : statusDict[status]
  }
