import { useCallback, useLayoutEffect, useRef } from 'react'
import { UseOutsideClickOptions } from './types'

export const useOutsideClick = <A extends HTMLElement>(
  fn: () => void,
  {
    checkModal = true,
    checkToast = true,
    disabled = false,
    ignoreIds = [],
  }: UseOutsideClickOptions = {},
) => {
  const ref = useRef<A>(null)

  const handleOutsideClick = useCallback(
    (event: MouseEvent) => {
      if (disabled) return

      const target = event.target as Node

      const modal = checkModal && document.querySelector('.ReactModalPortal')?.contains(target)
      const ignored = ignoreIds
        .concat(checkToast ? 'toast-container' : [])
        .some((id) => document.getElementById(id)?.contains(target) ?? false)

      if (ref.current && !ref.current.contains(target) && !modal && !ignored) {
        fn()
      }
    },
    [disabled, fn, ref.current],
  )

  useLayoutEffect(() => {
    document.addEventListener('click', handleOutsideClick, true)

    return () => {
      document.removeEventListener('click', handleOutsideClick, true)
    }
  }, [disabled, ref])

  return ref
}
