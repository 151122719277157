import { T, always, cond, equals, identity, pathOr, pipe } from 'ramda'

import { dateOr, renderBadge } from 'helpers'
import { FulfillmentStatus } from 'store/orderDetails/constants'

import type { BadgeProps } from 'components'
import type { QuoteListItem } from 'store/quotes/types'

const statusDict: Record<string, BadgeProps['type']> = {
  Delivered: 'green',
  'Partially Shipped': 'blue',
  Unfulfilled: 'yellow',
}

export const formatDate = dateOr()

export const pickStatus = pathOr(FulfillmentStatus.PENDING_PROCESSING, [
  'order',
  'fulfillmentStatus',
])

export const renderFooter = (quote: QuoteListItem) =>
  `Order Placed ${formatDate(quote.orderPurchaseDateTime)}`

type RenderStatus = (x: FulfillmentStatus | 'Unfilfilled') => JSX.Element
export const renderStatus: RenderStatus = pipe(
  cond<any, string>([
    [equals(FulfillmentStatus.PENDING_PROCESSING), always('Unfulfilled')],
    [equals(FulfillmentStatus.SHIPPED), always('Delivered')],
    [equals(FulfillmentStatus.PARTIALLY_SHIPPED), always('Partially Shipped')],
    [T, identity],
  ]),
  renderBadge(statusDict),
) as any

export const renderCardStatus = pipe(pickStatus, renderStatus)
