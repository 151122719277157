import styled from 'styled-components'
import Text from '@ubnt/ui-components/Text/Text'

export const IconWrapper = styled.div`
  margin-right: 10px;
`

export const Item = styled.li`
  display: flex;
  width: 100%;
  min-height: 40px;
  align-items: center;
  padding: 0px 20px;
  color: rgba(0, 0, 0, 0.85);

  :hover {
    background-color: ${({ theme }) => theme.colors.Light.color.neutral.n2};
    cursor: pointer;
  }

  &.selected {
    background-color: ${({ theme }) => theme.colors.Light.color.blue.b1};

    :hover {
      background-color: ${({ theme }) => theme.colors.Light.color.blue.b1};
    }
  }
`

export const ItemText = styled(Text)`
  flex-grow: 1;
  margin-right: 4px;
`

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`

export const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 334px;
  min-width: 20vw;
  width: 20vw;
  border-right: ${({ theme }) => `1px solid ${theme.borderColor}`};
  overflow: hidden;
`
