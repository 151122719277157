import { useEffect, useState } from 'react'

/**
 * TS doesn't recognise vendor props for document.hidden
 * So instead of TS-ignoring everything, it's easier to use JS
 */
const [hidden, visibilityChange] =
  typeof document.hidden !== 'undefined'
    ? ['hidden', 'visibilitychange']
    : typeof document.msHidden !== 'undefined'
    ? ['msHidden', 'msvisibilitychange']
    : ['webkitHidden', 'webkitvisibilitychange']

const isVisible = () => !document[hidden]

export const useIsVisible = () => {
  const [state, setState] = useState(isVisible())
  const updateState = () => setState(isVisible())

  useEffect(() => {
    document.addEventListener(visibilityChange, updateState, false)
    return () => document.removeEventListener(visibilityChange, updateState)
  })

  return state
}
