import { Button } from '@ubnt/ui-components/Button'
import { Text } from '@ubnt/ui-components/Text'

import { Loader } from 'components'
import { Container } from './styles'
import { AsyncFuncState } from 'shared'

import type { FC } from 'react'
import type { RefreshBubbleProps } from './types'

export const RefreshBubble: FC<RefreshBubbleProps> = ({
  buttonLabel = 'Refresh',
  children,
  className,
  refresh,
  show,
  status,
  text,
}) => {
  if (!show) return null
  return (
    <Container className={className} variant='info'>
      {text && <Text size='body'>{text}</Text>}&nbsp;
      {children}
      <Loader status={status ?? AsyncFuncState.NULL} type='small'>
        <Button onClick={refresh} size='small' variant='inline'>
          {buttonLabel}
        </Button>
      </Loader>
    </Container>
  )
}
