import { defaultTo, head, match, pipe } from 'ramda'

import { dateOr } from 'helpers'
import { REGEX } from 'shared'

const matchId = pipe<[string], string[], string | undefined>(match(REGEX.uuid), head)

// Exported functions
export const formatDate = dateOr()

export const getQuoteId = (id: string) => {
  const value = matchId(id)
  return defaultTo(id, value)
}
