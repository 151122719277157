import styled, { keyframes } from 'styled-components'
import { BubbleContainer } from 'components'
import { MEDIA } from 'styles/constants'

const growFrames = keyframes`
  0% { height: 0px; padding: 0px 32px; }
  100% { height: 35px; padding: 6px 32px; }
`

export const Container = styled(BubbleContainer)`
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: 14px;
  height: 0px;
  margin: 0px;
  margin-bottom: 12px;
  padding: 0px 32px;
  overflow: hidden;

  -webkit-animation: ${growFrames} 1s forwards;
  animation: ${growFrames} 1s forwards;

  @media ${MEDIA.MOBILE} {
    box-sizing: content-box;
  }
`
