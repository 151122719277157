import { FC, useEffect, useState } from 'react'
import { Modal } from '@ubnt/ui-components/Modal'

import { useActions, useOverState } from 'store'
import { DisputeForm } from './DisputeForm'
import { useLoader } from 'hooks'
import { getDisputeId, getDisputeText } from './helpers'
import texts from './texts.json'

import type { ModalType } from 'store/modal/types'
import type { WithQuoteId } from 'store/types'

export const QuoteDispute: FC = () => {
  const { modal, quotes } = useOverState()
  const { modalProps } = modal as ModalType<WithQuoteId>
  const actions = useActions()
  const [dispute, setDispute] = useState('')
  const [loader] = useLoader(quotes.status)

  useEffect(() => {
    setDispute(getDisputeText(quotes.current.quote))
  }, [quotes.current.quote?.disputes])

  return (
    <Modal
      isOpen
      onRequestClose={actions.modal.closeModal}
      shouldCloseOnOverlayClick={false}
      size='small'
      title={texts.title}
      actions={[
        {
          onClick: actions.modal.closeModal,
          text: texts.buttons.cancel,
          variant: 'tertiary',
        },
        {
          loader: loader(),
          onClick: () =>
            actions.quotes.dispute({
              disputeId: getDisputeId(quotes.current.quote),
              quoteId: modalProps.quoteId,
              dispute,
            }),
          text: texts.buttons.confirm,
          variant: 'primary',
        },
      ]}
    >
      <DisputeForm onChange={setDispute} value={dispute} />
    </Modal>
  )
}
