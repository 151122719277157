import { curry } from 'ramda'
import { Badge, BadgeProps } from 'components'
import { capitalise } from 'helpers'

const _renderBadge = <A extends string>(dictionary: Record<A, BadgeProps['type']>, status: A) => {
  return (
    <Badge type={dictionary[status]} size='small'>
      {capitalise(status)}
    </Badge>
  )
}

export const renderBadge = curry(_renderBadge)
