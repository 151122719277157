import { FormEvent, useCallback, useContext } from 'react'
import { useForm, Controller } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Text } from '@ubnt/ui-components/Text'
import { lensPath, set } from 'ramda'

import { Footer, FormSection, Note, ProjectFormContext } from 'components'
import {
  Checkbox,
  CheckboxImg,
  CheckboxImgContainer,
  Form,
  InputContainer,
  NumberSelector,
} from './styles'

import IndoorImg from 'assets/png/Indoor.png'

import type { ChangeEvent } from 'react'
import type { FormStepProps } from './types'
import type { BuildingCameras, NewProject, UpdateProject } from 'store/projects/types'

const schema = yup.object().shape({
  qty: yup.number().required().min(0),
})

export const CameraForm = <A extends NewProject | UpdateProject>({
  handleNext,
  handlePrev,
  isLoading = false,
  updateProject,
  project: { cameras },
}: FormStepProps<A>) => {
  const formContext = useContext(ProjectFormContext)
  const {
    clearErrors,
    control,
    handleSubmit,

    formState: { errors },
  } = useForm<BuildingCameras>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  })

  const nextIsDisabled = !schema.isValidSync(cameras)
  const isReadOnly = !formContext.editable || isLoading

  const onSubmit = (event: FormEvent<HTMLFormElement> | MouseEvent) => {
    event.preventDefault()
    if (isReadOnly) return handleNext(event)

    return handleSubmit(() => handleNext(event))()
  }

  const handleChange = useCallback(
    (changeFn: (...event: any[]) => void) => (event: ChangeEvent<HTMLInputElement>) => {
      if (isReadOnly) return

      const value = Number(event.target.value)
      if (Number.isNaN(value) || value < 0) return
      clearErrors()
      changeFn(value)
      return updateProject(set(lensPath(['cameras', 'qty']), value))
    },
    [updateProject],
  )

  const handleChangeNote = (_: any, value: string) => {
    if (isReadOnly) return

    return updateProject(set(lensPath(['cameras', 'note']), value))
  }

  return (
    <Form className={isLoading ? 'transparent' : undefined} onSubmit={onSubmit}>
      <FormSection main>
        <Text size='body'>Number of coverage areas *</Text>
        <InputContainer>
          <Checkbox
            id='indoor'
            checked={cameras.qty > 0}
            full
            location={formContext.location}
            pointer='default'
            readOnly
            type='big'
          >
            <CheckboxImgContainer checked={cameras.qty > 0} location={formContext.location}>
              <CheckboxImg src={IndoorImg} alt='' location={formContext.location} />
            </CheckboxImgContainer>
            <Text size='caption'>Cameras</Text>
            <Controller
              control={control}
              defaultValue={cameras.qty}
              name='qty'
              render={({ field: { onChange, onBlur } }) => (
                <NumberSelector
                  fixedWidth={25}
                  integer
                  invalid={errors.qty !== undefined}
                  min={0}
                  name='qty'
                  onBlur={onBlur}
                  onChange={handleChange(onChange)}
                  readOnly={isReadOnly}
                  value={cameras.qty}
                />
              )}
            />
          </Checkbox>
        </InputContainer>
        {errors.qty && (
          <Text size='caption' color='danger'>
            {errors.qty?.message}
          </Text>
        )}
        <Note editable={formContext.editable} onChange={handleChangeNote} value={cameras.note} />
      </FormSection>
      <Footer isLoading={isLoading} nextDisabled={nextIsDisabled} onPrev={handlePrev} />
    </Form>
  )
}
