import styled from 'styled-components'
import { Text as BaseText } from '@ubnt/ui-components/Text'
import { prop } from 'ramda'

import { FlexRow } from 'components'
import { MEDIA } from 'styles/constants'

import type { LineProps, StepProps } from './types'

export const Circle = styled.div<StepProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  grid-area: circle;

  color: white;

  height: 20px;
  width: 20px;

  background-color: ${({ status, theme }) => {
    const { blue, neutral } = theme.colors.Light.color
    return status === 'default' ? neutral.n6 : blue.b6
  }};
  border-radius: 50%;
  box-shadow: ${({ status, theme }) =>
    status === 'current' ? `0px 0px 0px 4px ${theme.colors.Light.color.blue.b2}` : 'none'};

  margin: 0px 10px 4px;

  @media ${MEDIA.MOBILE} {
    margin: 0px 10px 4px 0px;
  }
`

export const Container = styled(FlexRow)`
  margin-bottom: 20px;

  @media ${MEDIA.MOBILE} {
    flex-direction: column;
    margin-top: 20px;
  }
`

export const InfoText = styled(BaseText)`
  display: none;
  color: ${({ theme }) => theme.colors.textColor.black.b45};
  font-size: 12px;
  margin-bottom: 12px;

  @media ${MEDIA.MOBILE} {
    display: block;
  }
`

export const Line = styled.div<LineProps>`
  grid-area: ${prop('area')};
  height: 2px;
  background-color: ${({ theme }) => theme.colors.Light.color.neutral.n6};
  width: 100%;
  margin: auto;

  @media ${MEDIA.MOBILE} {
    display: none;
  }
`

export const Step = styled.div<StepProps>`
  display: grid;
  grid-template-columns: 1fr 40px 1fr;
  grid-template-areas:
    'line-1 circle line-2'
    'text text text';

  flex-grow: 1;

  @media ${MEDIA.MOBILE} {
    display: ${({ status }) => (status === 'current' ? 'grid' : 'none')};
    grid-template-columns: 30px 1fr;
    grid-template-areas: 'circle text';

    align-items: center;
    flex-grow: 0;
  }
`

export const StepText = styled(BaseText)`
  text-align: center;
  grid-area: text;
`

export const Text = styled(BaseText)`
  color: inherit;
`
