import { isNil, isNotNil } from 'ramda'

import { useOverState } from 'store'
import { BubbleContainer, FlexColumn, FlexRow } from 'components'
import { ConditionalRating } from './ConditionalRating'
import { InfoButton } from './InfoButton'
import { TitleAndBody } from './TitleAndBody'
import { Screen, useScreenWidth } from 'hooks'
import { getInfoData } from './helpers'
import { makeQuoteStep } from '../helpers'
import { IconWrapper } from './styles'

import type { FC } from 'react'
import type { InfoPanelProps } from './types'

export const InfoPanel: FC<InfoPanelProps> = ({ onFeedback, quote }) => {
  const { quotes } = useOverState()
  const screenWidth = useScreenWidth()

  const step = makeQuoteStep({
    ...quotes.current,
    checkout: quotes.checkout,
    quote,
  })
  const isRated = step === 3 && isNotNil(quote.feedback)
  const readyForRating = step === 3 && isNil(quote.feedback)
  const [Icon, texts] = getInfoData({
    ...quotes.current,
    checkout: quotes.checkout,
    quote,
  })

  const btnFn = () => readyForRating && onFeedback()

  return (
    <BubbleContainer variant='info' verticalSpacing={8}>
      {screenWidth > Screen.MOBILE && (
        <FlexRow justify='flex-start'>
          <IconWrapper>
            <Icon />
          </IconWrapper>

          <FlexColumn grow={1}>
            <TitleAndBody title={texts.title} body={texts.text} />
          </FlexColumn>
          {readyForRating && <InfoButton text={texts.button} onClick={btnFn} />}
          <ConditionalRating rating={quote.feedback?.rating} show={isRated} />
        </FlexRow>
      )}

      {screenWidth < Screen.TABLET && !isRated && (
        <FlexRow justify='flex-start'>
          <IconWrapper>
            <Icon />
          </IconWrapper>

          <FlexColumn grow={1}>
            <TitleAndBody title={texts.title} body={texts.text} />
            {step === 3 && <InfoButton text={texts.button} onClick={btnFn} />}
          </FlexColumn>
        </FlexRow>
      )}

      {screenWidth < Screen.TABLET && isRated && (
        <FlexRow justify='flex-start'>
          <FlexColumn>
            <FlexRow>
              <IconWrapper>
                <Icon />
              </IconWrapper>
              <TitleAndBody title={texts.title} body={texts.text} />
            </FlexRow>

            <ConditionalRating rating={quote.feedback?.rating} show />
          </FlexColumn>
        </FlexRow>
      )}
    </BubbleContainer>
  )
}
