import { Button } from '@ubnt/ui-components/Button'
import { Text } from '@ubnt/ui-components/Text'

import { useActions, useOverState } from 'store'
import { FlexColumn, FlexRow } from 'components'
import { ArrowIcon, LabelText, ProItemLabel } from './styles'
import { makeQuoteStep } from '../helpers'
import { toLocaleCurrency } from 'helpers'
import texts from '../../texts.json'

import type { FC, MouseEventHandler } from 'react'
import type { HardwareLabelProps } from './types'

export const HardwareLabel: FC<HardwareLabelProps> = ({ expanded, quote, toggle }) => {
  const { quotes } = useOverState()
  const actions = useActions()

  const handleClickDetails: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation()

    actions.modal.setSidebar({
      sidebar: 'order_details',
      props: { from: 'quote', order: quote, type: 'ui' },
    })
  }

  const step = makeQuoteStep({ ...quotes.current, checkout: null, quote })

  return (
    <ProItemLabel itemExpanded={expanded} showArrow={false} togglePanelOpen={toggle}>
      <FlexRow alignItems='stretch' grow={1} justify='space-between'>
        <LabelText size='body' weight='bold'>
          Hardware
        </LabelText>
        <FlexRow alignItems='flex-start' style={{ marginTop: 24 }}>
          <FlexColumn>
            <Text size='header-xs' weight='bold'>
              {toLocaleCurrency(quote.priceBreakdown.productVariants.total)}
            </Text>
            {step > 1 && (
              <Button onClick={handleClickDetails} size='small' variant='inline'>
                {texts.hardwarePanel.buttons.details}
              </Button>
            )}
          </FlexColumn>
          <ArrowIcon className={expanded ? 'expanded' : undefined} size='large' />
        </FlexRow>
      </FlexRow>
    </ProItemLabel>
  )
}
