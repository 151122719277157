import type { Context } from 'store'
import type { WithQuoteId } from 'store/types'

export const getCard = ({ state, effects }: Context, value: WithQuoteId) =>
  effects.orderDetails.api
    .getCard(value) //
    .then((data) => {
      state.orderDetails.card = data
    })

export const getFulfillments = ({ effects, state }: Context, { quoteId }: WithQuoteId) =>
  effects.orderDetails.api //
    .getFulfillments(quoteId)
    .then((data) => {
      state.orderDetails.fulfillments = data
    })
