import { Children, FC } from 'react'

import { UploaderButton } from 'components'
import { FloorContainer as StyledFloorContainer, FloorWrapper } from './styles'

interface FloorContainerProps {
  label?: boolean
  onChange: (file: File, dataUrl?: string | undefined) => void
  readOnly?: boolean
}

export const FloorContainer: FC<FloorContainerProps> = ({
  children,
  label = false,
  onChange,
  readOnly = false,
}) => {
  const _children = Children.toArray(children)

  return (
    <FloorWrapper className='bubble-field' label='Floorplan' showLabel={label}>
      {_children.length > 0 && <StyledFloorContainer>{_children}</StyledFloorContainer>}
      {_children.length === 0 && (
        <UploaderButton className='bubble-field' onLoad={onChange} readOnly={readOnly} />
      )}
    </FloorWrapper>
  )
}
