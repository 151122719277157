import { VFC, useEffect } from 'react'
import { Text } from '@ubnt/ui-components'

import { useOverState } from 'store'
import { Footer, Loader } from 'components'
import { Form, FormSection } from 'components/ProjectForm/styles'
import { useIsVisible } from 'hooks'
import { AsyncFuncState } from 'shared'

import type { DoneFormProps } from './types'

export const DoneForm: VFC<DoneFormProps> = ({
  handleNext,
  interval,
  isLoading = false,
  label = 'To Projects',
}) => {
  const { tokenData } = useOverState().projects
  const isVisible = useIsVisible()

  const text = tokenData?.clientEmail ? ` at ${tokenData.clientEmail}` : ''

  useEffect(() => {
    const _interval = setInterval(() => handleNext(undefined, false), interval)

    return () => clearInterval(_interval)
  }, [])

  useEffect(() => {
    if (isVisible) {
      handleNext(undefined, false)
    }
  }, [isVisible])

  return (
    <Form onSubmit={handleNext}>
      <FormSection main>
        <Text size='body'>
          {`We've sent you a verification email${text}. Please, verify it before continuing.`}
        </Text>
        <Loader status={AsyncFuncState.LOADING} cond={isLoading} />
      </FormSection>
      <Footer isLoading={isLoading} nextLabel={label} />
    </Form>
  )
}
