import React, { FC } from 'react'
import { Text } from '@ubnt/ui-components'
import { MoreVerticalIcon, ArrowDownPrimaryIcon, IconSize } from '@ubnt/icons'
import { isNil } from 'ramda'
import styled from 'styled-components'

import { toLocaleCurrency } from 'helpers'

interface AccordionLabelPropsA {
  arrowSize?: IconSize
  className?: string
  icon: React.ElementType
  itemExpanded: boolean
  label: string
  price: number
  showArrow?: boolean
  togglePanelOpen: () => void
}

interface AccordionLabelPropsB {
  arrowSize?: IconSize
  className?: string
  icon?: undefined
  itemExpanded: boolean
  label?: undefined
  price?: undefined
  showArrow?: boolean
  togglePanelOpen: () => void
}

export const AccordionLabel: FC<AccordionLabelPropsA | AccordionLabelPropsB> = ({
  arrowSize = 'small',
  children,
  className,
  icon,
  itemExpanded,
  label,
  price,
  showArrow = true,
  togglePanelOpen,
}) => {
  if (isNil(icon) || isNil(label) || isNil(price))
    return (
      <AccordionLabelWrapper className={className} onClick={togglePanelOpen}>
        {children}
        {showArrow && (
          <StyledArrowDownPrimaryIcon size={arrowSize} className={itemExpanded ? 'expanded' : ''} />
        )}
      </AccordionLabelWrapper>
    )

  return (
    <AccordionLabelWrapper className={className} onClick={() => togglePanelOpen()}>
      <div>
        {icon === MoreVerticalIcon &&
          React.createElement(icon, { isActive: price > 0, className: 'rotate' })}
        {icon !== MoreVerticalIcon && React.createElement(icon, { isActive: price > 0 })}
        <Text size='caption' weight='bold' style={{ marginLeft: '10px' }}>
          {label}
        </Text>
      </div>
      <div>
        <Text size='body' weight='bold' color={price > 0 ? 'info' : 'disabled'}>
          {toLocaleCurrency(price)}
        </Text>
        {showArrow && (
          <StyledArrowDownPrimaryIcon size={arrowSize} className={itemExpanded ? 'expanded' : ''} />
        )}
      </div>
    </AccordionLabelWrapper>
  )
}

const AccordionLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 20px;
  height: 48px;

  :hover {
    cursor: pointer;
    background-color: ${({ theme }) => `${theme.colors.Light.color.blue.b1}`};
  }

  > div > svg {
    transition: color 100ms ease, transform 200ms cubic-bezier(0.4, 0, 0, 1.4);
  }

  > svg {
    transition: color 100ms ease, transform 200ms cubic-bezier(0.4, 0, 0, 1.4);
  }

  .expanded {
    transform: rotate(180deg);
  }

  .fill {
    color: transparent;
  }

  .rotate {
    transform: rotate(90deg);
  }
`

const StyledArrowDownPrimaryIcon = styled(ArrowDownPrimaryIcon)`
  margin-left: 10px;
  color: ${({ theme }) => theme.colors.Light.color.neutral.n8};
`
