import { AsyncFuncState } from 'shared'
import type { ModalState } from './types'

export const state: ModalState = {
  modal: null,
  modalProps: {},
  sidebar: null,
  sidebarProps: null,
  status: AsyncFuncState.SUCCESS,
}
