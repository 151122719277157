import { Button } from '@ubnt/ui-components/Button'
import { Text } from '@ubnt/ui-components/Text'
import { ConditionalTooltip } from 'components'
import { toLocaleCurrency } from 'helpers'
import { AsyncFuncState, QuoteStatus } from 'shared'
import { useActions, useOverState } from 'store'
import styled from 'styled-components'
import { MEDIA } from 'styles/constants'
import texts from '../texts.json'

import type { FC, MouseEventHandler } from 'react'
import type { Quote } from 'store/quotes/types'

interface Props {
  quote: Quote
  outOfStock: boolean
  handleContactPro: () => void
}

const makeTooltipText = (
  shopifyStatus: AsyncFuncState,
  quoteStatus: QuoteStatus,
  outOfStock: boolean,
) => {
  if (shopifyStatus === AsyncFuncState.ERROR) return texts.checkoutButtons.tooltip.shopifyError
  if (quoteStatus !== QuoteStatus.SENT) return texts.checkoutButtons.tooltip.alreadyPurchased
  if (outOfStock) return texts.checkoutButtons.tooltip.outOfStock
}

const QuoteCheckout: FC<Props> = ({ quote, outOfStock }) => {
  const {
    modal: { modalProps },
    quotes,
  } = useOverState()
  const actions = useActions()

  const handlePurchase: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation()

    actions.modal.closeModal()
    modalProps.initiateCheckout()
  }

  const isLoading =
    quotes.loadingSection === 'ui-checkout' && quotes.status === AsyncFuncState.LOADING
  const isDisabledCheckout = () => quote.status !== QuoteStatus.SENT || outOfStock || isLoading

  return (
    <Container>
      <TotalWrapper>
        <QuoteValueWrapper>
          <Text color='primary' size='body' weight='bold'>
            Total Quote Value
          </Text>
          <Text size='caption' color='tertiary'>
            {quote.status !== QuoteStatus.SENT ? '(incl. shipping)' : ''}
          </Text>
        </QuoteValueWrapper>
        <Text color='primary' size='header-s' weight='bold'>
          {toLocaleCurrency(quote.priceBreakdown.productVariants.total)}
        </Text>
      </TotalWrapper>
      <ButtonWrapper>
        <ConditionalTooltip
          show={isDisabledCheckout()}
          description={makeTooltipText(quotes.status, quote.status, outOfStock)}
          portal
          position='top'
          width='100%'
          style={{ width: '100%' }}
        >
          <Button
            variant='primary'
            onClick={handlePurchase}
            disabled={isDisabledCheckout() || quotes.storeCheckout.inCheckout}
            loader={isLoading || quotes.storeCheckout.inCheckout ? 'loading' : undefined}
          >
            Purchase UniFi Devices
          </Button>
        </ConditionalTooltip>
      </ButtonWrapper>
    </Container>
  )
}

export default QuoteCheckout

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: ${({ theme }) => `${theme.colors.Light.color.neutral.n0}`};
  border-left: ${({ theme }) => `1px solid ${theme.colors.Light.color.neutral.n3}`};
  border-top: ${({ theme }) => `1px solid ${theme.colors.Light.color.neutral.n3}`};
  border-radius: 0 0 8px 0;
  z-index: 10;

  @media ${MEDIA.TABLET} {
    border-radius: 0;
  }
`

const TotalWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 100%;
`

const QuoteValueWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
