import {
  AccessElementsIcon,
  ProtectElementIconL,
  DevicesTalkIconL,
  LanIconL,
  WifiIconL,
} from '@ubnt/icons'

import ServicesCameraSecurity from 'assets/png/ServicesCameraSecurity.png'
import ServicesDoorAccess from 'assets/png/ServicesDoorAccess.png'
import ServicesTalk from 'assets/png/ServicesTalk.png'
import ServicesWiFi from 'assets/png/ServicesWiFi.png'
import UnifiBaseSystem from 'assets/png/UniFiBaseSystem.png'

export enum Categories {
  ROUTING = 'Routing',
  SWITCHING = 'Switching',
  WIRELESS = 'Wireless',
  PROTECT = 'Protect',
  ACCESS = 'Access',
  TALK = 'Talk',
  CABLING = 'Cabling',
  OTHER = 'Other',
}

export enum CategoryOrder {
  'UniFi Base System' = 1,
  'Wireless' = 2,
  'Protect' = 3,
  'Access' = 4,
  'Talk' = 5,
}

export const CategoryData = {
  Wireless: {
    image: ServicesWiFi,
    newName: 'WiFi',
    icon: WifiIconL,
    desc: 'Improve your wireless access and performance with our selection of home, office, and venue-grade access points.',
  },
  'UniFi Base System': {
    image: UnifiBaseSystem,
    newName: 'UniFi Base System',
    icon: LanIconL,
    desc: 'Start with the basics by building a UniFi base system that supports your devices and enhances network performance. Strengthen your UniFi system with our varied selection of powerful consoles and high-capacity switches.',
  },
  Protect: {
    image: ServicesCameraSecurity,
    newName: 'Camera Security',
    icon: ProtectElementIconL,
    desc: 'Protect your office or home with scalable camera systems that offer HD live-streaming, robust recording capacity, and instant event recall. ',
  },
  Access: {
    image: ServicesDoorAccess,
    newName: 'Door Access',
    icon: AccessElementsIcon,
    desc: 'Set access permissions and monitor visitor traffic from anywhere with our plug-and-play door access system.',
  },
  Talk: {
    image: ServicesTalk,
    newName: 'Phone System',
    icon: DevicesTalkIconL,
    desc: 'Our affordable, subscription-based Phone System simplifies call routing, logging, conferencing, and line management.',
  },
}
