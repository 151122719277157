import { InfoIcon } from '@ubnt/icons'
import { Text, Tooltip } from '@ubnt/ui-components'

import { FlexRow } from 'components'

export const TaxTooltip = () => (
  <FlexRow gap={4}>
    <Text color='secondary' size='body'>
      -
    </Text>
    <Tooltip
      description={
        <Text color='primary' motif='dark'>
          UniFi Hardware Tax will be calculated at checkout
        </Text>
      }
      portal
      position='bottom'
      width={240}
    >
      <InfoIcon size='original' isActive />
    </Tooltip>
  </FlexRow>
)
