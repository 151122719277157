import { branch, pipe } from 'overmind'

import { handleAsyncAction, toast as opToast } from 'store/operators'
import * as op from './operators'
import { closeModal } from 'store/modal/actions'
import { whenPropIs } from 'store/operators'

import type { AddContacts, WithClientId } from './types'

const handleClientAction = handleAsyncAction('client')

export const addContacts = handleClientAction<AddContacts, void>({
  action: pipe(
    branch<AddContacts, void>(op.addContacts),
    branch(
      whenPropIs('quoteId', undefined, {
        true: opToast('Success', 'Successfully added contact(s)'),
        false: opToast('Success', 'Successfully shared quote with colleague(s)'),
      }),
      closeModal,
    ),
    whenPropIs<AddContacts, void>('refetchUsers', true, {
      true: op.getInvitedUsers,
      false: () => undefined,
    }),
  ),
  errorTitle: 'Invite People Error',
})

export const getInvitedUsers = handleClientAction<WithClientId, void>({
  action: op.getInvitedUsers,
  errorTitle: 'Get Invited Users Error',
})
