import { Table as UITable } from '@ubnt/ui-components'
import styled from 'styled-components'

import { AsyncFuncState } from 'shared'
import { EmptyComponent } from './EmptyComponent'
import { TableWrapper } from './styles'

import type { OrderTableProps } from './types'
import type { QuoteListItem } from 'store/quotes/types'
import type { OrderRow } from 'app/orders/useOrderRows'

export const OrderTable = <A extends QuoteListItem | OrderRow>({
  handleClick,
  status,
  quotes,
  columns,
  selected,
  category,
}: OrderTableProps<A>) => {
  if (quotes.length === 0) return <EmptyComponent category={category} />

  return (
    <TableWrapper>
      {/* @ts-expect-error. TS doesn't like the added isLoading prop */}
      <Table
        columns={columns}
        className='table'
        disableColumnFilters
        hideHierarchyCellBorder={(row) => !!row.child}
        hideHierarchyLines
        isLoading={status === AsyncFuncState.LOADING}
        initialSortBy='quoteName'
        items={quotes}
        onRowClick={handleClick}
        rowHeight={48}
        rowSelectedClassName='selected-table-row'
        rowStyle={{ fontSize: '13px' }}
        selectedIds={selected}
      />
    </TableWrapper>
  )
}

const Table = styled(UITable)<{ isLoading: boolean }>`
  ${({ isLoading }) =>
    isLoading &&
    `opacity: 0.5;
    pointer-events: none;`}

  .selected-table-row {
    background-color: ${({ theme }) => theme.colors.Light.color.blue.b1};
  }
`
