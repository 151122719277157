import { PaymentMethodIconL, QuestionMarkIconL, ReceivedQuoteIconL } from '@ubnt/icons'

import Help from 'app/help'
import Orders from 'app/orders'
import Quotes from 'app/quotes'
import { QuoteView } from 'app/quotes/QuoteView'
import { ProjectPermissions } from 'app/account'

const routes = [
  {
    component: Quotes,
    exact: true,
    path: '/quotes',
    title: 'Projects & Quotes',
    icon: <ReceivedQuoteIconL actionable />,
    label: 'Projects & Quotes',
  },
  {
    component: Orders,
    exact: true,
    path: '/orders',
    title: 'Orders',
    icon: <PaymentMethodIconL actionable />,
    label: 'Orders',
  },
  {
    component: QuoteView,
    exact: false,
    path: '/quotes/:quoteId',
    title: null,
    icon: null,
    label: null,
  },
  {
    component: Help,
    exact: false,
    path: '/help',
    title: 'Help',
    icon: <QuestionMarkIconL actionable />,
    label: 'Help',
    link: '/help',
  },
  {
    component: ProjectPermissions,
    exact: false,
    path: '/account/project-permission',
    title: null,
    icon: null,
    label: null,
  },
]

export default routes
