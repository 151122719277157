import styled from 'styled-components'
import {
  is,
  always,
  concat,
  cond,
  equals,
  pathOr,
  pipe,
  prop,
  propEq,
  propIs,
  T,
  where,
  whereEq,
  __,
} from 'ramda'

import type { BubbleRadius, ContainerProps, IconWrapperProps } from './types'
import { MEDIA } from 'styles'

const getColour = (xs: string[]) => pathOr('', concat(['theme', 'colors', 'Light', 'color'], xs))
const prependBorder = (x: string) => `2px solid ${x}`
const makeBorder = (xs: string[]) => pipe(getColour(xs), prependBorder)

const typeEq = (type: string) => propEq(type, 'type')

const radiusDict: Record<BubbleRadius, number> = {
  small: 4,
  medium: 8,
  big: 16,
}

export const Container = styled.article<ContainerProps>`
  display: ${({ row }) => (row ? 'flex' : 'block')};
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, variant }) =>
    variant === 'primary'
      ? theme.colors.Light.color.neutral.n0
      : variant === 'secondary'
      ? theme.colors.Light.color.neutral.n1
      : theme.colors.Light.color.neutral.n2};

  border-radius: ${pipe(prop('radius'), prop(__, radiusDict))}px;
  border: ${({ border, theme, variant }) =>
    !border || variant === 'info' ? 'none' : `1px solid ${theme.colors.Light.color.neutral.n3}`};
  border-left: ${cond([
    [typeEq('info'), makeBorder(['blue', 'b6'])],
    [typeEq('success'), makeBorder(['green', 'g6'])],
    [typeEq('danger'), makeBorder(['red', 'r6'])],
    [typeEq('warn'), makeBorder(['orange', 'o6'])],
    [T, always('auto')],
  ])};

  margin: ${prop('verticalSpacing')}px 0px;
  padding: ${cond<any, string>([
    [
      where({ padding: is(Number), row: equals(true) }),
      pipe<[any], number, string>(
        prop('padding'),
        (x: number) => `${x}px ${x}px ${x}px ${x - 4}px`,
      ),
    ],
    [propIs(Number, 'padding'), pipe(prop('padding'), (x) => `${x}px`)],
    [propIs(String, 'padding'), prop('padding')],
    [whereEq({ row: true, variant: 'info' }), always('16px 24px 16px 12px')],
    [propEq('info', 'variant'), always('16px 24px')],
    [propEq(true, 'row'), always('24px 24px 24px 20px')],
    [T, always('24px')],
  ])};

  @media ${MEDIA.MOBILE} {
    padding: 18px;
  }
`

export const Header = styled.header`
  border-bottom: 1px solid ${({ theme }) => theme.colors.Light.color.neutral.n3};
  margin-bottom: 16px;
  padding-bottom: 16px;
`

export const IconWrapper = styled.div<IconWrapperProps>`
  margin-right: 10px;
  color: ${cond([
    [typeEq('info'), getColour(['blue', 'b6'])],
    [typeEq('success'), getColour(['green', 'g6'])],
    [typeEq('danger'), getColour(['red', 'r6'])],
    [typeEq('warn'), getColour(['orange', 'o6'])],
    [T, always('inherit')],
  ])};
`
