import { IOperator, OperatorContextFunction, pipe, when } from 'overmind'
import { findIndex, isNotNil, propEq, zip } from 'ramda'

import { whenPropIs } from 'store/operators'
import { parseProjectToName } from './helpers'

import type { Context } from 'store'
import type {
  AddProjectResult,
  CreateAccountOptions,
  CreateProjectOptions,
  CreateProjectTokenOptions,
  Files,
  FilesName,
  GetProjectNamesOptions,
  NewWifi,
  ParseTokenOptions,
  Plan,
  PutProjectOptions,
  UpdateProjectOptions,
  UploadFilesInput,
  WithMaybeProjectId,
  WithNames,
  WithProject,
  WithProjectId,
} from './types'

export const addProject = <A>({ effects }: Context, value: A): Promise<A & AddProjectResult> =>
  effects.projects.api
    .createProjectToken()
    .then(({ token }) => ({ ...value, path: `onboard/${token}` }))

export const checkTokenEmail = <A>(
  { state, effects }: Context,
  value: A,
): Promise<A & { exists: boolean }> =>
  effects.projects.api
    .checkAccountExists({ email: state.projects.tokenData?.clientEmail || '' })
    .then((data) => ({ ...value, ...data }))

export const createAccount = ({ effects }: Context, { account, token }: CreateAccountOptions) =>
  effects.projects.api.createAccountWithToken(token, account)

export const createProjectWithToken = (
  { effects }: Context,
  { project, token }: CreateProjectTokenOptions,
) => effects.projects.api.createProjectWithToken(token, project)

const doUploadFiles =
  (
    getUrls: (arr: Plan[]) => Promise<Files>,
    upload: (data: [Plan, FilesName]) => Promise<string>,
  ) =>
  (plans: Plan[], wifis: NewWifi[]) => {
    if (plans.length === 0) return wifis

    return getUrls(plans)
      .then(({ data }) => zip(plans, data))
      .then((data) => Promise.all(data.map(upload)))
      .then((data) =>
        wifis.map((item) => {
          if (!item.plan) return item
          return { ...item, plan: { ...item.plan, name: data.shift() as string } }
        }),
      )
  }

const extractFile = (item: NewWifi) => item.plan ?? []

export const extractFiles = <A extends CreateProjectOptions>(_: Context, payload: A) => {
  const indoor = payload.project.wifiIndoor.flatMap(extractFile)
  const outdoor = payload.project.wifiOutdoor.flatMap(extractFile)
  return { payload, indoor, outdoor }
}

export const getProfessionalUser = ({ state, effects }: Context) =>
  effects.projects.api
    .getProfessionalUser() //
    .then((data) => {
      state.projects.professionalUser = data
    })

export const getProject = <A extends WithProjectId>(
  { effects }: Context,
  value: A,
): Promise<A & WithProject> =>
  effects.projects.api.getProject(value).then((project) => ({ ...value, project }))

export const getProjectNames = <A>({ effects }: Context, value: A): Promise<A & WithNames> =>
  effects.projects.api
    .getProjects({ onlyNames: true }) //
    .then((names) => ({ ...value, names }))

export const parseToken = ({ state, effects }: Context, { token }: ParseTokenOptions) =>
  effects.projects.api
    .validateToken(token) //
    .then(({ data }) => {
      state.projects.tokenData = data
    })

export const redirectToLogin = ({ effects }: Context, { token }: ParseTokenOptions) =>
  effects.app.location.goToLogin(`onboard/${token}`)

export const passFirstProjectName = <A extends WithNames>(
  _: Context,
  value: A,
): A & WithMaybeProjectId => ({
  ...value,
  projectId: value.names.length === 0 ? null : value.names[0].id,
})

export const updateProject = <A extends PutProjectOptions>(
  { effects }: Context,
  value: A,
): Promise<A & UpdateProjectOptions> =>
  effects.projects.api
    .updateProject(value.projectId, value.project)
    .then((project) => ({ ...value, project }))

export const uploadFiles = <A extends CreateProjectOptions | CreateProjectTokenOptions>(
  { effects }: Context,
  { payload, indoor, outdoor }: UploadFilesInput<A>,
): Promise<A> => {
  const getUrls =
    'token' in payload && payload.token
      ? effects.projects.api.getUploadUrlsByToken(payload.token)
      : effects.projects.api.getUploadUrls
  const fun = doUploadFiles(getUrls, effects.projects.api.uploadFile)
  return Promise.all([
    fun(indoor, payload.project.wifiIndoor),
    fun(outdoor, payload.project.wifiOutdoor),
  ]).then(([wifiIndoor, wifiOutdoor]) => ({
    ...payload,
    project: { ...payload.project, wifiIndoor, wifiOutdoor },
  }))
}

export const updateProjectName = (
  { state }: Context,
  { project, projectId }: WithProject & WithProjectId,
) => {
  state.projects.names.hash[projectId] = parseProjectToName(project)
}

export const validateTokenEmail: IOperator<ParseTokenOptions, void> = pipe(
  checkTokenEmail,
  whenPropIs('exists', true, {
    true: redirectToLogin,
    false: () => undefined,
  }),
)

export const whenCurrentIsInProjects = <
  A extends GetProjectNamesOptions & WithNames,
  B = A,
>(paths: {
  true: OperatorContextFunction<A, B>
  false: OperatorContextFunction<A, B>
}) =>
  when(
    (_, { current, names }) => isNotNil(current) && findIndex(propEq(current, 'id'), names) > -1,
    paths,
  )
